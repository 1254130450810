/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 14:01:06 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-08-14 12:26:11
 */

import i18n from 'i18next';

const validations = () => ({
  email: [
    { validate: 'required', message: i18n.t('required_email') },
    {
      validate: (_, form) => form.email.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    { validate: 'email', message: i18n.t('invalid_email') },
  ],
  password: [
    { validate: 'required', message: i18n.t('password_required') },
    {
      validate: (_, form) => form.password.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, form) => {
        if (form.password.length < 8) {
          return false;
        }
        return true;
      },
      message: i18n.t('min_8_characters'),
      custom: true,
    },
  ],
});

export default validations;
