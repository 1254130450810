/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-24 11:47:20 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-02-12 19:57:40
 */
import { message } from 'antd';

const defaults = {
  duration: 5,
};

class Message {
  static error = (text, duration) => {
    message.error(text, duration || defaults.duration);
  };
  static success = (text, duration) => {
    message.success(text, duration || defaults.duration);
  };
  static warning = (text, duration) => {
    message.warning(text, duration || defaults.duration);
  };
  static default = (text, duration) => {
    message.info(text, duration || defaults.duration);
  };
}

export default Message;
