/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-22 21:52:10
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-25 11:21:14
 */
import {
  Card, Descriptions, Divider, Icon, Typography,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { RuleExecutionStatus } from '../../../constants';
import styles from './styles';
import './styles.css';

const { Title } = Typography;
const { Text } = Typography;

const capitalize = (word) => {
  if (word && word.length > 0) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
  return word;
};

const getCompletedRequired = (execution) => {
  if (execution.evidence && execution.evidence.completion) {
    return execution.evidence.completion.checklist
      .filter(i => i.evidenceType === 'required' && !!i.value).length;
  }

  return 0;
};

const getTotalRequired = (execution) => {
  if (execution.evidence && execution.evidence.completion) {
    return execution.evidence.completion.checklist
      .filter(i => !i.evidenceType || i.evidenceType === 'required').length;
  }

  return 0;
};

const getCompletedOptional = (execution) => {
  if (execution.evidence && execution.evidence.completion) {
    return execution.evidence.completion.checklist
      .filter(i => (i.evidenceType !== 'required' && (i.value || i.image))).length;
  }

  return 0;
};

const getTotalOptional = (execution) => {
  if (execution.evidence && execution.evidence.completion) {
    return execution.evidence.completion.checklist
      .filter(i => i.evidenceType !== 'required').length;
  }

  return 0;
};

const isExpired = (execution) => {
  const endTime = moment(execution.createdAt).add(execution.cron.duration, 'm');
  if (endTime < moment()) {
    return true;
  }
  return false;
};

const renderStatusIcon = (execution) => {
  if (execution.status === RuleExecutionStatus.DONE) {
    return (
      <Icon type="check-circle" theme="filled" style={{ color: '#31B550', fontSize: '25px' }} />
    );
  } else if (execution.status === RuleExecutionStatus.PENDING) {
    return (
      <Icon type="edit" theme="filled" style={{ color: '#CCCCCC', fontSize: '25px' }} />
    );
  } else if (execution.status === RuleExecutionStatus.CREATED) {
    if (isExpired(execution)) {
      return (
        <Icon type="close-circle" theme="filled" style={{ color: '#EA2B1F', fontSize: '25px' }} />
      );
    }

    return (
      <Icon type="minus-circle" theme="filled" style={{ color: '#d0ad30', fontSize: '25px' }} />
    );
  }

  return null;
};

const TypeDescriptionName = (name, execution) => {
  switch (name) {
    case 'Requeridas':
      return <b> <Icon type="like" /> {getCompletedRequired(execution)} de {getTotalRequired(execution)} </b>;
    case 'Opcionales':
      return <b> <Icon type="like" /> {getCompletedOptional(execution)} de {getTotalOptional(execution)} </b>;
    case 'Fecha':
      return <b> {moment(execution.createdAt).format('DD/MM/YYYY HH:mm')}h </b>;
    case 'Sucursal':
      return <b> {capitalize(execution.branchOfficeName)} </b>;
    case 'Rol':
      return <b>  {capitalize(execution.roleName)} </b>;
    case 'Usuario':
      return <b> {capitalize(execution.username)} </b>;
    default: return <>{ name && '' }</>;
  }
};

const descriptionName = [
  { name: 'Requeridas' },
  { name: 'Opcionales' },
  { name: 'Fecha' },
  { name: 'Sucursal' },
  { name: 'Rol' },
  { name: 'Usuario' },
];

const CompleteInfoCardList = ({
    execution,
    translate,
  }) => {
const t = translate;
    return (
      <div className="site-card-border-less-wrapper">
        <Card
          style={styles.borders}
          hoverable="true"
        >
          <Title
            level={4}
          >{renderStatusIcon(execution)}&nbsp;{capitalize(execution.nameRegister)}
          </Title>
          <Text type="secondary" strong>{t('original_activity')}:&nbsp;{capitalize(capitalize(execution.name))}</Text>
          <Divider />
          <Descriptions>
            {
              descriptionName.map(r => (
                <Descriptions.Item
                  key={r.name}
                  label={r.name}
                >
                  {
                    TypeDescriptionName(r.name, execution)
                  }
                </Descriptions.Item>
              ))
            }
          </Descriptions>
        </Card>
      </div>
    );
};

export default CompleteInfoCardList;
