/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:46:03 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-02-05 15:58:37
 */
const CONTAINER_KEY = 'USER_CONTAINER';
export const UPDATE_USER = `${CONTAINER_KEY}_UPDATE_USER`;
export const UPDATE_USER_FULFILLED = `${UPDATE_USER}_FULFILLED`;
export const FETCH_USERS_BY_BRANCH_OFFICE = `${CONTAINER_KEY}_FETCH_USERS_BY_BRANCH_OFFICE`;
export const FETCH_USERS_BY_ROLE = `${CONTAINER_KEY}_FETCH_USERS_BY_ROLE`;
export const FETCH_USERS_BY_PERMISSION = `${CONTAINER_KEY}_FETCH_USERS_BY_PERMISSION`;
export const REGISTER_USER = `${CONTAINER_KEY}_REGISTER_USER`;
export const REGISTER_USER_FULFILLED = `${REGISTER_USER}_FULFILLED`;
export const SEND_REGISTER_MAIL = `${CONTAINER_KEY}_SEND_REGISTER_MAIL`;
export const DELETE_USER = `${CONTAINER_KEY}_DELETE_USER`;
export const SET_FILTER_VALUE = `${CONTAINER_KEY}_SET_FILTER_VALUE`;
