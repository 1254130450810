/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:28:07
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-12-18 08:51:01
 */
import i18n from 'i18next';
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { Notification } from '../../../common/components/widgets';
import { parseApiError } from '../../../common/error';
import ExecutionService from '../../../services/execution';
import ReportService from '../../../services/report';
import { actions } from './index';
import { DELETE_EXECUTION, DOWNLOAD_REPORT } from './types';


const reportService = new ReportService();
const ruleExecutionService = new ExecutionService();

const executeCallbackAction = (callback = () => null, response) => {
  callback(response);
  return { type: '' };
};

const deleteSuccessAction = (execution) => {
  Notification.success(i18n.t('success'), `La evidencia ${execution} ${i18n.t('deleted_success')}`, 4);
  return { type: '' };
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const downloadReportEpic = action$ =>
  action$.ofType(DOWNLOAD_REPORT).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(reportService.downloadReport(action.reportId, action.params))
        .flatMap(() =>
          Observable.concat(Observable.of(actions.loading(false))))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(async () => {
              const parsedError = await parseApiError(error, true);
              Notification.error(parsedError.message);
            })),
          )),
    ));

const deleteExecutionEpic = action$ =>
action$.ofType(DELETE_EXECUTION).flatMap(action =>
  Observable.concat(
    Observable.of(actions.loading(true)),
    Observable.fromPromise(ruleExecutionService.deleteExecution(action.payload.id))
      .flatMap(response =>
        Observable.concat(
          Observable.of(actions.loading(false)),
          Observable.of(deleteSuccessAction(response)),
          Observable.of(executeCallbackAction(() => action.payload.history.push('/evidences'))),
        ))
      .catch(error =>
        Observable.concat(
          Observable.of(actions.loading(false)),
          Observable.of(actions.showMessage(() => {
            const parsedError = parseApiError(error);
            errorMessage(parsedError);
          })),
        )),
  ));

export default combineEpics(downloadReportEpic, deleteExecutionEpic);
