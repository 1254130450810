/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-12 02:12:26 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-07-12 15:34:26
 */
import { orderBy, omit } from 'lodash';

const formatData = (branchOffices) => {
  const data = branchOffices.map(item => omit(item, ['editable']));
  return orderBy(data, ['createdAt'], ['desc']);
};

export default formatData;
