/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-22 19:41:41
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-25 11:10:29
 */
export default {
  borders: {
    marginBottom: 8, borderRadius: 9, border: 1.8, borderStyle: 'solid', borderColor: '#cecece',
  },
  tag1: {
    margin: 0, borderRadius: 9, float: 'left', flex: 1,
  },
  tag2: {
    margin: 0, borderRadius: 9, float: 'right', flex: 1,
  },
};
