/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-20 15:40:53 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-03-26 13:53:36
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  FETCH_TOKEN, FETCH_TOKEN_SUCCESSFUL, REGISTER, REGISTER_SUCCESSFUL, REGISTER_SUCCESSFUL_RESET,
  RESET_PASSWORD, RESET_PASSWORD_SUCCESSFUL, RESETING_PASSWORD,
  FETCH_COMPANY_AVAILABILITY,
  FETCH_COMPANY_AVAILABILITY_FULFILLED,
} from './types';

const defaultState = {
  reducerKey: 'registerContainer',
  formErrors: {},
  successFetchToken: {
    user: '',
  },
  isLoading: true,
  form: {
    password: '',
    newPassword: '',
  },
  companyForm: {
    companyName: '',
    name: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    phoneNumber: '',
    terms: false,
  },
  companyForm2: {
    companyName: '',
    name: '',
    lastName: '',
    email: '',
    password: '',
    password2: '',
    phoneNumber: '',
    terms: false,
  },
  companyAvailability: {
    value: true,
    fulfilled: false,
  },
  companyFormErrors: {},
  resetPassworsSucessful: {
    reset: false,
  },
  loadingButton: false,
  recapthaNotverified: true,
};

// Actions
// Payload { form, defaultActions, navigate }
const fetchToken = token => ({ type: FETCH_TOKEN, token });
const fetchTokenSucess = payload => ({ type: FETCH_TOKEN_SUCCESSFUL, payload });
const resetPassword = payload => ({ type: RESET_PASSWORD, payload });
const resetPasswordSuccess = payload => ({ type: RESET_PASSWORD_SUCCESSFUL, payload });
const resetingPassword = payload => ({ type: RESETING_PASSWORD, payload });
const register = payload => ({ type: REGISTER, payload });
const registerSuccessful = payload => ({ type: REGISTER_SUCCESSFUL, payload });
const registerSuccessfulReset = payload => ({ type: REGISTER_SUCCESSFUL_RESET, payload });
const fetchCompanyAvailability = companyName => ({ type: FETCH_COMPANY_AVAILABILITY, companyName });
const fetchCompanyAvailabilityFulfilled = payload =>
  ({ type: FETCH_COMPANY_AVAILABILITY_FULFILLED, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_TOKEN_SUCCESSFUL]: (state, payload) => update(state, { path: 'successFetchToken', value: payload }),
  [RESET_PASSWORD_SUCCESSFUL]: (state, payload) => update(state, { path: 'resetPassworsSucessful', value: payload }),
  [RESETING_PASSWORD]: (state, payload) => update(state, { path: 'loadingButton', value: payload }),
  [FETCH_COMPANY_AVAILABILITY_FULFILLED]: (state, payload) => update(state, { path: 'companyAvailability', value: { value: payload, fulfilled: payload } }),
  [REGISTER_SUCCESSFUL_RESET]: state => update(state, { path: 'companyForm', value: { ...state.companyForm2 } }),
});

const actions = {
  ...defaultActions,
  fetchToken,
  fetchTokenSucess,
  resetPassword,
  resetPasswordSuccess,
  resetingPassword,
  register,
  fetchCompanyAvailability,
  fetchCompanyAvailabilityFulfilled,
  registerSuccessful,
  registerSuccessfulReset,
};

export { reducer, actions, epics };
