/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:39:38
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-06-03 12:47:10
 */
import React from 'react';
import moment from 'moment';
import { Row, Col, DatePicker, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.css';

const DashboardHeader = ({
  filters,
  branchOffices,
  onChangeDate,
  onChangeFilter,
}) => {
  const { t } = useTranslation();

  return (
    <Row className="dashboard-header">
      <Col sm={24} lg={12} xl={12}>
        <div className="dashboard-header__title">{t('dashboard')}</div>
      </Col>
      <Col sm={24} lg={6} xl={6}>
        <Select
          value={filters.branchOfficeId}
          className="select-data"
          onChange={val => onChangeFilter('branchOfficeId', val)}
        >
          <Select.Option value={null}>{t('all_offices')}</Select.Option>
          {
            branchOffices.map(i => (
              <Select.Option value={i.id} key={i.id}>{i.name}</Select.Option>
            ))
          }
        </Select>
      </Col>
      <Col sm={24} lg={6} xl={6}>
        <DatePicker.RangePicker
          value={[moment(filters.initDate), moment(filters.endDate)]}
          disabledDate={current => current > moment().endOf('day')}
          onChange={(val) => {
            onChangeDate(
              val[0] ? val[0].valueOf() : moment().valueOf(),
              val[1] ? val[1].valueOf() : moment().valueOf(),
            );
          }}
          format="YYYY/MM/DD"
        />
      </Col>
    </Row>
  );
};

export default DashboardHeader;
