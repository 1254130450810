import { Button, Col, Icon, Row, Modal } from 'antd';
import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Map, Marker, TileLayer } from 'react-leaflet';
import Control from 'react-leaflet-control';
import { Link } from 'react-router-dom';
import noLocation from '../../../../assets/images/noLocation.svg';
import { RuleTypes } from '../../../constants';
import { getFormattedFolio } from '../../../helpers/functions';
import { GeneralButton as Buttons } from '../../widgets';
import './styles.css';
import { isAllowed } from '../../../../security';

const { confirm } = Modal;

const showDeleteConfirm = (deleteExecution, history, execution) => {
  confirm({
    title: '¿Seguro que desea eliminar esta evidencia?',
    icon: <Icon type="warning" theme="filled" style={{ color: '#FF3333' }} />,
    content: 'La evidencia que intenta eliminar puede que este enlazada',
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk() {
      deleteExecution({ id: execution.id, history });
    },
    onCancel() {

    },
  });
};

const renderStatusIcon = (type) => {
  switch (type) {
    case 'done':
      return <Icon type="check-circle" theme="filled" style={{ color: '#31B550' }} />;
    case 'pending':
      return <Icon type="form" style={{ color: '#999999' }} />;
    case 'expired':
      return <Icon type="close-circle" style={{ color: '#D54C21' }} />;
    default:
      return <Icon type="info-circle" style={{ color: '#D5B821' }} />;
  }
};

const getPosition = (execution) => {
  if (execution.evidence && execution.evidence.location) {
    const { location } = execution.evidence;
    return [location.latitude, location.longitude];
  }
  return [0, 0];
};

const getComments = (execution) => {
  if (execution.evidence && execution.evidence.comments) {
    return execution.evidence.comments;
  }
  return i18n.t('no_comments');
};

const getMapUrl = p => (
  `https://www.openstreetmap.org/?mlat=${p[0]}&mlon=${p[1]}#map=19/${p[0]}/${p[1]}&layers=N`
);

const changeDownload = (id, t) => {
  switch (id) {
    case '7611e93f-03ed-e238-6706-5caeadbd50bc':
      return t('Recibo de pago');

    case '13630f8b-a3fd-b907-e42c-e2b1cd55e469':
      return t('Descargar contrato');

    default:
      return t('Reporte');
  }
};

const evidenceHeader = ({
  execution,
  status,
  enableTracking,
  downloadReport,
  deleteExecution,
  history,
}) => {
  const position = getPosition(execution);
  const comments = getComments(execution);
  const { t } = useTranslation();

  const canRemoveEvidences = isAllowed(['can_remove_evidences']);
  const canEditRuleExecutions = isAllowed(['can_edit_rule_executions']);

  return (
    <Row className="evidence-header">
      <Row className="evidence-header-title">
        <Col xs={24} sm={14} lg={16} xl={18}>
          <Col sm={24} lg={24}>
            {renderStatusIcon(status)}&nbsp;&nbsp;{`${getFormattedFolio(execution.folio)}${execution.nameRegister}`}
            <p
              className="rule-name"
            >
              {`${t('original_activity')}: ${execution.name}`}
            </p>
            {
              execution.ruleType === RuleTypes.WEB &&
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 'normal',
                  color: '#727272',
                  paddingLeft: '40px',
                }}
              >
                {t('public_form')}
              </p>
            }
          </Col>
          {
            execution.ruleType !== RuleTypes.WEB &&
            <div>
              <Col xs={12} sm={12} lg={12} xl={8} className="evidence-section">
                <b>{t('branch_office')}:</b><br />{execution.branchOfficeName}
              </Col>
              <Col xs={12} sm={12} lg={12} xl={8} className="evidence-section">
                <b>{t('role')}:</b><br />{execution.roleName || t('no_role')}
              </Col>
              <Col xs={12} sm={12} lg={12} xl={8} className="evidence-section">
                <b>{t('date_time')}:</b><br />
                {execution.completedAt ?
                  moment(execution.completedAt).format('DD/MM/YYYY - hh:mm a')
                  : '-----'
                }
              </Col>
              <Col xs={12} sm={12} lg={12} xl={8} className="evidence-section">
                <b>{t('user')}:</b><br />{execution.username ? execution.username : t('user_deleted_legend')}
              </Col>
              <Col xs={12} sm={12} lg={12} xl={8} className="evidence-section">
                <b>{t('activity')}:</b><br />{execution.internal ? t('private') : t('public')}
              </Col>
              <Col xs={12} sm={12} lg={12} xl={8} className="evidence-section">
                <b>{t('comments')}:</b><br />{comments}
              </Col>
            </div>
          }
          <Col sm={24} lg={24}>
            <div className="evidence-section">
              {
              enableTracking &&
                <Link to={`/evidences/${execution.id}/timeline`}>
                  <Buttons
                    name={t('see_historic')}
                    type="primary"
                    icon="history"
                  />
                </Link>
              }
              {
              execution.reportIds && execution.reportIds.length &&
              execution.reportIds.map(id => (
                <Buttons
                  onClick={() => downloadReport(id, {
                    ruleExecutionId: execution.id,
                  })}
                  type="primary"
                  icon="download"
                  name={changeDownload(id, t)}
                />
              ))
              }
              {
                execution.ruleType === RuleTypes.NON_SCHEDULED && !!canEditRuleExecutions &&
                <Link to={`/web-form-execution/${execution.id}`}>
                  <Buttons
                    name={t('edit_evidence')}
                    type="primary"
                    icon="edit"
                    disabled={!canEditRuleExecutions}
                  />
                </Link>
              }
              {
                !!canRemoveEvidences &&
                  <Buttons
                    name={t('remove_evidence')}
                    type="danger"
                    icon="delete"
                    disabled={!canRemoveEvidences}
                    onClick={() => showDeleteConfirm(deleteExecution, history, execution)}
                  />
              }
            </div>
          </Col>
        </Col>
        {
          execution.ruleType !== RuleTypes.WEB &&
          <Col xs={24} sm={10} lg={8} xl={6}>
            {
              execution.evidence && execution.evidence.location ?
                <Map center={position} zoom={17}>
                  <TileLayer
                    attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                    url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={position} />
                  <Control position="topright">
                    <Button
                      icon="fullscreen"
                      onClick={() => window.open(getMapUrl(position), '_blank')}
                    />
                  </Control>
                </Map> :
                <img
                  src={noLocation}
                  alt=""
                  style={{
                    border: '1px solid black',
                    maxHeight: '200px',
                    maxWidth: '100%',
                  }}
                />
            }
          </Col>
        }
        <Col>
          {}
        </Col>
      </Row>
    </Row>
  );
};

export default evidenceHeader;
