/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-15 23:34:38 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-05-28 12:06:02
 */
import { createFilesServiceUrl } from './service';

const getImageUrl = id => id && createFilesServiceUrl(`/api/files/${id}`);

export { getImageUrl };
