/*
 * @Author: Diana Resendiz
 * @Date: 2021-11-20 01:05:53
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-08 15:16:38
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';

import {
    FETCH_NOTIFICATIONS_FULFILLED,
    FETCH_USERS_FULFILLED,
    FETCH_EXECUTIONS_FULFILLED,
} from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';

const defaultFilters = {
  name: null,
  search: null,
  size: 10,
  sort: 'type,asc',
  totalElements: 0,
  page: 0,
  ruleExecutedId: '',
  userExecutedId: '',
  userNotifyId: '',
  type: '',
  sendBy: '',
  createdAt: '',
};

const defaultState = {
  reducerKey: 'notificationsContainer',
  catalogs: {
    notifications: [],
    executions: [],
    users: [],
  },
 filters: { ...defaultFilters },
 currentPage: 1,
};

// Actions
// Payload { form, defaultActions }

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_NOTIFICATIONS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.notifications', value: payload }),
  [FETCH_EXECUTIONS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.executions', value: payload }),
  [FETCH_USERS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.users', value: payload }),
});

const actions = {
  ...defaultActions,
  ...generalActions,
};

export { reducer, actions, epics, defaultActions };
