/*
 * @Author: Jhony Reyes 
 * @Date: 2018-09-24 17:21:04 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-09-24 17:28:47
 */

const initActions = (props, defaultActions) => {
  const { fetchRoles, updateState, formReset } = props;
  updateState({ path: 'creating', value: false });
  updateState({ path: 'newForm', value: { ...formReset } });
  updateState({ path: 'formErrors', value: {} });
  updateState({ path: 'errorForm', value: false });
  fetchRoles(defaultActions);
};

export default initActions;
