/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 15:57:29 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-08-05 18:52:24
 */

import i18n from 'i18next';

import { renderColumn } from '../../../common/components/widgets/table';

const columnTypes = {
  AVATAR: 'profileImageUrl',
  NAME: 'name',
  LASTNAME: 'lastName',
  EMAIL: 'email',
  PERMISSIONS: 'permissions',
  ROLE: 'role',
  BRANCHOFFICE: 'branchOffice',
  ACTIVE: 'active',
  ACTIONS: 'actions',
  ANALYTICS: 'tableauUsername',
};

const columns = () => [
  renderColumn(i18n.t('avatar'), columnTypes.AVATAR, 'avatar', 90),
  renderColumn(i18n.t('name'), columnTypes.NAME, 'input', 150, 100, true),
  renderColumn(i18n.t('lastnames'), columnTypes.LASTNAME, 'input', 150, 100, true),
  renderColumn('Email', columnTypes.EMAIL, 'input', 180, 100, true),
  renderColumn(i18n.t('roles'), columnTypes.ROLE, 'select', 200),
  renderColumn(i18n.t('branch_office'), columnTypes.BRANCHOFFICE, 'select', 150),
  renderColumn(i18n.t('active'), columnTypes.ACTIVE, 'check', 90),
  renderColumn(i18n.t('analytics_licence'), columnTypes.ANALYTICS, 'analytics', 90),
  renderColumn(i18n.t('actions'), columnTypes.ACTIONS, 'actions', 150),
];

export { columns, columnTypes };
