/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 11:09:48 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-05-08 11:56:03
 */
import axios from 'axios';
import Service from '../Service';
import { createMailServiceUrl, parseParams } from '../../common/helpers/service';

class WhatsappCustomerService extends Service {
    getByCompanyId = companyId => axios.get(createMailServiceUrl(`whatsapp-customers${parseParams({ companyId })}`))
      .then(data => data.data);
}

export default WhatsappCustomerService;
