/*
 * @Author: Misael Jiménez 
 * @Date: 2020-07-09 17:18:45 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-14 22:57:08
 */

const CONTAINER_KEY = 'ACTIVITY_FORM_CONTAINER';

export const UPDATE_FORM = `${CONTAINER_KEY}_UPDATE_FORM`;
export const UPDATE_USER = `${CONTAINER_KEY}_UPDATE_USER`;
export const UPDATE_USER_FULFILLED = `${CONTAINER_KEY}_UPDATE_USER_FULFILLED`;
export const UPDATE_USER_WITH_PASSWORD = `${CONTAINER_KEY}_UPDATE_USER_WITH_PASSWORD`;
export const UPDATE_USER_IMAGE = `${CONTAINER_KEY}_UPDATE_USER_IMAGE`;
export const UPDATE_COMPANY_LOGO_IMAGE = `${CONTAINER_KEY}_UPDATE_COMPANY_LOGO_IMAGE`;
export const UPDATE_COMPANY_HEADER_IMAGE = `${CONTAINER_KEY}_UPDATE_COMPANY_HEADER_IMAGE`;
export const FETCH_WEB_HOOKS = `${CONTAINER_KEY}_FETCH_WEB_HOOKS`;
export const FETCH_WEB_HOOKS_FULFILLED = `${CONTAINER_KEY}_FETCH_WEB_HOOKS_FULFILLED`;
export const CREATE_WEB_HOOK = `${CONTAINER_KEY}_CREATE_WEB_HOOK`;
export const CREATE_WEB_HOOK_FULFILLED = `${CONTAINER_KEY}_CREATE_WEB_HOOK_FULFILLED`;
export const UPDATE_WEB_HOOK = `${CONTAINER_KEY}_UPDATE_WEB_HOOK`;
export const UPDATE_WEB_HOOK_FULLFILLED = `${CONTAINER_KEY}_UPDATE_WEB_HOOK_FULLFILLED`;
export const DELETE_WEB_HOOK = `${CONTAINER_KEY}_DELETE_WEB_HOOK`;
