/*
 * @Author: Jhony Reyes 
 * @Date: 2019-03-04 14:29:49 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-03-15 13:15:51
 */

import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import { withTranslation } from 'react-i18next';

class CustomToolbar extends Toolbar {
  render() {
    const { t } = this.props;

    return (
      <div>
        <div className="rbc-toolbar">
          {this.props.view === 'day' &&
          <span className="rbc-btn-group">
            <button type="button" onClick={() => this.props.onNavigate('PREV')}>{t('back')}</button>
            <button type="button" onClick={() => this.props.onNavigate('NEXT')}>{t('next')}</button>
          </span>}
          <span className="rbc-toolbar-label">{this.props.label}</span>
          <span className="rbc-btn-group">
            <button type="button" onClick={() => this.view('week')}>{t('week')}</button>
            <button type="button" onClick={() => this.view('day')}>{t('day')}</button>
            <button type="button" onClick={() => this.view('agenda')}>{t('agenda')}</button>
          </span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(CustomToolbar);

