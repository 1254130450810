/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 17:09:03
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';

import { actions } from './index';
import { FETCH_ORDERS } from './types';
import PaymentService from '../../../services/payment';
import { Notification } from '../../../common/components/widgets';

const paymentService = new PaymentService();

const errorFetchCompanies = () => {
  Notification.error('No se pudo cargar la lista de pagos.');
};

const fetchCompaniesEpic = action$ =>
  action$.ofType(FETCH_ORDERS).flatMap(() =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(paymentService.getOrders())
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchOrdersFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              errorFetchCompanies();
            })),
          )),
    ));


export default combineEpics(fetchCompaniesEpic);
