/*
 * @Author: Cesar Medina
 * @Date: 2018-07-23 10:01:58
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-27 13:04:29
 */

import React, { Component } from 'react';
import { Table, Button, Row } from 'antd';
import { init, getFieldError } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';
import { omit } from 'lodash';
import i18next from 'i18next';
import Text from 'antd/lib/typography/Text';
import EditableCell from '../editableCell';
import { Error } from '../../layout';
import { RenderActionsCell, RenderCheckCell, RenderSelectCell } from '../table';
import { WebHookTriggerTypes } from '../../../constants';

const formatForm = (form) => {
  const formatedForm = omit(form, ['id', 'editable']);
  return formatedForm;
};

const formatFormEdit = (form) => {
  const formatedForm = omit(form, ['editable']);
  return formatedForm;
};


const renderColumn = (title, dataIndex, type, width, maxLength, required) =>
  ({
    title, dataIndex, type, width, maxLength, required,
  });

const columns = () => [
  renderColumn(i18next.t('url'), 'url', 'input', 150, 1000, true),
  renderColumn(i18next.t('bearer_token'), 'token', 'input', 150, 10000, false),
  renderColumn(i18next.t('activity'), 'ruleId', 'ruleId', 260, 100, false),
  renderColumn(i18next.t('trigger_action'), 'triggerTypes', 'triggerTypes', 260, 100, false),
  renderColumn(i18next.t('actions'), 'actions', 'actions', 125),
];

const validations = () => ({
  url: [
    { validate: 'required', message: i18next.t('required') },
    {
      validate: (_, form) => !!form.url.trim().length,
      message: i18next.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  ruleId: [
    { validate: 'required', message: i18next.t('required') },
  ],
});

const getWebHookTriggerTypesLabel = (triggerType) => {
  switch (triggerType) {
    case WebHookTriggerTypes.ON_CREATE:
      return i18next.t('on_create_activity');
    case WebHookTriggerTypes.ON_UPDATE:
      return i18next.t('on_update_activity');
    default:
      return i18next.t('error');
  }
};
class TechnicalSettingsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchRule: null,
    };
    this.validator = init(validations());
    this.dataColumns = this.getDataColumns();
    this.timer = null;
  }

  componentDidMount = () => {
    this.fetchRulesByName();
    this.props.fetchWebHooks();
  };

  onSearchRules = (value) => {
    this.setState({ searchRule: value });
    clearTimeout(this.timer);
    if (value) {
      this.timer = setTimeout(() => this.fetchRulesByName({
        name: this.state.searchRule,
      }), 250);
    } else { this.fetchRulesByName(); }
  }

  getDataColumns = () => columns().map((item) => {
    if (item.type === 'input') {
      return ({
        ...item,
        render: (text, record) =>
          this.renderInputCell(
            text, record, item.dataIndex, item.maxLength,
            item.required, item.title, item.width,
          ),
      });
    }
    if (item.type === 'ruleId') {
      return ({
        ...item,
        onCell: () => ({ 'data-label': item.title }),
        render: (text, record) => {
          const { catalogs: { rules: { content: rules } }, notAsign } = this.props;
          const insertCurrentRule = record.rule &&
            rules.findIndex(r => r.id === record.rule.id) < 0;
          const data = insertCurrentRule ? [record.rule, ...rules] : rules;
          const target = data.find(rule => record.ruleId === rule.id);
          const selected = target ? { ...target } : record.ruleId;
          return (<RenderSelectCell
            selected={record.ruleId ? selected : notAsign}
            data={data}
            record={record}
            onSelect={value => this.handleChangeForm(value, record.id, 'ruleId')}
            onSearch={this.onSearchRules}
            error={this.props.errorForm}
            errorMessage={getFieldError(this.props.formErrors, item.dataIndex)}
          />);
        },
      });
    }
    if (item.type === 'triggerTypes') {
      return ({
        ...item,
        onCell: () => ({ 'data-label': item.title }),
        render: this.triggerTypesCell,
      });
    }
    return ({
      ...item,
      render: (text, record) => (<RenderActionsCell
        record={record}
        onCancel={this.handleCancel}
        onSave={() => this.save(record.id)}
        onEdit={() => this.handleEdit(record.id)}
        creating={this.props.creating}
        onDelete={() => this.handleDelete(record.id)}
      />),
    });
  });

  triggerTypesCell = (text, record) => {
    const triggerTypesArray = record.triggerTypes.trim() ? record.triggerTypes.split(',') : [];
    return (
      <div>
        {
          Object.values(WebHookTriggerTypes).map((triggerType) => {
            const checked = triggerTypesArray.findIndex(v => v === triggerType) > -1;
            return (
              <Row>
                <RenderCheckCell
                  record={record}
                  text={checked}
                  onChange={(value) => {
                    let newTriggerTypesArray = [];
                    if (value.target.checked) {
                      newTriggerTypesArray = [
                        ...triggerTypesArray,
                        triggerType,
                      ];
                    } else {
                      newTriggerTypesArray = triggerTypesArray.filter(v => triggerType !== v);
                    }
                    this.handleChangeForm(newTriggerTypesArray.join(','), record.id, 'triggerTypes');
                  }}
                />
                <Text>
                  {getWebHookTriggerTypesLabel(triggerType)}
                </Text>
              </Row>
            );
          })
        }
      </div>
    );
  }

  fetchRulesByName(params) {
    const { fetchRulesByName, catalogs: { rules: { size } } } = this.props;
    fetchRulesByName({
      page: 0,
      size,
      ...params,
    });
  }

  handleAdd = () => {
    const { newWebHook, updateState, catalogs: { webHooks } } = this.props;

    updateState({ path: 'catalogs.webHooksEdit', value: webHooks });
    updateState({ path: 'catalogs.webHooks', value: [newWebHook, ...webHooks] });
    updateState({ path: 'creating', value: true });
  }

  handleCancel = () => {
    const { catalogs: { webHooksEdit }, updateState, resetWebHook } = this.props;
    updateState({ path: 'catalogs.webHooks', value: webHooksEdit });
    updateState({ path: 'creating', value: false });
    updateState({ path: 'newWebHook', value: { ...resetWebHook } });
    updateState({ path: 'errorForm', value: false });
    updateState({ path: 'formErrors', value: {} });
  }

  handleChangeForm(value, id, column) {
    const { updateState, catalogs: { webHooks } } = this.props;
    const newData = [...webHooks];
    const target = newData.find(item => id === item.id);
    if (target) {
      target[column] = value;
      updateState({ path: 'catalogs.webHooks', value: newData });
    }
  }

  handleEdit(id) {
    const { updateState, catalogs: { webHooks } } = this.props;
    updateState({ path: 'catalogs.webHooksEdit', value: webHooks });
    const newData = [...webHooks];
    const target = newData.map((item) => {
      if (id === item.id) {
        return ({ ...item, editable: true });
      }
      return item;
    });
    updateState({ path: 'catalogs.webHooks', value: target });
    updateState({ path: 'creating', value: true });
  }

  handleDelete(id) {
    const { deleteWebHook, catalogs: { webHooks } } = this.props;
    const target = [...webHooks].find(item => id === item.id);
    if (target) {
      deleteWebHook({ form: target });
    }
  }

  save(id) {
    const { createWebHook, updateWebHook, catalogs: { webHooks } } = this.props;
    const target = [...webHooks].find(item => id === item.id);
    if (target) {
      const { formValid, valid } = this.validateFormFunction(target);
      if (valid) {
        if (target.id === -1) {
          createWebHook({ form: formValid });
        } else {
          updateWebHook({ form: formValid });
        }
      }
    }
  }

  validateFormFunction = (target) => {
    const formValid = target.id === -1 ? formatForm(target) : formatFormEdit(target);
    const { valid, errors } = this.validator.validateForm(formValid);
    const { updateState } = this.props;
    if (!valid) {
      updateState({ path: 'formErrors', value: errors });
      updateState({ path: 'errorForm', value: true });
    }
    return ({ formValid, valid });
  }

  renderInputCell(text, record, column, maxLength, required, placeholder, maxWidth = null) {
    return (
      <Row>
        <EditableCell
          editable={record.editable}
          value={text}
          maxLength={maxLength}
          error={this.props.errorForm}
          errorMessage={getFieldError(this.props.formErrors, column)}
          onChange={value => this.handleChangeForm(value, record.id, column)}
          required={required}
          placeholder={placeholder}
          maxWidth={maxWidth}
        />
      </Row>
    );
  }

  render() {
    const {
      error,
      isLoading,
      catalogs: { webHooks },
      creating,
      t,
    } = this.props;

    if (error) { return (<Error error={error} />); }

    return (
      <div>
        <Button type="primary" onClick={this.handleAdd} disabled={creating}>{t('add_web_hook')}</Button>
        <Table
          rowKey="id"
          dataSource={webHooks}
          columns={this.dataColumns}
          locale={{ emptyText: t('show_no_data') }}
          loading={isLoading}
        />
      </div>
    );
  }
}

export default withTranslation()(TechnicalSettingsForm);
