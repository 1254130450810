/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 15:57:29 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 17:14:21
 */

import i18n from 'i18next';

import { renderColumn } from '../../common/components/widgets/table';

const columns = () => [
      renderColumn(i18n.t('state'), 'state', 'select', 170, undefined, true),
      renderColumn(i18n.t('city'), 'city', 'input', 160, 100, true),
      renderColumn(i18n.t('name'), 'name', 'input', 260, 100, true),
      renderColumn(i18n.t('number'), 'numberExt', 'input', 120, 10, true),
      renderColumn(i18n.t('post_code'), 'postalCode', 'input', 130, 10, true),
      renderColumn(i18n.t('activ_fem'), 'active', 'check', 70),
      renderColumn(i18n.t('responsible'), 'manager', 'select', 125),
      renderColumn(i18n.t('actions'), 'actions', 'actions', 125),
    ];

export default columns;
