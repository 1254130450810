/*
 * @Author: Cesar Medina
 * @Date: 2019-07-02 14:41:21
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-12-18 08:50:09
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Row, Col, Modal, Tabs, Table, Icon, Tooltip, Timeline, Empty, Button } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import { isEmpty } from 'lodash';
import Paragraph from 'antd/lib/typography/Paragraph';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { actions } from './redux';
import { EvidenceHeader, PopoverList, TasksGrid } from '../../common/components/widgets';
import { Loading } from '../../common/components/layout';
import { RuleExecutionStatus, EvaluationTypes, RuleTypes, TaskTypes } from '../../common/constants';
import { evidenceTypeLabel, getTypeIcon, taskTypeLabel } from '../../common/labels';
import { getImageUrl } from '../../common/helpers/images';
import TasksFilters from './tasksFilters';
import { filenameIsImage, getFormattedFolio } from '../../common/helpers/functions';
import SimpleUserInfo from '../../common/components/widgets/simpleUserInfo';

const { TabPane } = Tabs;

class TasksEvidences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewImage: '',
      modalImage: false,
      modalDescription: false,
      textDescription: '',
      filters: {
        type: null,
        required: null,
      },
      viewTasksAsList: false,
    };
  }

  componentDidMount = () => {
    const { fetchExecutionById, match } = this.props;

    fetchExecutionById(match.params.id);
  };

  onChangeFilter = (filter, value) => this.setState({
    filters: {
      ...this.state.filters,
      [filter]: value,
    },
  })

  getColumns = () => (
    [
      {
        title: '',
        key: 'status',
        onCell: () => ({ 'data-label': 'Estatus' }),
        render: (text, record) => {
          const isDone = !!record.value;
          return (
            <Icon
              type={getTypeIcon(record.type)}
              className={`task-type__icon ${isDone ? 'task-type__icon--blue' : ''}`}
            />
          );
        },
      },
      {
        title: this.props.t('name'),
        dataIndex: 'name',
        key: 'name',
        onCell: () => ({ 'data-label': this.props.t('name') }),
        render: (text, record) => this.renderTaskName(record),
      },
      {
        title: this.props.t('description'),
        dataIndex: 'description',
        key: 'description',
        onCell: () => ({ 'data-label': this.props.t('description') }),
        render: text => (text || this.props.t('without_description')),
      },
      {
        title: this.props.t('group_name'),
        dataIndex: 'groupInfo',
        key: 'group_name',
        onCell: () => ({ 'data-label': this.props.t('group_name') }),
        render: (text, record) => this.renderTaskGroupName(record),
      },
      {
        title: this.props.t('value'),
        dataIndex: 'value',
        key: 'value',
        onCell: () => ({ 'data-label': this.props.t('value') }),
        render: (text, record) => (
          <Row>
            {this.renderValue(text, record)}
          </Row>
          ),
      },
      {
        title: this.props.t('required'),
        dataIndex: 'evidenceType',
        key: 'evidenceType',
        onCell: () => ({ 'data-label': this.props.t('required') }),
        render: evidenceTypeLabel,
        className: 'table__td--gray table__td--border-left',
      },
      {
        title: this.props.t('type'),
        dataIndex: 'type',
        key: 'type',
        onCell: () => ({ 'data-label': this.props.t('type') }),
        render: taskTypeLabel,
        className: 'table__td--gray',
      },
      {
        title: this.props.t('grade'),
        key: 'rate',
        onCell: () => ({ 'data-label': this.props.t('grade') }),
        render: (text, record) => this.renderTaskRate(record),
        className: 'table__td--gray',
      },
      {
        title: this.props.t('comment'),
        key: 'comments',
        onCell: () => ({ 'data-label': this.props.t('comment') }),
        render: (text, record) => this.renderTaskRateComment(record),
        className: 'table__td--gray',
      },
    ]
  );

  getWebActivityColumns = () => (
    [
      {
        title: this.props.t('task'),
        dataIndex: 'name',
        key: 'name',
        onCell: () => ({ 'data-label': this.props.t('task') }),
      },
      {
        title: this.props.t('type'),
        dataIndex: 'type',
        key: 'type',
        onCell: () => ({ 'data-label': this.props.t('type') }),
        render: taskTypeLabel,
      },
      {
        title: this.props.t('value'),
        dataIndex: 'value',
        key: 'value',
        onCell: () => ({ 'data-label': this.props.t('value') }),
        render: this.renderValue,
      },
    ]
  );

  getStatus = () => {
    const {
      catalogs: { execution },
    } = this.props;

    if (execution.status === RuleExecutionStatus.DONE
        || execution.status === RuleExecutionStatus.PENDING) {
      return execution.status;
    }

    const endTime = moment(execution.createdAt).add(execution.cron.duration, 'm');
    if (endTime < moment()) {
      return 'expired';
    }
    return 'process';
  }

  getFilteredTasks = () => {
    const {
      catalogs: { checklist },
    } = this.props;
    const { filters } = this.state;

    if (checklist.length) {
      let newChecklist = [...checklist.map(task => task)];

      if (filters.required) {
        newChecklist = newChecklist.filter(task => task.evidenceType === filters.required);
      }

      if (filters.type) {
        newChecklist = newChecklist.filter(task => task.type === filters.type);
      }

      return newChecklist;
    }

    return [];
  }

  getRateIcon = (task) => {
    const {
      catalogs: { execution },
    } = this.props;

    const icons = [
      { rating: 1, type: 'smile', color: '#31B550' },
      { rating: 2, type: 'meh', color: '#D5B821' },
      { rating: 0, type: 'frown', color: '#D54C21' },
    ];

    if (execution.evidence && execution.evidence.completion.rate) {
      const rate = execution.evidence.completion.rate.checklist
        .find((i) => {
          if (i.groupId && task.groupId) {
            return i.name === task.name &&
            i.groupId === task.groupId &&
            i.groupInfo.repeatNumber === task.groupInfo.repeatNumber;
          }
          return i.name === task.name && i.repeatNumber === task.repeatNumber;
        });

      if (rate) {
        const icon = icons.find(i => i.rating === rate.rating);

        if (icon) {
          return (
            <Icon
              type={icon.type}
              style={{
                color: icon.color,
                fontSize: 25,
              }}
            />
          );
        }
      }
    } else if (task.rating) {
      return task.rating;
    }

    return null;
  }

  getIsRated = (task) => {
    if (typeof task.rating === 'number') {
      return true;
    }
    return false;
  }

  getIconColor = (task) => {
    const {
      catalogs: { execution },
    } = this.props;
    let icons = [
      { id: 0, type: 'frown', color: '#BDBDBD' },
      { id: 2, type: 'meh', color: '#BDBDBD' },
      { id: 1, type: 'smile', color: '#BDBDBD' },
    ];

    if (execution.evidence && execution.evidence.completion && execution.evidence.completion.rate) {
      const rate = execution.evidence.completion.rate.checklist.find(i => i.name === task.name);
      const colors = ['#D54C21', '#31B550', '#D5B821'];

      if (rate) {
        icons = icons.map(i => (i.id === rate.rating ? { ...i, color: colors[rate.rating] } : i));
      }
    } else if (
      (task.evaluation && task.evaluation.type === EvaluationTypes.GRAPHIC) ||
      task.rating >= 0
    ) {
      icons = [
        { id: 0, type: 'frown', color: task.rating === 0 ? '#D54C21' : '#BDBDBD' },
        { id: 2, type: 'meh', color: task.rating === 50 ? '#D5B821' : '#BDBDBD' },
        { id: 1, type: 'smile', color: task.rating === 100 ? '#31B550' : '#BDBDBD' },
      ];
    }

    return icons;
  }

  getRatingComment = (t) => {
    const {
      catalogs: { execution },
    } = this.props;

    if (execution.evidence && execution.evidence.completion && execution.evidence.completion.rate) {
      const rate = execution.evidence.completion.rate.checklist.find(i => i.name === t.name);

      if (rate) {
        return rate.comments;
      }
    } else if (t.comments) {
      return t.comments;
    }
    return null;
  }

  getLogText = (log) => {
    const date = moment(log.createdAt).format('DD/MM/YY LT');

    return (
      <div>
        <p>{date}</p>
        {log.newValues.map(i => (
          <p key={i.name}>
            <b>{log.userName} </b>
            {this.getLogDescription(this.getOldTask(log.oldValues, i), i)}
          </p>
        ))}
      </div>
    );
  }

  getLogDescription = (oldTask, task) => {
    const taskName = task.name.length > 40 ? `${task.name.substring(0, 40)}...` : task.name;

    switch (task.type) {
      case TaskTypes.TEXT:
      case TaskTypes.NUMERIC:
      case TaskTypes.RULE_FOLIO:
      case TaskTypes.ENUM:
      case TaskTypes.BARCODE:
      case TaskTypes.DATE:
      case TaskTypes.TIME:
        return (
          <span>
            cambió el valor <b>{oldTask.value}</b> de la tarea <b>{taskName}</b>
            &nbsp;por <b>{task.value}</b>
          </span>
        );
      case TaskTypes.CHECK:
        return (
          <span>
            cambió el valor <b>{task.value ? 'Realizado' : 'No realizado'}</b> de la tarea <b>{taskName}</b>
            &nbsp;por <b>{oldTask.value ? 'Realizado' : 'No realizado'}</b>
          </span>
        );
      case TaskTypes.FILE:
        if (!isEmpty(oldTask.value)) {
          return (
            <span>
              cambió el documento
              {oldTask.value ? <a href={getImageUrl(oldTask.value.id)}> {oldTask.value.filename}</a> : ' vacío'}
              &nbsp;de la tarea <b>{taskName}</b>
              &nbsp;por {task.value ? <a href={getImageUrl(task.value.id)}>{task.value.filename}</a> : 'vacío'}
            </span>
          );
        }
        break;
      case TaskTypes.PHOTO:
      case TaskTypes.SIGNATURE:
        return (
          <span>
            cambió la
            {oldTask.value ? <a href={getImageUrl(oldTask.value)}> evidencia</a> : ' vacío'}
            &nbsp;de la tarea <b>{taskName}</b>
            &nbsp;por {task.value ? <a href={getImageUrl(task.value)}> nueva evidencia</a> : ' vacío'}
          </span>
        );
      case TaskTypes.RULE_LINK:
        return (
          <span>
            cambió la tarea <b>{taskName}</b> a <b>{task.value.folio} - {task.value.ruleName}</b>
          </span>
        );
      default:
        return <span>cambió la tarea <b>{taskName}</b></span>;
    }
    return null;
  }

  getOldTask = (oldValues, task) => {
    const oldTask = oldValues.find(i => i.name === task.name);
    return oldTask;
  }

  openModalImage = (imageUrl) => {
    this.setState({ modalImage: true, previewImage: imageUrl });
  }

  showModalDescription = (text) => {
    this.setState({
      modalDescription: true,
      textDescription: text,
    });
  }

  renderTaskName = record => (
    <Text>
      {record.name}
      {record.repeatNumber > 1 && ` ${record.repeatNumber}` }
    </Text>
  )

  renderTaskGroupName = (task) => {
    const group = (task.groupId && task.groupInfo) && task.groupInfo;
        let groupName = this.props.t('without_group');
        if (group) {
          groupName = group.repeatNumber > 1 ? `${group.name} ${group.repeatNumber}` : group.name;
        }

    return (
      <Text>
        {groupName}
      </Text>
    );
  }

  renderTaskRate = record => (
    this.getRateIcon(record) || <span className="gray-text">{this.props.t('no_grade')}</span>
  )

  renderTaskRateComment = record => this.getRatingComment(record) || <span className="gray-text">{this.props.t('no_comments')}</span>

  renderTabTasks = () => {
    const { t } = this.props;
    const tasks = this.getFilteredTasks();

    const hasTasks = tasks.length >= 1;

    return (
      <div>
        <TasksFilters
          onChangeRequired={val => this.onChangeFilter('required', val)}
          onChangeType={val => this.onChangeFilter('type', val)}
        />
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row className="margin-bottom-1" >
            {hasTasks &&
            <Row>
              <Col span={22}>
                <Text style={{ fontSize: 20, fontWeight: 500, color: '#414141' }} >{t('tasks')}</Text>
              </Col>
              <Col span={2}>
                <Icon
                  type={this.state.viewTasksAsList ? 'appstore' : 'unordered-list'}
                  onClick={() => this.setState({ viewTasksAsList: !this.state.viewTasksAsList })}
                  style={{
                    color: 'white',
                    fontSize: 25,
                    backgroundColor: '#72ADFF',
                    padding: 7,
                  }}
                />
              </Col>
            </Row>
            }
          </Row>
          <Row gutter={16} type="flex" className="execution-section" >
            {hasTasks && !this.state.viewTasksAsList &&
            <Col xs={24} sm={24} md={24} lg={24}>
              <TasksGrid
                checklist={tasks}
                renderValue={this.renderValue}
                onClick={this.openModalImage}
                renderTaskRate={this.renderTaskRate}
                renderTaskRateComment={this.renderTaskRateComment}
              />
            </Col>
            }
            {hasTasks && this.state.viewTasksAsList &&
            <Col xs={24} sm={24} md={24} lg={24}>
              <Table
                columns={this.getColumns()}
                className="responsive-table"
                dataSource={tasks}
                pagination={false}
                // rowKey="name"
                locale={{ emptyText: this.props.t('show_no_data') }}
                style={{ backgroundColor: 'white' }}
              />
            </Col>
            }
          </Row>
        </Col>
      </div>
    );
  }

  renderWebActivity = () => {
    const { catalogs: { execution } } = this.props;
    let tasks = [];

    if (execution.evidence && execution.evidence.completion) {
      tasks = execution.evidence.completion.checklist;
    }

    return (
      <Table
        columns={this.getWebActivityColumns()}
        className="responsive-table"
        dataSource={tasks}
        pagination={false}
        rowKey="name"
        locale={{ emptyText: this.props.t('show_no_data') }}
        style={{ backgroundColor: 'white' }}
      />
    );
  }

  renderContactInfo = (contact) => {
    const info = [
      `${contact.name} ${contact.lastName}`,
      contact.companyName || '-----',
      contact.role || '-----',
      contact.email || '-----',
      contact.phoneNumber || '-----',
    ];

    return info.join(' / ');
  }

  renderItemInfo = (item) => {
    const info = [
      `${item}`,
    ];

    return info.join(' / ');
  }

  renderContactInfo = (contact) => {
    const info = [
      `${contact.name} ${contact.lastName}`,
      contact.companyName || '-----',
      contact.role || '-----',
      contact.email || '-----',
      contact.phoneNumber || '-----',
    ];

    return info.join(' / ');
  };

  renderItemInfo = (item) => {
    const info = [
      `${item}`,
    ];
    return info.join(' / ');
  };

  renderValue = (text, task, ellipsis = false) => {
    const { t } = this.props;

    if (!task.value && task.type !== TaskTypes.CHECK) {
      return <span className="gray-text">{t('no_value')}</span>;
    }

    if (task.type === TaskTypes.CHECK) {
      return task.value ? t('completed') : t('not_completed');
    }

    if (task.type === TaskTypes.CONTACT) {
      if (Array.isArray(task.value)) {
        return (
          <PopoverList
            title={t('contacts')}
            label={`${t('contacts')}: ${task.value.length}`}
            data={task.value}
            renderItem={this.renderContactInfo}
          />
        );
      }
      return (
        <Tooltip
          title={
            <div>
              {t('name')}: {task.value.name} {task.value.lastName}<br />
              Email: {task.value.email}<br />
              {t('phone')}: {task.value.phoneNumber}<br />
              {t('company')}: {task.value.companyName}<br />
              {t('role')}: {task.value.role}
            </div>
          }
        >
          {task.value.name} {task.value.lastName}
        </Tooltip>
      );
    }

    if (task.type === TaskTypes.MULTI_ENUM) {
      const items = task.value.split(',');
      if (Array.isArray(items)) {
        return (
          <PopoverList
            title={t('values')}
            label={`${t('values')}: ${items.length}`}
            data={items}
            renderItem={this.renderItemInfo}
          />
        );
      }
      return (
        <Tooltip
          title={
            <div>
              {t('name')}: {task.value}<br />
            </div>
          }
        >
          {task.value}
        </Tooltip>
      );
    }

    if (task.type === TaskTypes.USER) {
      return <SimpleUserInfo id={task.value} />;
    }

    if (
      task.type === TaskTypes.FILE &&
      !isEmpty(task.value) &&
      !filenameIsImage(task.value.filename)
    ) {
      let fileId = task.value;
      let paramName = '';

      if ((typeof task.value) === 'object' && task.value.id) {
        fileId = task.value.id;
        paramName = `?name=${task.value.filename}`;
      }

      return (
        <a
          href={`${getImageUrl(fileId)}${paramName}`}
          download=""
        >
          {t('download')}
        </a>
      );
    }

    if (
      task.type === TaskTypes.PHOTO ||
      task.type === TaskTypes.SIGNATURE ||
      (
        task.type === TaskTypes.FILE &&
        !isEmpty(task.value) &&
        filenameIsImage(task.value.filename)
      )
    ) {
      const image = getImageUrl(task.type === TaskTypes.FILE ? task.value.id : task.value);
      return !isEmpty(image) && <Icon
        type="eye"
        onClick={() => this.openModalImage(image)}
      />;
    }

    if (task.type === TaskTypes.RULE_LINK) {
      if (task.value && task.value.ruleExecutionId
          && task.value.folio && task.value.ruleName) {
            return (
              <a
                href={`/evidences/${task.value.ruleExecutionId}`}
              >
                {`${getFormattedFolio(task.value.folio)}${task.value.ruleName}`}
              </a>
            );
      }
        return '';
    }

    return (
      <Row title={text}>
        <Paragraph ellipsis={ellipsis}>
          {text}
        </Paragraph>
      </Row>
    );
  };

  render() {
    const {
      modalImage,
      previewImage,
      modalDescription,
      textDescription,
    } = this.state;
    const {
      catalogs: { execution },
      isLoading,
      forbidden,
      t,
      downloadReport,
      deleteExecution,
      history,
    } = this.props;

    if (forbidden) {
      return <Redirect to={{ pathname: '/403' }} />;
    }

    if (isLoading) {
      return <Loading />;
    }

    return (
      <Row>
        <EvidenceHeader
          status={this.getStatus()}
          enableTracking={execution.status === RuleExecutionStatus.DONE}
          execution={execution}
          downloadReport={downloadReport}
          deleteExecution={deleteExecution}
          history={history}
        />
        {
          execution.ruleType === RuleTypes.WEB ?
            this.renderWebActivity() :
            <Tabs defaultActiveKey="1">
              <TabPane tab={t('tasks')} key="1">
                {this.renderTabTasks()}
              </TabPane>
              <TabPane tab={t('changelogs')} key="3">
                { execution.logs && execution.logs.length > 0 ?
                  <Timeline className="timeline-logs">
                    {execution.logs.map(i => (
                      <Timeline.Item key={i.id}>{this.getLogText(i)}</Timeline.Item>
                    ))}
                  </Timeline> :
                  <Empty description={t('no_changes')} />
                }
              </TabPane>
            </Tabs>
        }

        <Modal
          title={t('description')}
          visible={modalDescription}
          onCancel={() => this.setState({ modalDescription: false })}
          footer={null}
        >
          {textDescription}
        </Modal>
        <Modal
          visible={modalImage}
          footer={null}
          onCancel={() => this.setState({ modalImage: false })}
          style={{ top: 10 }}
          width="fit-content"
          wrapClassName="preview-image-container"
          maskStyle={{ backgroundColor: '#000000E0' }}
          closable={false}
        >
          <center>
            <TransformWrapper>
              {({
 zoomIn, zoomOut, resetTransform,
}) => (
  <React.Fragment>
    <TransformComponent>
      <img
        alt=""
        src={previewImage}
        style={{ height: '700px' }}
      />
    </TransformComponent>
    <div className="tools">
      <Button ghost onClick={() => zoomIn()}>Zoom in +</Button>
      <Button ghost onClick={() => zoomOut()}>Zoom out -</Button>
      <Button ghost onClick={() => resetTransform()}>Reset</Button>
    </div>
  </React.Fragment>
            )}
            </TransformWrapper>
          </center>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = ({ tasksEvidencesContainer }) => ({
  ...tasksEvidencesContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(TasksEvidences));
