/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-17 16:26:13 
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-20 11:40:32
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import * as actions from './actions';
import { defaultActions } from './reducer';
import {
  FETCH_BRANCH_OFFICES,
  FETCH_ROLES,
  FETCH_STATES,
  FETCH_BRANCH_OFFICES_ACTIVE,
  FETCH_PERMISSIONS,
  FETCH_SUBSCRIPTION_PLAN,
  FETCH_USER_INFO_BY_TOKEN,
  FETCH_AVAILABLE_USERS,
  FETCH_RULES_BY_FILTERS,
  SEARCH_USERS,
  FETCH_TASKS,
  FETCH_EVALUATIONS,
  FETCH_CONTACTS,
  FETCH_USERS,
  FETCH_RULE_BY_ID,
  FETCH_RULE_RELATIONS,
  FETCH_GROUPS,
  FETCH_WHATSAPP_CUSTOMERS,
  FETCH_WIDGETS,
  FETCH_WIDGETS_DATA,
  FETCH_RULES_BY_NAME,
  FETCH_TASK_GROUPS,
  FETCH_NOT_SCHEDULED_RULES,
  FETCH_RULES_FILTER,
  FETCH_EXECUTIONS,
  FETCH_EXECUTION_BY_ID,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_BY_USER,
} from './types';


import BranchOfficeService from '../../services/branch.office';
import RoleService from '../../services/role';
import RuleService from '../../services/rule';
import StateService from '../../services/states';
import PermissionService from '../../services/permission';
import PaymentService from '../../services/payment';
import UserService from '../../services/user';
import TaskService from '../../services/tasks';
import EvaluationService from '../../services/evaluations';
import ContactService from '../../services/contacts';
import GroupService from '../../services/groups';
import WhatsappCustomerService from '../../services/whatsapp-customer';
import WidgetService from '../../services/widgets';
import TaskGroupService from '../../services/taskGroup';
import ExecutionsService from '../../services/execution';
import NotificationService from '../../services/notifications';

import { Notification, Message } from '../../common/components/widgets';

import { parseApiError } from '../../common/error';

const executeCallbackAction = (callback = () => null, response) => {
  callback(response);
  return { type: '' };
};


const branchOfficeService = new BranchOfficeService();
const roleService = new RoleService();
const stateService = new StateService();
const permissionService = new PermissionService();
const paymentService = new PaymentService();
const userService = new UserService();
const ruleService = new RuleService();
const taskService = new TaskService();
const evaluationService = new EvaluationService();
const contactService = new ContactService();
const groupService = new GroupService();
const whatsappCustomerService = new WhatsappCustomerService();
const widgetService = new WidgetService();
const taskGroupService = new TaskGroupService();
const executionsService = new ExecutionsService();
const notificationService = new NotificationService();

const errorFetchBranchOffices = () => {
  Notification.error(i18n.t('offices_list_fail'), 'Verifica tu conexion al servidor');
};

const errorFetchRule = () => {
  Notification.error(i18n.t('list_activities_fail'), i18n.t('verify_server_conection'));
};

const errorMessage = () => {
  Notification.error(i18n.t('verify_server_conection'));
};

const fetchUsersEpic = action$ =>
  action$.ofType(FETCH_USERS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
      Observable.fromPromise(userService.getAll(action.payload))
        .flatMap(response =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(actions.fetchUsersFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(defaultActions.showMessage(() => {
              Notification.error(i18n.t('user_list_fail'), i18n.t('verify_server_conection'));
            })),
          )),
    ));

const fetchOfficesEpic = action$ =>
  action$.ofType(FETCH_BRANCH_OFFICES)
    .flatMap(() =>
      Observable.concat(
        Observable.of(defaultActions.loading(true)),
        Observable.fromPromise(branchOfficeService.getAll())
          .flatMap(response =>
            Observable.concat(
              Observable.of(defaultActions.loading(false)),
              Observable.of(actions.fetchBrancOfficesFullfilled(response)),
            ))
          .catch(() =>
            Observable.concat(
              Observable.of(defaultActions.loading(false)),
              Observable.of(defaultActions.showMessage(() => errorFetchBranchOffices())),
            )),
      ));

const fetchOfficesAllEpic = action$ =>
  action$.ofType(FETCH_BRANCH_OFFICES_ACTIVE)
    .flatMap(() =>
      Observable.concat(
        Observable.of(defaultActions.loading(true)),
        Observable.fromPromise(branchOfficeService.getActive())
          .flatMap(response =>
            Observable.concat(
              Observable.of(defaultActions.loading(false)),
              Observable.of(actions.fetchBrancOfficesFullfilled(response)),
            ))
          .catch(() =>
            Observable.concat(
              Observable.of(defaultActions.loading(false)),
              Observable.of(defaultActions.showMessage(() => errorFetchBranchOffices())),
            )),
      ));

const fetchRolesEpic = action$ =>
  action$.ofType(FETCH_ROLES)
    .flatMap(action =>
      Observable.concat(
        Observable.of(action.payload.loading(true)),
        Observable.fromPromise(roleService.getAll())
          .flatMap(response =>
            Observable.concat(
              Observable.of(action.payload.loading(false)),
              Observable.of(actions.fetchRolesFullfilled(response)),
            ))
          .catch(() =>
            Observable.concat(
              Observable.of(action.payload.loading(false)),
              Observable.of(action.payload.showMessage(() => {
                Notification.error('No se pudo cargar la lista de puestos.', i18n.t('verify_server_conection'));
              })),
            )),
      ));

const fetchStatesEpic = action$ =>
  action$.ofType(FETCH_STATES)
    .flatMap(action =>
      Observable.concat(
        Observable.of(action.payload.loading(true)),
        Observable.fromPromise(stateService.getAll())
          .flatMap(response =>
            Observable.concat(
              Observable.of(action.payload.loading(false)),
              Observable.of(actions.fetchStatesFullfilled(response)),
            ))
          .catch(() =>
            Observable.concat(
              Observable.of(action.payload.loading(false)),
              Observable.of(action.payload.showMessage(() => {
                Notification.error('No se pudo cargar la lista de estados.', i18n.t('verify_server_conection'));
              })),
            )),
      ));

const fetchPermissionsEpic = action$ =>
  action$.ofType(FETCH_PERMISSIONS)
    .flatMap(action =>
      Observable.concat(
        Observable.of(action.payload.loading(true)),
        Observable.fromPromise(permissionService.getAll())
          .flatMap(response =>
            Observable.concat(
              Observable.of(action.payload.loading(false)),
              Observable.of(actions.fetchPermissionsFullfilled(response)),
            ))
          .catch(() =>
            Observable.concat(
              Observable.of(action.payload.loading(false)),
              Observable.of(action.payload.showMessage(() => {
                Notification.error('No se pudo cargar la lista de permisos.', i18n.t('verify_server_conection'));
              })),
            )),
      ));

const fetchSubscriptionPlanEpic = action$ =>
  action$.ofType(FETCH_SUBSCRIPTION_PLAN).flatMap(action =>
    Observable.concat(
      Observable.of(action.payload.loading(true)),
      Observable.fromPromise(paymentService.getSubscriptionByUserId())
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.fetchSubscriptionPlanFulfilled(response)),
            Observable.of(action.payload.loading(false)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(action.payload.loading(false)),
            Observable.of(action.payload.showMessage(() => {})),
          )),
    ));

const fetchUserInfoByTokenEpic = action$ =>
  action$.ofType(FETCH_USER_INFO_BY_TOKEN).flatMap(() =>
    Observable.concat(Observable.fromPromise(userService.getUserInfoByToken())
      .flatMap(response =>
        Observable.concat(Observable.of(actions.fetchUserInfoByTokenFulfilled(response))))
      .catch(() =>
        Observable.concat(Observable.of(defaultActions.showMessage(() => {}))))));

const fetchAvailableUsersEpic = action$ =>
  action$.ofType(FETCH_AVAILABLE_USERS).flatMap(() =>
    Observable.fromPromise(paymentService.getAvailableUsers())
      .flatMap(response =>
        Observable.of(actions.fetchAvailableUsersFulfilled(response)))
      .catch(() =>
        Observable.concat(
          Observable.of(defaultActions.loading(false)),
          Observable.of(defaultActions.showMessage(() => errorMessage())),
        )));

const fetchRulesByFiltersEpic = action$ =>
  action$.ofType(FETCH_RULES_BY_FILTERS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
      Observable.fromPromise(ruleService.getByFilters(action.filters))
        .flatMap(response =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(actions.fetchRulesFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(defaultActions.showMessage(() => errorFetchRule())),
          )),
    ));

const searchUsersEpic = action$ =>
  action$.ofType(SEARCH_USERS).switchMap(action =>
    Observable.concat(Observable.fromPromise(userService.searchUsers({
      value: action.value,
      ...action.params,
    }))
      .flatMap(response =>
        Observable.concat(Observable.of(actions.searchUsersFulfilled(response))))
      .catch(() =>
        Observable.concat(Observable.of(action.payload.showMessage(() => {}))))));

const fetchTasksEpic = action$ =>
  action$.ofType(FETCH_TASKS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
      Observable.fromPromise(taskService.getTasks(action.params))
        .flatMap(response =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(actions.fetchTasksFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(action.payload.showMessage(() => {
              Notification.error('No se pudo cargar la lista de tareas.');
            })),
          )),
    ));

const fetchEvaluationsEpic = action$ =>
  action$.ofType(FETCH_EVALUATIONS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
      Observable.fromPromise(evaluationService.getAll(action.params))
        .flatMap(response =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(actions.fetchEvaluationsFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(action.payload.showMessage(() => {
              Notification.error('No se pudo cargar la lista de evaluaciones.');
            })),
          )),
    ));

const fetchContactsEpic = action$ =>
  action$.ofType(FETCH_CONTACTS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
      Observable.fromPromise(contactService.getAll(action.params))
        .flatMap(response =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(actions.fetchContactsFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(defaultActions.showMessage(() => {
              Notification.error('No se pudo cargar la lista de contactos.', i18n.t('verify_server_conection'));
            })),
          )),
    ));

const fetchRuleByIdEpic = action$ =>
  action$.ofType(FETCH_RULE_BY_ID).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
      Observable.fromPromise(ruleService.getById(action.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.fetchRuleByIdFulfilled(response)),
            Observable.of(defaultActions.loading(false)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(defaultActions.showMessage(() => {
              Notification.error('No se pudo obtener información de esta actividad');
            })),
          )),
    ));

const fetchRuleRelationsEpic = action$ =>
  action$.ofType(FETCH_RULE_RELATIONS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
      Observable.fromPromise(ruleService.getRuleRelations(action.payload))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.fetchRuleRelationsFulfilled(response)),
            Observable.of(defaultActions.loading(false)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(defaultActions.showMessage(() => {
              Notification.error('No se pudo obtener las relaciones de esta actividad');
            })),
          )),
    ));

const fetchGroupsEpic = action$ =>
  action$.ofType(FETCH_GROUPS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
      Observable.fromPromise(groupService.getAll(action.params))
        .flatMap(response =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(actions.fetchGroupsFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(defaultActions.showMessage(() => {
              Notification.error('No se pudo cargar la lista de grupos.', i18n.t('verify_server_conection'));
            })),
          )),
    ));

const fetchWhatsappCustomersEpic = action$ =>
  action$.ofType(FETCH_WHATSAPP_CUSTOMERS).flatMap(action =>
    Observable.fromPromise(whatsappCustomerService.getByCompanyId(action.companyId))
      .flatMap(response =>
        Observable.of(actions.fetchWhatsappCustomersFulfilled(response)))
      .catch(() =>
        Observable.of(defaultActions.showMessage(() => {
          Notification.error('No se pudo cargar el cliente whatsapp.', i18n.t('verify_server_conection'));
        }))));

const fetchWidgetsEpic = action$ =>
  action$.ofType(FETCH_WIDGETS).switchMap(action =>
    Observable.fromPromise(widgetService.getAll())
      .switchMap(response =>
        Observable.concat(
          Observable.of(actions.fetchWidgetsFulfilled(response)),
          Observable.from(response)
            .flatMap(widget => Observable.of(actions.fetchWidgetsData(widget, action.params))),
        )));

const fetchWidgetsDataEpic = action$ =>
  action$.ofType(FETCH_WIDGETS_DATA).flatMap(({ payload: { widget, params } }) =>
    Observable.fromPromise(widgetService.getWidgetData(widget.id, params))
      .flatMap(response => Observable.of(actions.fetchWidgetsDataFulfilled(response)))
      .catch(() => (
        Observable.of(actions.fetchWidgetsDataFulfilled({ widget, error: true }))
      )));

const fetchRulesByNameEpic = action$ =>
  action$.ofType(FETCH_RULES_BY_NAME).flatMap(action =>
    Observable.fromPromise(ruleService.getByFiltersPaginated(action.payload))
      .flatMap(response => Observable.of(actions.fetchRulesByNameFulfilled(response)))
      .catch(error =>
        Observable.of(defaultActions.showMessage(() => {
          const parsedError = parseApiError(error);
          Notification.error(parsedError.message);
        }))));

const fetchTaskGroupsEpic = action$ =>
  action$.ofType(FETCH_TASK_GROUPS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
      Observable.fromPromise(taskGroupService.getTaskGroups(action.params))
        .flatMap(response =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(actions.fetchTaskGroupsFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(defaultActions.loading(false)),
            Observable.of(defaultActions.showMessage(() => {
              Notification.error('No se pudo cargar la lista de grupos de tareas.');
            })),
          )),
    ));

const fetchNotScheduledRulesEpic = action$ =>
action$.ofType(FETCH_NOT_SCHEDULED_RULES).flatMap(action =>
  Observable.concat(
    Observable.of(defaultActions.loading(true)),
    Observable.fromPromise(ruleService.getNotScheduledRules({
      branchOfficeId: action.branchOfficeId,
      roleIds: action.roleIds,
      userId: action.userId,
    }))
      .flatMap(response =>
        Observable.concat(
          Observable.of(actions.fetchNotScheduledRulesFulfilled(response)),
          Observable.of(defaultActions.loading(false)),
        ))
      .catch(() =>
        Observable.concat(
          Observable.of(defaultActions.loading(false)),
          Observable.of(defaultActions.showMessage(() => Message.error('Error al cargar lista de reglas'))),
        )),
  ));

const fetchRulesFilterEpic = action$ =>
action$.ofType(FETCH_RULES_FILTER).flatMap(action =>
  Observable.concat(
    Observable.of(defaultActions.loading(true)),
    Observable.fromPromise(ruleService.getRulesFilter({
      branchOfficeId: action.branchOfficeId,
      roleIds: action.roleIds,
      ruleType: action.ruleType,
    }))
      .flatMap(response =>
        Observable.concat(
          Observable.of(actions.fetchRulesFilterFulfilled(response)),
          Observable.of(executeCallbackAction(action.callback, response)),
          Observable.of(defaultActions.loading(false)),
        ))
      .catch(() =>
        Observable.concat(
          Observable.of(defaultActions.loading(false)),
          Observable.of(defaultActions.showMessage(() => Message.error('Error al cargar lista de actividades'))),
        )),
  ));

const fetchExecutionsEpic = action$ =>
  action$.ofType(FETCH_EXECUTIONS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.updateState({ path: 'loadingExecutions', value: true })),
      Observable.fromPromise(executionsService.getExecutions(action.payload))
        .flatMap(response =>
          Observable.concat(
            Observable.of(defaultActions.updateState({ path: 'loadingExecutions', value: false })),
            Observable.of(actions.fetchExecutionsFulfilled(response)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(defaultActions.updateState({ path: 'loadingExecutions', value: false })),
            Observable.of(defaultActions.showMessage(() => {
              const parsedError = parseApiError(error);
              Notification.error(parsedError.message);
            })),
          )),
    ));

const fetchExecutionEpic = action$ =>
    action$.ofType(FETCH_EXECUTION_BY_ID).flatMap(action =>
      Observable.concat(
        Observable.of(defaultActions.loading(true)),
        Observable.fromPromise(executionsService.getExecutionById(action.id))
          .flatMap(response =>
            Observable.concat(
              Observable.of(defaultActions.loading(false)),
              Observable.of(actions.fetchExecutionByIdFulfilled(response)),
            ))
          .catch((error) => {
            const parsedError = parseApiError(error);

            if (parsedError.status === 403) {
              return Observable.of(defaultActions.updateState({ path: 'forbidden', value: true }));
            }

            return Observable.concat(
              Observable.of(defaultActions.loading(false)),
              Observable.of(defaultActions.showMessage(() => {
                Notification.error(parsedError.message);
              })),
            );
          }),
      ));

const fetchNotificationsEpic = action$ =>
  action$.ofType(FETCH_NOTIFICATIONS).flatMap(action =>
    Observable.concat(
      Observable.of(defaultActions.loading(true)),
        Observable.fromPromise(notificationService.getAll(action.payload))
          .flatMap(response =>
            Observable.concat(
              Observable.of(defaultActions.loading(false)),
              Observable.of(actions.fetchNotificationsFulfilled(response)),
            ))
          .catch(() =>
            Observable.concat(
              Observable.of(defaultActions.loading(false)),
              Observable.of(defaultActions.showMessage(() => {
                Notification.error('No se pudo cargar la lista de notificaciones.', i18n.t('verify_server_conection'));
              })),
            )),
      ));

const fetchNotificationsByUserEpic = action$ =>
  action$.ofType(FETCH_NOTIFICATIONS_BY_USER)
    .flatMap(() =>
      Observable.concat(
        Observable.of(defaultActions.loading(true)),
        Observable.fromPromise(notificationService.getByUserId())
          .flatMap(response =>
            Observable.concat(
              Observable.of(defaultActions.loading(false)),
              Observable.of(actions.fetchNotificationsByUserFulfilled(response)),
            ))
            .catch(() =>
              Observable.concat(
                Observable.of(defaultActions.loading(false)),
                Observable.of(defaultActions.showMessage(() => errorFetchBranchOffices())),
              )),
      ));

export default combineEpics(
  fetchUsersEpic,
  fetchOfficesEpic,
  fetchOfficesAllEpic,
  fetchRolesEpic,
  fetchStatesEpic,
  fetchPermissionsEpic,
  fetchSubscriptionPlanEpic,
  fetchUserInfoByTokenEpic,
  fetchAvailableUsersEpic,
  fetchRulesByFiltersEpic,
  searchUsersEpic,
  fetchTasksEpic,
  fetchEvaluationsEpic,
  fetchContactsEpic,
  fetchRuleByIdEpic,
  fetchRuleRelationsEpic,
  fetchGroupsEpic,
  fetchWhatsappCustomersEpic,
  fetchWidgetsEpic,
  fetchWidgetsDataEpic,
  fetchRulesByNameEpic,
  fetchTaskGroupsEpic,
  fetchNotScheduledRulesEpic,
  fetchRulesFilterEpic,
  fetchExecutionsEpic,
  fetchExecutionEpic,
  fetchNotificationsEpic,
  fetchNotificationsByUserEpic,
);
