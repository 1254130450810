import axios from 'axios';

import { createAuthenticationServiceUrl } from '../common/helpers/service';
import { getTokenData, saveTokenData, logout } from '../security';

let isRefreshing = false;
let subscribers = [];

export const setAuthorizationHeader = (token) => {
  axios.defaults.headers.common.Authorization = `bearer ${token}`;
};

export const initInterceptor = () => {
  axios.interceptors.response.use(response => response, (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && error.response.data.error_description && error.response.data.error_description.startsWith('Access token expired') && !originalRequest._retry) { // eslint-disable-line

      if (!isRefreshing) {
        isRefreshing = true;

        const tokenData = getTokenData();
        const formData = new FormData(); // eslint-disable-line
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', tokenData.refresh_token);

        // Refreshing access token
        axios.post(createAuthenticationServiceUrl('oauth/token'), formData, {
          headers: { Authorization: 'Basic d2ViLWFwcDpVamtqMkYzbVB3cld1Y3F2' },
        }).then((data) => {
          const newTokenData = data.data;
          isRefreshing = false;
          axios.defaults.headers.common.Authorization = `${newTokenData.token_type} ${newTokenData.access_token}`;
          saveTokenData(newTokenData);

          subscribers = subscribers.filter(callback => callback(newTokenData.access_token));
        }).catch(() => { // could not refresh token
          isRefreshing = false;
          logout();
          window.location.reload();
        });
      }

      originalRequest._retry = true; // eslint-disable-line

      return new Promise((resolve) => {
        subscribers.push((accessToken) => {
          originalRequest.headers.Authorization = `bearer ${accessToken}`;
          resolve(axios(originalRequest));
        });
      });
    }

    return Promise.reject(error);
  });
};
