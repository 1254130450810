/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-17 23:00:45 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-10-06 10:04:30
 */

import { Modal } from 'antd';
import i18n from 'i18next';


const disableConfirm = (updateUser) => {
  Modal.confirm({
    title: '¿Seguro que desea desactivar al usuario?',
    content: 'El usuario no podrá accesar a la aplicación.',
    okText: i18n.t('yes'),
    okType: 'danger',
    cancelText: i18n.t('no'),
    onOk() {
      updateUser();
    },
  });
};

const enableConfirm = (updateUser) => {
  Modal.confirm({
    title: '¿Seguro que desea activar al usuario?',
    content: 'El usuario podrá accesar a la aplicación.',
    okText: i18n.t('yes'),
    okType: 'primary',
    cancelText: i18n.t('no'),
    onOk() {
      updateUser();
    },
  });
};

export { disableConfirm, enableConfirm };
