/*
 * @Author: Jhony Reyes 
 * @Date: 2019-01-14 01:09:49 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-08-27 15:04:29
 */

import { Modal } from 'antd';
import i18n from 'i18next';

export default (message, upgradeFunction) => {
  Modal.confirm({
    title: i18n.t('unavailable_users'),
    content: message,
    okText: i18n.t('get_users'),
    cancelText: i18n.t('cancel'),
    onOk() {
      upgradeFunction();
    },
  });
};
