/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-11 01:04:54
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-27 20:14:37
 */
import { Tabs } from 'antd';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CompleteInfoCardList, GeneralButton as Buttons } from '../../common/components/widgets';
import { RuleTypes } from '../../common/constants';
import { getUserData } from '../../security';
import NotScheduled from './notScheduled';
import { actions, defaultActions } from './redux';
import { NextScheduled, Scheduled } from './scheduled';

const { TabPane } = Tabs;

class ExecutionActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeScheduled: true,
      changeUnscheduled: true,
    };
  }

  componentDidMount() {
    const {
      fetchNotScheduledRules,
      fetchDrafts,
      fetchActivities,
      fetchRulesFilter,
    } = this.props;
    const userData = getUserData();
    const { branchOffice, roles, id } = userData;
        fetchNotScheduledRules(
          branchOffice.id,
          roles && roles.length ? roles.map(r => r.id) : [], id,
        );

    if (branchOffice && roles) {
        const roleIds = roles.length ? roles.map(r => r.id) : [];
        fetchActivities(defaultActions, branchOffice.id, roleIds, branchOffice.state.timeZone, id);
        fetchRulesFilter(branchOffice.id, roleIds, RuleTypes.SCHEDULED);
        fetchDrafts();
    }
  }

  changeOfview = change => (
    change ? this.renderActivitiesProgress() : this.renderNextActivities()
  )

  changeUnscheduledView = change => (
    change ? this.renderUnscheduled() : this.renderUnscheduledDrafts()
  )

  renderActivitiesProgress = () => {
    const { catalogs: { executions }, t } = this.props;
    return (
      <Scheduled
        t={t}
        rules={executions}
      />
    );
  }

  renderNextActivities = () => {
    const { catalogs: { rulesExecution }, t } = this.props;
    return (
      <NextScheduled
        t={t}
        rulesExecution={rulesExecution}
      />
    );
  }

  renderUnscheduled = () => {
    const { catalogs: { rules }, t } = this.props;
    return (
      <NotScheduled
        t={t}
        rules={rules}
      />
    );
  }

  renderUnscheduledDrafts = () => {
    const { catalogs: { drafts }, t } = this.props;

    if (drafts.length > 0) {
      return drafts.map(re => (
        <Link to={`/web-form-execution/${re.id}`}>
          <CompleteInfoCardList
            key={re.id}
            execution={re}
            // onPress={() => this.onSelectActivity(re)}
            translate={t}
          />
        </Link>
      ));
    }

    return (
      <div>No hay Borradores</div>
    );
  }

  render() {
    const {
      changeScheduled,
      changeUnscheduled,
    } = this.state;
    const { t } = this.props;
    return (
      <div>
        <Tabs>
          <TabPane tab={t('scheduled')} key="scheduled" >
            <div>
              <Buttons
                name={t('activities_in_progress')}
                type={changeScheduled ? 'primary' : 'default'}
                onClick={() => this.setState({ changeScheduled: true })}
                icon="message"
              />
              <Buttons
                name={t('next_activities')}
                type={changeScheduled ? 'default' : 'primary'}
                onClick={() => this.setState({ changeScheduled: false })}
                icon="clock-circle"
              />
            </div>
            {
              this.changeOfview(changeScheduled)
            }
          </TabPane>
          <TabPane tab={t('nonScheduled')} key="nonScheduled" >
            <Buttons
              name={t('unscheduled_activities')}
              type={changeUnscheduled ? 'primary' : 'default'}
              onClick={() => this.setState({ changeUnscheduled: true })}
              icon="history"
            />
            <Buttons
              name={t('drafts')}
              type={changeUnscheduled ? 'default' : 'primary'}
              onClick={() => this.setState({ changeUnscheduled: false })}
              icon="form"
            />
            {
              this.changeUnscheduledView(changeUnscheduled)
            }
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = ({ executionActivitiesContainer }) => ({
  ...executionActivitiesContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(ExecutionActivities));

