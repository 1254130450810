/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 16:15:33 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-12-07 13:46:14
 */
const fetchActions = (props, defaultActions) => {
  const { fetchCompanies } = props;
  fetchCompanies(defaultActions);
};

export default fetchActions;
