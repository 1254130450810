/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:13:19
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-06 14:01:17
 */
const CONTAINER_KEY = 'TASKS_TIMELINE_CONTAINER';

export const FETCH_EXECUTION_TIMELINE = `${CONTAINER_KEY}_FETCH_EXECUTION_TIMELINE`;
export const FETCH_EXECUTION_TIMELINE_FULFILLED = `${CONTAINER_KEY}_FETCH_EXECUTION_TIMELINE_FULFILLED`;
