/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:42:42
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-11-25 12:08:09
 */
import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';

import * as generalData from './general';
import * as rulesContainer from '../routes/rules/redux';
import * as calendarContainer from '../routes/calendar/redux';
import * as taskitProcessesContainer from '../routes/taskitProcesses/redux';
import * as branchOfficesContainer from '../routes/branchOffices/redux';
import * as rolesContainer from '../routes/roles/redux';
import * as usersContainer from '../routes/users/redux';
import * as loginUser from '../routes/auth/login/redux';
import * as resetPassword from '../routes/auth/resetPassword/redux';
import * as registerContainer from '../routes/auth/register/redux';
import * as companiesContainer from '../routes/companies/redux';
import * as subscriptionContainer from '../routes/subscriptions/redux';
import * as paymentsContainer from '../routes/payments/redux';
import * as ordersContainer from '../routes/orders/redux';
import * as evidencesContainer from '../routes/evidences/redux';
import * as tasksTimelineContainer from '../routes/tasksTimeline/redux';
import * as tasksEvidencesContainer from '../routes/tasksEvidences/redux';
import * as tasksContainer from '../routes/tasks/redux';
import * as evaluationsContainer from '../routes/evaluations/redux';
import * as contactsContainer from '../routes/contacts/redux';
import * as webActivityContainer from '../routes/webActivity/redux';
import * as activityFormContainer from '../common/components/widgets/activityForm/redux';
import * as widgetsContainer from '../routes/dashboard/redux';
import * as mainContainer from '../common/components/layout/main/redux';
import * as executionActivitiesContainer from '../routes/executionActivities/redux';
import * as notificationsContainer from '../routes/notifications/redux';

export const rootEpic = combineEpics(
  generalData.epics,
  rulesContainer.epics,
  calendarContainer.epics,
  branchOfficesContainer.epics,
  rolesContainer.epics,
  usersContainer.epics,
  loginUser.epics,
  resetPassword.epics,
  registerContainer.epics,
  companiesContainer.epics,
  subscriptionContainer.epics,
  paymentsContainer.epics,
  ordersContainer.epics,
  evidencesContainer.epics,
  tasksContainer.epics,
  tasksEvidencesContainer.epics,
  tasksTimelineContainer.epics,
  evaluationsContainer.epics,
  contactsContainer.epics,
  webActivityContainer.epics,
  activityFormContainer.epics,
  widgetsContainer.epics,
  mainContainer.epics,
  taskitProcessesContainer.epics,
  executionActivitiesContainer.epics,
  notificationsContainer.epics,
);

export const rootReducer = combineReducers({
  generalData: generalData.reducer,
  rulesContainer: rulesContainer.reducer,
  calendarContainer: calendarContainer.reducer,
  branchOfficesContainer: branchOfficesContainer.reducer,
  rolesContainer: rolesContainer.reducer,
  usersContainer: usersContainer.reducer,
  loginUser: loginUser.reducer,
  resetPassword: resetPassword.reducer,
  registerContainer: registerContainer.reducer,
  companiesContainer: companiesContainer.reducer,
  subscriptionContainer: subscriptionContainer.reducer,
  paymentsContainer: paymentsContainer.reducer,
  ordersContainer: ordersContainer.reducer,
  evidencesContainer: evidencesContainer.reducer,
  tasksContainer: tasksContainer.reducer,
  tasksEvidencesContainer: tasksEvidencesContainer.reducer,
  tasksTimelineContainer: tasksTimelineContainer.reducer,
  evaluationsContainer: evaluationsContainer.reducer,
  contactsContainer: contactsContainer.reducer,
  webActivityContainer: webActivityContainer.reducer,
  activityFormContainer: activityFormContainer.reducer,
  widgetsContainer: widgetsContainer.reducer,
  mainContainer: mainContainer.reducer,
  taskitProcessesContainer: taskitProcessesContainer.reducer,
  executionActivitiesContainer: executionActivitiesContainer.reducer,
  notificationsContainer: notificationsContainer.reducer,
});
