/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 14:41:21 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-08-24 22:00:03
 */

import React, { Component } from 'react';
import { Table, Tabs } from 'antd';
import { connect } from 'react-redux';
import { init, getFieldError } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';

import { RenderActionsCell, RenderCheckCell } from '../../common/components/widgets/table';
import EditContactForm from '../../common/components/widgets/editContactForm';
import { EditableCell, Notification } from '../../common/components/widgets';
import validations from './utils/validations';
import { HeaderContent } from './utils/components';
import Groups from './utils/components/groups';
import { getUserData } from '../../security';
import { actions } from './redux';

const defaultNewContact = {
  name: '',
  lastName: '',
  companyId: '',
  companyName: '',
  role: '',
  email: '',
  phoneNumber: '',
  notifyWhatsapp: false,
  notifyEmail: false,
  active: true,
  groups: [],
  editable: true,
  branchOfficeId: null,
  userId: null,
};

class Contacts extends Component {
  constructor(props) {
    super(props);

    this.validator = init(validations());
    this.columns = this.getColumns();
    this.state = {
      errorForm: false,
      formErrors: {},
    };
  }

  componentDidMount = () => {
    const {
      fetchContacts,
      fetchGroups,
      fetchWhatsappCustomers,
      filters,
      fetchBranchOffices,
      fetchUsers,
    } = this.props;
    const companyId = getUserData().company.id;

    fetchWhatsappCustomers(companyId);
    fetchContacts(filters);
    fetchGroups();
    fetchBranchOffices();
    fetchUsers();
  };

  onChangeTab = (activeKey) => {
    const {
      fetchContacts,
      fetchGroups,
      resetFilters,
      filters,
    } = this.props;

    this.setState({ newRow: false });
    resetFilters();

    if (activeKey === 'contacts') {
      fetchContacts(filters);
    } else if (activeKey === 'groups') {
      fetchGroups({});
    }
  }

  onChangeSearchFilter = (event) => {
    const { updateState } = this.props;
    updateState({ path: 'filters.search', value: event.target.value });
  }

  onSearchFilter = (val) => {
    const { updateState, fetchContacts, filters } = this.props;
    const updatedFilters = {
      ...filters,
      page: 0,
      search: val,
    };

    this.setState({ newRow: false });
    updateState({ path: 'filters', value: updatedFilters });
    fetchContacts({ ...updatedFilters });
  }

  onChangeGroupFilter = (val) => {
    const { updateState, fetchContacts, filters } = this.props;
    const updatedFilters = {
      ...filters,
      page: 0,
      groupId: val,
    };

    this.setState({ newRow: false });
    updateState({ path: 'filters', value: updatedFilters });
    fetchContacts({ ...updatedFilters });
  }

  onChangeNotificationFilter = (val) => {
    const { updateState, fetchContacts, filters } = this.props;
    let notifyWhatsapp = null;
    let notifyEmail = null;

    switch (val) {
      case 'whatsapp':
        notifyWhatsapp = true;
        notifyEmail = false;
        break;
      case 'email':
        notifyWhatsapp = false;
        notifyEmail = true;
        break;
      case 'both':
        notifyWhatsapp = true;
        notifyEmail = true;
        break;
      case 'none':
        notifyWhatsapp = false;
        notifyEmail = false;
        break;
      default:
    }

    const updatedFilters = {
      ...filters,
      page: 0,
      notifyWhatsapp,
      notifyEmail,
      notificationMode: val,
    };
    this.setState({ newRow: false });
    updateState({ path: 'filters', value: updatedFilters });
    fetchContacts({ ...updatedFilters });
  }

  onChangePagination = (page, pageSize) => {
    const { fetchContacts, filters, updateState } = this.props;

    this.setState({ newRow: false });
    updateState({ path: 'filters.page', value: page - 1 });
    fetchContacts({
      ...filters,
      page: page - 1,
      size: pageSize,
    });
  }

  onFileChange = (file) => {
    if (!file) {
      Notification.error('For favor seleccione un archivo.');
      return;
    }

    if (file.size >= 5242880) {
      Notification.error('El tamaño del archivo excede los 5MB');
      return;
    }

    const data = new FormData();
    data.append('file', file);
    data.append('name', file.name);

    const { uploadContactsInCsv, filters } = this.props;
    uploadContactsInCsv(data, {
      page: 0,
      size: filters.size,
      sort: filters.sort,
    });
  }

  getColumns = () => [
    {
      title: this.props.t('name'),
      dataIndex: 'name',
      width: '8%',
      onCell: () => ({ 'data-label': this.props.t('name') }),
      render: (text, record) => this.renderInputCell(text, record, 'name', this.props.t('name'), true),
    },
    {
      title: this.props.t('lastname'),
      dataIndex: 'lastName',
      width: '7%',
      onCell: () => ({ 'data-label': this.props.t('lastname') }),
      render: (text, record) => this.renderInputCell(text, record, 'lastName', this.props.t('lastname'), false),

    },
    {
      title: this.props.t('company'),
      dataIndex: 'companyName',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('company') }),
      render: (text, record) => this.renderInputCell(text, record, 'companyName', this.props.t('company'), false),

    },
    {
      title: this.props.t('role'),
      dataIndex: 'role',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('role') }),
      render: (text, record) => this.renderInputCell(text, record, 'role', this.props.t('role'), false),
    },
    {
      title: this.props.t('email_long'),
      dataIndex: 'email',
      width: '10%',
      onCell: () => ({ 'data-label': 'Email' }),
      render: (text, record) => this.renderInputCell(text, record, 'email', 'Email', true),
    },
    {
      title: this.props.t('whatsapp'),
      dataIndex: 'phoneNumber',
      align: 'center',
      width: '11%',
      onCell: () => ({ 'data-label': 'Whatsapp' }),
      render: (text, record) => this.renderInputCell(text, record, 'phoneNumber', 'Whatsapp', false),
    },
    {
      title: this.props.t('notify_email'),
      align: 'center',
      dataIndex: 'notifyEmail',
      width: '7%',
      onCell: () => ({ 'data-label': this.props.t('notify_email') }),
      render: (text, record) => (
        <RenderCheckCell
          record={record}
          text={text}
          onChange={value => this.handleChange(value.target.checked, record.id, 'notifyEmail')}
        />
      ),
    },
    {
      title: this.props.t('notify_whatsapp'),
      align: 'center',
      dataIndex: 'notifyWhatsapp',
      width: '7%',
      onCell: () => ({ 'data-label': this.props.t('notify_whatsapp') }),
      render: (text, record) => {
        const { catalogs: { whatsAppCustomers } } = this.props;
        return (
          <RenderCheckCell
            record={record}
            text={text}
            onChange={value => this.handleChange(value.target.checked, record.id, 'notifyWhatsapp')}
            disabled={whatsAppCustomers.length <= 0}
          />
        );
      },
    },
    {
      title: this.props.t('actions'),
      align: 'center',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('actions') }),
      render: (text, record) => (
        <RenderActionsCell
          creating={this.state.newRow}
          record={record}
          onSave={() => this.save(record)}
          onCancel={() => this.cancel()}
          onEdit={() => this.edit(record)}
          onDelete={() => this.delete(record.id)}
        />
      ),
    },
  ];

  clearFilters = () => {
    const { filters, fetchContacts, resetFilters } = this.props;

    this.setState({ newRow: false });
    resetFilters();
    fetchContacts({
      page: 0,
      size: filters.size,
      sort: filters.sort,
    });
  }

  handleAdd = () => {
    this.setState({ newRow: defaultNewContact });
  }

  handleChange(value, id, column) {
    const { catalogs: { contacts }, updateState } = this.props;
    const newData = [...contacts];
    const target = newData.find(item => id === item.id);

    if (target) {
      target[column] = value;
      updateState({ path: 'catalogs.contacts', value: newData });
    }
  }

  handleChangeGroups(contactId, value) {
    const {
      catalogs: { contacts, groups },
      updateState,
    } = this.props;
    const newData = [...contacts];
    const target = newData.find(item => contactId === item.id);
    const selectedGroups = groups.filter(g => value.some(groupId => groupId === g.id));

    if (target) {
      target.groups = selectedGroups;
      updateState({ path: 'catalogs.contacts', value: newData });
    }
  }

  save = (record) => {
    if (record.id) {
      this.update(record);
    } else {
      this.create(record);
    }
  }

  create = (record) => {
    const { registerContact, filters, resetFilters } = this.props;

    if (!record) { return; }

    const { valid, errors } = this.validator.validateForm(record);

    if (!valid) {
      this.setState({ errorForm: true, formErrors: errors });
      return;
    }

    resetFilters();
    registerContact(record, {
      page: 0,
      size: filters.size,
      sort: filters.sort,
    }, () => this.setState({ errorForm: false, formErrors: null, newRow: false }));
  }

  update = (record) => {
    const { updateContact, filters } = this.props;


    const { valid, errors } = this.validator.validateForm(record);

    if (!valid) {
      this.setState({ errorForm: true, formErrors: errors });
      return;
    }

    updateContact(
      record,
      filters,
      () => this.setState({ errorForm: false, formErrors: null, newRow: false }),
    );
  }

  cancel = () => {
    const { fetchContacts, filters } = this.props;
    this.setState({ newRow: false });
    fetchContacts(filters);
  }

  edit(record) {
    this.setState({ newRow: record });
  }

  delete(id) {
    const { deleteContact, filters } = this.props;
    deleteContact(id, filters);
  }

  renderInputCell = (text, record, column, placeholder, required) => (
    <EditableCell
      required={required}
      editable={record.editable}
      value={text}
      error={this.state.errorForm}
      errorMessage={getFieldError(this.state.formErrors, column)}
      onChange={value => this.handleChange(value, record.id, column)}
      placeholder={placeholder}
    />
  );

  render() {
    const {
      catalogs: {
        contacts,
        groups,
        whatsAppCustomers,
        branchOffices,
        users,
      },
      filters, isLoading, t,
      downloadContactsInCsv,
    } = this.props;
    const { newRow } = this.state;

    return (
      <div>

        <Tabs defaultActiveKey="contacts" onChange={this.onChangeTab}>
          <Tabs.TabPane tab={t('contacts')} key="contacts">
            <div>
              <HeaderContent
                onCreate={this.handleAdd}
                creating={newRow}
                groups={groups}
                selectedGroup={filters.groupId}
                onChangeGroup={this.onChangeGroupFilter}
                selectedNotificationMode={filters.notificationMode}
                onChangeNotificationFilter={this.onChangeNotificationFilter}
                searchValue={filters.search}
                onChangeSearchFilter={this.onChangeSearchFilter}
                onSearchFilter={this.onSearchFilter}
                resetFilters={this.clearFilters}
                downloadContactsInCsv={downloadContactsInCsv}
                onFileChange={this.onFileChange}
              />
              <Table
                ref={(table) => { this.table = table; }}
                rowKey="id"
                className="responsive-table"
                dataSource={contacts}
                columns={this.columns}
                locale={{ emptyText: t('show_no_data') }}
                loading={isLoading}
                pagination={{
                current: filters.page + 1,
                pageSize: filters.size,
                total: filters.totalElements,
                onChange: this.onChangePagination,
              }}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('groups')} key="groups">
            <Groups />
          </Tabs.TabPane>
        </Tabs>
        {!!newRow &&
        <EditContactForm
          visible={!!newRow}
          form={newRow}
          t={t}
          whatsAppCustomers={whatsAppCustomers}
          onCancel={() => this.setState({ newRow: null })}
          onSave={this.save}
          groups={groups}
          branchOffices={branchOffices}
          users={users}
          formErrors={this.state.formErrors}
        />
      }
      </div>
    );
  }
}

const mapStateToProps = ({ contactsContainer, generalData: { isLoading } }) => ({
  ...contactsContainer,
  isLoading,
});

export default withTranslation()(connect(mapStateToProps, actions)(Contacts));
