/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-08 17:10:47 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-01-24 21:57:10
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import { FETCH_ORDERS, FETCH_ORDERS_FULFILLED } from './types';

const defaultState = {
  reducerKey: 'ordersContainer',
  catalogs: {
    orders: [],
  },
};

// Actions
// Payload { form, defaultActions, navigate }
const fetchOrders = () => ({ type: FETCH_ORDERS });
const fetchOrdersFulfilled = payload => ({ type: FETCH_ORDERS_FULFILLED, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_ORDERS_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.orders', value: payload }),
});

const actions = {
  ...defaultActions,
  fetchOrders,
  fetchOrdersFulfilled,
};

export { reducer, actions, epics };
