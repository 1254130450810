/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-17 16:49:11 
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-12-18 08:49:14
 */

// TODO: make strong validations and adapt this for your API specific response

import i18n from 'i18next';

export const parseApiError = (error) => {
  try {
    const jsonError = JSON.parse(JSON.stringify(error));
    const { response } = jsonError;
    const { response: { data } } = jsonError;
    let errorMessage;

    switch (response.status) {
      case 500:
        return {
          timestamp: data.timestamp,
          details: data.error,
          message: data.message || i18n.t('server_connection_fail'),
          status: response.status,
        };
      case 400:
      case 401:
        if (data.error === 'invalid_grant') {
          switch (data.error_description) {
            case 'Bad credentials':
              errorMessage = i18n.t('invalid_credentials');
              break;
            case 'User account is locked':
              errorMessage = i18n.t('invalid_account');
              break;
            case 'User is disabled':
              errorMessage = i18n.t('deactivated_account');
              break;
            default:
              errorMessage = response.message;
          }
        } else if (data.error === 'invalid_token') {
          errorMessage = i18n.t('invalid_token');
        } else {
          switch (data.message) {
            case 'Usuario no registrado':
              errorMessage = i18n.t('user_not_registered');
              break;
            default:
              errorMessage = data.message;
          }
        }

        return {
          timestamp: data.timestamp,
          details: data.error,
          message: errorMessage,
          status: response.status,
        };
      default:
        return {
          timestamp: data.timestamp,
          details: data.error,
          message: data.message,
          status: response.status,
        };
    }
  } catch (e) {
    return {
      timestamp: '',
      details: '',
      message: error.message,
      status: '',
    };
  }
};
