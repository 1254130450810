/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-24 10:55:40 
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-09-09 17:20:33
 */

import { createReducer, update } from 'aleonor-redux-helpers';
import moment from 'moment';
import {
  LOAD_RULE,
  UPDATE_FORM,
  CHANGE_STEP,
  INSERT_TASK,
  UPDATE_COLLABORATIVE,
  UPDATE_SELECTED_USERS,
} from './types';
import {
  FETCH_RULE_RELATIONS,
  FETCH_RULE_RELATIONS_FULFILLED,
  FETCH_RULES_BY_NAME_FULFILLED,
  FETCH_TASK_GROUPS_FULFILLED,
} from '../../../../../store/general/types';
import epics from './epics';
import * as generalActions from '../../../../../store/general/actions';

const defaultState = {
  reducerKey: 'activityFormContainer',
  form: {
    branchOfficeIds: [],
    name: '',
    description: '',
    roleId: '',
    checklist: [],
    cron: null,
    ruleType: null,
    collaborative: true,
    usersIds: null,
    userNotifications: [],
    internal: false,
    tasks: [],
    gpsRequired: false,
    nameRegister: '',
    visibleNameRegister: false,
  },
  catalogs: {
    rules: {
      content: [],
      size: 100,
      number: 0,
      totalElements: 1,
    },
    taskGroups: [],
  },
  durationSelected: moment(),
  events: [],
  checkboxDays: null,
  activePanel: [],
  selectedUsers: [],
  formErrors: [],
  currentStep: 0,
  ruleRelations: [],
  loadingRelations: false,
};

// Actions
// Payload { form, defaultActions }
const loadRule = payload => ({ type: LOAD_RULE, payload });
const updateForm = payload => ({ type: UPDATE_FORM, payload });
const changeStep = payload => ({ type: CHANGE_STEP, payload });
const insertTask = payload => ({ type: INSERT_TASK, payload });
const updateCollaborative = payload => ({ type: UPDATE_COLLABORATIVE, payload });
const updateSelectedUsers = payload => ({ type: UPDATE_SELECTED_USERS, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [LOAD_RULE]: (state, payload) => ({
    ...state,
    form: payload.form,
    durationSelected: payload.durationSelected,
    checkboxDays: payload.checkboxDays,
    events: payload.events,
  }),
  [UPDATE_FORM]: (state, payload) =>
    update(state, { path: `form.${payload.prop}`, value: payload.value }),
  [CHANGE_STEP]: (state, payload) =>
    update(state, { path: 'currentStep', value: state.currentStep + payload.value }),
  [INSERT_TASK]: (state, payload) => {
    const updatedState = update(state, { path: 'form.checklist', value: payload.checklist });
    return ({
      ...updatedState,
      activePanel: [`${payload.taskIndex}`],
    });
  },
  [UPDATE_COLLABORATIVE]: (state, payload) => ({
    ...state,
    form: {
      ...state.form,
      collaborative: payload.value,
      roleId: '',
      usersIds: null,
    },
    selectedUsers: [],
  }),
  [UPDATE_SELECTED_USERS]: (state, payload) => ({
    ...state,
    form: {
      ...state.form,
      usersIds: payload.values.map(i => i.key).join(),
    },
    selectedUsers: payload.values,
  }),
  [FETCH_RULE_RELATIONS]: state =>
    update(state, { path: 'loadingRelations', value: true }),
  [FETCH_RULE_RELATIONS_FULFILLED]: (state, payload) => ({
    ...state,
    ruleRelations: payload,
    loadingRelations: false,
  }),
  [FETCH_RULES_BY_NAME_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.rules', value: payload }),
  [FETCH_TASK_GROUPS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.taskGroups', value: payload }),
});

const actions = {
  ...defaultActions,
  ...generalActions,
  loadRule,
  updateForm,
  changeStep,
  insertTask,
  updateCollaborative,
  updateSelectedUsers,
};

export { epics, reducer, actions, defaultActions };
