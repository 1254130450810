/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-11 01:09:47
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-12 16:45:20
 */
import React from 'react';
import { Row, Col, Input } from 'antd';

const { Search } = Input;
// const onSearch = e => console.log(e.target.value);

const HeaderContent = ({ t, setOnChangeSearch }) => (
  <div className="headerContainer">
    <Row>
      <Col xs={24} sm={12} md={12} lg={8} xl={8} className="filter-box">
        <h1 className="textBranch">
          {t('name')}{' '}
        </h1>
        <Search
          className="select-data"
          placeholder="Buscar"
          onChange={e => setOnChangeSearch(e.target.value)}
          enterButton
        />
      </Col>
    </Row>
  </div>
);

export default HeaderContent;
