/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-08 17:10:47 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-10-28 13:08:25
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  REGISTER_EVALUATION,
  UPDATE_EVALUATION,
  DELETE_EVALUATION,
  OPEN_MODAL,
  CLOSE_MODAL,
} from './types';
import { FETCH_EVALUATIONS_FULFILLED } from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';


const defaultState = {
  reducerKey: 'evaluationsContainer',
  catalogs: {
    evaluations: {
      content: [],
      size: 10,
      number: 0,
      totalElements: 1,
    },
  },
  filters: {
    type: null,
    sort: 'name,asc',
  },
  showModal: false,
  currentEvaluation: {},
};

// Actions
// Payload { form, defaultActions, navigate }
const registerEvaluation = form => ({ type: REGISTER_EVALUATION, form });
const updateEvaluation = (form, filters = {}) => ({ type: UPDATE_EVALUATION, form, filters });
const deleteEvaluation = (id, filters) => ({ type: DELETE_EVALUATION, id, filters });
const openModal = (evaluation = {}) => ({ type: OPEN_MODAL, payload: { evaluation } });
const closeModal = () => ({ type: CLOSE_MODAL });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_EVALUATIONS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.evaluations', value: payload }),
  [OPEN_MODAL]: (state, payload) => ({
    ...state,
    currentEvaluation: payload.evaluation,
    showModal: true,
  }),
  [CLOSE_MODAL]: state => ({
    ...state,
    currentEvaluation: {},
    showModal: false,
  }),
});

const actions = {
  ...defaultActions,
  ...generalActions,
  registerEvaluation,
  updateEvaluation,
  deleteEvaluation,
  openModal,
  closeModal,
};

export { reducer, actions, epics, defaultActions };
