/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:41:48
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-18 12:37:17
 */
import { createStore, applyMiddleware, compose } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic, rootReducer } from './modules';

const epicMiddleware = createEpicMiddleware(rootEpic);
const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const configure = () => {
  const store = createStore(
    rootReducer,
    composeEnhacers(applyMiddleware(epicMiddleware)),
  );

  return store;
};

export default configure;
