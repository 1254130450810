/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-20 15:40:53 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-08-23 23:28:03
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  SEND_EMAIL, SENDING_EMAIL, SEND_EMAIL_SUCCESSFUL, FETCH_TOKEN,
  FETCH_TOKEN_SUCCESSFUL, RESET_PASSWORD, RESET_PASSWORD_SUCCESSFUL,
} from './types';

const defaultState = {
  reducerKey: 'resetPassword',
  formErrors: {},
  form: {
    email: '',
  },
  loadingButton: false,
  successSendEmail: {
    send: false,
    email: '',
  },
  successFetchToken: {
    user: '',
  },
  isLoading: true,
  resetForm: {
    password: '',
    newPassword: '',
  },
  resetPasswordSucessful: {
    reset: false,
  },
};

// Actions
// Payload { form, defaultActions, navigate }
const sendEmail = payload => ({ type: SEND_EMAIL, payload });
const sendingEmail = payload => ({ type: SENDING_EMAIL, payload });
const sendEmailSuccess = payload => ({ type: SEND_EMAIL_SUCCESSFUL, payload });
const fetchToken = token => ({ type: FETCH_TOKEN, token });
const fetchTokenSucess = payload => ({ type: FETCH_TOKEN_SUCCESSFUL, payload });
const resetPassword = payload => ({ type: RESET_PASSWORD, payload });
const resetPasswordSucess = payload => ({ type: RESET_PASSWORD_SUCCESSFUL, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [SENDING_EMAIL]: (state, payload) => update(state, { path: 'loadingButton', value: payload }),
  [SEND_EMAIL_SUCCESSFUL]: (state, payload) => update(state, { path: 'successSendEmail', value: payload }),
  [FETCH_TOKEN_SUCCESSFUL]: (state, payload) => update(state, { path: 'successFetchToken', value: payload }),
  [RESET_PASSWORD_SUCCESSFUL]: (state, payload) => update(state, { path: 'resetPasswordSucessful', value: payload }),
});

const actions = {
  ...defaultActions,
  sendEmail,
  sendingEmail,
  sendEmailSuccess,
  fetchToken,
  fetchTokenSucess,
  resetPassword,
  resetPasswordSucess,
};

export { reducer, actions, epics };
