/*
 * @Author: Jhony Reyes 
 * @Date: 2019-02-06 11:36:49 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-10-07 03:28:27
 */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { init, getFieldError } from 'aleonor-object-validations';
import { Modal, Row, Col, Input, Radio, Select, Steps, Button, Popconfirm, Icon, Message } from 'antd';
import { omit } from 'lodash';
import { withTranslation } from 'react-i18next';
import InputEmoji from 'react-input-emoji';

import { ErrorText } from '../../widgets';
import validations from './validations';
import UserService from '../../../../services/user';
import { RuleTypes } from '../../../constants';
import { actions } from './redux';
import { Loading } from '../../layout';
import TasksStep from './components/tasksStep';
import BranchOfficeStep from './components/branchOfficeStep';
import EventsStep from './components/eventsStep';
import Graphviz from '../graphviz';
import TaskService from '../../../../services/tasks';
import './activityForm.css';

const userService = new UserService();
const taskService = new TaskService();


class ActivityForm extends React.Component {
  constructor(props) {
    super(props);
    this.validator = init(validations());
  }
  componentWillMount = () => {
    const {
      currentEvent,
      created,
      branchOfficeDefault,
      roleDefault,
      ruleType,
      loadRule,
    } = this.props;
    const { start, end } = (ruleType === RuleTypes.TASKGROUP) ? moment() : currentEvent;
    const durationSelected = moment(end).subtract(moment(start).hours(), 'h').subtract(moment(start).minutes(), 'm');
    let scheduleProps = {};
    let formProps = {};

    if (created.id) {
      if (ruleType === RuleTypes.SCHEDULED) {
        const dayIndex = created.cron.days.findIndex(i => i === true);
        const day = dayIndex === 0 ? 13 : 6 + dayIndex;
        const events = created.cron.events.map((e) => {
          const hour = e.substr(0, 2);
          const minute = e.substr(3, 2);
          return {
            start: moment(new Date(2019, 0, day, hour, minute)),
            end: moment(new Date(2019, 0, day, hour, minute)).add(created.cron.duration, 'm'),
          };
        });

        scheduleProps = {
          durationSelected:
            moment(events[0].end).subtract(moment(events[0].start).hours(), 'h').subtract(moment(events[0].start).minutes(), 'm'),
          checkboxDays:
            created.cron.days.map((val, index) => (val ? index : null)).filter(val => val != null),
          events,
        };
      }

      const updatedChecklist = this.processChecklist(created.checklist);

      formProps = {
        ...created,
        tasks: Object.values(created.checklist).filter(i => i.id).map(i => i.id),
        checklist: updatedChecklist,
        ruleType,
      };
    } else {
      if (ruleType === RuleTypes.SCHEDULED) {
        scheduleProps = {
          checkboxDays: [parseInt(moment(start).format('d'), 10)],
          durationSelected,
          events: [{ start: moment(start), end: moment(end) }],
        };
      }

      formProps = {
        ...this.props.form,
        branchOfficeId: branchOfficeDefault ? branchOfficeDefault.id : '',
        roleId: roleDefault ? roleDefault.id : '',
        ruleType,
      };
    }

    loadRule({
      form: formProps,
      ...scheduleProps,
    });
  }

  componentDidMount = () => {
    const { created, updateState } = this.props;

    if (created.collaborative === false) {
      userService.searchUsers({ ids: created.usersIds })
        .then(data => updateState({
          path: 'selectedUsers',
          value: data.map(i => ({ key: i.id, label: i.name })),
        }));
    }
  }

  onClose = () => {
    this.props.resetState();
    this.props.onCancel();
  }

  onOk = () => {
    const {
      onSave,
      form,
      form: { checklist },
      durationSelected,
      events,
      checkboxDays,
      updateState,
      ruleType,
    } = this.props;

    const cron = ruleType === RuleTypes.SCHEDULED ?
      this.buildCron(durationSelected, events, checkboxDays) : null;

    const updatedChecklist = this.unprocessChecklist(checklist);
    // Saving tasks
    Promise.all(updatedChecklist.map(async (task, index) => {
      if (task.saveTask) {
        const savedTask = await taskService.createTask(task);
        return { ...savedTask };
      }

      if (task.id && task.descriptionEdited &&
          !updatedChecklist.some((tsk, i) => tsk.id === task.id && i > index)) {
        const updatedTask = await taskService.updateTask(task);
        return { ...updatedTask };
      }

      return omit(task, 'saveTask');
    })).then((updatedTasks) => {
      const updatedForm = {
        ...form,
        cron,
        checklist: updatedTasks,
      };

      updateState({ path: 'form', value: updatedForm });
      onSave(updatedForm);
      this.onClose();
    }).catch(() => {
      Message.error(this.props.t('error_save_task'));
    });
  }

  getButtons = () => {
    const {
      ruleType,
      currentStep,
      changeStep,
      created,
      isLoading,
      onDelete,
      fetchRuleRelations,
      form,
      t,
      fromTaskitProcesses,
    } = this.props;
    const buttons = [
      <Popconfirm
        key="cancel"
        title={t('cancel_entry_confirmation')}
        cancelText={t('no')}
        okText={t('yes')}
        onConfirm={this.onClose}
      >
        <Button loading={isLoading}>{this.props.t('cancel')}</Button>
      </Popconfirm>,
    ];

    if (currentStep > 0) {
      buttons.push((
        <Button
          key="before-button"
          onClick={() => {
            if (ruleType === RuleTypes.NON_SCHEDULED && currentStep === 3) {
              changeStep({ value: -2 });
            } else if (ruleType === RuleTypes.WEB && currentStep === 3) {
              changeStep({ value: -3 });
            } else {
              changeStep({ value: -1 });
            }
          }}
          loading={isLoading}
        >
          <Icon type="left" />
          {this.props.t('previous')}
        </Button>
      ));
    }

    if (currentStep === 3) {
      buttons.push((
        <Button
          key="save-button"
          type="primary"
          onClick={() => this.validateStep() && this.onOk()}
          loading={isLoading}
        >
          { created.id ? this.props.t('update_activity') : this.props.t('create_activity') }
        </Button>
      ));
    } else {
      buttons.push((
        <Button
          key="next-button"
          type="primary"
          onClick={() => {
            if (this.validateStep()) {
              if (ruleType === RuleTypes.NON_SCHEDULED && currentStep === 1) {
                fetchRuleRelations(form);
                changeStep({ value: 2 });
              } else if (ruleType === RuleTypes.WEB && currentStep === 0) {
                fetchRuleRelations(form);
                changeStep({ value: 3 });
              } else {
                if (currentStep === 2) {
                  fetchRuleRelations(form);
                }
                changeStep({ value: 1 });
              }
            }
          }}
          loading={isLoading}
        >
          {this.props.t('next')}
          <Icon type="right" />
        </Button>
      ));
    }

    if (created.id) {
      buttons.push((
        <Popconfirm
          key="delete"
          title={this.props.t('delete_confirmation')}
          cancelText={this.props.t('no')}
          okText={this.props.t('yes')}
          onConfirm={() => {
            onDelete(created.id);
            this.onClose();
          }}
          disabled={fromTaskitProcesses}
        >
          <Button
            type="danger"
            icon="delete"
            style={{ position: 'absolute', left: 0, marginLeft: 16 }}
            loading={isLoading}
            disabled={fromTaskitProcesses}
          >
            {this.props.t('delete')}
          </Button>
        </Popconfirm>
      ));
    }

    return buttons;
  }

  unprocessChecklist = (checklist) => {
    let checklistTasks = [];
    for (let i = 0; i < checklist.length; i += 1) {
      const taskOrGroup = checklist[i];
      if (taskOrGroup.isGroup) {
        const groupTasks = taskOrGroup.checklist.map(t => ({
          ...t,
          groupId: taskOrGroup.id,
          groupInfo: {
            name: taskOrGroup.name,
            repetitive: taskOrGroup.repetitive,
          },
        }));
        checklistTasks = [
          ...checklistTasks,
          ...groupTasks,
        ];
      } else { checklistTasks.push({ ...taskOrGroup }); }
    }
    return checklistTasks;
  };

  processChecklist = (checklist) => {
    const checklistTasks = [];
    for (let i = 0; i < checklist.length; i += 1) {
      const task = checklist[i];
      if (task.groupId) {
        const group = checklistTasks.find(g => (
          g.id === task.groupId));
        if (group) {
          group.checklist.push(task);
        } else {
          checklistTasks.push({
            isGroup: true,
            id: task.groupId,
            name: task.groupInfo.name,
            repetitive: task.groupInfo.repetitive,
            checklist: [task],
          });
        }
      } else { checklistTasks.push({ ...task }); }
    }
    return checklistTasks;
  };

  validateStep = () => {
    const { currentStep, form, updateState } = this.props;
    const { valid, errors } = this.validator.validateForm(form);

    if (!valid) {
      updateState({ path: 'formErrors', value: errors });
      return false;
    }
    updateState({ path: 'formErrors', value: {} });

    switch (currentStep) {
      case 0:
        return this.tasksStep.validate();
      case 1:
        return this.branchOfficeStep.validate();
      default:
        return true;
    }
  }

  buildCron = (duration, events, checkboxDays) => {
    const days = [false, false, false, false, false, false, false];
    const cron = {
      duration: (duration.hours() * 60) + duration.minutes(),
      days: days.map((v, i) => checkboxDays.includes(i)),
      events: events.map(e => e.start.format('HH:mm')).filter((v, i, a) => a.indexOf(v) === i),
    };
    return cron;
  }

  updateName = (name) => {
    const { updateState, form, form: { visibleNameRegister } } = this.props;
    const newForm = {
      ...form,
      name,
    };
    if (!visibleNameRegister) {
      newForm.nameRegister = name;
    }
    updateState({ path: 'form', value: newForm });
  }

  updateVisibleNameRegister = (visible) => {
    const { updateState, form, form: { visibleNameRegister, name } } = this.props;
    const newForm = {
      ...form,
      visibleNameRegister: visible,
    };
    if (visibleNameRegister) {
      newForm.nameRegister = name;
    }
    updateState({ path: 'form', value: newForm });
  }


  renderSteps = (current) => {
    const {
      tasksData,
      evaluationsData,
      branchOfficesData,
      rolesData,
      onSearch,
      searchedUsers,
      loadingRelations,
      ruleRelations,
      fromTaskitProcesses,
    } = this.props;

    switch (current) {
      case 0:
        return (
          <TasksStep
            tasksData={tasksData}
            evaluationsData={evaluationsData}
            ref={(connectedComponent) => {
              this.tasksStep =
                connectedComponent && connectedComponent.getWrappedInstance();
            }}
            fromTaskitProcesses={fromTaskitProcesses}
          />
        );
      case 1:
        return (
          <BranchOfficeStep
            branchOfficesData={branchOfficesData}
            rolesData={rolesData}
            searchedUsers={searchedUsers}
            onSearch={onSearch}
            ref={(connectedComponent) => {
              this.branchOfficeStep =
                connectedComponent && connectedComponent.getWrappedInstance();
            }}
          />
        );
      case 2:
        return (
          <EventsStep />
        );
      case 3:
        if (loadingRelations) return <Loading style={{ height: '200px' }} />;
        return (<Graphviz data={ruleRelations} />);
      default:
        return null;
    }
  }

  render() {
    const {
      visible,
      usersData,
      ruleType,
      updateForm,
      form,
      formErrors,
      currentStep,
      t,
      fromTaskitProcesses,
    } = this.props;
    const selectedUserNotifications = form.userNotifications ?
      form.userNotifications.filter(u => usersData.some(i => i.id === u)) : [];

    return (
      <Modal
        title={t('activity')}
        visible={visible}
        width={1100}
        maskClosable={false}
        closable={false}
        className="modal-header-dark"
        style={{ top: 20 }}
        footer={this.getButtons()}
        maskStyle={{ backgroundColor: '#00000066' }}
        bodyStyle={{ padding: '0px', minHeight: '500px' }}
      >
        <Row className="form-section form-border-bottom lightblue-background">
          <Col sm={24} lg={8} className="form-input">
            <div className="form-label">{t('name')}</div>
            {!fromTaskitProcesses ?
              <InputEmoji
                value={form.name}
                onChange={e => this.updateName(e)}
                cleanOnEnter
                placeholder={t('what_to_do')}
                borderRadius="0px"
              />
            :
              <Input
                value={form.name}
                onChange={e => this.updateName(e.target.value)}
                placeholder={t('what_to_do')}
                disabled={fromTaskitProcesses}
                style={{ height: '43px', margin: '5px 10px', fontSize: '15px' }}
              />
            }
            <ErrorText message={getFieldError(formErrors, 'name')} />
          </Col>
          <Col sm={24} lg={16}>
            <div className="form-label">{t('description')}<span>({t('optional')})</span></div>
            <Input
              value={form.description}
              onChange={e => updateForm({ prop: 'description', value: e.target.value })}
              placeholder={t('add_activity_comment')}
              disabled={fromTaskitProcesses}
              style={{ height: '43px', margin: '5px 10px', fontSize: '15px' }}
            />
          </Col>
        </Row>
        <Row className="form-section form-border-bottom gray-background">
          <Col sm={24} lg={6}>
            <div className="form-label">{t('is_private_activity')}</div>
            <Radio.Group
              onChange={e => updateForm({ prop: 'internal', value: e.target.value })}
              value={form.internal}
            >
              <Radio value={!!true}>{t('yes')}</Radio>
              <Radio value={false}>{t('no')}</Radio>
            </Radio.Group>
          </Col>
          {
            ruleType !== RuleTypes.WEB &&
            <Col sm={24} lg={6}>
              <div className="form-label">{t('is_gps_required')}</div>
              <Radio.Group
                onChange={e => updateForm({ prop: 'gpsRequired', value: e.target.value })}
                value={form.gpsRequired}
              >
                <Radio value={!!true}>{t('yes')}</Radio>
                <Radio value={false}>{t('no')}</Radio>
              </Radio.Group>
            </Col>
          }
          <Col sm={24} lg={12}>
            <div className="form-label">{t('notify_to')}</div>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={t('select_option')}
              notFoundContent={null}
              filterOption={(input, option) => {
                let optValue = option.props.children;

                if (Array.isArray(option.props.children)) {
                  optValue = optValue.join('');
                }

                return optValue.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0;
              }}
              value={selectedUserNotifications}
              onChange={value => updateForm({ prop: 'userNotifications', value })}
            >
              {usersData.map(item => (
                <Select.Option key={item.id}>{item.name} {item.lastName}</Select.Option>
              ))}
            </Select>
          </Col>
          {
          ruleType !== RuleTypes.WEB &&
          <>
            <Col sm={24} lg={12}>
              <div className="form-label">{t('change_activity_name')}</div>
              <Radio.Group
                onChange={e => this.updateVisibleNameRegister(e.target.value)}
                value={form.visibleNameRegister}
              >
                <Radio value={false}>{t('yes')}</Radio>
                <Radio value={!!true}>{t('no')}</Radio>
              </Radio.Group>
            </Col>
            <Col sm={24} lg={12}>
              <div className="form-label">{t('record_name')}</div>
              <Input
                value={form.nameRegister}
                onChange={e => updateForm({ prop: 'nameRegister', value: e.target.value })}
                placeholder={t('enter_activity_name')}
                disabled={!form.visibleNameRegister || fromTaskitProcesses}
              />
              <ErrorText message={getFieldError(formErrors, 'nameRegister')} />
            </Col>
          </>
          }
        </Row>
        <Row className="form-section form-border-bottom">
          <Col sm={24} lg={24}>
            <Steps current={currentStep}>
              <Steps.Step title={t('what_tasks')} />
              {
                ruleType !== RuleTypes.WEB &&
                <Steps.Step title={t('where_who')} />
              }
              {
                ruleType === RuleTypes.SCHEDULED &&
                <Steps.Step title={t('when')} />
              }
              <Steps.Step title={t('relations')} />
            </Steps>
          </Col>
        </Row>
        { this.renderSteps(currentStep) }
      </Modal>
    );
  }
}

const mapStateToProps = ({ activityFormContainer }) => ({
  ...activityFormContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(ActivityForm));
