/*
 * @Author: Cesar Medina
 * @Date: 2018-07-23 10:01:58
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-18 12:26:33
 */

import React, { Component } from 'react';
import { Table, Button } from 'antd';
import { connect } from 'react-redux';
import { init, getFieldError } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';

import columns from './columns';
import validations from './validations';
import { formatForm, formatFormEdit } from './formatForm';
import { actions, defaultActions } from './redux';
import { RenderActionsCell } from '.././../common/components/widgets/table';
import { EditableCell } from '../../common/components/widgets';
import { Error } from '../../common/components/layout';
import './styles.css';
import initActions from './initActions';

class Roles extends Component {
  constructor(props) {
    super(props);
    this.validator = init(validations());
    this.dataColumns = this.getDataColumns();
  }

  componentDidMount = () => {
    initActions(this.props, defaultActions);
  };

  getDataColumns = () => columns().map((item) => {
    if (item.type === 'input') {
      return ({
        ...item,
        render: (text, record) =>
          this.renderInputCell(
            text, record, item.dataIndex, item.maxLength,
            item.required, item.title,
          ),
      });
    }
    return ({
      ...item,
      render: (text, record) => (<RenderActionsCell
        record={record}
        onCancel={this.handleCancel}
        onSave={() => this.save(record.id)}
        onEdit={() => this.handleEdit(record.id)}
        creating={this.props.creating}
        onDelete={() => this.handleDelete(record.id)}
      />),
    });
  });

  handleAdd = () => {
    const { newForm, updateState, catalogs: { roles } } = this.props;

    updateState({ path: 'catalogs.rolesEdit', value: roles });
    updateState({ path: 'catalogs.roles', value: [newForm, ...roles] });
    updateState({ path: 'creating', value: true });
    updateState({ path: 'currentPage', value: 1 });
  }

  handleCancel = () => {
    const { catalogs: { rolesEdit }, updateState, formReset } = this.props;
    updateState({ path: 'catalogs.roles', value: rolesEdit });
    updateState({ path: 'creating', value: false });
    updateState({ path: 'newForm', value: { ...formReset } });
    updateState({ path: 'errorForm', value: false });
    updateState({ path: 'formErrors', value: {} });
  }

  handleChangeForm(value, id, column) {
    const { updateState, catalogs: { roles } } = this.props;
    const newData = [...roles];
    const target = newData.find(item => id === item.id);
    if (target) {
      target[column] = value;
      updateState({ path: 'catalogs.roles', value: newData });
    }
  }

  handleEdit(id) {
    const { updateState, catalogs: { roles } } = this.props;
    updateState({ path: 'catalogs.rolesEdit', value: roles });
    const newData = [...roles];
    const target = newData.map((item) => {
      if (id === item.id) {
        return ({ ...item, editable: true });
      }
      return item;
    });
    updateState({ path: 'catalogs.roles', value: target });
    updateState({ path: 'creating', value: true });
  }

  handleDelete(id) {
    const { deleteRole, catalogs: { roles } } = this.props;
    const target = [...roles].find(item => id === item.id);
    if (target) {
      deleteRole({ form: target });
    }
  }

  save(id) {
    const { createRole, updateRole, catalogs: { roles } } = this.props;
    const target = [...roles].find(item => id === item.id);
    if (target) {
      const { formValid, valid } = this.validateFormFunction(target);
      if (valid) {
        if (target.id === -1) {
          createRole({ form: formValid });
        } else {
          updateRole({ form: formValid });
        }
      }
    }
  }

  validateFormFunction = (target) => {
    const formValid = target.id === -1 ? formatForm(target) : formatFormEdit(target);
    const { valid, errors } = this.validator.validateForm(formValid);
    const { updateState } = this.props;
    if (!valid) {
      updateState({ path: 'formErrors', value: errors });
      updateState({ path: 'errorForm', value: true });
    }
    return ({ formValid, valid });
  }

  renderInputCell(text, record, column, maxLength, required, placeholder) {
    return (
      <EditableCell
        editable={record.editable}
        value={text}
        maxLength={maxLength}
        error={this.props.errorForm}
        errorMessage={getFieldError(this.props.formErrors, column)}
        onChange={value => this.handleChangeForm(value, record.id, column)}
        required={required}
        placeholder={placeholder}
      />
    );
  }

  render() {
    const {
      error,
      isLoading,
      catalogs: { roles },
      creating,
      currentPage,
      t,
    } = this.props;

    if (error) { return (<Error error={error} />); }

    return (
      <div>
        <Button type="primary" onClick={this.handleAdd} disabled={creating}>{t('new_role')}</Button>
        <Table
          rowKey="id"
          dataSource={roles}
          columns={this.dataColumns}
          locale={{ emptyText: t('show_no_data') }}
          pagination={{
            current: currentPage,
            onChange: page => this.props.updateState({ path: 'currentPage', value: page }),
          }}
          loading={isLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ rolesContainer }) => ({
  ...rolesContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(Roles));
