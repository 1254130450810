/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-11 01:10:21
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-18 16:31:15
 */
import moment from 'moment';
import { RuleExecutionStatus } from '../../../../../common/constants';

const finishOn15Minutes = (ruleExecution) => {
  const { cron } = ruleExecution;
  const m = moment.utc();
  const activityEnd = moment.utc(ruleExecution.createdAt).add(cron.duration, 'm');
  const nowPlus15 = m.add(15, 'm');

  return nowPlus15.isAfter(activityEnd);
};

const getWarningActivities = executions => executions.filter(re => !finishOn15Minutes(re));

const getDangerActivities = executions => executions.filter(re => finishOn15Minutes(re));

const getRemainingTime = (ruleExecution) => {
  const now = moment.utc();
  const endTime = moment.utc(ruleExecution.createdAt).add(ruleExecution.cron.duration, 'm');
  const momentLeft = moment.utc(endTime.diff(now));
  const minutesLeft = parseInt(momentLeft.format('m'), 10);
  const hoursLeft = parseInt(momentLeft.format('H'), 10);

  if (hoursLeft > 0) {
    return `Vence en ${hoursLeft} hora y ${minutesLeft} minutos`;
  }

  return `Vence en ${minutesLeft === 0 ? '1' : minutesLeft} minutos`;
};

const validateRuleExecution = (re) => {
  const { cron } = re;
  const m = moment.utc();
  const activityStart = moment.utc(re.createdAt).subtract(1, 'm');
  const activityEnd = moment.utc(re.createdAt).add(cron.duration, 'm');
  const isOnTime = m.isBetween(activityStart, activityEnd, null, '[]');

  const valid = (re.status === RuleExecutionStatus.CREATED && isOnTime);
  const labelExpired = () => {
    const endTime = moment(re.createdAt).add(cron.duration, 'm');
    if (re.status === RuleExecutionStatus.CREATED && endTime < moment()) {
      if (moment(endTime).hours() === 1 || moment(endTime).hours() === 13) {
        return `La actividad expiró a la ${moment(endTime).format('LT')}`;
      }
      return `La actividad expiró a las ${moment(endTime).format('LT')}`;
    }
    return '';
  };

  return { valid, labelExpired };
};

export { getDangerActivities, getWarningActivities, getRemainingTime, validateRuleExecution };

