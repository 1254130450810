/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 14:06:44 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-01-30 15:43:21
 */

import i18next from 'i18next';
import columns from './columns';
import fetchActions from './fetchActions';

const setPaymentStatus = (paymentStatus) => {
  switch (paymentStatus) {
    case 'pending_payment':
      return 'Pendiente';
    case 'declined':
      return 'Declinado';
    case 'expired':
      return i18next.t('expired');
    case 'paid':
      return 'Pagado';
    default:
      return '- - - - - -';
  }
};

export {
  columns,
  fetchActions,
  setPaymentStatus,
};

