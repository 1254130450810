/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-09-07 11:47:08
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './';
import * as generalActions from '../../../store/general/actions';
import {
  REGISTER_WIDGET,
  REGISTER_WIDGET_PERFORMANCE_BY_BRANCH,
  UPDATE_WIDGET,
  DELETE_WIDGET,
} from './types';

import WidgetService from '../../../services/widgets';
import { parseApiError } from '../../../common/error';
import { Notification } from '../../../common/components/widgets';

const widgetService = new WidgetService();

const registerSuccessAction = (record) => {
  Notification.success(i18n.t('success'), `${record.name} ${i18n.t('registered_success')}`, 4);
  return { type: '' };
};

const updateSuccessAction = (record) => {
  Notification.success(i18n.t('success'), `${record.name} ${i18n.t('updated_success')}`, 4);
  return { type: '' };
};

const deleteSuccessAction = (record) => {
  Notification.success(i18n.t('success'), `${record} ${i18n.t('deleted_success')}`, 4);
  return { type: '' };
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const registerWidgetEpic = action$ =>
  action$.ofType(REGISTER_WIDGET).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(widgetService.createWidget(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.fetchWidgets(action.filters)),
            Observable.of(actions.closeModal()),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));
const registerWidgetPerformanceByBranchEpic = action$ =>
    action$.ofType(REGISTER_WIDGET_PERFORMANCE_BY_BRANCH).flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(widgetService.createWidgetPerformanceByBranch(action.form))
          .flatMap(response =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(registerSuccessAction(response)),
              Observable.of(actions.fetchWidgets(action.filters)),
              Observable.of(actions.closeModal()),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                errorMessage(parsedError);
              })),
            )),
      ));

const updateWidgetEpic = action$ =>
  action$.ofType(UPDATE_WIDGET).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(widgetService.updateWidget(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.updateWidgetFulfilled(response)),
            Observable.of(actions.loading(false)),
            Observable.of(updateSuccessAction(response)),
            Observable.of(generalActions.fetchWidgetsData(response, action.filters)),
            Observable.of(actions.closeModal()),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteWidgetEpic = action$ =>
  action$.ofType(DELETE_WIDGET).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(widgetService.deleteWidget(action.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(deleteSuccessAction(response)),
            Observable.of(actions.deleteWidgetFulfilled(action.id)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

export default combineEpics(
  registerWidgetEpic,
  registerWidgetPerformanceByBranchEpic,
  updateWidgetEpic,
  deleteWidgetEpic,
);
