/*
 * @Author: Jhony Reyes 
 * @Date: 2018-12-05 11:36:59 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-08-24 18:42:28
 */

import axios from 'axios';
import Service from '../Service';
import { createCoreServiceUrl, parseParams } from '../../common/helpers/service';


class ContactService extends Service {
  getById = id => axios.get(createCoreServiceUrl(`contacts/${id}`)).then(data => data.data);
  createContact = form => axios.post(createCoreServiceUrl('contacts'), form).then(data => data.data);
  updateContact = form => axios.put(createCoreServiceUrl('contacts'), form).then(data => data.data);
  deleteContact = id => axios.delete(createCoreServiceUrl(`contacts/${id}`)).then(data => data.data);
  getAll = params => axios.get(createCoreServiceUrl(`contacts${parseParams(params)}`)).then(data => data.data);
  getAllInCSVFormat = () => axios.get(createCoreServiceUrl('contacts/csv/export')).then((data) => {
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const a = document.createElement('a');
    a.href = url;
    a.download = 'contacts.csv';
    a.click();
  });
  createContactsWithCSV = form => axios.post(createCoreServiceUrl('contacts/csv/import'), form).then(data => data.data);
}

export default ContactService;
