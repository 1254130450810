/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:39:38
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2023-05-30 22:13:43
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Spin, Result, Empty } from 'antd';
import { withTranslation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';

import DashboardHeader from './utils/components/dashboardHeader';
import WidgetContainer from './utils/components/widgetContainer';
import { WidgetForm, NivoResponsiveBar, NivoResponsivePie, NivoResponsiveLine } from '../../common/components/widgets';
import { TaskTypes, WidgetTypes } from '../../common/constants';
import { Loading } from '../../common/components/layout';
import { actions } from './redux';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    const {
      fetchTasks,
      fetchWidgets,
      fetchBranchOfficesActive,
      filters,
    } = this.props;

    fetchTasks({ type: TaskTypes.ENUM, includeTaskitProccess: true });
    fetchWidgets(filters);
    fetchBranchOfficesActive();
  }

  onSearchRules = (value, params) => {
    this.setState({ searchRule: value });
    clearTimeout(this.timer);
    if (value) {
      this.timer = setTimeout(() => this.fetchRulesByName({
        name: this.state.searchRule,
        ...params,
      }), 250);
    } else { this.fetchRulesByName(params); }
  }

  onBlurRule = () => {
    this.fetchRulesByName();
  }

  onChangeDate = (initDate, endDate) => {
    const { updateState, filters, fetchWidgets } = this.props;
    const updatedFilters = {
      ...filters,
      initDate,
      endDate,
    };

    fetchWidgets(updatedFilters);
    updateState({ path: 'filters', value: updatedFilters });
  }

  onChangeFilter = (filter, value) => {
    const { updateState, filters, fetchWidgets } = this.props;
    const updatedFilters = {
      ...filters,
      [filter]: value,
    };

    fetchWidgets(updatedFilters);
    updateState({ path: 'filters', value: updatedFilters });
  }

  getWidgetPerformanceByBranchData = (widgetResponse) => {
    const { data, widget } = widgetResponse;

    const dataValues = data.filter(Boolean, null).map(selectedBranchOffice => ({
        id: selectedBranchOffice.taskValue,
        value: selectedBranchOffice.quantity,
      }));

    const filteredValues = (widget.selectedEnumValues && widget.selectedEnumValues.length > 0) ?
      dataValues.filter(i => !widget.selectedEnumValues.some(v => v === i.id)) : dataValues;

    return widget.hideEmpty ? filteredValues.filter(i => i.value > 0) : filteredValues;
  };

  getWidgetData = (widgetResponse) => {
    const { task, data, widget } = widgetResponse;
    const dataValues = task.enumValues.split(',').map((enumValue) => {
      const dataResult = data.find(i => i.taskValue.trim() === enumValue.trim());

      return {
        id: enumValue.trim(),
        value: dataResult ? dataResult.quantity : 0,
      };
    });

    const filteredValues = (widget.selectedEnumValues && widget.selectedEnumValues.length > 0) ?
      dataValues.filter(i => !widget.selectedEnumValues.some(v => v === i.id)) : dataValues;

    return widget.hideEmpty ? filteredValues.filter(i => i.value > 0) : filteredValues;
  };

  getWidgetLineData = (widgetResponse) => {
    const { task, data, widget } = widgetResponse;
    const enumValues = task.enumValues.split(',').filter(e => e && e.trim()).map(e => e.trim());
    const dataValues = data.filter(i => enumValues.some(e => e === i.id));

    const filteredValues = (widget.selectedEnumValues && widget.selectedEnumValues.length > 0) ?
      dataValues.filter(i => !widget.selectedEnumValues.some(v => v === i.id)) : dataValues;

    return filteredValues;
  };

  fetchRulesByName = (params) => {
    const { fetchRulesByName, catalogs: { rules: { size } } } = this.props;
    fetchRulesByName({
      page: 0,
      size,
      ...params,
    });
  }

  handleOnSave = (values) => {
    const {
      registerWidget, registerWidgetPerformanceByBranch, updateWidget, selectedWidget, filters,
    } = this.props;

    if (selectedWidget.id) {
      updateWidget({ ...selectedWidget, ...values }, filters);
    } else if (values.type === WidgetTypes.PERFORMANCE_BY_BRANCH) {
      const newValues = { ...values };
      newValues.taskId = '';
      registerWidgetPerformanceByBranch(newValues, filters);
    } else {
      registerWidget(values, filters);
    }
  }

  handleAdd = () => {
    const { updateState, openModal } = this.props;
    updateState({ path: 'selectedWidget', value: {} });
    openModal();
  }

  handleEdit = (widget) => {
    const { updateState, openModal } = this.props;
    updateState({ path: 'selectedWidget', value: widget });
    openModal();
  }

  handleDelete = (widget) => {
    const { deleteWidget } = this.props;
    deleteWidget(widget.id);
  }

  customTooltip = ({ slice }) => {
    const date = slice.points[0].data.xFormatted;
    return (
      <div
        style={{
          background: 'white',
          padding: '9px 12px',
          border: '1px solid #ccc',
        }}
      >
        <strong>{date}</strong>
        {slice.points.map(point => (
          <div
            key={point.id}
            style={{
              color: point.serieColor,
              padding: '3px 0',
            }}
          >
            <strong>{point.serieId}</strong>
            <span style={{ color: 'darkgray' }}> ({point.data.yFormatted.toFixed(2)})</span>
          </div>
        ))}
      </div>
    );
  };

  renderChart = (widget) => {
    let data;
    let isEmpty = false;

    if (widget.type === WidgetTypes.LINE) {
      data = this.getWidgetLineData(widget.data);
      isEmpty = !data.some(i => i.data.length > 0);
    } else if (widget.type === WidgetTypes.TIME_BETWEEN_RULES) {
      data = widget.data && widget.data.data ? widget.data.data : [];
      if (widget.presentationType && widget.presentationType === 'percentage') {
        isEmpty = !data.length > 0;
      } else {
        isEmpty = !data.some(i => i.data.length > 0);
      }
    } else if (widget.type === WidgetTypes.PERFORMANCE_BY_BRANCH) {
      data = this.getWidgetPerformanceByBranchData(widget.data);
      isEmpty = !data.some(i => i.value > 0);
    } else {
      data = this.getWidgetData(widget.data);
      isEmpty = !data.some(i => i.value > 0);
    }

    if (isEmpty) {
      return <Empty style={{ height: '250px' }} description="Sin datos" />;
    }

    switch (widget.type) {
      case WidgetTypes.PERFORMANCE_BY_BRANCH:
        return <NivoResponsiveBar data={data} />;
      case WidgetTypes.BAR:
        return <NivoResponsiveBar data={data} />;
      case WidgetTypes.PIE:
        return <NivoResponsivePie data={data} />;
      case WidgetTypes.LINE:
        return (
          <NivoResponsiveLine
            data={data}
            refValue={widget.referenceNumber}
            initDate={this.props.filters.initDate}
            endDate={this.props.filters.endDate}
          />
          );
      case WidgetTypes.TIME_BETWEEN_RULES:
        if (widget.presentationType && widget.presentationType === 'percentage') {
          return (<ResponsiveBar
            data={data}
            {...{
                margin: {
 top: 20, right: 50, bottom: 40, left: 40,
},
                indexBy: 'month',
                keys: ['inTime', 'notInTime'],
                colors: bar => ({ inTime: 'hsl(212, 83%, 64%)', notInTime: 'hsl(354, 70%, 50%)' }[bar.id]),
                axisLeft: { format: v => `${v}%` },
                padding: data.length > 3 ? 0.3 : 0.7,
                labelFormat: v => `${v}%`,
                tooltipFormat: v => `${v}%`,
                axisBottom: {
                  tickSize: 0,
                  tickPadding: 5,
                  tickRotation: 20,
                },
                labelTextColor: { from: 'color', modifiers: [['darker', 1.4]] },
          }}
          />);
        }
        return (
          <NivoResponsiveLine
            data={data}
            refValue={widget.referenceNumber}
            initDate={data[0].data[0].x}
            endDate={data[data.length - 1].data[data[data.length - 1].data.length - 1].x}
            formatHours
            extraProps={{
              sliceTooltip: this.customTooltip,
              xScale: {
                type: 'time',
                format: '%Y-%m-%d %H:%M:%S',
                useUTC: false,
                precision: 'hour',
              },
              xFormat: 'time:%d/%m/%y %H:%M:%S',
              yScale: {
                type: 'linear',
                stacked: false,
                min: 'auto',
              },
            }}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      closeModal,
      showWidgetForm,
      selectedWidget,
      catalogs: {
        tasks, widgets, branchOffices, rules,
      },
      filters,
      loadingWidgets,
      t,
      isLoading,
    } = this.props;

    if (loadingWidgets) {
      return <Loading />;
    }

    return (
      <div>
        <DashboardHeader
          filters={filters}
          branchOffices={branchOffices}
          onChangeDate={this.onChangeDate}
          onChangeFilter={this.onChangeFilter}
        />
        <Row className="actions-container">
          <Button type="link" onClick={this.handleAdd}>+ {t('add_widget')}</Button>
        </Row>
        <Row type="flex" style={{ padding: '10px' }}>
          {
            widgets.map(i => (
              <Col xs={24} sm={12} key={i.id}>
                <WidgetContainer
                  title={i.name}
                  onEdit={() => this.handleEdit(i)}
                  onDelete={() => this.handleDelete(i)}
                >
                  <Spin spinning={!i.data && !i.error}>
                    <div style={{ height: '250px' }}>
                      {i.error && <Result status="warning" subTitle={t('something_went_wrong')} />}
                      {i.data && this.renderChart(i)}
                    </div>
                  </Spin>
                </WidgetContainer>
              </Col>
            ))
          }
        </Row>
        {
          showWidgetForm &&
          <WidgetForm
            widget={selectedWidget}
            onSave={this.handleOnSave}
            onClose={closeModal}
            tasks={tasks}
            branchOffices={branchOffices}
            rules={rules.content}
            fetchRulesByName={this.fetchRulesByName}
            onSearchRules={this.onSearchRules}
            onBlurRule={this.onBlurRule}
            fetchTasks={this.props.fetchTasks}
            loading={isLoading}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = ({ widgetsContainer }) => ({
  ...widgetsContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(Dashboard));
