/*
 * @Author: Jhony Reyes 
 * @Date: 2019-01-24 15:03:06 
 * @Last Modified by:   Jhony Reyes 
 * @Last Modified time: 2019-01-24 15:03:06 
 */

import React, { Component } from 'react';
import { Button, List } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const defaultState = {
  checklist: {},
};

class Builder extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentWillMount = () => {
    const { created } = this.props;
    this.setState({ checklist: created });
  }

  render() {
    const { checklist } = this.state;
    const { onDone, t } = this.props;
    const data = Object.values(checklist);

    return (
      <div>
        <span style={{ color: '#5D89FF', fontWeight: 'bold' }}>
          {t('assigned_permissions')}:
        </span>
        <List
          dataSource={data}
          style={{ maxHeight: '20rem', overflow: 'auto' }}
          renderItem={item => (
            <List.Item>
              {item.name}
            </List.Item>
            )}
          locale={{ emptyText: t('no_assigned_permissions') }}
        />
        <div className="justify-center margin-top-1">
          <Button
            className="margin-left-half-rem"
            type="primary"
            onClick={onDone}
          >
            {t('accept')}
          </Button>
        </div>
      </div>
    );
  }
}

Builder.propTypes = {
  onDone: PropTypes.func,
};

Builder.defaultProps = {
  onDone: () => {},
};

export default withTranslation()(Builder);
