/*
 * @Author: Jhony Reyes 
 * @Date: 2018-12-10 14:17:02 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-12-10 14:28:30
 */

const disableFields = (id, data) => {
  const permissionExist = data.find(item => item.id === id);
  if (permissionExist && permissionExist.keyName === 'DIRECTOR_KEY') {
    return true;
  }
  return false;
};

export default disableFields;
