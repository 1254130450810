/*
 * @Author: Jhony Reyes 
 * @Date: 2018-12-05 11:36:59 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-06-19 23:10:40
 */

import axios from 'axios';
import Service from '../Service';
import { createRulesServiceUrl, parseParams } from '../../common/helpers/service';

class WidgetService extends Service {
  getById = id => axios.get(createRulesServiceUrl(`widgets/${id}`)).then(data => data.data);
  createWidget = form => axios.post(createRulesServiceUrl('widgets'), form).then(data => data.data);
  createWidgetPerformanceByBranch = form => axios.post(createRulesServiceUrl('widgets'), form).then(data => data.data);
  updateWidget = form => axios.put(createRulesServiceUrl('widgets'), form).then(data => data.data);
  deleteWidget = id => axios.delete(createRulesServiceUrl(`widgets/${id}`)).then(data => data.data);
  getAll = () => axios.get(createRulesServiceUrl('widgets')).then(data => data.data);
  getWidgetData = (widgetId, params = {}) => axios.get(createRulesServiceUrl(`widgets/${widgetId}/data${parseParams(params)}`))
    .then(data => data.data);
}

export default WidgetService;
