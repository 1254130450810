/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-06 09:46:46 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-03-25 10:06:52
 */
const CONTAINER_KEY = 'BRANCH_OFFICES_CONTAINER';

export const CREATE_BRANCH_OFFICE = `${CONTAINER_KEY}_CREATE_BRANCH_OFFICE`;
export const CREATE_BRANCH_OFFICES_FULFILLED = `${CONTAINER_KEY}_CREATE_BRANCH_OFFICES_FULFILLED`;
export const UPDATE_BRANCH_OFFICE = `${CONTAINER_KEY}_UPDATE_BRANCH_OFFICE`;
export const DELETE_BRANCH_OFFICE = `${CONTAINER_KEY}_DELETE_BRANCH_OFFICE`;
export const FETCH_BRANCH_OFFICES_BY_STATE = `${CONTAINER_KEY}_FETCH_BRANCH_OFFICES_BY_STATE`;
