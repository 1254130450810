/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-30 13:28:29 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-07-30 13:29:06
 */

const capitalizeText = (str) => {
  const text = str.toLowerCase();
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export default capitalizeText;
