/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:39:38
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-05-19 12:52:06
 */
import React from 'react';
import { withTranslation } from 'react-i18next';


import { TableauView } from '../../common/components/widgets';

const ChartViewer = ({
    location: {
         state: {
            url,
            parameters,
            options,
        },
    },
}) => (
  <TableauView
    url={url}
    parameters={parameters}
    options={options}
  />
);

export default withTranslation()(ChartViewer);
