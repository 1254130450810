/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-15 12:02:01
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-20 11:30:50
 */
const CONTAINER_KEY = 'GENERAL_DATA';

// Used to load common data. Actions hanlded by epics.
export const FETCH_ROLES = `${CONTAINER_KEY}_FETCH_ROLES`;
export const FETCH_BRANCH_OFFICES = `${CONTAINER_KEY}_FETCH_BRANCH_OFFICES`;
export const FETCH_BRANCH_OFFICES_ACTIVE = `${CONTAINER_KEY}_FETCH_BRANCH_OFFICES_ACTIVE`;
export const FETCH_STATES = `${CONTAINER_KEY}_FETCH_STATES`;
export const FETCH_PERMISSIONS = `${CONTAINER_KEY}_FETCH_PERMISSIONS`;
export const FETCH_SUBSCRIPTION_PLAN = `${CONTAINER_KEY}_FETCH_SUBSCRIPTION_PLAN`;
export const FETCH_USER_INFO_BY_TOKEN = `${CONTAINER_KEY}_FETCH_USER_INFO_BY_TOKEN`;
export const FETCH_AVAILABLE_USERS = `${CONTAINER_KEY}_FETCH_AVAILABLE_USERS`;
export const FETCH_RULES_BY_FILTERS = `${CONTAINER_KEY}_FETCH_RULES_BY_FILTERS`;
export const SEARCH_USERS = `${CONTAINER_KEY}_SEARCH_USERS`;

// Handled by each reducer
export const FETCH_USERS = `${CONTAINER_KEY}_FETCH_USERS`;
export const FETCH_USERS_FULFILLED = `${FETCH_USERS}_FULFILLED`;
export const FETCH_RULES_FULFILLED = `${CONTAINER_KEY}_FETCH_RULES_FULFILLED`;
export const FETCH_ROLES_FULFILLED = `${FETCH_ROLES}_FULFILLED`;
export const FETCH_BRANCH_OFFICES_FULFILLED = `${FETCH_BRANCH_OFFICES}_FULFILLED`;
export const FETCH_STATES_FULFILLED = `${FETCH_STATES}_FULFILLED`;
export const FETCH_PERMISSIONS_FULFILLED = `${FETCH_PERMISSIONS}_PERMISSIONS`;
export const FETCH_SUBSCRIPTION_PLAN_FULFILLED = `${FETCH_SUBSCRIPTION_PLAN}_FULFILLED`;
export const FETCH_USER_INFO_BY_TOKEN_FULFILLED = `${FETCH_USER_INFO_BY_TOKEN}_FULFILLED`;
export const FETCH_AVAILABLE_USERS_FULFILLED = `${CONTAINER_KEY}_FETCH_AVAILABLE_USERS_FULFILLED`;
export const SEARCH_USERS_FULFILLED = `${CONTAINER_KEY}_SEARCH_USERS_FULFILLED`;
export const FETCH_TASKS = `${CONTAINER_KEY}_FETCH_TASKS`;
export const FETCH_TASKS_FULFILLED = `${FETCH_TASKS}_FULFILLED`;
export const FETCH_TASK_GROUPS = `${CONTAINER_KEY}_FETCH_TASK_GROUPS`;
export const FETCH_TASK_GROUPS_FULFILLED = `${FETCH_TASKS}_GROUPS_FULFILLED`;
export const FETCH_EVALUATIONS = `${CONTAINER_KEY}_FETCH_EVALUATIONS`;
export const FETCH_EVALUATIONS_FULFILLED = `${FETCH_EVALUATIONS}_FULFILLED`;
export const FETCH_CONTACTS = `${CONTAINER_KEY}_FETCH_CONTACTS`;
export const FETCH_CONTACTS_FULFILLED = `${FETCH_CONTACTS}_FULFILLED`;
export const FETCH_RULE_BY_ID = `${CONTAINER_KEY}_FETCH_RULE_BY_ID`;
export const FETCH_RULE_BY_ID_FULFILLED = `${FETCH_RULE_BY_ID}_FULLFILLED`;
export const FETCH_RULE_RELATIONS = `${CONTAINER_KEY}_FETCH_RULE_RELATIONS`;
export const FETCH_RULE_RELATIONS_FULFILLED = `${FETCH_RULE_RELATIONS}_FULLFILLED`;
export const FETCH_GROUPS = `${CONTAINER_KEY}_FETCH_GROUPS`;
export const FETCH_GROUPS_FULFILLED = `${FETCH_GROUPS}_FULFILLED`;
export const FETCH_WHATSAPP_CUSTOMERS = `${CONTAINER_KEY}_FETCH_WHATSAPP_CUSTOMERS`;
export const FETCH_WHATSAPP_CUSTOMERS_FULFILLED = `${FETCH_WHATSAPP_CUSTOMERS}_FULFILLED`;
export const FETCH_WIDGETS = `${CONTAINER_KEY}_FETCH_WIDGETS`;
export const FETCH_WIDGETS_FULFILLED = `${FETCH_WIDGETS}_FULFILLED`;
export const FETCH_WIDGETS_DATA = `${CONTAINER_KEY}_FETCH_WIDGETS_DATA`;
export const FETCH_WIDGETS_DATA_FULFILLED = `${FETCH_WIDGETS_DATA}_FULFILLED`;
export const FETCH_RULES_BY_NAME = `${CONTAINER_KEY}FETCH_RULES_BY_NAME`;
export const FETCH_RULES_BY_NAME_FULFILLED = `${CONTAINER_KEY}FETCH_RULES_BY_NAME_FULFILLED`;
export const FETCH_NOT_SCHEDULED_RULES_FULFILLED = `${CONTAINER_KEY}_FETCH_NOT_SCHEDULED_RULES_FULFILLED`;
export const FETCH_NOT_SCHEDULED_RULES = `${CONTAINER_KEY}_FETCH_NOT_SCHEDULED_RULES`;
export const FETCH_RULES_FILTER_FULFILLED = `${CONTAINER_KEY}_FETCH_RULES_FILTER_FULFILLED`;
export const FETCH_RULES_FILTER = `${CONTAINER_KEY}_FETCH_RULES_FILTER`;
export const FETCH_EXECUTIONS = `${CONTAINER_KEY}_FETCH_EXECUTIONS`;
export const FETCH_EXECUTIONS_FULFILLED = `${CONTAINER_KEY}_FETCH_EXECUTIONS_FULFILLED`;
export const FETCH_EXECUTION_BY_ID = `${CONTAINER_KEY}_FETCH_EXECUTION_BY_ID`;
export const FETCH_EXECUTION_BY_ID_FULFILLED = `${CONTAINER_KEY}_FETCH_EXECUTION_BY_ID_FULFILLED`;
export const FETCH_NOTIFICATIONS = `${CONTAINER_KEY}_FETCH_NOTIFICATIONS`;
export const FETCH_NOTIFICATIONS_FULFILLED = `${FETCH_NOTIFICATIONS}_FULFILLED`;
export const FETCH_NOTIFICATIONS_BY_USER = `${CONTAINER_KEY}_FETCH_NOTIFICATIONS_BY_USER`;
export const FETCH_NOTIFICATIONS_BY_USER_FULFILLED = `${FETCH_NOTIFICATIONS_BY_USER}_FULFILLED`;
