/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-20 15:41:30 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-01-06 12:19:17
 */

import i18n from 'i18next';

const defaultFormValidations = () => ({
  email: [
    { validate: 'required', message: i18n.t('required_email') },
    { validate: 'email', message: i18n.t('invalid_email') },
    {
      validate: (_, form) => form.email.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
});

const resetFormValidations = () => ({
  password: [
    { validate: 'required', message: i18n.t('password_required') },
    {
      validate: (_, resetForm) => resetForm.password.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, resetForm) => {
        if (resetForm.password.length < 8) {
          return false;
        }
        return true;
      },
      message: i18n.t('min_8_characters'),
      custom: true,
    },
  ],
  newPassword: [
    { validate: 'required', message: i18n.t('confirm_password') },
    {
      validate: (_, resetForm) => resetForm.newPassword.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, resetForm) => {
        if (resetForm.newPassword.length < 8) {
          return false;
        }
        return true;
      },
      message: i18n.t('min_8_characters'),
      custom: true,
    },
    {
      validate: (_, resetForm) => resetForm.newPassword === resetForm.password,
      message: i18n.t('passwords_not_match'),
      custom: true,
    },
  ],
});

export { defaultFormValidations, resetFormValidations };
