/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 15:57:29 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 17:14:51
 */
import i18n from 'i18next';

import { renderColumn } from '../../../common/components/widgets/table';

const columns = () => [
  renderColumn(i18n.t('Fecha de pago'), 'createdAt', 'input', 180, 100, true),
  renderColumn('Cantidad', 'quantity', 'input', 50, 10, true),
  renderColumn('Precio Unitario', 'unitPrice', 'input', 50, 10, true),
  renderColumn(i18n.t('total'), 'amount', 'input', 150, 50, true),
  renderColumn('Moneda', 'currency', 'input', 50, 10, true),
  renderColumn('Número de tarjeta', 'last4', 'input', 280, 100, true),
  renderColumn(i18n.t('state'), 'status', 'input', 280, 100, true),
];

export default columns;
