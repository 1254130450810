/*
 * @Author: Jhony Reyes 
 * @Date: 2018-12-26 10:38:52 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-05-08 12:07:32
 */
import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import i18n from 'i18next';

import validations from './validations';
import { isAuthenticated } from '../../../security';

const statusTypes = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  PAYMENT_FAILED: 'payment_failed',
};

const subscriptionTypes = {
  MONTHLY: 'monthly',
  YEAR: 'year',
  FREE: 'free',
};

const validateInputStatus = (value) => {
  if (value.length > 0) {
    return 'success';
  }
  return null;
};

const fetchPaymentSourceAction = (fetchPaymentSourceByUserId) => {
  const user = isAuthenticated();
  fetchPaymentSourceByUserId(user.id);
};

const planStatus = (status) => {
  switch (status) {
    case 'active':
      return i18n.t('active');
    case 'in_trial':
      return i18n.t('test');
    case 'paused':
      return i18n.t('paused');
    case 'canceled':
      return i18n.t('canceled');
    case 'past_due':
      return i18n.t('expired');
    default:
      return '';
  }
};

const statusPlanColor = (status) => {
  switch (status) {
    case 'active':
      return '#00962a';
    case 'canceled':
      return '  #F75C4B';
    default:
      return '#FBC02D';
  }
};

const ShowBillingCycleEnd = ({ subscription, onResume }) => {
  if (subscription.status === 'canceled' && moment().isBefore(moment.unix(subscription.billingCycleEnd))) {
    return <div>{i18n.t('valid_until')} {moment.unix(subscription.billingCycleEnd).format('DD/MM/YYYY, hh:mm a')}</div>;
  }
  return <Button type="primary" onClick={onResume}>{i18n.t('resume_subscription')}</Button>;
};

export {
  validateInputStatus, validations, fetchPaymentSourceAction,
  planStatus, statusPlanColor, ShowBillingCycleEnd,
  statusTypes, subscriptionTypes,
};

