/*
 * @Author: Jhony Reyes 
 * @Date: 2018-12-19 12:00:49 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-01-31 12:19:42
 */

import React, { Component } from 'react';
import moment from 'moment';
import { Button, Table, Popconfirm, Tag, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { init } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';

import { actions } from './redux';
import { Notification, AvailableUsers } from '../../common/components/widgets';
import { Loading } from '../../common/components/layout';
import { validations, statusTypes, subscriptionTypes } from './utils';


class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.validator = init(validations());
  }

  componentWillMount() {
    const {
      resetState,
      fetchSubscriptions, fetchAvailableUsers,
    } = this.props;

    resetState();
    fetchSubscriptions();
    fetchAvailableUsers();
  }

  componentDidMount() {

  }

  getColumns = () => {
    const columns = [{
      title: this.props.t('initial_date'),
      dataIndex: 'billingCycleStart',
      key: 'billingCycleStart',
      render: text => moment(text).format('DD/MM/YYYY'),
    }, {
      title: this.props.t('final_date'),
      key: 'billingCycleEnd',
      dataIndex: 'billingCycleEnd',
      render: text => moment(text).format('DD/MM/YYYY'),
    }, {
      title: this.props.t('users'),
      dataIndex: 'usersPurchased',
      key: 'usersPurchased',
    }, {
      title: this.props.t('type'),
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        const subscriptionTypesLabels = {
          monthly: { label: this.props.t('monthly') },
          year: { label: this.props.t('anual') },
          free: { label: this.props.t('gratis') },
        };
        const type = subscriptionTypesLabels[record.type].label;

        return type;
      },
    }, {
      title: this.props.t('total'),
      dataIndex: 'amount',
      key: 'amount',
      render: text => `$${text}`,
    }, {
      title: this.props.t('state'),
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => {
        const statusTypesLabels = {
          active: { label: this.props.t('active'), color: 'green' },
          canceled: { label: this.props.t('canceled'), color: 'volcano' },
          expired: { label: this.props.t('expired'), color: null },
          payment_failed: { label: this.props.t('payment_failed'), color: 'magenta' },
        };
        const type = statusTypesLabels[record.status];

        return <Tag color={type.color}>{type.label}</Tag>;
      },
    }, {
      title: this.props.t('actions'),
      key: 'actions',
      align: 'center',
      render: (text, record) => (
        <div>{this.getActionButton(record)}</div>
      ),
    }];

    return columns;
  }

  getActionButton = (record) => {
    if (record.type === subscriptionTypes.FREE) {
      return '';
    }

    switch (record.status) {
      case statusTypes.ACTIVE:
        return (
          <Popconfirm
            title={this.props.t('cancel_subscription_confirmation')}
            onConfirm={() => this.props.updateStatus(record.id, statusTypes.CANCELED)}
            cancelText={this.props.t('no')}
            okText={this.props.t('yes')}
            placement="left"
          >
            <Button>{this.props.t('cancel')}</Button>
          </Popconfirm>
        );
      case statusTypes.CANCELED:
        return (
          <Popconfirm
            title={this.props.t('resume_subscription_confirmation')}
            onConfirm={() => this.props.updateStatus(record.id, statusTypes.ACTIVE)}
            cancelText={this.props.t('no')}
            okText={this.props.t('yes')}
            placement="left"
          >
            <Button>{this.props.t('resume')}</Button>
          </Popconfirm>
        );
      case statusTypes.EXPIRED:
        return <Button>{this.props.t('renew')}</Button>;
      case statusTypes.PAYMENT_FAILED:
        return <Button>{this.props.t('pay')}</Button>;
      default:
        return '';
    }
  }

  errorResponseHandler = (error) => {
    const { updateState } = this.props;

    updateState({ path: 'loadingButton', value: false });
    Notification.error('Error', error.message_to_purchaser);
  };

  handleResumeSubscription = () => this.props.generateSubscription('');

  handlePayment = () => {
    const { paymentSource, changePaymentMethod, planInfo } = this.props;

    if (paymentSource && changePaymentMethod) {
      return true;
    }

    if (!planInfo.planId) {
      return true;
    }

    return false;
  }

  successResponseHandler = (token) => {
    const {
      generateSubscription, changePaymentMethod,
      updatePaymentMethod,
    } = this.props;

    if (changePaymentMethod) {
      return updatePaymentMethod(token.id);
    }

    return generateSubscription(token.id, '', '', '');
  };

  render() {
    const {
      isLoading, currentPage, subscriptions,
      purchasedUsers, availableUsers, t,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <Row style={{ marginBottom: '10px' }}>
          <Col span={6}>
            <Link to="/payments">
              <Button onClick={() => { }} type="primary" style={{ marginBottom: 16 }}>
                {t('new_subscription')}
              </Button>
            </Link>
          </Col>
          <Col span={9} offset={9}>
            <Row justify="end" type="flex">
              <AvailableUsers purchasedUsers={purchasedUsers} availableUsers={availableUsers} />
            </Row>
          </Col>
        </Row>
        <Table
          scroll={{ x: 1024 }}
          ref={(table) => { this.table = table; }}
          rowKey="id"
          dataSource={subscriptions}
          columns={this.getColumns()}
          locale={{ emptyText: t('show_no_data') }}
          loading={isLoading}
          pagination={{
            current: currentPage,
            onChange: page => this.props.updateState({ path: 'currentPage', value: page }),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ subscriptionContainer }) => subscriptionContainer;

export default withTranslation()(connect(mapStateToProps, actions)(Subscriptions));
