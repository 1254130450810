/*
 * @Author: Cesar Medina
 * @Date: 2019-07-02 14:41:21 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2022-06-30 20:58:39
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Icon, Avatar, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { RuleTypes, RuleExecutionStatus, TaskTypes } from '../../../constants';
import draftIcon from '../../../../assets/icons/draft.svg';
import inProcessIcon from '../../../../assets/icons/inprocess.svg';
import './executionCard.css';
import { getFormattedFolio } from '../../../helpers/functions';
import SimpleUserInfo from '../../../../common/components/widgets/simpleUserInfo';

const renderIcon = (execution, status) => {
  if (execution.ruleType === RuleTypes.WEB) {
    return <i className="fa fa-globe" aria-hidden="true" style={{ color: '#427BC6' }} />;
  }

  switch (status) {
    case 'done':
      return <Icon type="check-circle" theme="filled" style={{ color: '#31B550', fontSize: '25px' }} />;
    case 'expired':
      return <Icon type="close-circle" theme="filled" style={{ color: '#D54C21', fontSize: '25px' }} />;
    case 'process':
      return <img src={inProcessIcon} alt="" />;
    case 'pending':
      return <img src={draftIcon} alt="" />;
    default:
      return '';
  }
};

const renderActivityInfo = (execution, status) => {
  switch (status) {
    case 'expired':
      return (
        <div className="data-left__section info-section__text">
          {i18n.t('expired_fem')}
          { execution.ruleType !== RuleTypes.WEB && <span className="info-divider info-divider--hide" /> }
        </div>
      );
    case 'process':
      return (
        <div className="data-left__section info-section__text">
          {i18n.t('in_process')}
          { execution.ruleType !== RuleTypes.WEB && <span className="info-divider info-divider--hide" /> }
        </div>
      );
    default:
      return null;
  }
};

const checkRated = (execution) => {
  if (execution.evidence && execution.evidence.completion) {
    if (execution.evidence.completion.rate ||
        execution.evidence.completion.checklist.some(t => typeof t.rating === 'number')) {
      return true;
    }
  }

  return false;
};

const isTextFormatTaskType = (type) => {
  switch (type) {
    case TaskTypes.TEXT:
      return true;
    case TaskTypes.NUMERIC:
      return true;
    case TaskTypes.DATE:
      return true;
    case TaskTypes.TIME:
      return true;
    case TaskTypes.ENUM:
      return true;
    case TaskTypes.MULTI_ENUM:
      return true;
    case TaskTypes.CHECK:
      return true;
    case TaskTypes.BARCODE:
      return true;
    case TaskTypes.RULE_FOLIO:
      return true;
    case TaskTypes.USER:
      return true;
    case TaskTypes.CONTACT:
      return true;
    default:
      return false;
  }
};

const orderByTrackedTasks = (execution) => {
  let checklistVisibleEvidence = [];
  if (execution.evidence !== null) {
    if (execution.evidence.completion !== null) {
      checklistVisibleEvidence = execution.evidence.completion.checklist
        .filter(task => !!task.visibleEvidence && !!task.value);
    }
  }

  if (checklistVisibleEvidence.length && execution.evidence && execution.evidence.completion &&
    execution.evidence.completion.checklist && execution.evidence.completion.checklist.length) {
    const checklist = checklistVisibleEvidence.filter(t => isTextFormatTaskType(t.type));
    if (checklist && checklist.length) {
      const withTracking = [];
      const withoutTracking = [];
      checklist.forEach((task) => {
        if (task.tracking) {
          withTracking.push(task);
        } else {
          withoutTracking.push(task);
        }
      });
      const orderTasks = withTracking.concat(withoutTracking);
      return orderTasks;
    }
  }

  return [];
};

const getTaskValue = (task, color) => {
  switch (task.type) {
    case !task.value:
      return i18n.t('no_value');
    case TaskTypes.CHECK:
      return task.value ? i18n.t('completed') : i18n.t('not_completed');
    case TaskTypes.CONTACT:
      if (Array.isArray(task.value)) {
        return task.value.map((contact, index) => `${contact.name } ${ contact.lastName
         }${index === task.value.length - 1 ? '' : ', \n'}`);
      }
      return '';
    case TaskTypes.USER:
      return <SimpleUserInfo id={task.value} evidence color={color} />;
    default:
      return task.value;
  }
};

const renderTrackedTasks = (tasks) => {
  const dimensions = [
    {
      xs: 12, sm: 6, md: 5, lg: 6, xl: 5,
    },
    {
      xs: 12, sm: 6, md: 5, lg: 6, xl: 5,
    },
    {
      xs: 0, sm: 6, md: 4, lg: 6, xl: 4,
    },
    {
      xs: 0, sm: 6, md: 5, lg: 6, xl: 5,
    },
    {
      xs: 0, sm: 0, md: 5, lg: 0, xl: 5,
    },
  ];

  return (
    <div>
      <Row>
        {
          tasks.slice(0, 5).map((t, index) => (
            <Col
              key={t.name}
              xs={dimensions[index].xs}
              sm={dimensions[index].sm}
              md={dimensions[index].md}
              lg={dimensions[index].lg}
              xl={dimensions[index].xl}
              className="info-section__label"
            >
              {t.name}:
            </Col>
          ))
        }
      </Row>
      <Row>
        {
          tasks.slice(0, 5).map((t, index) => (
            <Col
              key={t.name}
              xs={dimensions[index].xs}
              sm={dimensions[index].sm}
              md={dimensions[index].md}
              lg={dimensions[index].lg}
              xl={dimensions[index].xl}
              className="info-section__text"
            >
              <Tooltip
                title={getTaskValue(t, 'white')}
              >
                <div
                  style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                  {getTaskValue(t)}
                </div>
              </Tooltip>
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

const executionCard = ({
  folio,
  ruleName,
  executionName,
  branchOffice,
  date,
  roleName,
  username,
  profileImageUrl,
  status,
  image,
  id,
  done,
  execution,
  rules,
  onClick,
}) => {
  const isRated = checkRated(execution);
  const trackedTasks = orderByTrackedTasks(execution, rules);
  const { t } = useTranslation();

  return (
    <Link
      to={onClick ? '#' : `/evidences/${id}`}
      className={done ? '' : 'disabled-link'}
      onClick={(e) => {
        if (onClick) {
          onClick();
          return false;
        }
          return !done && e.preventDefault();
      }}
    >
      <Row type="flex" className="execution-card">
        <Col sm={24} xxl={20} style={{ maxWidth: '100%' }}>
          {
            execution.ruleType !== RuleTypes.WEB &&
            status === RuleExecutionStatus.DONE &&
            <Row className="rating-section">
              <span className={`rating-section__ribbon ${isRated ? '' : 'rating-section__ribbon--yellow'}`}>
                {isRated ? t('graded') : t('pending_evaluation')}
              </span>
              {
                isRated &&
                <span className="rating-section__ribbon-rate">{execution.evidence.completion.total.toFixed(1)}%</span>
              }
            </Row>
          }
          <Row className="execution-card__info-section">
            <div className="info-wrapper">
              <div className="info-section__title">{renderIcon(execution, status)}&nbsp;{`${getFormattedFolio(folio)}${executionName}`}</div>
              <div className="info-section__subtitle">{`${t('original_activity')}: ${ruleName}`}</div>
              <div className="info-section__data-right">
                <div className="data-right__section data-right__section--primary">&nbsp;</div>
                <div className="data-right__section data-right__section--secondary">{date}</div>
                <div className="data-right__section data-right__section--purple">
                  {execution.collaborative ? t('collaborative') : ''}
                </div>
              </div>
              <div className="info-section__data-left">
                {renderActivityInfo(execution, status)}
                {
                  execution.ruleType !== RuleTypes.WEB &&
                  <div className="data-left__section data-left__section info-section__text">
                    <span className="data-left__inline-container">
                      <span
                        className="info-section__label info-section__label--absolute"
                      >
                        {t('branch_office')}
                      </span>
                      <span>{branchOffice}</span>
                    </span>
                    <span className="info-divider" />
                    <span className="data-left__inline-container">
                      <span
                        className="info-section__label info-section__label--absolute"
                      >
                        {t('role')}
                      </span>
                      <span>{roleName}</span>
                    </span>
                    <span className="info-divider" />
                    <Avatar
                      size="small"
                      src={profileImageUrl}
                      icon="user"
                      className="execution-card__avatar"
                    />
                    <span className="data-left__inline-container">
                      <span
                        className="info-section__label info-section__label--absolute"
                      >
                        {t('user')}
                      </span>
                      <span
                        style={{
                          display: 'inline-block',
                          whiteSpace: 'nowrap',
                          width: '50px',
                        }}
                      >
                        {username}
                      </span>
                    </span>
                  </div>
                }
              </div>
            </div>
          </Row>
          <Row className="execution-card__task-section">
            {
              trackedTasks.length <= 0 &&
              <Row>
                <Col span={24} className="info-section__label">
                  {t('not_id_tasks')}
                </Col>
              </Row>
            }
            { trackedTasks.length > 0 && renderTrackedTasks(trackedTasks) }
          </Row>
        </Col>
        <Col xs={0} sm={0} xxl={4} align="middle">
          {image && <img src={image} alt="" className="card__image" />}
        </Col>
      </Row>
    </Link>
  );
};

export default executionCard;
