/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-14 11:39:13
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-03-14 11:40:29
 */
import React from 'react';

const Error = ({ error }) => (
  <div>
    <h1>Error</h1>
    {error.toString()}
  </div>
);

export default Error;
