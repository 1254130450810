/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:47:00 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-02-11 23:44:33
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './';
import {
  REGISTER_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  REGISTER_TASK_GROUP,
  UPDATE_TASK_GROUP,
  DELETE_TASK_GROUP,
} from './types';

import TaskService from '../../../services/tasks';
import TaskGroupService from '../../../services/taskGroup';
import { parseApiError } from '../../../common/error';
import { Notification } from '../../../common/components/widgets';

const taskService = new TaskService();
const taskGroupService = new TaskGroupService();

const registerSuccessAction = (task) => {
  Notification.success(i18n.t('success'), `${task.name} ${i18n.t('registered_success')}`, 4);
  return { type: '' };
};

const updateSuccessAction = (rule) => {
  Notification.success(i18n.t('success'), `${rule.name} ${i18n.t('updated_success')}`, 4);
  return { type: '' };
};

const deleteSuccessAction = (rule) => {
  Notification.success(i18n.t('success'), `${rule} ${i18n.t('deleted_success')}`, 4);
  return { type: '' };
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const registerTaskEpic = action$ =>
  action$.ofType(REGISTER_TASK).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(taskService.createTask(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.toggleModal(false)),
            Observable.of(actions.resetTask()),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.fetchTasks(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateTaskEpic = action$ =>
  action$.ofType(UPDATE_TASK).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(taskService.updateTask(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.toggleModal(false)),
            Observable.of(actions.resetTask()),
            Observable.of(updateSuccessAction(response)),
            Observable.of(actions.fetchTasks(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteTaskEpic = action$ =>
  action$.ofType(DELETE_TASK).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(taskService.deleteTask(action.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(deleteSuccessAction(response)),
            Observable.of(actions.fetchTasks(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const registerTaskGroupEpic = action$ =>
  action$.ofType(REGISTER_TASK_GROUP).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(taskGroupService.createTaskGroup(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.toggleModalTaskGroup(false)),
            Observable.of(actions.resetTaskGroupForm()),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.fetchTaskGroups(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateTaskGroupEpic = action$ =>
  action$.ofType(UPDATE_TASK_GROUP).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(taskGroupService.updateTaskGroup(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.toggleModalTaskGroup(false)),
            Observable.of(actions.resetTaskGroupForm()),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.fetchTaskGroups(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteTaskGroupEpic = action$ =>
  action$.ofType(DELETE_TASK_GROUP).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(taskGroupService.deleteTaskGroup(action.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(deleteSuccessAction(response)),
            Observable.of(actions.fetchTaskGroups(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

export default combineEpics(
  registerTaskEpic,
  updateTaskEpic,
  deleteTaskEpic,
  registerTaskGroupEpic,
  updateTaskGroupEpic,
  deleteTaskGroupEpic,
);
