import React from 'react';
import { Button, Icon, Row, Col } from 'antd';
import SignaturePad from 'react-signature-pad-wrapper';

const SignatureTask = ({ value, onChange }) => {
    const signatureRef = React.useRef(null);
    const timer = React.useRef(null);

    const clearSignature = React.useCallback(
      () => {
        const signaturePad = signatureRef.current;

        if (signaturePad) {
            signaturePad.instance.clear();
          }
          onChange(null);
      },
      [signatureRef],
    );

    const onChangeSingapur = () => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            saveSignature();
        }, 1000);
    };


    const saveSignature = React.useCallback(
      () => {
        const signaturePad = signatureRef.current;

        if (!signaturePad) {
            return;
        }

        if (!signaturePad.isEmpty()) {
            const image = signaturePad.toDataURL();
            onChange({ file: image });
        }
      },
      [signatureRef],
    );

    return (
      <>
        <Row>
          <Col span={18} >
            <div>

              {(!value || value.file) &&
                <div style={{
    width: 426, height: 240, borderWidth: 1, borderColor: 'gray', borderStyle: 'solid',
    }}
                >
                  <SignaturePad
                    ref={signatureRef}
                    options={{ onEnd: onChangeSingapur }}
                  />
                </div>
                }
              {value && !value.file &&
              <div >
                <img src={value} alt="" />
              </div>
                }

            </div>
          </Col>
          <Col span={6} style={{ textAlign: 'center' }}>
            <Button type="danger" onClick={clearSignature}>
              <Icon type="delete"> Delete </Icon>
            </Button>
          </Col>
        </Row>
      </>
    );
  };

export default SignatureTask;
