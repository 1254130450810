/*
 * @Author: Jose Juan Muñoz
 * @Date: 2019-05-07 10:22:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-01-30 15:13:58
 */
import { Row, Col, Card } from 'antd';
import React from 'react';
import Background from '../../../../assets/images/background-web_task-it.jpg';

const PaymentComponent = ({ annualCost, monthlyCost }) => (
  <Row gutter={16}>
    <Col span={18}>
      <Card
        align="center"
        title="Planes Disponibles"
        headStyle={{
          background: `url(${Background})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          color: 'white',
        }}
        bordered={false}
      >
        <Card.Grid style={{ width: '50%', textAlign: 'center' }}>
          <h2>Plan Anual</h2>
          <span align="left" style={{ fontSize: '20px', verticalAlign: 'top' }}>USD $</span>
          <span align="center" style={{ fontSize: '70px' }}>{annualCost}</span>
          <span align="right" style={{ fontSize: '20px', verticalAlign: 'text-top' }}>Al mes</span>
          <h2> Por usuario adicional</h2>
        </Card.Grid>
        <Card.Grid style={{ width: '50%', textAlign: 'center' }}>
          <h2>Plan Mensual</h2>
          <span align="left" style={{ fontSize: '20px', verticalAlign: 'top' }}>USD $</span>
          <span align="center" style={{ fontSize: '70px' }}>{monthlyCost}</span>
          <span align="right" style={{ fontSize: '20px', verticalAlign: 'text-top' }}>Al mes</span>
          <h2> Por usuario adicional</h2>
        </Card.Grid>
      </Card>
    </Col>
  </Row>
);

export default PaymentComponent;
