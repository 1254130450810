import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const NivoResponsivePie = ({ data }) => {
  const totalPercent = data.reduce((acc, i) => acc + i.value, 0);

  return (
    <ResponsivePie
      data={data}
      margin={{
        top: 20, right: 20, bottom: 20, left: 20,
      }}
      startAngle={0}
      padAngle={0}
      cornerRadius={0}
      colors={{ scheme: 'nivo' }}
      borderWidth={0}
      borderColor={{ from: 'color', modifiers: [['darker', 0.5]] }}
      radialLabelsSkipAngle={1}
      radialLabelsTextXOffset={6}
      radialLabelsTextColor="#333333"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={16}
      radialLabelsLinkHorizontalLength={24}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: 'color' }}
      radialLabel={d => `${d.id}: ${((d.value / totalPercent) * 100).toFixed(1)}%`}
      enableSlicesLabels={false}
      animate
      motionStiffness={90}
      motionDamping={15}
      tooltipFormat={value => `${((value / totalPercent) * 100).toFixed(2)}% (${value})`}
    />
  );
};

export default NivoResponsivePie;
