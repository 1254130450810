/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-06 06:15:44 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-09-20 23:19:53
 */
import React from 'react';
import { Checkbox } from 'antd';

const RenderCheckCell = ({
  text, record, onChange, disabled,
}) => {
  const { editable } = record;
  return editable ? <Checkbox checked={text} onChange={onChange} disabled={disabled} />
    : <Checkbox checked={text} />;
};

export default RenderCheckCell;
