import { Row } from 'antd';
import i18n from 'i18next';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import UserService from '../../../services/user';

const userService = new UserService();

const getUserInfo = async (setUser, id) => {
  const usr = await userService.getById(id);
  setUser(usr);
};

const SimpleUserInfo = ({
 id, evidence, color,
}) => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        getUserInfo(setUser, id);
    }, []);

  return user ? (
    <Row>
      <Text style={evidence ? { color } : {}}>
        {user.name} {user.lastName}
      </Text>
      {
        !evidence &&
        <Text disabled>
          {', '}{user.email}
        </Text>
      }
    </Row>
  ) : i18n.t('loading');
};


export default SimpleUserInfo;
