/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:47:00 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-06-17 00:42:39
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions, defaultActions } from './index';
import {
  UPDATE_USER,
  FETCH_USERS_BY_BRANCH_OFFICE,
  FETCH_USERS_BY_ROLE,
  FETCH_USERS_BY_PERMISSION,
  REGISTER_USER,
  SEND_REGISTER_MAIL,
  DELETE_USER,
} from './types';
import UserService from '../../../services/user';
import { Notification } from '../../../common/components/widgets';
import { parseApiError } from '../../../common/error';
import { logout, saveUserData } from '../../../security';

const userService = new UserService();

const registerSuccessAction = (user) => {
  Notification.success(i18n.t('success'), `${i18n.t('user')} ${user.name} ${i18n.t('registered_success')}`, 4);
  return { type: '' };
};

const updateSuccessAction = (user) => {
  Notification.success(i18n.t('success'), `${i18n.t('user')} ${user.name} ${i18n.t('updated_success')}`, 4);
  return { type: '' };
};

const errorUpdateMessage = (error) => {
  Notification.error(i18n.t('update_user_fail'), error.message);
};

const errorRegisterMessage = (error) => {
  Notification.error(i18n.t('register_user_fail'), error.message);
};

const errorFetchUsers = () => {
  Notification.error(i18n.t('user_list_fail'), i18n.t('verify_server_conection'));
};

const errorMessage = error => Notification.error(error.message);

const sendMailSuccessAction = (user) => {
  Notification.success(i18n.t('success'), `${i18n.t('email_sent')} ${user.name}.`, 4);
  return { type: '' };
};

const errorSendMail = (error) => {
  Notification.error(i18n.t('send_email_fail'), error.message);
};

const updateUserEpic = action$ =>
  action$.ofType(UPDATE_USER).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(userService.update(action.payload.form))
        .flatMap((response) => {
          if (action.payload.oldUserData) {
            saveUserData({
              ...action.payload.oldUserData,
              ...response,
            });
          }
          return Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.resetState()),
            Observable.of(actions.fetchAvailableUsers()),
            Observable.of(updateSuccessAction(response)),
            Observable.of(actions.fetchUsers()),
            Observable.of(actions.fetchBranchOfficesActive(defaultActions)),
            Observable.of(actions.fetchRoles(defaultActions)),
            Observable.of(actions.fetchPermissions(defaultActions)),
          );
})
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorUpdateMessage(parsedError);
            })),
          )),
    ));

const fetchUsersByBranchOfficeEpic = action$ =>
  action$.ofType(FETCH_USERS_BY_BRANCH_OFFICE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(userService.getByBranchOfficeId(action.branchOfficeId))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchUsersFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              errorFetchUsers();
            })),
          )),
    ));

const fetchUsersByRoleEpic = action$ =>
  action$.ofType(FETCH_USERS_BY_ROLE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(userService.getByRoleId(action.roleId))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchUsersFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              errorFetchUsers();
            })),
          )),
    ));

const fetchUsersByPermissionEpic = action$ =>
  action$.ofType(FETCH_USERS_BY_PERMISSION).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(userService.getByPermissionId(action.permissionId))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchUsersFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              errorFetchUsers();
            })),
          )),
    ));

const regitserUserEpic = action$ =>
  action$.ofType(REGISTER_USER)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(userService.register(action.form))
          .flatMap(userRegistered =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(defaultActions.resetState()),
              Observable.of(actions.fetchAvailableUsers()),
              Observable.of(registerSuccessAction(userRegistered)),
              Observable.of(actions.fetchUsers()),
              Observable.of(actions.fetchBranchOfficesActive(defaultActions)),
              Observable.of(actions.fetchRoles(defaultActions)),
              Observable.of(actions.fetchPermissions(defaultActions)),
              Observable.of(actions.registeredUser()),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                errorRegisterMessage(parsedError);
              })),
            )),
      ));

const sendRegisterMailEpic = action$ =>
  action$.ofType(SEND_REGISTER_MAIL).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(userService.sendRegisterToken(action.userId))
        .flatMap(user =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              sendMailSuccessAction(user);
            })),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              errorSendMail(error);
            })),
          )),
    ));

const deleteUserEpic = action$ =>
  action$.ofType(DELETE_USER).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(userService.delete(action.userId))
        .flatMap(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchUsers()),
            Observable.of(actions.fetchAvailableUsers()),
            Observable.of(actions.showMessage(() => {
              Notification.success(i18n.t('success'), i18n.t('user_deleted'), 4);
            })),
          ))
        .do({
          complete: () => {
            if (action.loggedId === action.userId) {
              logout();
              window.location.reload();
            }
          },
        })
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              errorMessage(parseApiError(error));
            })),
          )),
    ));

export default combineEpics(
  updateUserEpic, fetchUsersByBranchOfficeEpic, fetchUsersByRoleEpic,
  fetchUsersByPermissionEpic, regitserUserEpic, sendRegisterMailEpic, deleteUserEpic,
);
