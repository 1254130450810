import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import configure from './store/configure';
import { getTokenData } from './security';
import { setAuthorizationHeader, initInterceptor } from './services/axiosConfig';

import './styles/index.css';

import Routes from './routes';
import registerServiceWorker from './registerServiceWorker';

import i18n from './i18n';


class App extends React.Component {
  componentWillMount() {
    const tokenData = getTokenData();

    initInterceptor();

    if (tokenData) {
      setAuthorizationHeader(tokenData.access_token);
    }
  }

  render() {
    return (
      <Suspense fallback="">
        <Provider store={configure()}>
          <Routes />
        </Provider>
      </Suspense>
    );
  }
}


ReactDOM.render(<I18nextProvider i18n={i18n}> <App /> </I18nextProvider>, document.getElementById('root'));
registerServiceWorker();
