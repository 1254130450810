/*
 * @Author: Cesar Medina
 * @Date: 2019-07-02 14:41:21 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-04-14 19:54:20
 */

import React from 'react';
import './noImageCard.css';
import iconInProcess from '../../../../assets/icons/iconInProcess.svg';

const imageInProcess = () => (
  <div className="card-container" >
    <img className="image-icon" src={iconInProcess} alt="" />
  </div>
);

export default imageInProcess;
