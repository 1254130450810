/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:13:19
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-11-11 19:32:08
 */
const CONTAINER_KEY = 'EVIDENCES_CONTAINER';

export const FETCH_USERS_BY_BRANCH_OFFICE = `${CONTAINER_KEY}_FETCH_USERS_BY_BRANCH_OFFICE`;
export const FETCH_USERS_BY_BRANCH_OFFICE_FULFILLED = `${CONTAINER_KEY}_FETCH_USERS_BY_BRANCH_OFFICE_FULFILLED`;
export const SELECTED_FILTER = `${CONTAINER_KEY}_SELECTED_FILTER`;
export const RESET_VIEW = `${CONTAINER_KEY}_RESET_VIEW`;
export const RESET_FILTERS = `${CONTAINER_KEY}_RESET_FILTERS`;
