
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon, Row, Col, Checkbox, Tooltip, Select, message, Card, Switch, Typography, List, Button, Modal, Tabs } from 'antd';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RenderActionsCell, RenderChecklistCell } from '../../../common/components/widgets/table';
import { Message, ActivityForm } from '../../../common/components/widgets';
import { RuleTypes } from '../../../common/constants';
import { WEB_URL } from '../../../boot/config';
import { actions } from '../redux';
import { isAllowed } from '../../../security';

import diagramDdad6043 from '../../../assets/images/ddad6043-80e4-14dd-e9ac-f65b938c9740.jpg';
import diagramAd99b615 from '../../../assets/images/ad99b615-912e-fcce-1b7a-c596928d579c.jpg';
import diagram6e4ccf04 from '../../../assets/images/6e4ccf04-3efc-0904-b84b-a487225179e4.jpg';
import diagram262b776e from '../../../assets/images/262b776e-94e5-437d-ae8c-b6bb0b317d16.jpg';
import diagram07fbe638 from '../../../assets/images/07fbe638-d411-9339-1ba7-3d6a4151e125.jpg';
import diagram6d80c9ff from '../../../assets/images/6d80c9ff-73a2-333d-cf58-b793bd3396cb.jpg';
import diagram35262d8b from '../../../assets/images/35262d8b-761e-4d17-84a7-adc9f340ae5f.jpg';
import diagram60cc6eb2 from '../../../assets/images/60cc6eb2-f69c-7059-2567-eeed39fbe81b.jpg';
import diagram4f15e46a from '../../../assets/images/4f15e46a-4298-0ef6-05a3-b662236b10b8.jpg';
import diagram77e62351 from '../../../assets/images/77e62351-de71-9568-29f4-87900592ed4e.jpg';

const { Text } = Typography;
moment.locale('es');

class TaskitProcesses extends Component {
  constructor(props) {
    super(props);
    this.columns = this.getColumns();
    this.state = {
      currentEvent: [],
      modalImage: null,
    };
  }

  componentWillMount = () => {
    // fetchInitData(this.props, defaultActions);
  }

  componentDidMount = () => {

  }


  onChangeBranchOffice = (value) => {
    const { updateState, fetchRulesByFilters, filters } = this.props;

    updateState({ path: 'filters.branchOfficeId', value });
    fetchRulesByFilters({ ...filters, branchOfficeId: value });
  };

  onChangeRole = (value) => {
    const { updateState, fetchRulesByFilters, filters } = this.props;

    updateState({ path: 'filters.roleId', value });
    fetchRulesByFilters({ ...filters, roleIds: value ? [value] : null });
  };

  onChangeSwitchActiveTaskiProcess = (taskitProcessId) => {
    this.props.switchEnableTaskitProcess({ taskitProcessId });
  }

  getDiagramImage = (id) => {
    switch (id) {
      case 'ddad6043-80e4-14dd-e9ac-f65b938c9740':
        return diagramDdad6043;
      case 'ad99b615-912e-fcce-1b7a-c596928d579c':
        return diagramAd99b615;
      case '6e4ccf04-3efc-0904-b84b-a487225179e4':
        return diagram6e4ccf04;
      case '262b776e-94e5-437d-ae8c-b6bb0b317d16':
        return diagram262b776e;
      case 'd7af3145-10c8-c98d-3fd0-b2ce89df4318':
        return null;
      case '07fbe638-d411-9339-1ba7-3d6a4151e125':
        return diagram07fbe638;
      case '6d80c9ff-73a2-333d-cf58-b793bd3396cb':
        return diagram6d80c9ff;
      case '35262d8b-761e-4d17-84a7-adc9f340ae5f':
        return diagram35262d8b;
      case '60cc6eb2-f69c-7059-2567-eeed39fbe81b':
        return diagram60cc6eb2;
      case '4f15e46a-4298-0ef6-05a3-b662236b10b8':
        return diagram4f15e46a;
      case '77e62351-de71-9568-29f4-87900592ed4e':
        return diagram77e62351;
      default:
        return null;
    }
  }


  getColumns = () => [
    {
      title: this.props.t('name'),
      dataIndex: 'name',
      onCell: () => ({ 'data-label': this.props.t('name') }),
    },
    {
      title: this.props.t('description'),
      dataIndex: 'description',
      width: '20%',
      onCell: () => ({ 'data-label': this.props.t('description') }),
    },
    {
      title: this.props.t('tasks'),
      dataIndex: 'checklist',
      onCell: () => ({ 'data-label': this.props.t('tasks') }),
      render: (text, record) => (
        <RenderChecklistCell
          required
          record={record}
          checklist={record.checklist}
        />
      ),
    },
    {
      title: this.props.t('private'),
      dataIndex: 'internal',
      onCell: () => ({ 'data-label': this.props.t('private') }),
      render: (text, record) => <Checkbox checked={record.internal} />,
    },
    {
      title: (
        <div>{this.props.t('notify')}&nbsp;
          <Tooltip
            placement="right"
            title={this.props.t('user_notification_msg')}
          >
            <Icon type="question-circle" theme="outlined" />
          </Tooltip>
        </div>
      ),
      dataIndex: 'users',
      onCell: () => ({ 'data-label': this.props.t('notify') }),
      render: (text, record) => {
        const { catalogs: { users } } = this.props;
        const selectedUsers = record.userNotifications ?
          record.userNotifications.filter(u => users.some(i => i.id === u)) : [];

        return (
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={this.props.t('select_option')}
            notFoundContent={null}
            filterOption={(input, option) => option.props.children.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            value={selectedUsers}
            onChange={value => this.onChangeUsers(value, record.id)}
            disabled
          >
            {users.map(item => (
              <Select.Option key={item.id}>{item.email}</Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: this.props.t('actions'),
      onCell: () => ({ 'data-label': this.props.t('actions') }),
      render: (text, record) => (
        <div>
          <RenderActionsCell
            creating={this.state.newRow}
            record={record}
            onSave={() => this.save(record)}
            onCancel={() => {}}
            onEdit={() => this.handleEdit(record.id)}
            onDelete={() => this.handleDelete(record.id)}
            customButtons={[{
              key: 'link',
              icon: 'link',
              onClick: () => this.copyToClipboard(`${WEB_URL}/web-form/${record.id}`),
            }]}
          />
        </div>
      ),
    },
  ];

  copyToClipboard = (str) => {
    const elem = document.createElement('textarea');
    elem.value = str;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    message.success(this.props.t('copied_clipboard'));
  };

  handleSelect = ({ start, end }) => {
    const {
      filters: { branchOfficeId }, updateState,
    } = this.props;

    if (!branchOfficeId) {
      this.branchSelect.focus();
      return Message.error(this.props.t('select_office'), 2.5);
    }

    this.setState({
      currentEvent: {
        id: '',
        title: '',
        start,
        end,
      },
    });
    return updateState({ path: 'createForm', value: true });
  }

  handleDelete = (id) => {
    const {
      created,
      deleteRule,
      updateState,
      filters,
    } = this.props;

    updateState({ path: 'registering', value: true });

    if (filters.ruleType === RuleTypes.SCHEDULED) {
      deleteRule({ id: created.id, branchOfficeId: created.branchOfficeId }, filters);
    } else {
      deleteRule({ id }, { ruleType: RuleTypes.WEB });
    }
  }

  handleSave = (value) => {
    const {
      registerRule,
      updateState,
      filters,
    } = this.props;

    updateState({ path: 'registering', value: true });

    switch (filters.ruleType) {
      case RuleTypes.WEB:
        registerRule(value, { ruleType: RuleTypes.WEB });
        break;
      case RuleTypes.SCHEDULED:
        registerRule(value, { ...filters, ruleType: RuleTypes.SCHEDULED });
        break;
      case RuleTypes.NON_SCHEDULED:
        registerRule(value, { ...filters, ruleType: RuleTypes.NON_SCHEDULED });
        break;
      default:
    }
  }

  handleEdit = (ruleId) => {
    const { loadForm } = this.props;

    loadForm(ruleId);
  }

  renderTaskitProcess = () => {
    const {
        id, name, description, active, rules, taskitPredefinedRules, tableauDashboards,
    } = this.props.taskitProcess;
    const {
        isLoading, t, userInfo,
    } = this.props;
    const userIsAnalytics = !!userInfo.tableauUsername;
    const hasRules = rules && !!rules.length;
    const hasTaskitPredefinedRules =
        taskitPredefinedRules && !!taskitPredefinedRules.length;
    let finalRules = [];
    if (hasRules) {
        finalRules = [...rules];
    } else if (hasTaskitPredefinedRules) {
        finalRules = [...taskitPredefinedRules];
    }
    const hasFinalRules = finalRules && !!finalRules.length;
    const canEditCatalogs = isAllowed(['can_edit_catalogs']);
    return (
      <Card>
        <div>
          <Row>
            <Col span={/* hasRules ? 20 :  */24} >
              <Row>
                <Col className="text-bold" span={20}>
                  {name}
                  {!!this.getDiagramImage(id) &&
                  <Button
                    style={{ marginLeft: 10 }}
                    icon="apartment"
                    type="dashed"
                    size="large"
                    onClick={() => this.setState({
                            modalImage: this.getDiagramImage(id),
                        })}
                  />
                }
                </Col>
                <Col offset={4}>
                  <Switch
                    checked={active}
                    disabled={isLoading || !canEditCatalogs}
                    onChange={() => this.onChangeSwitchActiveTaskiProcess(id)}
                  />
                </Col>
              </Row>
              <Row>
                <p>{description}</p>
              </Row>
            </Col>
          </Row>
        </div>
        <Tabs /* activeKey={filters.ruleType} onChange={this.onChangeTab} */>
          <Tabs.TabPane tab={t('activities')} key="activities">
            {hasFinalRules &&
            <Col span={24} >
              <List
                itemLayout="horizontal"
                dataSource={finalRules}
                renderItem={rule => (
                  <List.Item>
                    <List.Item.Meta

                      title={
                        <p>
                          <Text type="strong">
                            { `${rule.name}` }
                          </Text>
                          {!!rule.companyId && !!rule.id && canEditCatalogs &&
                          <Icon
                            type="edit"
                            style={{ marginLeft: 10, color: '#2684FF', fontSize: 16 }}
                            onClick={() => this.handleEdit(rule.id)}
                          />
                        }
                          { (rule.name === '🤝 Cierre de viaje') &&
                          <Tooltip title="Reporte cierre de viaje.">
                            <Icon
                              type="file-text"
                              style={{ marginLeft: 10, color: '#2684FF', fontSize: 16 }}
                            />
                          </Tooltip>
                        }
                          { (rule.name === '🆘  Registro de problemática para Acción Correctiva/Preventiva') &&
                          <Tooltip title="Reporte de Acción Correctiva/Preventiva.">
                            <Icon
                              type="file-text"
                              style={{ marginLeft: 10, color: '#2684FF', fontSize: 16 }}
                            />
                          </Tooltip>
                        }
                          { (rule.name === '🎖️ Evaluación del colaborador') &&
                          <Tooltip title="CV">
                            <Icon
                              type="file-text"
                              style={{ marginLeft: 10, color: '#2684FF', fontSize: 16 }}
                            />
                          </Tooltip>
                        }
                          { (rule.name === '📋 Registro de proyecto') &&
                          <Tooltip title="Reporte de Gestión de Proyectos Educativos">
                            <Icon
                              type="file-text"
                              style={{ marginLeft: 10, color: '#2684FF', fontSize: 16 }}
                            />
                          </Tooltip>
                        }
                          { (rule.name === 'Cobrar facturas') &&
                          <Tooltip title="Recibo de pago central de gas">
                            <Icon
                              type="file-text"
                              style={{ marginLeft: 10, color: '#2684FF', fontSize: 16 }}
                            />
                          </Tooltip>
                        }
                          { (rule.name === '📄 Generacion de Contratos') &&
                          <Tooltip title="CONTRATO DE ARRENDAMIENTO">
                            <Icon
                              type="file-text"
                              style={{ marginLeft: 10, color: '#2684FF', fontSize: 16 }}
                            />
                          </Tooltip>
                        }
                        </p>
                    }
                      description={rule.description}
                    />
                  </List.Item>
                )}
              />
            </Col>
            }
          </Tabs.TabPane>
          {!!tableauDashboards && tableauDashboards.length > 0 &&
          <Tabs.TabPane tab={t('reports')} key="reports">
            <Col span={24} >
              {true &&
                    !!tableauDashboards &&
                    tableauDashboards
                    .filter(td => (
                      !td.companyId || (!!td.companyId && td.companyId === userInfo.company.id)
                    ))
                    .map(tableauDashboard => (
                      <Row style={{ marginTop: 10 }} title={!userIsAnalytics && t('user_without_analytics_licence')} >
                        <Link
                          to={{
                            pathname: '/chartViewer',
                            state: {
                                ...tableauDashboard,
                                options: {
                                hideTabs: false,
                                },
                                parameters: {
                                company_id: userInfo.company.id,
                                },
                            },
                            }}
                          disabled={!userIsAnalytics}
                        >
                          {tableauDashboard.name}
                        </Link>
                        <Text type={!userIsAnalytics && 'secondary'}> {tableauDashboard.description}</Text>
                      </Row>
                ))}
            </Col>
          </Tabs.TabPane>
          }
        </Tabs>

      </Card>
    );
    }

  render() {
    const { currentEvent } = this.state;
    const {
        taskitProcess,
      catalogs: {
        tasks,
        evaluations,
        users,
        branchOffices,
        roles,
      },
      filters: { branchOfficeId, roleId },
      updateState,
      registering,
      fetchingSelectData,
      searchedUsers,
      searchUsers,
      created,
      createForm,
    } = this.props;
    const branchOfficeSelected = branchOffices.find(item => branchOfficeId === item.id);
    const roleSelected = roles.find(item => roleId === item.id);

    return (
      <div>
        {taskitProcess && this.renderTaskitProcess()}
        {
          createForm &&
          <ActivityForm
            visible={createForm}
            onCancel={() => {
              updateState({ path: 'created', value: {} });
              updateState({ path: 'createForm', value: false });
            }}
            usersData={users}
            tasksData={tasks}
            evaluationsData={evaluations.content}
            rolesData={roles}
            roleDefault={roleSelected}
            branchOfficesData={branchOffices}
            branchOfficeDefault={branchOfficeSelected}
            onSearch={searchUsers}
            fetchingSelectData={fetchingSelectData}
            searchedUsers={searchedUsers}
            clearSearchedUsers={() => updateState({ path: 'searchedUsers', value: [] })}
            onSave={this.handleSave}
            onDelete={this.handleDelete}
            loading={registering}
            created={created}
            currentEvent={currentEvent}
            ruleType={created.ruleType}
            fromTaskitProcesses
          />
        }
        {!!this.state.modalImage &&
        <Modal
          visible={!!this.state.modalImage}
          footer={null}
          onCancel={() => this.setState({ modalImage: null })}
          style={{ top: 10 }}
          width="fit-content"
          wrapClassName="preview-image-container"
          maskStyle={{ backgroundColor: '#000000E0' }}
        >
          <center>
            <img
              alt="example"
              style={{ maxWidth: '95%', maxHeight: '90vh' }}
              src={this.state.modalImage}
            />
          </center>
        </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ taskitProcessesContainer, generalData }) => ({
  ...taskitProcessesContainer,
  ...generalData,
});

export default withTranslation()(connect(mapStateToProps, actions)(TaskitProcesses));
