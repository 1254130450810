/*
 * @Author: Jhony Reyes 
 * @Date: 2019-01-24 15:03:14 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-09-26 17:33:01
 */

import React, { Component } from 'react';
import { Popover } from 'antd';
import { withTranslation } from 'react-i18next';

import Builder from './builder';
import '../styles.css';

class PermissionsShow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  done = () => {
    this.setState(state => ({ visible: !state.visible }));
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  render() {
    const { created, t } = this.props;

    return (
      <div>
        <Popover
          content={
            <Builder
              onDone={this.done}
              created={created}
            />}
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          trigger="click"
        >
          <div className="link-button">
            { created ? `${t('permissions')}: ${Object.keys(created).length}` : null }
          </div>
        </Popover>
      </div>
    );
  }
}

export default withTranslation()(PermissionsShow);
