import axios from 'axios';
import fileDownload from 'js-file-download';
import Service from '../Service';
import { createRulesServiceUrl } from '../../common/helpers/service';

class ReportService extends Service {
  downloadReport = (reportId, params) => axios.get(createRulesServiceUrl(`reports/download/${reportId}`), {
    responseType: 'blob',
    params,
  })
    .then(data => fileDownload(data.data, 'report.pdf'));
}

export default ReportService;
