/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-06 09:46:20 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-03-25 10:07:32
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import i18n from 'i18next';

import epics from './epics';
import { CREATE_BRANCH_OFFICE, CREATE_BRANCH_OFFICES_FULFILLED, UPDATE_BRANCH_OFFICE, FETCH_BRANCH_OFFICES_BY_STATE, DELETE_BRANCH_OFFICE } from './types';
import { FETCH_BRANCH_OFFICES_FULFILLED, FETCH_STATES_FULFILLED, FETCH_USERS_FULFILLED } from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';
import { actions as userActions } from '../../users/redux';


const defaultItem = () => ({
  id: -1,
  name: i18n.t('select_option'),
});

const defaultState = () => ({
  reducerKey: 'branchOfficesContainer',
  catalogs: {
    branchOffices: [],
    states: [],
    users: [],
  },
  formErrors: {},
  formReset: {
    id: 1,
    state: defaultItem(),
    city: '',
    street: '',
    numberExt: '',
    postalCode: '',
    new: true,
    active: true,
    editable: true,
    errorForm: false,
    manager: defaultItem(),
  },
  newForm: {
    id: 1,
    state: defaultItem(),
    city: '',
    street: '',
    numberExt: '',
    postalCode: '',
    new: true,
    active: true,
    editable: true,
    errorForm: false,
    manager: defaultItem(),
  },
  editableRow: {},
  addForm: false,
  createdFulfilled: false,
  keyNew: 1,
  filters: {
    state: defaultItem(),
  },
  resetFilters: {
    state: defaultItem(),
  },
  filtering: false,
  notAsign: { id: -1, name: i18n.t('not_assigned') },
  currentPage: 1,
});

// Actions
// Payload {form, navigate}
const createBranch = payload => ({ type: CREATE_BRANCH_OFFICE, payload });
const branchCreated = payload => ({ type: CREATE_BRANCH_OFFICES_FULFILLED, payload });
const updateBranch = payload => ({ type: UPDATE_BRANCH_OFFICE, payload });
const deleteBranch = payload => ({ type: DELETE_BRANCH_OFFICE, payload });
const fetchBranchOfficesByState = stateId => ({ type: FETCH_BRANCH_OFFICES_BY_STATE, stateId });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState(), {
  [FETCH_BRANCH_OFFICES_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.branchOffices', value: payload }),
  [FETCH_STATES_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.states', value: payload }),
  [CREATE_BRANCH_OFFICES_FULFILLED]: (state, payload) => update(state, { path: 'createdFulfilled', value: payload }),
  [FETCH_USERS_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.users', value: payload }),
});

const actions = {
  ...generalActions,
  ...defaultActions,
  createBranch,
  branchCreated,
  updateBranch,
  deleteBranch,
  fetchBranchOfficesByState,
  fetchUsers: userActions.fetchUsers,
};

export {
  epics,
  reducer,
  actions,
  defaultActions,
};
