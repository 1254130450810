/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 10:56:55 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-05-08 11:54:33
 */
import {
  GATEWAY_API_URL, CORE_SERVICE_KEY, RULES_SERVICE_KEY,
  FILES_SERVICE_KEY, AUTHENTICATION_SERVICE_KEY,
  PAYMENT_SERVICE_KEY, MAIL_SERVICE_KEY,
} from '../../boot/config';

const createUrl = (service, path) => `${GATEWAY_API_URL}/${service}/${path}`;
export const createAuthenticationServiceUrl = path => createUrl(AUTHENTICATION_SERVICE_KEY, path);
export const createCoreServiceUrl = path => createUrl(CORE_SERVICE_KEY, path);
export const createRulesServiceUrl = path => createUrl(RULES_SERVICE_KEY, path);
export const createFilesServiceUrl = path => createUrl(FILES_SERVICE_KEY, path);
export const createPaymentServiceUrl = path => createUrl(PAYMENT_SERVICE_KEY, path);
export const createMailServiceUrl = path => createUrl(MAIL_SERVICE_KEY, path);

export const parseParams = (params) => {
  const keys = Object.keys(params).filter(k => params[k] !== null && params[k] !== undefined);

  if (!keys.length) { return ''; }
  return `?${keys.map(key => `${key}=${params[key]}`).join('&')}`;
};
