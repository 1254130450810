/*
 * @Author: Cesar Medina
 * @Date: 2019-04-25 23:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-01-31 12:20:49
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './index';
import { GENERATE_SUBSCRIPTION, VALIDATE_CODE } from './types';
import { Notification } from '../../../common/components/widgets';
import PaymentService from '../../../services/payment';
import { parseApiError } from '../../../common/error';

const paymentService = new PaymentService();

const successPaid = () => {
  Notification.success(i18n.t('success'), 'Pago realizado satisfactoriamente.', 3.5);
};

const errorMessage = (error) => {
  const parsedError = parseApiError(error);
  Notification.error(parsedError.message);
};

const gereratePaymentEpic = action$ =>
  action$.ofType(GENERATE_SUBSCRIPTION).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(paymentService.generatePayment({
        tokenId: action.tokenId,
        quantity: action.quantity,
        code: action.code,
        subscriptionType: action.subscriptionType,
      }))
        .flatMap(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.updateState({ path: 'loadingButton', value: false })),
            Observable.of(actions.showMessage(() => {
              successPaid();
            })),
          ))
        .do({
          complete: () => {
            action.navigate();
          },
        })
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.updateState({ path: 'loadingButton', value: false })),
            Observable.of(actions.showMessage(() => {
              errorMessage(error);
            })),
          )),
    ));

const validateCodeEpic = action$ =>
  action$.ofType(VALIDATE_CODE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.of(actions.updateState({ path: 'errorDistributorCode', value: false })),
      Observable.fromPromise(paymentService.validateCode(action.code))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.validateCodeFulfilled({ code: response.code })),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.updateState({ path: 'errorDistributorCode', value: true })),
          )),
    ));


export default combineEpics(gereratePaymentEpic, validateCodeEpic);
