/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 11:09:48 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-02-08 17:46:25
 */
import axios from 'axios';
import Service from '../Service';
import { createRulesServiceUrl, parseParams } from '../../common/helpers/service';

class TaskGroupService extends Service {
    getById = id => axios.get(createRulesServiceUrl(`taskGroup/${id}`)).then(data => data.data);
    createTaskGroup = form => axios.post(createRulesServiceUrl('taskGroup'), form).then(data => data.data);
    updateTaskGroup = form => axios.put(createRulesServiceUrl('taskGroup'), form).then(data => data.data);
    deleteTaskGroup = id => axios.delete(createRulesServiceUrl(`taskGroup/${id}`)).then(data => data.data);
    getTaskGroups = (params = {}) => axios.get(createRulesServiceUrl(`taskGroup${parseParams(params)}`)).then(data => data.data);
}

export default TaskGroupService;
