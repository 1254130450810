/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-15 11:57:12
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-20 11:29:34
 */

import {
  FETCH_USERS,
  FETCH_USERS_FULFILLED,
  FETCH_ROLES,
  FETCH_BRANCH_OFFICES,
  FETCH_BRANCH_OFFICES_ACTIVE,
  FETCH_STATES,
  FETCH_BRANCH_OFFICES_FULFILLED,
  FETCH_ROLES_FULFILLED,
  FETCH_STATES_FULFILLED,
  FETCH_PERMISSIONS,
  FETCH_PERMISSIONS_FULFILLED,
  FETCH_SUBSCRIPTION_PLAN,
  FETCH_SUBSCRIPTION_PLAN_FULFILLED,
  FETCH_USER_INFO_BY_TOKEN,
  FETCH_USER_INFO_BY_TOKEN_FULFILLED,
  FETCH_AVAILABLE_USERS,
  FETCH_AVAILABLE_USERS_FULFILLED,
  FETCH_RULES_BY_FILTERS,
  FETCH_RULES_FULFILLED,
  SEARCH_USERS,
  SEARCH_USERS_FULFILLED,
  FETCH_TASKS,
  FETCH_TASKS_FULFILLED,
  FETCH_EVALUATIONS,
  FETCH_EVALUATIONS_FULFILLED,
  FETCH_CONTACTS,
  FETCH_CONTACTS_FULFILLED,
  FETCH_RULE_BY_ID,
  FETCH_RULE_BY_ID_FULFILLED,
  FETCH_RULE_RELATIONS,
  FETCH_RULE_RELATIONS_FULFILLED,
  FETCH_GROUPS,
  FETCH_GROUPS_FULFILLED,
  FETCH_WHATSAPP_CUSTOMERS,
  FETCH_WHATSAPP_CUSTOMERS_FULFILLED,
  FETCH_WIDGETS,
  FETCH_WIDGETS_FULFILLED,
  FETCH_WIDGETS_DATA,
  FETCH_WIDGETS_DATA_FULFILLED,
  FETCH_RULES_BY_NAME,
  FETCH_RULES_BY_NAME_FULFILLED,
  FETCH_TASK_GROUPS,
  FETCH_TASK_GROUPS_FULFILLED,
  FETCH_NOT_SCHEDULED_RULES_FULFILLED,
  FETCH_NOT_SCHEDULED_RULES,
  FETCH_RULES_FILTER_FULFILLED,
  FETCH_RULES_FILTER,
  FETCH_EXECUTIONS,
  FETCH_EXECUTIONS_FULFILLED,
  FETCH_EXECUTION_BY_ID,
  FETCH_EXECUTION_BY_ID_FULFILLED,
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_FULFILLED,
  FETCH_NOTIFICATIONS_BY_USER,
  FETCH_NOTIFICATIONS_BY_USER_FULFILLED,
} from './types';

// Fetch actions to handle into epics
export const fetchRoles = payload => ({ type: FETCH_ROLES, payload });
export const fetchBranchOffices = payload => ({ type: FETCH_BRANCH_OFFICES, payload });
export const fetchBranchOfficesActive = payload => ({ type: FETCH_BRANCH_OFFICES_ACTIVE, payload });
export const fetchStates = payload => ({ type: FETCH_STATES, payload });
export const fetchPermissions = payload => ({ type: FETCH_PERMISSIONS, payload });
export const fetchUserInfoByToken = payload => ({ type: FETCH_USER_INFO_BY_TOKEN, payload });
export const fetchSubscriptionPlan = payload => ({ type: FETCH_SUBSCRIPTION_PLAN, payload });
export const fetchAvailableUsers = () => ({ type: FETCH_AVAILABLE_USERS });
export const fetchRulesByFilters = filters => ({ type: FETCH_RULES_BY_FILTERS, filters });
export const searchUsers = (value, params) =>
  ({ type: SEARCH_USERS, value, params });
export const fetchUsers = payload => ({ type: FETCH_USERS, payload });
export const fetchUsersFulfilled = payload => ({ type: FETCH_USERS_FULFILLED, payload });
export const fetchRuleRelations = payload => ({ type: FETCH_RULE_RELATIONS, payload });
export const fetchRuleRelationsFulfilled = payload =>
  ({ type: FETCH_RULE_RELATIONS_FULFILLED, payload });

// Actions hanlding on each reducer that needs general information
export const fetchRulesFulfilled = payload => ({ type: FETCH_RULES_FULFILLED, payload });
export const fetchRolesFullfilled = payload => ({ type: FETCH_ROLES_FULFILLED, payload });
export const fetchBrancOfficesFullfilled = payload => ({
  type: FETCH_BRANCH_OFFICES_FULFILLED,
  payload,
});
export const fetchStatesFullfilled = payload => ({ type: FETCH_STATES_FULFILLED, payload });
export const fetchPermissionsFullfilled = payload =>
  ({ type: FETCH_PERMISSIONS_FULFILLED, payload });
export const fetchSubscriptionPlanFulfilled = payload =>
  ({ type: FETCH_SUBSCRIPTION_PLAN_FULFILLED, payload });
export const fetchUserInfoByTokenFulfilled = payload =>
  ({ type: FETCH_USER_INFO_BY_TOKEN_FULFILLED, payload });
export const fetchAvailableUsersFulfilled = payload =>
  ({ type: FETCH_AVAILABLE_USERS_FULFILLED, payload });
export const searchUsersFulfilled = payload => ({ type: SEARCH_USERS_FULFILLED, payload });
export const fetchTasks = params => ({ type: FETCH_TASKS, params });
export const fetchTasksFulfilled = payload => ({ type: FETCH_TASKS_FULFILLED, payload });
export const fetchTaskGroups = params => ({ type: FETCH_TASK_GROUPS, params });
export const fetchTaskGroupsFulfilled = payload => ({ type: FETCH_TASK_GROUPS_FULFILLED, payload });
export const fetchEvaluations = (params = {}) => ({ type: FETCH_EVALUATIONS, params });
export const fetchEvaluationsFulfilled = payload =>
  ({ type: FETCH_EVALUATIONS_FULFILLED, payload });
export const fetchContacts = (params = {}) => ({ type: FETCH_CONTACTS, params });
export const fetchContactsFulfilled = payload => ({ type: FETCH_CONTACTS_FULFILLED, payload });
export const fetchRuleById = id => ({ type: FETCH_RULE_BY_ID, id });
export const fetchRuleByIdFulfilled = payload => ({ type: FETCH_RULE_BY_ID_FULFILLED, payload });
export const fetchGroups = (params = {}) => ({ type: FETCH_GROUPS, params });
export const fetchGroupsFulfilled = payload => ({ type: FETCH_GROUPS_FULFILLED, payload });
export const fetchWhatsappCustomers = companyId => ({ type: FETCH_WHATSAPP_CUSTOMERS, companyId });
export const fetchWhatsappCustomersFulfilled = payload =>
  ({ type: FETCH_WHATSAPP_CUSTOMERS_FULFILLED, payload });
export const fetchWidgets = (params = {}) => ({ type: FETCH_WIDGETS, params });
export const fetchWidgetsFulfilled = payload => ({ type: FETCH_WIDGETS_FULFILLED, payload });
export const fetchWidgetsData = (widget, params) =>
  ({ type: FETCH_WIDGETS_DATA, payload: { widget, params } });
export const fetchWidgetsDataFulfilled = payload =>
  ({ type: FETCH_WIDGETS_DATA_FULFILLED, payload });
export const fetchRulesByName = payload => ({ type: FETCH_RULES_BY_NAME, payload });
export const fetchRulesByNameFulfilled = payload =>
  ({ type: FETCH_RULES_BY_NAME_FULFILLED, payload });
  export const fetchNotScheduledRulesFulfilled = payload =>
  ({ type: FETCH_NOT_SCHEDULED_RULES_FULFILLED, payload });
  export const fetchNotScheduledRules = (branchOfficeId, roleIds, userId) => ({
    type: FETCH_NOT_SCHEDULED_RULES, roleIds, branchOfficeId, userId,
  });
export const fetchRulesFilterFulfilled = payload => ({
  type: FETCH_RULES_FILTER_FULFILLED,
  payload,
});
export const fetchRulesFilter = (branchOfficeId, roleIds, ruleType, callback) => ({
  type: FETCH_RULES_FILTER, roleIds, branchOfficeId, ruleType, callback,
});
export const fetchExecutions = payload => ({ type: FETCH_EXECUTIONS, payload });
export const fetchExecutionsFulfilled = payload => ({ type: FETCH_EXECUTIONS_FULFILLED, payload });

export const fetchExecutionById = id => ({ type: FETCH_EXECUTION_BY_ID, id });
export const fetchExecutionByIdFulfilled = payload =>
  ({ type: FETCH_EXECUTION_BY_ID_FULFILLED, payload });
export const fetchNotifications = payload => ({ type: FETCH_NOTIFICATIONS, payload });
export const fetchNotificationsFulfilled = payload => ({
  type: FETCH_NOTIFICATIONS_FULFILLED, payload,
});
export const fetchNotificationsByUser = payload => ({ type: FETCH_NOTIFICATIONS_BY_USER, payload });
export const fetchNotificationsByUserFulfilled = payload => ({
  type: FETCH_NOTIFICATIONS_BY_USER_FULFILLED, payload,
});
