/*
 * @Author: Cesar Medina
 * @Date: 2018-07-23 09:55:17 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-11-11 19:34:22
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import moment from 'moment';
import epics from './epics';
import {
  FETCH_USERS_BY_BRANCH_OFFICE, FETCH_USERS_BY_BRANCH_OFFICE_FULFILLED,
  SELECTED_FILTER,
  RESET_VIEW,
  RESET_FILTERS,
} from './types';
import {
  FETCH_BRANCH_OFFICES_FULFILLED,
  FETCH_ROLES_FULFILLED,
  FETCH_TASKS_FULFILLED,
  FETCH_RULES_BY_NAME_FULFILLED,
  FETCH_USERS_FULFILLED,
  FETCH_EXECUTIONS_FULFILLED,
} from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';

const defaultState = {
  reducerKey: 'evidencesContainer',
  catalogs: {
    executions: {
      content: [],
      size: 10,
      number: 0,
      totalElements: 1,
    },
    rules: {
      content: [],
      size: 100,
      number: 0,
      totalElements: 1,
    },
    branchOffices: [],
    roles: [],
    users: [],
    usersAll: [],
    taskFilters: [],
  },
  filters: {
    branchOfficeId: null,
    roleId: null,
    ruleId: undefined,
    status: 'completed',
    initDate: moment().valueOf(),
    endDate: moment().valueOf(),
    time: null,
    userId: null,
    name: null,
    filters: [],
    folio: null,
  },
  selectedTab: '0',
  selectedFilter: [],
  loadingExecutions: false,
  loadingRules: false,
};

// Actions  
// Payload {form, navigate}
const fetchUsersByBranchOffice = branchOfficeId =>
  ({ type: FETCH_USERS_BY_BRANCH_OFFICE, branchOfficeId });
const fetchUsersByBranchOfficeFulfilled = payload =>
  ({ type: FETCH_USERS_BY_BRANCH_OFFICE_FULFILLED, payload });
const selectedFilterNames = payload => ({ type: SELECTED_FILTER, payload });
const resetView = () => ({ type: RESET_VIEW });
const resetFilters = () => ({ type: RESET_FILTERS });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_EXECUTIONS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.executions', value: payload }),
  [FETCH_RULES_BY_NAME_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.rules', value: payload }),
  [FETCH_BRANCH_OFFICES_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.branchOffices', value: payload }),
  [FETCH_ROLES_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.roles', value: payload }),
  [FETCH_USERS_BY_BRANCH_OFFICE_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.users', value: payload }),
  [FETCH_USERS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.usersAll', value: payload }),
  [FETCH_TASKS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.taskFilters', value: payload.map(i => ({ ...i, value: null })) }),
  [SELECTED_FILTER]: (state, payload) =>
    update(state, { path: 'selectedFilter', value: payload }),
  [RESET_VIEW]: state => ({
    ...state,
    ...defaultState,
  }),
  [RESET_FILTERS]: state => ({
    ...state,
    catalogs: {
      ...state.catalogs,
      taskFilters: state.catalogs.taskFilters.map(i => ({
        ...i,
        value: null,
      })),
    },
    filters: {
      branchOfficeId: null,
      roleId: null,
      ruleId: undefined,
      status: 'completed',
      initDate: moment().valueOf(),
      endDate: moment().valueOf(),
      time: null,
      userId: null,
      name: null,
      filters: [],
      folio: null,
    },
  }),
});

const actions = {
  ...generalActions,
  ...defaultActions,
  fetchUsersByBranchOffice,
  fetchUsersByBranchOfficeFulfilled,
  selectedFilterNames,
  resetFilters,
  resetView,
};

export {
  epics,
  reducer,
  actions,
  defaultActions,
};
