import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Row, Col, Button, Checkbox, TimePicker } from 'antd';
import { init } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';

import validations from './validations';
import { actions } from '../../redux';

class EventsStep extends React.Component {
  constructor(props) {
    super(props);
    this.validator = init(validations);
  }

  onChangeTime = (time, propName, eventKey) => {
    const { durationSelected, events, updateState } = this.props;
    const maximumStart = this.getMaximumStart(events);
    const dataTime = propName === 'duration' ? maximumStart : durationSelected;
    const endMinutes = moment(dataTime).minutes() + time.minutes();
    const endHours = moment(dataTime).hours() + time.hours() + (endMinutes / 60);

    if (endHours >= 24) {
      time.set({
        hours: (moment(dataTime).minutes() > 0 ? 23 : 24) - moment(dataTime).hours(),
        minute: (60 - moment(dataTime).minutes()) % 60,
      });
    }

    if (propName === 'duration' && time.hours() <= 0 && time.minutes() <= 0) {
      time.set({ minute: 15 });
    }

    const end = moment(dataTime).add(time.hours(), 'h').add(time.minutes(), 'm');

    const eventStart = events.map((i, index) => {
      if (index === eventKey) {
        return ({
          ...i,
          start: time,
          end,
        });
      }
      return i;
    });

    const eventDuration = events.map((i) => {
      if (propName === 'duration') {
        return ({
          ...i,
          end: moment(i.start).add(time.hours(), 'h').add(time.minutes(), 'm'),
        });
      }
      return i;
    });

    if (propName === 'duration') {
      updateState({ path: 'durationSelected', value: time });
      updateState({ path: 'events', value: eventDuration });
    } else {
      updateState({ path: 'events', value: eventStart });
    }
  }

  onChangeDays = (checkedValues) => {
    if (checkedValues.length > 0) {
      this.props.updateState({ path: 'checkboxDays', value: checkedValues });
    }
  }

  getMaximumStart = (events) => {
    let maxMoment = null;

    events.forEach((e) => {
      if (maxMoment === null || e.start > maxMoment) {
        maxMoment = e.start;
      }
    });

    return maxMoment;
  }

  createItem = () => {
    const { updateState, events } = this.props;

    updateState({
      path: 'events',
      value: [
        ...events,
        {
          start: moment(events[events.length - 1].start),
          end: moment(events[events.length - 1].end),
        },
      ],
    });

    this.repetitions.scrollTop = this.repetitions.scrollHeight;
  };

  deleteItem = (eventIndex) => {
    const { updateState, events } = this.props;

    if (events.length > 1) {
      updateState({
        path: 'events',
        value: events.filter((i, index) => index !== eventIndex),
      });
    }
  }

  handleDisabledHours = (propName) => {
    const hours = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const { durationSelected, events } = this.props;
    const maximumStart = this.getMaximumStart(events);
    const dataTime = propName === 'duration' ? maximumStart : durationSelected;
    const differenceHours = (moment(dataTime).minutes() > 0 ? 23 : 24) - moment(dataTime).hours();

    return hours.filter(i => i > differenceHours);
  }

  handleDisabledMinutes = (propName, eventIndex) => {
    const minutes = [0, 15, 30, 45];
    const { durationSelected, events } = this.props;
    const maximumStart = this.getMaximumStart(events);
    const dataTime = propName === 'duration' ? maximumStart : events[eventIndex].start;
    const totalHours = dataTime.hours() + durationSelected.hours();

    if (totalHours === 23) {
      return minutes.filter(i => i > 60 - moment(dataTime).minutes());
    }

    if (totalHours === 24) {
      return [15, 30, 45];
    }

    return [];
  }

  render() {
    const {
      durationSelected,
      events,
      checkboxDays,
      t,
    } = this.props;

    return (
      <div>
        <div className="form-description">
          {t('activity_periodicity')}
        </div>
        <Row className="form-section">
          <Col sm={24} lg={24}>
            <div className="form-label">
              {t('select_days_activity')}
            </div>
            <Checkbox.Group
              className="notranslate"
              options={[
                { label: t('monday'), value: 1 },
                { label: t('tuesday'), value: 2 },
                { label: t('wednesday'), value: 3 },
                { label: t('thursday'), value: 4 },
                { label: t('friday'), value: 5 },
                { label: t('saturday'), value: 6 },
                { label: t('sunday'), value: 0 },
              ]}
              value={checkboxDays}
              onChange={this.onChangeDays}
            />
          </Col>
        </Row>
        <Row className="form-section">
          <Col sm={24} lg={24}>
            <div className="form-label">
              {t('duration_activity')}
            </div>
            <TimePicker
              format="HH:mm"
              minuteStep={15}
              allowClear={false}
              value={moment(durationSelected, 'HH:mm')}
              disabledHours={() => this.handleDisabledHours('duration')}
              disabledMinutes={() => this.handleDisabledMinutes('duration')}
              hideDisabledOptions
              onChange={time => this.onChangeTime(time, 'duration')}
            />
          </Col>
        </Row>
        <Row className="form-section">
          <Col sm={24} lg={24}>
            <Row>
              <Col span={6}><div className="form-label">{t('start_time')}</div></Col>
              <Col span={6}><div className="form-label">{t('end_time')}</div></Col>
            </Row>
            <div style={{ overflowY: 'auto', height: 200, overflowX: 'hidden' }} ref={(ref) => { this.repetitions = ref; }}>
              {events.map((event, index) => ( // eslint-disable-next-line
                <Row key={index} style={{ paddingTop: 10, paddingBottom: 10 }} >
                  <Col span={6}>
                    <TimePicker
                      format="HH:mm"
                      minuteStep={15}
                      value={event.start}
                      allowClear={false}
                      onChange={time => this.onChangeTime(time, 'start', index)}
                      disabledHours={this.handleDisabledHours}
                      disabledMinutes={() => this.handleDisabledMinutes(null, index)}
                      hideDisabledOptions
                    />
                  </Col>
                  <Col span={6}>
                    <TimePicker
                      disabled
                      format="HH:mm"
                      value={event.end}
                    />
                  </Col>
                  <Col span={6}>
                    <Button type="danger" shape="circle" icon="minus" onClick={() => this.deleteItem(index)} />
                  </Col>
                </Row>
              ))}
            </div>
            <Row>
              <Col span={12}>
                <Button
                  type="dashed"
                  block
                  icon="plus"
                  size="small"
                  onClick={this.createItem}
                  style={{
                    marginTop: 15,
                    color: '#4fa1ff',
                    borderColor: '#4fa1ff',
                  }}
                >
                  {t('add_repetition')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ activityFormContainer }) => ({
  ...activityFormContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(EventsStep));
