/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-11 01:08:35
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-24 10:30:51
 */
import { Table, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { ChecklistShow } from '../../../common/components/widgets';

const NextScheduled = ({ t, rulesExecution }) => {
  const getFutureExecutions = () => {
    const now = moment();
    const weekDay = now.isoWeekday() % 7;
    const hour = now.hour();
    const minutes = now.minutes();
    const futureExecutions = [];

    rulesExecution.forEach((r) => {
      if (r.active && r.cron && r.cron.days[weekDay]) {
        const executions = r.cron.events.filter((e) => {
          const evHour = parseInt(e.substring(0, 2), 10);
          const evMinutes = parseInt(e.substring(3), 10);
          return (evHour > hour || (evHour === hour && evMinutes > minutes));
        }).map(e => ({
          id: `${r.id}-${e}`,
          name: r.name,
          description: r.description,
          checklist: r.checklist,
          event: e,
        }));

        futureExecutions.push(...executions);
      }
    });

    futureExecutions.sort((a, b) => a.event > b.event);
    return futureExecutions;
  };

  const getColumns = () => [
    {
      title: t('name'),
      dataIndex: 'name',
      onCell: () => ({ 'data-label': t('name') }),
      width: '10%',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      width: '15%',
      onCell: () => ({ 'data-label': t('description') }),
      ellipsis: true,
      render: text => (
        <Tooltip title={text}>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{text}</div>
        </Tooltip>
     ),
    },
    {
      title: t('tasks'),
      dataIndex: 'checklist',
      width: '10%',
      onCell: () => ({ 'data-label': t('tasks') }),
      render: (text, record) => (
        <ChecklistShow key={record.id} created={record.checklist} />
      ),
    },
    {
      title: t('start_of_activity'),
      dataIndex: 'event',
      width: '10%',
      onCell: () => ({ 'data-label': t('start_of_activity') }),
      render: text => (
        <>
          {`Comienza a las ${text} hrs.`}
        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        className="responsive-table"
        dataSource={getFutureExecutions()}
        columns={getColumns()}
        locale={{ emptyText: t('show_no_data') }}
      />
    </div>
  );
};

export default NextScheduled;

