/*
 * @Author: Jhony Reyes 
 * @Date: 2018-12-19 11:55:44 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-01-31 11:35:36
 */

import axios from 'axios';
import { orderBy } from 'lodash';
import Service from '../Service';
import { createPaymentServiceUrl, createAuthenticationServiceUrl } from '../../common/helpers/service';

const formatData = data => orderBy(data, ['createdAt'], ['desc']);

class PaymentService extends Service {
  generatePayment = params => axios.post(createPaymentServiceUrl('payments'), params).then(data => data.data);
  getOrders = () => axios.get(createPaymentServiceUrl('orders')).then(data => formatData(data.data));
  getPaymentSourceByUserId = () => axios.get(createAuthenticationServiceUrl('users/payment-source')).then(data => data.data);
  getSubscriptionByUserId = () => axios.get(createAuthenticationServiceUrl('users/subscription')).then(data => data.data);
  updatePaymentMethod = tokenId => axios.post(createAuthenticationServiceUrl(`update-payment-source?tokenId=${tokenId}`)).then(data => data.data);
  updateStatus = (subscriptionId, status) =>
    axios.put(createPaymentServiceUrl(`subscriptions/${subscriptionId}/status`), { status }).then(data => data.data)
  renewSubscriptions = (subscriptionId, status, billingCycleEnd) =>
    axios.put(createPaymentServiceUrl(`subscriptions/${subscriptionId}/renew`), { status, billingCycleEnd }).then(data => data.data)
  getAllSubscriptions = () => axios.get(createPaymentServiceUrl('subscriptions/companies')).then(data => data.data)
  getSubscriptions = () => axios.get(createPaymentServiceUrl('subscriptions')).then(data => data.data)
  getAvailableUsers = () => axios.get(createPaymentServiceUrl('subscriptions/users')).then(data => data.data)
  validateCode = code => axios.get(createPaymentServiceUrl(`distributors/code/${code}`)).then(data => data.data)
}

export default PaymentService;
