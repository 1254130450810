/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-19 11:41:23 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-07-25 12:21:50
 */
import React, { Component } from 'react';
import { Popover, Button } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { format } from './helpers';

import Builder from './builder';

class CronBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      formatted: '',
    };
  }

  done = (values) => {
    if (values && values.range.length > 0) {
      this.setState({ visible: false, formatted: values.range.length ? values.formatted : '' });
      this.props.onChange(values);
    } else {
      this.setState({ visible: false, formatted: null });
      this.props.onChange('');
    }
  }

  toggle = () => this.setState(state => ({ visible: !state.visible }));

  handleVisibleChange = (visible) => {
    if (!visible) {
      const { state } = this.builderCron;
      this.done({ ...state, formatted: format(state.start, state.end, state.every) });
    }
    this.setState({ visible });
  }

  render() {
    const { formatted } = this.state;
    const { cronCreated, t } = this.props;

    return (
      <Popover
        content={
          <Builder
            ref={(ref) => { this.builderCron = ref; }}
            onDone={this.done}
            onCancel={this.done}
            cronCreated={cronCreated}
          />}
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        trigger="click"
      >
        <Button type="primary" onClick={this.toggle}>
          {formatted || t('select')}
        </Button>
      </Popover>
    );
  }
}

CronBuilder.propTypes = {
  onChange: PropTypes.func,
};

CronBuilder.defaultProps = {
  onChange: () => {},
};

export default withTranslation()(CronBuilder);
