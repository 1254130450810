/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-14 09:53:11
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2022-03-28 13:07:16
 */
import moment from 'moment';
import React, { Component } from 'react';
import { Layout, Menu, Icon, Spin, Avatar, Dropdown, notification, Row, Col, Modal, Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { capitalize, isEmpty, omit } from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { init } from 'aleonor-object-validations';

import { NotificationTypes } from '../../../constants';
import { logout, getUserData, isAllowed } from '../../../../security';
import { menu } from '../../../../routes';
import { actions } from './redux';
import validations from './validations';
import validationsPersonalInfo from './validationsPersonalInfo';
import validationsPassword from './validationsPassword';
import { getImageUrl } from '../../../helpers/images';
import { version } from '../../../../../package.json';
import UserService from '../../../../services/user';
import { API_DOC_URL } from '../../../../boot/config';
import { EditProfileForm, Notification, TechnicalSettingsForm } from '../../widgets';


import './styles.css';
import styles from './styles';
import { getBrowserLanguage } from '../../../helpers/functions';

import logo from '../../../../assets/images/taskit_logo_blanco_web.png';

const userService = new UserService();

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.validator = init(validations());
    this.validatorPersonalInfo = init(validationsPersonalInfo());
    this.validatorPassword = init(validationsPassword());
    this.state = {
      collapsed: false,
      currenLanguage: localStorage.getItem('language') || getBrowserLanguage(),
    };
  }

  componentWillMount = () => {
    setTimeout(() => this.fetchActions(), 0);
    const {
      fetchNotificationsByUser,
      fetchUsers,
      userInfo,
    } = this.props;
    if (userInfo.id) {
      fetchUsers();
      fetchNotificationsByUser();
    }
  }

  componentWillUpdate = (nextProps) => {
    const title = nextProps.title ? `Taskit - ${nextProps.title}` : 'Taskit';
    document.title = title;
  }

  onChangeLanguage = (value) => {
    this.setState({ currenLanguage: value });
  }

  getDurationTime = (createdAt) => {
    const { t } = this.props;

    const now = moment();
    const startTime = moment(createdAt);
    const momentLeft = moment(now.diff(startTime));
    const weeksDiff = parseInt(now.diff(startTime, 'weeks'), 10);
    const daysDiff = parseInt(now.diff(startTime, 'days'), 10);
    const hoursDiff = parseInt(now.diff(startTime, 'hours'), 10);
    const minutesLeft = momentLeft.format('mm');

    if (weeksDiff > 1) {
      return t('weeks_ago', { value: weeksDiff });
    } else if (weeksDiff === 1) {
      return t('week_ago', { value: weeksDiff });
    } else if (daysDiff > 1) {
      return t('days_ago', { value: daysDiff });
    } else if (daysDiff === 1) {
      return t('day_ago', { value: daysDiff });
    } else if (hoursDiff > 0) {
      return t('hours_ago', { hours: hoursDiff, minutes: minutesLeft });
    }
    return t('minutes_ago', { value: minutesLeft });
  };

  getNotifications = (id) => {
    const {
      catalogs: {
        notifications,
      },
      t,
    } = this.props;

    const myNotifications = notifications.filter(n => n.userNotifyId.includes(id)).slice(0, 10);

    return (
      <Menu style={styles.menu}>
        <Menu.Item>
          <Row style={styles.notification} >
            <span style={styles.title}>{t('notifications')}</span>
            <Link to="/notifications">
              <Button type="primary" style={styles.buttonSeeAll}>{t('see_all')}</Button>
            </Link>
          </Row>
        </Menu.Item>
        {
          myNotifications.length > 0 ?
            myNotifications.map(n => (
              <Menu.Item style={styles.menuNotification}>
                <a
                  href={(n.type === 'assign_activity_users' || n.type === 'assign_activity_role') ?
                    '/executionActivities' : `/evidences/${n.ruleExecutedId}`}
                >
                  {
                    n.type === NotificationTypes.SCHEDULED_USERS ?
                      <Row>
                        <Col span={20}>
                          <Row style={styles.textBold}>{n.ruleInformation.name}</Row>
                          <Row>{this.getDurationTime(n.createdAt)}</Row>
                        </Col>
                        <Col span={4} style={styles.icon}>
                          <Icon type="user" />
                        </Col>
                      </Row>
                      :
                      <Row>
                        <Col span={20}>
                          <Row style={styles.notification}>
                            <span style={styles.textBold}>
                              {this.renderUserExecuted(n.userExecuteId)}
                            </span>
                            &nbsp;{t('done_activity')}&nbsp;
                            <span style={styles.textBold}>{n.ruleInformation.name}</span>
                          </Row>
                          <Row>{this.getDurationTime(n.createdAt)}</Row>
                        </Col>
                        <Col span={4} style={styles.icon}>
                          <Icon type="check-square" s />
                        </Col>
                      </Row>
                  }
                </a>
              </Menu.Item>
            ))
            :
            <span style={styles.textBold}>
              {t('no_notifications')}
            </span>
        }
      </Menu>
    );
  }

  logoutOption = () => (
    <Menu onClick={this.handleMenuAction}>
      <Menu.Item key="editProfile"><Icon type="user" /> {this.props.t('edit_profile')}</Menu.Item>
      <Menu.Item key="generateToken"><Icon type="key" /> {this.props.t('generate_token')}</Menu.Item>
      <Menu.Item key="technicalSettings"><Icon type="setting" /> {this.props.t('technical_settings')}</Menu.Item>
      <Menu.Item key="logout"><Icon type="logout" /> {this.props.t('log_out')}</Menu.Item>
    </Menu>
  );

  save = (type) => {
    const {
      form, updateUser, updateUserWithPassword, updateState, i18n, userInfo,
    } = this.props;
    const { valid, errors } = type === 'personal_information' ? this.validatorPersonalInfo.validateForm(form) : this.validatorPassword.validateForm(form);
    const formatForm = omit(form, ['updatedAt, createdAt']);

    localStorage.setItem('language', this.state.currenLanguage);
    i18n.changeLanguage(this.state.currenLanguage);

    updateState({ path: 'formErrors', value: errors });
    if (valid) {
      switch (type) {
        case 'personal_information': {
          const updateName = formatForm.name;
          const updateLastName = formatForm.lastName;
          const updateEmail = formatForm.email;
          updateUser({
            form: {
              ...userInfo,
              name: updateName,
              lastName: updateLastName,
              email: updateEmail,
            },
            navigate: this.switchEditProfileModal,
          });
          break;
        }
        case 'password': {
          const password = formatForm.newPassword;
          updateUserWithPassword({
            form: {
              ...userInfo,
              password: formatForm.password,
            },
            password,
            navigate: this.switchEditProfileModal,
          });
          break;
        }
        default:
      }
    }
  }


  fetchActions = () => {
    const { fetchUserInfoByToken } = this.props;
    fetchUserInfoByToken();
  }

  handleMenuAction = ({ key }) => {
    switch (key) {
      case 'generateToken':
        userService.generateApiToken().then((token) => {
          notification.success({
            message: this.props.t('token_success'),
            description: token,
          });
        }).catch((error) => {
          notification.error({
            message: this.props.t('token_fail'),
            description: `Error: ${error}`,
          });
        });
        break;
      case 'logout':
        logout();
        this.props.history.push('/auth/login');
        break;
      case 'editProfile':
        this.switchEditProfileModal();
        break;
      case 'technicalSettings':
        this.switchTechnicalSettingsModal();
        break;
      default:
    }
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  handleMenu = () => {
    let allowedItems = [...menu()];
    const userData = getUserData();
    const admin = userData ? userData.admin : false;

    if (!userData) {
      allowedItems = allowedItems.filter(i => i.key === 'home');
    }

    if (!admin) {
      allowedItems = allowedItems.filter(i => i.key !== 'companies');
    }

    allowedItems = allowedItems
      .filter(({ permissions }) => (
        !permissions || permissions.length === 0 || isAllowed(permissions)
      ));

    return allowedItems;
  };

  switchEditProfileModal = () => {
    const { updateState, editProfileModalVisible, resetState } = this.props;
    if (!editProfileModalVisible) {
      updateState({ path: 'editProfileModalVisible', value: true });
    } else {
      resetState();
    }
  }

  switchTechnicalSettingsModal = () => {
    const { updateState, technicalSettingsModalVisible, resetState } = this.props;
    if (!technicalSettingsModalVisible) {
      updateState({ path: 'technicalSettingsModalVisible', value: true });
    } else {
      resetState();
    }
  }

  uploadImage = ({ file: image }) => {
    const { updateUserImage, form: { id }, t } = this.props;
    const isJpgOrPng = image.type === 'image/jpeg' || image.type === 'image/png';
    if (!isJpgOrPng) {
      Notification.error(t('only_jpg_png'));
      return;
    }
    const isLt2M = image.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      Notification.error(t('file_small_than_20mb'));
      return;
    }
    const fileType = image.name.substring(image.name.lastIndexOf('.') + 1);
    const sendImage = new File([image], `Photo${Date.now()}.${fileType}`, { type: image.type });
    const form = new FormData();
    form.append('file', sendImage);

    updateUserImage({ id, form });
  }


  uploadCompanyLogoImage = async ({ file, file: image }) => {
    const { updateCompanyLogoImage, userInfo, t } = this.props;
    const isJpgOrPng = image.type === 'image/jpeg' || image.type === 'image/png';
    if (!isJpgOrPng) {
      Notification.error(t('only_jpg_png'));
      return;
    }
    const isLt2M = image.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      Notification.error(t('file_small_than_20mb'));
      return;
    }
    const isOnDimensions = await this.imageHasAspectRatio(file, 4 / 1);
    if (!isOnDimensions) {
      Notification.error(t('image_has_not_valid_dimensions'));
      return;
    }

    const fileType = image.name.substring(image.name.lastIndexOf('.') + 1);
    const sendImage = new File([image], `Photo${Date.now()}.${fileType}`, { type: image.type });
    const form = new FormData();
    form.append('file', sendImage);

    updateCompanyLogoImage({ id: userInfo.company.id, form });
  }

  uploadCompanyHeaderImage = async ({ file, file: image }) => {
    const { updateCompanyHeaderImage, userInfo, t } = this.props;
    const isJpgOrPng = image.type === 'image/jpeg' || image.type === 'image/png';
    if (!isJpgOrPng) {
      Notification.error(t('only_jpg_png'));
      return;
    }
    const isLt2M = image.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      Notification.error(t('file_small_than_20mb'));
      return;
    }
    const isOnDimensions = await this.imageHasAspectRatio(file, 17 / 1);
    if (!isOnDimensions) {
      Notification.error(t('image_has_not_valid_dimensions'));
      return;
    }

    const fileType = image.name.substring(image.name.lastIndexOf('.') + 1);
    const sendImage = new File([image], `Photo${Date.now()}.${fileType}`, { type: image.type });
    const form = new FormData();
    form.append('file', sendImage);

    updateCompanyHeaderImage({ id: userInfo.company.id, form });
  }


  imageHasAspectRatio = (file, aspectRatio) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', (event) => {
      const loadedImageUrl = event.target.result;
      const image = document.createElement('img');
      image.src = loadedImageUrl;
      image.addEventListener('load', () => {
        const { width, height } = image;
        const imageAspectRatio = width / height;
        resolve(imageAspectRatio === aspectRatio);
      });
    });
  });

  renderUserExecuted = (userId) => {
    const {
      catalogs: {
        users,
      },
    } = this.props;
    const selectedUserIds = userId ? users.filter(u => u.id === userId) : [];
    const names = (selectedUserIds && selectedUserIds.length) ? selectedUserIds.map(s => (`${s.name} ${s.lastName}`)).join(', ') : '';
    return names;
  }

  render() {
    const {
      isLoading,
      location,
      contentStyle,
      form,
      updateForm,
      userInfo,
      editProfileModalVisible,
      technicalSettingsModalVisible,
      formErrors,
      updateState,
      resetState,
      t,
    } = this.props;

    const userIsAnalytics = !!userInfo.tableauUsername;

    return (
      <Layout style={styles.layout}>
        <Layout.Sider
          breakpoint="lg"
          collapsedWidth="0"
          className="fixed-sider"
        >
          <Row type="flex" justify="center" >
            <img alt="Taskit" src={userInfo.company && userInfo.company.logoImageUrl ? getImageUrl(userInfo.company.logoImageUrl) : logo} width={180} style={{ marginTop: 50, marginBottom: 50 }} />
          </Row>
          <Menu
            theme="dark"
            // mode="inline"
            defaultSelectedKeys={[this.handleMenu()[0].key]}
            selectedKeys={[location.pathname === '/' ? this.handleMenu()[0].key : location.pathname.split('/')[1]]}
          >
            {
              this.handleMenu().map(item => (
                <Menu.Item key={item.key}>
                  <Link to={item.path}>
                    <Icon type={item.icon} />
                    <span>{item.title}</span>
                  </Link>
                </Menu.Item>
              ))
            }
          </Menu>
        </Layout.Sider>
        <Layout>
          <Layout.Header style={styles.header}>
            <Row type="flex" justify="space-between" align="middle">
              <Col xs={4} sm={2} md={2} />
              <Col xs={10} sm={14} md={16} style={{ textAlign: 'center' }}>
                <div className="header-section header-section__title">{this.props.title}</div>
              </Col>
              <Col xs={10} sm={8} md={6} style={{ textAlign: 'end' }}>
                <div className="header-section header-section__user-info user-info">
                  <Dropdown placement="bottomCenter" overlay={this.getNotifications(userInfo.id)} trigger={['click']}>
                    <Icon type="bell" className="header-section__menu-icon" />
                  </Dropdown>
                  <Dropdown
                    overlay={(
                      <Menu>
                        <Menu.Item key="apiDoc">
                          <a href={API_DOC_URL} target="_blank" rel="noopener noreferrer">
                            {t('api_documentation')}
                          </a>
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Icon type="question-circle" className="header-section__menu-icon" />
                  </Dropdown>
                  {userIsAnalytics &&
                    <Icon type="line-chart" className="header-section__menu-icon" title={t('analytics_licence')} />
                  }
                  <div className="user-info__text-container">
                    {userInfo.id && (
                      <div>
                        <span className="user-info__text user-info__text--primary">{t('greeting')} {userInfo.name}</span>
                        <span className="user-info__text user-info__text--secundary">{capitalize(userInfo.company.name)}</span>
                      </div>
                    )}
                  </div>
                  {
                    !isEmpty(userInfo) &&
                    <Dropdown overlay={this.logoutOption()}>
                      <Avatar icon="user" src={getImageUrl(userInfo.profileImageUrl)} />
                    </Dropdown>
                  }
                </div>
              </Col>
            </Row>
            {editProfileModalVisible &&
              <Modal
                title={t('edit_profile')}
                visible={editProfileModalVisible}
                width={700}
                maskClosable={false}
                className="modal-header-dark"
                style={{ top: 20 }}
                maskStyle={{ backgroundColor: '#00000066' }}
                onCancel={this.switchEditProfileModal}
                confirmLoading={isLoading}
                footer={null}
              >
                <EditProfileForm
                  onChangeField={(prop, value) => updateForm({ prop, value })}
                  user={form}
                  currentUser={userInfo}
                  fetchActions={this.fetchActions}
                  formErrors={formErrors}
                  uploadImage={this.uploadImage}
                  uploadCompanyLogoImage={this.uploadCompanyLogoImage}
                  uploadCompanyHeaderImage={this.uploadCompanyHeaderImage}
                  updateState={updateState}
                  currenLanguage={this.state.currenLanguage}
                  onChangeLanguage={this.onChangeLanguage}
                  onSave={this.save}
                  resetState={resetState}
                />
              </Modal>
            }
            {technicalSettingsModalVisible &&
              <Modal
                title={t('technical_settings')}
                visible={technicalSettingsModalVisible}
                width="70vw"
                maskClosable={false}
                className="modal-header-dark"
                style={{ top: 20 }}
                maskStyle={{ backgroundColor: '#00000066' }}
                onCancel={this.switchTechnicalSettingsModal}
                confirmLoading={isLoading}
                footer={null}
              >
                <TechnicalSettingsForm
                  catalogs={this.props.catalogs}
                  creating={this.props.creating}
                  updateState={this.props.updateState}
                  deleteWebHook={this.props.deleteWebHook}
                  createWebHook={this.props.createWebHook}
                  updateWebHook={this.props.updateWebHook}
                  fetchWebHooks={this.props.fetchWebHooks}
                  newWebHook={this.props.newWebHook}
                  resetWebHook={this.props.resetWebHook}
                  notAsign={this.props.notAsign}
                  fetchRulesByName={this.props.fetchRulesByName}
                  formErrors={this.props.formErrors}
                  errorForm={this.props.errorForm}
                  t={this.props.t}
                />
              </Modal>
            }
          </Layout.Header>
          <Layout.Content style={{ ...styles.content, ...contentStyle }}>
            {
              isLoading &&
              <div className="justify-center">
                <Spin indicator={<Icon type="loading" style={styles.spin} spin />} />
              </div>
            }
            {this.props.children}
          </Layout.Content>
          <div align="right">
            {`V ${version}`}
          </div>
        </Layout>
      </Layout >
    );
  }
}

const mapStateToProps = ({ generalData, mainContainer }) => ({ ...generalData, ...mainContainer });

export default withTranslation()(withRouter(connect(mapStateToProps, actions)(MainLayout)));
