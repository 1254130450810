/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-19 10:57:15 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-12-28 11:46:38
 */
import { notification } from 'antd';

const defaultsDuration = 5;

class Notification {
  static error = (message, description, duration, onClick = () => null) => {
    notification.error({
 message, description, duration: duration || defaultsDuration, onClick,
});
  };
  static success = (message, description, duration, onClick = () => null) => {
    notification.success({
 message, description, duration: duration || defaultsDuration, onClick,
});
  };
  static warning = (message, description, duration, onClick = () => null) => {
    notification.warning({
 message, description, duration: duration || defaultsDuration, onClick,
});
  };
  static default = (message, description, duration, onClick = () => null) => {
    notification.info({
 message, description, duration: duration || defaultsDuration, onClick,
});
  };
}

export default Notification;
