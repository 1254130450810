import React from 'react';
import { Form, Input, Modal } from 'antd';
import { withTranslation } from 'react-i18next';

import './styles.css';

class GroupForm extends React.Component {
  handleOk = () => {
    const { form: { validateFields }, onSave } = this.props;

    validateFields((err, values) => {
      if (!err) {
        onSave(values);
      }
    });
  }

  render() {
    const {
      form: { getFieldDecorator }, visible, onClose, t,
    } = this.props;

    return (
      <Modal
        title={t('new_group')}
        className="modal-header-dark"
        maskStyle={{ backgroundColor: '#00000066' }}
        visible={visible}
        onOk={this.handleOk}
        onCancel={onClose}
        okText={t('save')}
        cancelText={t('cancel')}
      >
        <Form>
          <Form.Item key="name" label={t('name')} name="name">
            {getFieldDecorator('name', {
              initialValue: '',
              rules: [{ required: true, message: t('required_field') }],
            })(<Input />)}
          </Form.Item>
          <Form.Item key="description" label={t('description')} name="description">
            {getFieldDecorator('description', {
              initialValue: '',
            })(<Input />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

const WrappedContactForm = Form.create({
  name: 'group_form',
  onFieldsChange(props, changedFields) {
    props.onChange(changedFields);
  },
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({
        ...props.name,
        value: props.name.value,
      }),
      description: Form.createFormField({
        ...props.description,
        value: props.description.value,
      }),
    };
  },
})(GroupForm);

export default withTranslation()(WrappedContactForm);
