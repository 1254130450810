/*
 * @Author: Jhony Reyes 
 * @Date: 2018-05-04 12:15:26 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-21 20:22:08
 */

import i18n from 'i18next';
import { TaskTypes } from '../../../../../constants';

const validateTaskRuleLink = (task) => {
  if (task.type === TaskTypes.RULE_LINK) {
    if (!task.additionalInfo) {
      return true;
    }
    if (task.ruleLinkSettings) {
      if (task.ruleLinkSettings.linkSpecificallyBy === 'USER_TASK_IN_SOURCE_RULE' &&
      !task.ruleLinkSettings.linkSpecificallyUserTaskName) {
        return true;
      }
    }
  }
  return false;
};


const validations = () => ({
  checklist: [
    {
      validate: (_, { checklist }) => !Object.keys(checklist)
        .some(t => !checklist[t].name ||
          checklist[t].name.length <= 0 ||
            !checklist[t].name.trim()),
      message: i18n.t('tasks_without_name'),
      custom: true,
    },
    {
      validate: (_, { checklist }) => !Object.keys(checklist)
        .some(t => validateTaskRuleLink(checklist[t])),
      message: i18n.t('activity_not_empty'),
      custom: true,
    },
    {
      validate: (_, { checklist }) => !Object.keys(checklist)
        .filter(t => !checklist[t].groupId)
        .map(t => checklist[t].name)
        .some((k, i, a) => a.indexOf(k) !== i),
      message: i18n.t('tasks_same_name'),
      custom: true,
    },
    {
      validate: (_, { checklist, tasks }) => Object.keys(checklist).length > 0 || tasks.length > 0,
      message: i18n.t('required_tasks'),
      custom: true,
    },
  ],
});

export default validations;
