/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-08 17:10:47 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-04-27 14:18:59
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import * as generalActions from '../../../store/general/actions';
import { FETCH_AVAILABLE_USERS_FULFILLED } from '../../../store/general/types';
import {
  RESET_FORM, UPDATE_STATUS, FETCH_SUBSCRIPTIONS,
  FETCH_SUBSCRIPTIONS_FULFILLED,
} from './types';

const defaultState = {
  reducerKey: 'subscriptionContainer',
  loadingButton: false,
  paymentSource: false,
  changePaymentMethod: false,
  planInfo: false,
  isLoading: true,
  currentPage: 1,
  subscriptions: [],
  purchasedUsers: 0,
  availableUsers: 0,
};

// Actions
// Payload { form, defaultActions, navigate }
const resetForm = () => ({ type: RESET_FORM });
const updateStatus = (subscriptionId, status) => ({ type: UPDATE_STATUS, subscriptionId, status });
const fetchSubscriptions = () => ({ type: FETCH_SUBSCRIPTIONS });
const fetchSubscriptionsFulfilled = payload => ({ type: FETCH_SUBSCRIPTIONS_FULFILLED, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [RESET_FORM]: state => update(state, { path: 'form', value: { ...state.formReset } }),
  [FETCH_SUBSCRIPTIONS_FULFILLED]: (state, payload) => update(state, { path: 'subscriptions', value: payload }),
  [FETCH_AVAILABLE_USERS_FULFILLED]: (state, payload) => ({
    ...state,
    purchasedUsers: payload.purchasedUsers,
    availableUsers: payload.availableUsers,
  }),
});

const actions = {
  ...defaultActions,
  ...generalActions,
  resetForm,
  updateStatus,
  fetchSubscriptions,
  fetchSubscriptionsFulfilled,
};

export { reducer, actions, epics, defaultActions };
