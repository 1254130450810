/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:39:38
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2022-06-29 12:51:29
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import { Icon, Row, Col, Button, Tabs, Table, Checkbox, Tooltip, Select, message, Input } from 'antd';
import randomColor from 'randomcolor';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es';
import { withTranslation } from 'react-i18next';

import { RenderActionsCell, RenderChecklistCell } from '../../common/components/widgets/table';
import { SelectData, Message, ActivityForm } from '../../common/components/widgets';
import { RuleTypes } from '../../common/constants';
import { WEB_URL } from '../../boot/config';
import { actions, defaultActions } from './redux';
import { fetchInitData } from './utils';
import { AgendaEvent, CustomToolbar } from './utils/components';
import Rules from '../rules';

moment.locale('es');
const localizer = BigCalendar.momentLocalizer(moment);
const { Search } = Input;

class RulesCalendar extends Component {
  constructor(props) {
    super(props);
    this.columns = this.getColumns();
    this.state = {
      currentEvent: [],
      currentDate: new Date(2019, 0, 7),
    };
  }

  componentWillMount = () => {
    fetchInitData(this.props, defaultActions);
  }

  onChangeBranchOffice = (value) => {
    const { updateState, fetchRulesByFilters, filters } = this.props;

    updateState({ path: 'filters.branchOfficeId', value });
    fetchRulesByFilters({ ...filters, branchOfficeId: value });
  };

  onChangeRole = (value) => {
    const { updateState, fetchRulesByFilters, filters } = this.props;

    updateState({ path: 'filters.roleId', value });
    fetchRulesByFilters({ ...filters, roleIds: value ? [value] : null });
  };

  onChangeName = (e) => {
    const {
      updateState,
      fetchRulesByFilters,
      filters,
    } = this.props;
    updateState({ path: 'filters.name', value: e.target.value });

    fetchRulesByFilters({ ...filters });
  };

  onSearchName = () => {
    const { fetchRulesByFilters, filters } = this.props;
    fetchRulesByFilters({ ...filters });
  };

  onChangeTab = (activeKey) => {
    const {
      fetchRulesByFilters,
      filters,
      changeTab,
      catalogs: { branchOffices },
    } = this.props;

    let updatedFilters = { ...filters };

    switch (activeKey) {
      case RuleTypes.WEB:
        updatedFilters = {
          ruleType: RuleTypes.WEB,
          branchOfficeId: null,
          roleIds: null,
        };
        break;
      case RuleTypes.SCHEDULED:
        updatedFilters = {
          ruleType: RuleTypes.SCHEDULED,
          branchOfficeId: branchOffices.length ? branchOffices[0].id : null,
          roleIds: null,
          name: '',
        };
        break;
      case RuleTypes.NON_SCHEDULED:
        updatedFilters = {
          ruleType: RuleTypes.NON_SCHEDULED,
          branchOfficeId: null,
          roleIds: null,
          name: '',
        };
        break;
      default:
    }

    changeTab(updatedFilters);
    fetchRulesByFilters(updatedFilters);
  }

  getColumns = () => [
    {
      title: this.props.t('name'),
      dataIndex: 'name',
      onCell: () => ({ 'data-label': this.props.t('name') }),
    },
    {
      title: this.props.t('description'),
      dataIndex: 'description',
      width: '20%',
      onCell: () => ({ 'data-label': this.props.t('description') }),
    },
    {
      title: this.props.t('tasks'),
      dataIndex: 'checklist',
      onCell: () => ({ 'data-label': this.props.t('tasks') }),
      render: (text, record) => (
        <RenderChecklistCell
          required
          record={record}
          checklist={record.checklist}
        />
      ),
    },
    {
      title: this.props.t('private'),
      dataIndex: 'internal',
      onCell: () => ({ 'data-label': this.props.t('private') }),
      render: (text, record) => <Checkbox checked={record.internal} />,
    },
    {
      title: (
        <div>{this.props.t('notify')}&nbsp;
          <Tooltip
            placement="right"
            title={this.props.t('user_notification_msg')}
          >
            <Icon type="question-circle" theme="outlined" />
          </Tooltip>
        </div>
      ),
      dataIndex: 'users',
      onCell: () => ({ 'data-label': this.props.t('notify') }),
      render: (text, record) => {
        const { catalogs: { users } } = this.props;
        const selectedUsers = record.userNotifications ?
          record.userNotifications.filter(u => users.some(i => i.id === u)) : [];

        return (
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={this.props.t('select_option')}
            notFoundContent={null}
            filterOption={(input, option) => option.props.children.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            value={selectedUsers}
            onChange={value => this.onChangeUsers(value, record.id)}
            disabled
          >
            {users.map(item => (
              <Select.Option key={item.id}>{item.email}</Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: this.props.t('actions'),
      onCell: () => ({ 'data-label': this.props.t('actions') }),
      render: (text, record) => (
        <div>
          <RenderActionsCell
            creating={this.state.newRow}
            record={record}
            onSave={() => this.save(record)}
            onCancel={() => {}}
            onEdit={() => this.handleEdit(record.id)}
            onDelete={() => this.handleDelete(record.id)}
            customButtons={[{
              key: 'link',
              icon: 'link',
              onClick: () => this.copyToClipboard(`${WEB_URL}/web-form/${record.id}`),
            }]}
          />
        </div>
      ),
    },
  ];

  copyToClipboard = (str) => {
    const elem = document.createElement('textarea');
    elem.value = str;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    message.success(this.props.t('copied_clipboard'));
  };

  handleSelect = ({ start, end }) => {
    const {
      filters: { branchOfficeId }, updateState,
    } = this.props;

    if (!branchOfficeId) {
      this.branchSelect.focus();
      return Message.error(this.props.t('select_office'), 2.5);
    }

    this.setState({
      currentEvent: {
        id: '',
        title: '',
        start,
        end,
      },
    });
    return updateState({ path: 'createForm', value: true });
  }

  handleDelete = (id) => {
    const {
      created,
      deleteRule,
      updateState,
      filters,
    } = this.props;

    updateState({ path: 'registering', value: true });

    if (filters.ruleType === RuleTypes.SCHEDULED) {
      deleteRule({ id: created.id, branchOfficeId: created.branchOfficeId }, filters);
    } else {
      deleteRule({ id }, { ruleType: RuleTypes.WEB });
    }
  }

  handleSave = (value) => {
    const {
      registerRule,
      updateState,
      filters,
    } = this.props;

    updateState({ path: 'registering', value: true });

    switch (filters.ruleType) {
      case RuleTypes.WEB:
        registerRule(value, { ruleType: RuleTypes.WEB });
        break;
      case RuleTypes.SCHEDULED:
        registerRule(value, { ...filters, ruleType: RuleTypes.SCHEDULED });
        break;
      case RuleTypes.NON_SCHEDULED:
        registerRule(value, { ...filters, ruleType: RuleTypes.NON_SCHEDULED });
        break;
      default:
    }
  }

  handleEdit = (ruleId) => {
    const { loadForm } = this.props;

    loadForm(ruleId);
  }

  buildEvents = () => {
    const { catalogs: { rules } } = this.props;
    let events = [];

    if (rules.length) {
      rules.filter(rule => rule.cron !== null && !!rule.cron.days).forEach((rule) => {
        rule.cron.days.forEach((value, index) => {
          if (value) {
            const ruleEvents = rule.cron.events.map((e) => {
              const hour = e.substr(0, 2);
              const minute = e.substr(3, 2);
              const day = index === 0 ? 13 : 6 + index;
              const start = new Date(2019, 0, day, hour, minute);
              const momentEnd = moment(new Date(2019, 0, day, hour, minute)).add(rule.cron.duration, 'm');

              if (momentEnd.hours() === 0) {
                momentEnd.subtract(1, 'seconds');
              }

              return {
                id: rule.id,
                roleId: rule.roleId,
                usersIds: rule.usersIds,
                collaborative: rule.collaborative,
                title: rule.name,
                start,
                end: momentEnd.toDate(),
              };
            });
            events = [...events, ...ruleEvents];
          }
        });
      });
    }

    return events;
  }

  buildCalendarEvent = event =>
    <AgendaEvent event={event} onEvent={() => this.handleEdit(event.event.id)} />

  eventStyleGetter = (event) => {
    const style = {
      backgroundColor: randomColor({
        luminosity: 'dark',
        seed: event.collaborative ? event.roleId : event.usersIds,
      }),
    };

    return { style };
  }


  buttonCreateRule =() => {
    const start = moment();
    const end = moment();
    if (start.minutes() >= 1 && start.minutes() <= 15) {
      start.set('minute', 15);
      end.set('minute', 30);
    } else if (start.minutes() >= 16 && start.minutes() <= 30) {
      start.set('minute', 30);
      end.set('minute', 45);
    } else if (start.minutes() >= 31 && start.minutes() <= 45) {
      start.set('minute', 45);
      end.set('minute', 0).add(1, 'hour');
    } else if (start.minutes() > 45) {
      start.set('minute', 0).add(1, 'hour');
      end.set('minute', 15).add(1, 'hour');
    }
    this.handleSelect({ start, end });
  }

  renderScheduledTab = () => {
    const { currentDate } = this.state;
    const {
      catalogs: {
        branchOffices,
        roles,
      },
      filters: { branchOfficeId, roleId },
      t,
    } = this.props;
    const branchOfficeSelected = branchOffices.find(item => branchOfficeId === item.id);
    const roleSelected = roles.find(item => roleId === item.id);

    return (
      <div>
        <div className="headerContainer">
          <Row className="filtersRow">
            <Col span={6}>
              <h1 className="textBranch">{t('branch_office')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
              <SelectData
                style={{ width: '90% ' }}
                data={branchOffices}
                selected={branchOfficeSelected}
                onSelect={this.onChangeBranchOffice}
                refer={(ref) => { this.branchSelect = ref; }}
              />
            </Col>
            <Col span={6}>
              <h1 className="textBranch">{t('role')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
              <SelectData
                style={{ width: '90% ' }}
                data={[{ name: t('all_plural'), id: '' }, ...roles]}
                selected={roleSelected || { name: t('all_plural'), id: '' }}
                onSelect={this.onChangeRole}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Button
              onClick={this.buttonCreateRule}
              type="primary"
            >
              {t('create_scheduled_activity')}
            </Button>
          </Row>
        </div>
        <div style={{ height: 1500 }}>
          <BigCalendar
            min={new Date(2019, 0, 7)}
            selectable
            toolbar
            localizer={localizer}
            events={this.buildEvents()}
            defaultView={BigCalendar.Views.WEEK}
            views={{ week: true, day: true, agenda: true }}
            scrollToTime={new Date(2019, 0, 7, 5)}
            date={currentDate}
            onNavigate={(date) => {
              const dateTime = date.getTime();
              const minDate = new Date(2019, 0, 7).getTime();
              const maxDate = new Date(2019, 0, 13).getTime();

              if (dateTime >= minDate && dateTime <= maxDate) {
                this.setState({ currentDate: date });
              }
            }}
            onView={(view) => {
              if (view === 'agenda') {
                this.setState({ currentDate: new Date(2019, 0, 7) });
              }
            }}
            step={15}
            timeslots={4}
            onSelectEvent={event => this.handleEdit(event.id)}
            onSelectSlot={this.handleSelect}
            eventPropGetter={this.eventStyleGetter}
            formats={{
              dayFormat: 'dddd',
              timeGutterFormat: 'h:mm A',
              dayHeaderFormat: 'dddd',
              agendaHeaderFormat: () => '',
              agendaDateFormat: 'dddd',
              dayRangeHeaderFormat: () => '',
            }}
            popup
            components={{ toolbar: CustomToolbar, agenda: { event: this.buildCalendarEvent } }}
            messages={{
              time: t('time'),
              event: t('activity'),
              date: t('day'),
              noEventsInRange: t('no_events'),
            }}
          />
        </div>
      </div>
    );
  }

  renderWebTab = () => {
    const {
      catalogs: {
        rules,
      },
      updateState,
      filters: { name },
      t,
    } = this.props;

    return (
      <div>
        <div className="headerContainer">
          <Row>
            <Col xs={24} sm={12} md={12} lg={8} xl={8} className="filter-box">
              <h1 className="textBranch">
                {t('name')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
              </h1>
              <Search
                className="select-data"
                value={name}
                onChange={this.onChangeName}
                onSearch={this.onSearchName}
                enterButton
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={() => updateState({ path: 'createForm', value: true })}
                type="primary"
              >
                {t('create_web_activity')}
              </Button>
            </Col>
          </Row>
        </div>
        <Table
          ref={(table) => { this.table = table; }}
          rowKey="id"
          className="responsive-table"
          dataSource={rules.filter(r => r.ruleType === RuleTypes.WEB)}
          columns={this.columns}
          locale={{ emptyText: t('show_no_data') }}
          pagination={{
            current: 1,
            onChange: page => this.props.updateState({ path: 'currentPage', value: page }),
          }}
        />
      </div>
    );
  }

  render() {
    const { currentEvent } = this.state;
    const {
      catalogs: {
        tasks,
        evaluations,
        users,
        branchOffices,
        roles,
        rules,
      },
      filters,
      filters: { branchOfficeId, roleId },
      updateState,
      registering,
      fetchingSelectData,
      searchedUsers,
      searchUsers,
      created,
      createForm,
      t,
    } = this.props;
    const branchOfficeSelected = branchOffices.find(item => branchOfficeId === item.id);
    const roleSelected = roles.find(item => roleId === item.id);

    return (
      <div>
        <Tabs activeKey={filters.ruleType} onChange={this.onChangeTab}>
          <Tabs.TabPane tab={t('scheduled')} key="scheduled">
            { this.renderScheduledTab() }
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('nonScheduled')} key="nonScheduled">
            <Rules
              onCreate={() => updateState({ path: 'createForm', value: true })}
              rules={rules.filter(r => r.ruleType === RuleTypes.NON_SCHEDULED)}
              branchOffices={branchOffices}
              roles={roles}
              tasks={tasks}
              filters={filters}
              handleEdit={this.handleEdit}
              evaluations={evaluations}
              users={users}
              onChangeBranchOffice={this.onChangeBranchOffice}
              onChangeRole={this.onChangeRole}
              onChangeName={this.onChangeName}
              onSearchName={this.onSearchName}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t('web_activity')} key="web">
            { this.renderWebTab() }
          </Tabs.TabPane>
        </Tabs>
        {
          createForm &&
          <ActivityForm
            visible={createForm}
            onCancel={() => {
              updateState({ path: 'created', value: {} });
              updateState({ path: 'createForm', value: false });
            }}
            usersData={users}
            tasksData={tasks}
            evaluationsData={evaluations.content}
            rolesData={roles}
            roleDefault={roleSelected}
            branchOfficesData={branchOffices}
            branchOfficeDefault={branchOfficeSelected}
            onSearch={searchUsers}
            fetchingSelectData={fetchingSelectData}
            searchedUsers={searchedUsers}
            clearSearchedUsers={() => updateState({ path: 'searchedUsers', value: [] })}
            onSave={this.handleSave}
            onDelete={this.handleDelete}
            loading={registering}
            created={created}
            currentEvent={currentEvent}
            ruleType={filters.ruleType}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = ({ calendarContainer }) => ({
  ...calendarContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(RulesCalendar));
