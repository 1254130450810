import i18n from 'i18next';
import { isEmpty } from 'lodash';

export default () => ({
    name: [{ validate: 'required', message: i18n.t('name_required') }],
    lastName: [{ validate: 'required', message: i18n.t('lastnames_required') }],
    email: [{ validate: 'required', message: i18n.t('required_email') }, { validate: 'email', message: i18n.t('invalid_email') }],
    password: [
      {
        validate: (_, form) => {
          if (isEmpty(form.newPassword)) {
            return true;
          } else if (!isEmpty(form.newPassword) && isEmpty(form.password)) {
            return false;
          }
          return true;
        },
        message: i18n.t('password_required'),
        custom: true,
      },
      {
        validate: (_, form) => {
          if (form.password.length > 0 && form.password.length < 8) {
            return false;
          }
          return true;
        },
        message: i18n.t('min_8_characters'),
        custom: true,
      },
    ],
    newPassword: [
      {
        validate: (_, form) => (
          !form.password ||
          (form.password && form.newPassword)
        ),
        message: i18n.t('password_required'),
        custom: true,
      },
      {
        validate: (_, form) => {
          if (form.newPassword.length > 0 && form.newPassword.length < 8) {
            return false;
          }
          return true;
        },
        message: i18n.t('min_8_characters'),
        custom: true,
      },
    ],
    newPasswordConfirm: [
      {
        validate: (_, form) => {
          if (isEmpty(form.newPassword)) {
            return true;
          } else if (form.newPasswordConfirm !== form.newPassword) {
            return false;
          }
          return true;
        },
        message: i18n.t('passwords_not_match'),
        custom: true,
      },
      {
        validate: (_, form) => {
          if (form.newPasswordConfirm.length > 0 && form.newPasswordConfirm.length < 8) {
            return false;
          }
          return true;
        },
        message: i18n.t('min_8_characters'),
        custom: true,
      },
    ],
  });
