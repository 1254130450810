/*
 * @Author: Misael Jimenez 
 * @Date: 2021-02-08 17:45:39 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-06-18 00:09:34
 */

import React from 'react';
import { Row, Col, Button, Select, Modal, Popconfirm, Input, Checkbox } from 'antd';
import { getFieldError } from 'aleonor-object-validations';

import { ErrorText } from '../../widgets';
import { isAllowed } from '../../../../security';


export default class EditContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: props.form,
    };
  }


  getButtons = () => {
    const { t, onCancel, onSave } = this.props;
    return [
      <Popconfirm
        key="cancel"
        title={t('cancel_entry_confirmation')}
        cancelText={t('no')}
        okText={t('yes')}
        onConfirm={onCancel}
      >
        <Button>{t('cancel')}</Button>
      </Popconfirm>,
      <Button
        key="save-button"
        type="primary"
        onClick={() => onSave(this.state.form)}
      >
        { t('save_contact') }
      </Button>,
    ];
  }

  updateForm = (key, value) => {
      this.setState({
        form: {
            ...this.state.form,
            [key]: value,
        },
      });
  }


  render() {
    const {
      t,
      visible,
      whatsAppCustomers,
      groups,
      branchOffices,
      users,
      formErrors,
    } = this.props;
    const { form } = this.state;

    const selectedGroups = form.groups.map(i => i.id);

    const canMonitorAllBranches = isAllowed(['can_monitor_all_branches']);

    return (
      <Modal
        title={form.id === undefined ? t('new_contact') : t('edit_contact')}
        visible={visible}
        width={500}
        maskClosable={false}
        closable={false}
        className="modal-header-dark"
        style={{ top: 20 }}
        footer={this.getButtons()}
        maskStyle={{ backgroundColor: '#00000066' }}
        bodyStyle={{ padding: '0px' }}

      >
        <Row className="form-section">
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('name')}
              </div>
              <Input
                value={form.name}
                onChange={e => this.updateForm('name', e.target.value)}
              />
              <ErrorText message={getFieldError(formErrors, 'name')} />
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('lastname')}
              </div>
              <Input
                value={form.lastName}
                onChange={e => this.updateForm('lastName', e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('company')}
              </div>
              <Input
                value={form.companyName}
                onChange={e => this.updateForm('companyName', e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('role')}
              </div>
              <Input
                value={form.role}
                onChange={e => this.updateForm('role', e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('email_long')}
              </div>
              <Input
                value={form.email}
                onChange={e => this.updateForm('email', e.target.value)}
              />
            </Col>
            <ErrorText message={getFieldError(formErrors, 'email')} />
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('whatsapp')}
              </div>
              <Input
                value={form.phoneNumber}
                onChange={e => this.updateForm('phoneNumber', e.target.value)}
              />
              <ErrorText message={getFieldError(formErrors, 'phoneNumber')} />
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('notify_email')}
              </div>
              <Checkbox
                checked={form.notifyEmail}
                onChange={e => this.updateForm('notifyEmail', e.target.checked)}
                disabled={false}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('notify_whatsapp')}
              </div>
              <Checkbox
                checked={form.notifyWhatsapp}
                onChange={e => this.updateForm('notifyWhatsapp', e.target.checked)}
                disabled={whatsAppCustomers.length <= 0}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('assigned_group')}
              </div>
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder={t('select_option')}
                notFoundContent={null}
                filterOption={(input, option) => option.props.children.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
                value={selectedGroups}
                onChange={(value) => {
                    const newSelectedGroups = groups
                        .filter(g => value.some(groupId => groupId === g.id));
                    this.updateForm('groups', newSelectedGroups);
                }}

              >
                {groups.map(item => (
                  <Select.Option key={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('branch_office')}
              </div>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder={t('select_option')}
                notFoundContent={null}
                filterOption={(input, option) => option.props.children.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
                value={form.branchOfficeId}
                onChange={value => this.updateForm('branchOfficeId', value)}
                disabled={form.id && !canMonitorAllBranches}
              >
                {branchOffices.map(item => (
                  <Select.Option key={item.id}>{item.name}</Select.Option>
                ))}
              </Select>
              <ErrorText message={getFieldError(formErrors, 'branchOfficeId')} />
            </Col>
          </Row>
          <Row>
            <Col sm={24} lg={24} className="form-input">
              <div className="form-label">
                {t('user')}
              </div>
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder={t('select_option')}
                notFoundContent={null}
                filterOption={(input, option) => option.props.children.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
                value={form.userId}
                onChange={value => this.updateForm('userId', value)}
                disabled={form.id && !canMonitorAllBranches}
              >
                {users.map(item => (
                  <Select.Option key={item.id}>
                    {`${item.name} ${item.lastName} (${item.email})`}
                  </Select.Option>
                ))}
              </Select>
              <ErrorText message={getFieldError(formErrors, 'userId')} />
            </Col>
          </Row>
        </Row>
      </Modal>

    );
  }
}
