
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon, Row, Col, Select, Card } from 'antd';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/es';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';
import { blue, grey } from '@ant-design/colors';
import Search from 'antd/lib/input/Search';

import { actions, defaultActions } from './redux';
import { fetchInitData } from './utils';
import backgroundWebTaskItJpg from '../../assets/images/background-web_task-it.jpg';

import procesoPostVentaPng from '../../assets/images/proceso_post_venta.png';
import gestiónDeViajeJpg from '../../assets/images/gestión_de_viaje.jpg';
import evaluación360Jpg from '../../assets/images/evaluación_360.jpg';
import procesoProducciónJpg from '../../assets/images/proceso_producción.jpg';
import recursosHumanosJpeg from '../../assets/images/recursos_humanos.jpeg';
import procesoDeMarketingYPromociónJpg from '../../assets/images/proceso_de_marketing_y_promoción.jpg';
import procesoComercialJpg from '../../assets/images/proceso_comercial.jpg';
import gestiónDeProyectosEducativosPng from '../../assets/images/gestión_de_proyectos_educativos.png';
import métodoDeLas5SPng from '../../assets/images/método_de_las_5_s.png';
import procesoDeGestiónDeAccionesCorrectivasPreventivasJpg from '../../assets/images/proceso_de_gestión_de_acciones_correctivas_preventivas.jpg';
import procesoDeMantenimientoJpg from '../../assets/images/proceso_de_mantenimiento.jpg';

const { Option } = Select;

moment.locale('es');

class TaskitProcesses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      status: null,
    };
    this.timer = React.createRef();
  }

  componentWillMount = () => {
    fetchInitData(this.props, defaultActions);
  }

  onChangeStatus = async (value) => {
    await this.setState({ status: value });
    this.fetchTaskitProcesses();
  }

  onChangeName = async (value) => {
    clearTimeout(this.timer.current);
    await this.setState({ name: value });
    this.timer.current = setTimeout(() => {
      this.fetchTaskitProcesses();
    }, 250);
  }

  getTaskitProcessImage = (id) => {
    switch (id) {
      case 'ddad6043-80e4-14dd-e9ac-f65b938c9740':
        return procesoPostVentaPng;
      case 'ad99b615-912e-fcce-1b7a-c596928d579c':
        return gestiónDeViajeJpg;
      case '6e4ccf04-3efc-0904-b84b-a487225179e4':
        return evaluación360Jpg;
      case '262b776e-94e5-437d-ae8c-b6bb0b317d16':
        return procesoProducciónJpg;
      case 'd7af3145-10c8-c98d-3fd0-b2ce89df4318':
        return recursosHumanosJpeg;
      case '07fbe638-d411-9339-1ba7-3d6a4151e125':
        return procesoComercialJpg;
      case '6d80c9ff-73a2-333d-cf58-b793bd3396cb':
        return procesoDeMarketingYPromociónJpg;
      case '35262d8b-761e-4d17-84a7-adc9f340ae5f':
        return gestiónDeProyectosEducativosPng;
      case '60cc6eb2-f69c-7059-2567-eeed39fbe81b':
        return métodoDeLas5SPng;
      case '4f15e46a-4298-0ef6-05a3-b662236b10b8':
        return procesoDeGestiónDeAccionesCorrectivasPreventivasJpg;
      case '77e62351-de71-9568-29f4-87900592ed4e':
        return procesoDeMantenimientoJpg;
      default:
        return backgroundWebTaskItJpg;
    }
  }

  fetchTaskitProcesses = () => {
    this.props.fetchTaskitProcesses({
      name: this.state.name,
      active: this.state.status,
    });
  }

  renderTaskitProcesses = () => {
    const {
      catalogs: { taskitProcesses }, t,
    } = this.props;

    const hasTaskitProcesses = taskitProcesses && taskitProcesses.length;
    if (hasTaskitProcesses) {
      return (
        <Card style={{ borderColor: 'white' }}>
          {taskitProcesses.map((taskitProcess) => {
            const {
              id, name, description, active,
            } = taskitProcess;
            return (
              <Card.Grid style={{ width: 370, height: 300, margin: 20 }}>
                <Link
                  to={{ pathname: `/taskitProcesses/${id}` }}
                  onClick={() => this.props.updateTaskitProcess(taskitProcess)}
                >
                  <img
                    alt="example"
                    src={this.getTaskitProcessImage(id)}
                    width="100%"
                    height="150"
                  />
                  <Row justify="center" align="middle" style={{ paddingTop: 10 }}>
                    <Col span={20} >
                      <Paragraph strong ellipsis={{ rows: 2 }} style={{ width: '100%', justifySelf: 'center' }} >{name}</Paragraph>
                    </Col>
                    <Col span={4}>
                      <Icon
                        style={{ fontSize: 24, color: active ? blue.primary : grey.primary }}
                        type="poweroff"
                        title={active ? t('active') : t('inactive')}
                      />
                    </Col>
                  </Row>
                  <Paragraph
                    type="secondary"
                    ellipsis={{ rows: 3 }}
                  >
                    {description}
                  </Paragraph>
                </Link>
              </Card.Grid>
            );
          })}
        </Card>
      );
    }
  return <h1>{t('no_taskit_process_available_message')}</h1>;
  }

  renderFiltersHeader = () => (
    <div className="headerContainer">
      <Row className="filtersRow">
        <Col span={6}>
          <h1 className="textBranch">
            {this.props.t('name')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <Search
            className="select-data"
            value={this.state.name}
            onChange={event => this.onChangeName(event.target.value)}
            onSearch={this.fetchTaskitProcesses}
            enterButton
          />
        </Col>

        <Col span={6}>
          <h1 className="textBranch">{this.props.t('status')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
          <Select
            value={this.state.status}
            style={{ width: '100%', paddingRight: '1em' }}
            onChange={this.onChangeStatus}
          >
            <Option value={null}>{this.props.t('all_plural')}</Option>
            <Option value>{this.props.t('active')}</Option>
            <Option value={false}>{this.props.t('inactive')}</Option>
          </Select>
        </Col>

      </Row>
    </div>
  )


  render() {
    return (
      <div>
        {this.renderFiltersHeader()}
        {this.renderTaskitProcesses()}
      </div>
    );
  }
}

const mapStateToProps = ({ taskitProcessesContainer, generalData }) => ({
  ...taskitProcessesContainer,
  ...generalData,
});

export default withTranslation()(connect(mapStateToProps, actions)(TaskitProcesses));
