/*
 * @Author: Jhony Reyes 
 * @Date: 2019-01-22 16:59:43 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-18 12:18:55
 */
import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { PermissionsBuilder, PermissionsShow, ErrorText } from '../../index';

const RenderPermissionsCell = ({
  record, created, onChange, error, errorMessage, required, data, disabled,
}) => {
  const { t } = useTranslation();
  return (
    record.editable ?
      <div>
        <div className="inputContainer">
          {required &&
          <Tooltip title={t('field_required')} placement="topLeft">
            <span className="inputContainer__span">*</span>
          </Tooltip>}
          <PermissionsBuilder
            data={data}
            created={created}
            onChange={onChange}
            disabled={disabled}
          />
        </div>
        <ErrorText message={error && errorMessage} />
      </div>
      : <PermissionsShow key={record.id} created={created} />
  );
};

export default RenderPermissionsCell;
