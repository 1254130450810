/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 15:31:18
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './';
import { REGISTER_RULE, UPDATE_RULE, DELETE_RULE } from './types';

import RuleService from '../../../services/rule';
import { parseApiError } from '../../../common/error';
import { Notification } from '../../../common/components/widgets';

const ruleService = new RuleService();

const registerSuccessAction = (rule) => {
  Notification.success(i18n.t('success'), `${rule.name} ${i18n.t('registered_success')}`, 4);
  return { type: '' };
};

const updateSuccessAction = (rule) => {
  Notification.success(i18n.t('success'), `${rule.name} ${i18n.t('updated_success')}`, 4);
  return { type: '' };
};

const deleteSuccessAction = (rule) => {
  Notification.success(i18n.t('success'), `${rule} ${i18n.t('deleted_success')}`, 4);
  return { type: '' };
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const registerRuleEpic = action$ =>
  action$.ofType(REGISTER_RULE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(ruleService.postRule(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.fetchRulesByFilters(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateRuleEpic = action$ =>
  action$.ofType(UPDATE_RULE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(ruleService.putRule(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(updateSuccessAction(response)),
            Observable.of(actions.fetchRulesByFilters(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteRuleEpic = action$ =>
  action$.ofType(DELETE_RULE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(ruleService.deleteRule(action.payload.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(deleteSuccessAction(response)),
            Observable.of(actions.fetchRulesByFilters(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

export default combineEpics(registerRuleEpic, updateRuleEpic, deleteRuleEpic);
