/*
 * @Author: Cesar Medina
 * @Date: 2018-05-07 14:43:45 
 * @Last Modified by: Misael Jiménez
 * @Last Modified time: 2020-07-14 14:58:53
 */
import React, { Component } from 'react';
import { Popover, List, Button } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

class PopoverList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  done = () => {
    this.setState(state => ({ visible: !state.visible }));
  }

  handleVisibleChange = (visible) => {
    if (this.props.onVisibleChange && typeof this.props.onVisibleChange === 'function') {
      this.props.onVisibleChange(visible);
    }
    this.setState({ visible });
  }

  render() {
    const {
      title,
      titleStyle,
      data,
      renderItem,
      label,
      t,
    } = this.props;

    return (
      <Popover
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        trigger="click"
        content={
          <div>
            <span style={titleStyle}>
              {title}
            </span>
            <List
              dataSource={data}
              style={{ maxHeight: '20rem', overflow: 'auto' }}
              locale={{ emptyText: t('no_data') }}
              renderItem={item => (
                <List.Item>
                  {renderItem(item)}
                </List.Item>
              )}
            />
            <div className="justify-center margin-top-1">
              <Button
                className="margin-left-half-rem"
                type="primary"
                onClick={this.done}
              >
                {t('accept')}
              </Button>
            </div>
          </div>
        }
      >
        <div className="link-button">
          {label}
        </div>
      </Popover>
    );
  }
}

PopoverList.propTypes = {
  titleStyle: PropTypes.object,
  title: PropTypes.string,
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
};

PopoverList.defaultProps = {
  titleStyle: { color: '#d60007', fontWeight: 'bold' },
  title: i18n.t('list'),
};

export default withTranslation()(PopoverList);
