/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-22 13:04:36 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 15:30:18
 */

import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from '.';
import { FETCH_TOKEN, RESET_PASSWORD, FETCH_COMPANY_AVAILABILITY, REGISTER } from './types';
import { actions as loginActions } from '../../login/redux';

import UserService from '../../../../services/user';
import CompanyService from '../../../../services/company';
import { Notification } from '../../../../common/components/widgets';
import { parseApiError } from '../../../../common/error';

const userService = new UserService();
const companyService = new CompanyService();

const loginUserAction = (user, navigate) => loginActions.loginUser({
  form: { email: user.email, password: user.password },
  navigate,
});

const fetchTokenEpic = action$ =>
  action$.ofType(FETCH_TOKEN).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(userService.getRegisterToken(action.token))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.resetState()),
            Observable.of(actions.fetchTokenSucess({ user: response })),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchTokenSucess({ error: parseApiError(error) })),
          )),
    ));

const resetPasswordEpic = action$ =>
  action$.ofType(RESET_PASSWORD)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable
          .fromPromise(userService.resetPassword(action.payload.form, action.payload.password))
          .flatMap(user =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.resetState()),
              Observable.of(actions.resetingPassword(false)),
              Observable.of(actions.resetPasswordSuccess({ reset: true, user })),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.resetingPassword(false)),
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                Notification.error(i18n.t('password_update_fail'), parsedError.message);
              })),
            )),
      ));

const fetchCompanyAvailabilityEpic = action$ =>
  action$.ofType(FETCH_COMPANY_AVAILABILITY).flatMap(action =>
    Observable.concat(Observable.fromPromise(companyService.getAvailability(action.companyName))
      .flatMap(response =>
        Observable.concat(Observable.of(actions.fetchCompanyAvailabilityFulfilled(response))))
      .catch(() => {})));

const registerCompanyAndUserEpic = action$ =>
  action$.ofType(REGISTER)
    .flatMap(action =>
      Observable.concat(Observable
        .fromPromise(companyService.registerCompanyAndUser(action.payload.form))
        .flatMap(() =>
          Observable.concat(Observable
            .of(loginUserAction(action.payload.form, action.payload.navigate))))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.resetingPassword(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              Notification.error(parsedError.message);
            })),
          ))));

export default combineEpics(
  fetchTokenEpic, resetPasswordEpic,
  fetchCompanyAvailabilityEpic, registerCompanyAndUserEpic,
);
