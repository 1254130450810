/*
 * @Author: Jhony Reyes 
 * @Date: 2018-05-31 09:57:22 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-09 15:16:53
 */

import axios from 'axios';
import Service from '../Service';
import { createFilesServiceUrl } from '../../common/helpers/service';

class FileService extends Service {
  uploadFile = file => axios.post(createFilesServiceUrl('api/files'), file)
    .then(data => data.data);
}

export default FileService;
