/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-20 15:41:30 
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2019-12-18 09:54:32
 */

import i18n from 'i18next';

const formValidations = () => ({
  password: [
    { validate: 'required', message: i18n.t('password_required') },
    {
      validate: (_, form) => form.password.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, form) => {
        if (form.password.length < 8) {
          return false;
        }
        return true;
      },
      message: i18n.t('min_8_characters'),
      custom: true,
    },
  ],
  newPassword: [
    { validate: 'required', message: i18n.t('confirm_password') },
    {
      validate: (_, form) => form.newPassword.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, form) => {
        if (form.newPassword.length < 8) {
          return false;
        }
        return true;
      },
      message: i18n.t('min_8_characters'),
      custom: true,
    },
    {
      validate: (_, form) => form.newPassword === form.password,
      message: i18n.t('passwords_not_match'),
      custom: true,
    },
  ],
});

const companyFormValidations = () => ({
  companyName: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.companyName.trimRight(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, form) => {
        if (form.companyName.length > 40) {
          return false;
        }
        return true;
      },
      message: i18n.t('max_40_characters'),
      custom: true,
    },
  ],
  name: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.name.trimRight(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  lastName: [
    { validate: 'required', message: i18n.t('lastnames_required') },
    {
      validate: (_, form) => form.lastName.trimRight(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  email: [
    { validate: 'required', message: i18n.t('required_email') },
    { validate: 'email', message: i18n.t('invalid_email') },
    {
      validate: (_, form) => form.email.trimRight(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  password: [
    { validate: 'required', message: i18n.t('password_required') },
    {
      validate: (_, form) => form.password.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, form) => {
        if (form.password.length < 8) {
          return false;
        }
        return true;
      },
      message: i18n.t('min_8_characters'),
      custom: true,
    },
  ],
  password2: [
    { validate: 'required', message: i18n.t('confirm_password') },
    {
      validate: (_, form) => form.password2.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, form) => {
        if (form.password2.length < 8) {
          return false;
        }
        return true;
      },
      message: i18n.t('min_8_characters'),
      custom: true,
    },
    {
      validate: (_, form) => form.password2 === form.password,
      message: i18n.t('passwords_not_match'),
      custom: true,
    },
  ],
  phoneNumber: [
    { validate: 'required', message: i18n.t('phone_required') },
    {
      validate: (_, form) => form.phoneNumber.trimRight(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, resetForm) => {
        if (resetForm.phoneNumber.length > 10) {
          return false;
        }
        return true;
      },
      message: i18n.t('max_10_digits'),
      custom: true,
    },
    {
      validate: (_, form) => !/[^\d]/g.test(form.phoneNumber),
      message: i18n.t('only_numbers'),
      custom: true,
    },
  ],
  terms: [
    {
      validate: (_, form) => {
        if (form.terms) {
          return true;
        } return false;
      },
      message: i18n.t('accept_terms'),
      custom: true,
    },
  ],
});

export { formValidations, companyFormValidations };
