import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import moment from 'moment';


const getTickValue = (initDate, endDate, formatHours) => {
  let tickValues = 'every 1 year';
  let format = '%Y';
  const diffDays = moment(endDate).diff(moment(initDate), 'days');
  if (diffDays <= 1 && formatHours) {
    tickValues = 'every 1 hour';
    format = '%H:%M';
  } else if (diffDays <= 7 && formatHours) {
    tickValues = 'every 12 hour';
    format = '%d/%m/%y %H:%M';
  } else if (diffDays <= 15) {
    tickValues = 'every 1 days';
    format = '%d/%m/%y';
  } else if (diffDays <= 31) {
    tickValues = 'every 2 days';
    format = '%d/%m/%y';
  } else if (diffDays <= 365) {
    tickValues = 'every 1 month';
    format = '%m/%Y';
  }

  return {
    format,
    tickValues,
    tickRotation: -45,
  };
};

const customTooltip = ({ slice }) => {
  const date = slice.points[0].data.xFormatted;
  return (
    <div
      style={{
        background: 'white',
        padding: '9px 12px',
        border: '1px solid #ccc',
      }}
    >
      <strong>{date}</strong>
      {slice.points.map(point => (
        <div
          key={point.id}
          style={{
            color: point.serieColor,
            padding: '3px 0',
          }}
        >
          <strong>{point.serieId}</strong>
          <span style={{ color: 'darkgray' }}> ({point.data.yFormatted.toFixed(2)}%)</span>
        </div>
      ))}
    </div>
  );
};

const NivoResponsiveLine = ({
  data,
  refValue,
  initDate,
  endDate,
  extraProps = {},
  formatHours = false,
}) => {
  const marker = [
    {
      axis: 'y',
      value: refValue,
      lineStyle: { stroke: '#FF0000', strokeWidth: 2 },
    },
  ];
  const tickValue = getTickValue(initDate, endDate, formatHours);

  return (
    <ResponsiveLine
      data={data}
      markers={refValue !== null ? marker : []}
      margin={{
        top: 5,
        right: 110,
        bottom: 60,
        left: 32,
      }}
      xScale={{
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: false,
        precision: 'day',
      }}
      xFormat="time:%d/%m/%y"
      yScale={{
        type: 'linear',
        stacked: false,
      }}
      axisBottom={tickValue}
      enableSlices="x"
      animate={false}
      sliceTooltip={customTooltip}
      legends={[
        {
          anchor: 'top-right',
          direction: 'column',
          justify: false,
          translateX: 95,
          translateY: 5,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 12,
          itemOpacity: 0.75,
          symbolSize: 10,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
        },
      ]}
      {...extraProps}
    />
  );
};

export default NivoResponsiveLine;
