/*
 * @Author: Jhony Reyes 
 * @Date: 2018-05-04 12:15:26 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-25 19:04:40
 */
import { isEmpty } from 'lodash';

import i18n from 'i18next';

const validations = () => ({
    roleId: [{
      validate: (_, form) => !(isEmpty(form.roleId) && isEmpty(form.usersIds)),
      message: i18n.t('required_role_users'),
      custom: true,
    }],
    branchOfficeIds: [
      { validate: 'required', message: i18n.t('office_required') },
      {
        validate: (_, form) => form.branchOfficeIds
          && form.branchOfficeIds.length && form.branchOfficeIds[0].trim(),
        message: i18n.t('only_spaces_invalid'),
        custom: true,
      },
    ],
  });

export default validations;
