/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 16:15:33 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-09-23 10:32:12
 */
const fetchActions = (props, defaultActions) => {
  const {
    fetchUsers, fetchBranchOfficesActive, fetchRoles, fetchPermissions,
    updateState, formReset, fetchAvailableUsers, filters,
  } = props;
  updateState({ path: 'newUser', value: false });
  updateState({ path: 'form', value: { ...formReset } });
  updateState({ path: 'formErrors', value: {} });
  updateState({ path: 'errorForm', value: false });
  fetchAvailableUsers();
  fetchUsers(filters);
  fetchBranchOfficesActive(defaultActions);
  fetchRoles(defaultActions);
  fetchPermissions(defaultActions);
};

export default fetchActions;
