/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 11:09:48 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-10-22 16:04:54
 */
import axios from 'axios';
import Service from '../Service';
import { createRulesServiceUrl, parseParams } from '../../common/helpers/service';

class EvaluationService extends Service {
    getAll = params => axios.get(createRulesServiceUrl(`evaluations${parseParams(params)}`)).then(data => data.data);
    getById = id => axios.get(createRulesServiceUrl(`evaluations/${id}`)).then(data => data.data);
    createEvaluation = form => axios.post(createRulesServiceUrl('evaluations'), form).then(data => data.data);
    updateEvaluation = form => axios.put(createRulesServiceUrl('evaluations'), form).then(data => data.data);
    deleteEvaluation = id => axios.delete(createRulesServiceUrl(`evaluations/${id}`)).then(data => data.data);
}

export default EvaluationService;
