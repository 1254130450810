import React from 'react';
import { withTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import url from 'url';
import UserService from '../../../../services/user';

const userService = new UserService();


class TableauView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableauTicket: null,
      loading: true,
    };
    this.timer = null;
  }

  componentDidMount = () => {
    this.getTableauTicket();
  }

  getTableauTicket = async () => {
    try {
      const { tableauUsername } = this.props;
      const tableauTicket = await userService.getTableauTicket(tableauUsername || '');
      this.setState({ tableauTicket, loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  getTableauParsedUrl(nextUrl, token) {
    const newUrl = nextUrl || this.props.url;
    const { query } = this.props;
    const parsed = url.parse(newUrl, true);

    if (token) {
      return `${parsed.protocol}//${parsed.host}/trusted/${token}${parsed.pathname}${query}`;
    }

    return `${parsed.protocol}//${parsed.host}${parsed.pathname}${query}`;
  }

  render() {
    const {
      url: urlDashboard, t,
    } = this.props;
    const { tableauTicket, loading } = this.state;
    if (urlDashboard && tableauTicket) {
      return (
        <iframe title="tableauViz" src={this.getTableauParsedUrl(urlDashboard, tableauTicket)} width="100%" height="100%" />
      );
    }
    return (
      <div className="justify-center">
        {!loading ?
          <Text type="danger">
            {t('user_without_analytics_licence')}
          </Text>
        :
          <Spin tip="Loading..." size="default" />
        }
      </div>
    );
  }
}

TableauView.propTypes = {
  url: PropTypes.string,
  query: PropTypes.string,
  tableauUsername: PropTypes.string,
};
TableauView.defaultProps = {
  url: null,
  query: '?:embed=yes&:comments=no&:toolbar=no&:refresh=no',
  tableauUsername: null,
};


export default withTranslation()(TableauView);
