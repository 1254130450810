/*
 * @Author: Cesar Medina
 * @Date: 2019-04-24 11:12:44 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-09-24 15:08:35
 */
import React from 'react';
import { Icon, Row, Col } from 'antd';
import i18n from 'i18next';

const availableUsers = props => (
  <Row>
    <Col span={24} style={{ padding: '5px 10px' }}>
      <Icon type="user" style={{ fontSize: '20px', color: '#2684FF' }} />
      <span style={{ color: '#959595' }}>{i18n.t('subscribed_users')}: </span>{props.purchasedUsers}
    </Col>
    <Col span={24} style={{ padding: '5px 10px' }}>
      <Icon type="user" style={{ fontSize: '20px', color: '#1AB542' }} />
      <span style={{ color: '#959595' }}>{i18n.t('available_users')}: </span>{props.availableUsers}
    </Col>
  </Row>
);

export default availableUsers;
