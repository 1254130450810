/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-20 15:40:34 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-08-23 22:48:07
 */

const CONTAINER_KEY = 'RESET_PASSWORD_CONTAINER';
export const SEND_EMAIL = `${CONTAINER_KEY}_SEND_EMAIL`;
export const SENDING_EMAIL = `${CONTAINER_KEY}_SENDING_EMAIL`;
export const SEND_EMAIL_SUCCESSFUL = `${SEND_EMAIL}_SUCCESSFUL`;
export const FETCH_TOKEN = `${CONTAINER_KEY}_FETCH_TOKEN`;
export const FETCH_TOKEN_SUCCESSFUL = `${FETCH_TOKEN}_SUCCESSFUL`;
export const RESET_PASSWORD = `${CONTAINER_KEY}_RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESSFUL = `${RESET_PASSWORD}_SUCCESSFUL`;
