/*
 * @Author: Jhony Reyes 
 * @Date: 2019-02-11 23:21:48 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-05-17 21:46:53
 */

const fetchInitData = (props, defaultActions) => {
  const {
    fetchBranchOfficesActive,
    fetchRoles,
    resetState,
    fetchUsers,
    fetchTaskitProcesses,
  } = props;

  resetState();
  fetchBranchOfficesActive(defaultActions);
  fetchRoles(defaultActions);
  fetchUsers({ active: true });
  fetchTaskitProcesses();
};

export { fetchInitData };
