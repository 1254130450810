/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-19 11:46:55 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2018-12-12 16:51:08
 */
import React, { Component } from 'react';
import { Select, Button, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';

import { hours, dividedHours, periodicity } from './constants';
import { format, filterRange, filterPeriodicity } from './helpers';
import ErrorText from '../errorText';

const defaultState = {
  start: hours[7],
  end: hours[hours.length - 6],
  every: periodicity[2],
  duration: periodicity[2],
  range: [],
  hoursInRange: [],
};

class Builder extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentDidMount = () => {
    const { cronCreated } = this.props;
    const {
      start, end, every, range, newRow, duration,
    } = cronCreated;

    if (cronCreated && !newRow) {
      this.setState({
        range, start, end, every, cronCreated, duration, hoursInRange: range,
      });
    }
  }

  getHoursInRange = () => {
    const { start, end, every } = this.state;

    return dividedHours
      .filter(item => filterPeriodicity(item, every))
      .filter(item => filterRange(item, start, end));
  }

  setHoursInRange = () => {
    const hoursInRange = this.getHoursInRange();

    this.setState({ range: hoursInRange, hoursInRange });
  }

  handleOk = () => {
    const {
      start, end, every, range, duration,
    } = this.state;
    const { onDone } = this.props;
    if (range.length <= 0) {
      this.setState({ invalidRange: true });
    } else {
      this.setState({
        invalidRange: false,
        start,
        end,
        every,
        range,
        duration,
      }, () => onDone({ ...this.state, formatted: format(start, end, every) }));
    }
  }

  resetState = () => {
    const { cronCreated } = this.props;
    const { newRow } = cronCreated;
    if (cronCreated && !newRow) {
      this.setState(state => (state.cronCreated), this.handleCancel);
    } else {
      this.setState(defaultState, this.handleCancel);
    }
  }

  handleCancel = () => {
    const {
      start, end, every, range, cronCreated,
    } = this.state;
    const { onCancel } = this.props;
    if (range.length > 0) {
      if (cronCreated) {
        onCancel(cronCreated);
      } else {
        onCancel({ ...defaultState, formatted: format(start, end, every) });
      }
    } else {
      onCancel();
    }
    this.setState({ invalidRange: false });
  }

  render() {
    const {
      start, end, every, duration, range, invalidRange, hoursInRange,
    } = this.state;
    const { t } = this.props;

    return (
      <div style={{ width: 600 }}>
        <div className="margin-bottom-1">
          Desde:
          <Select
            mode="single"
            className="full-width since-time"
            placeholder={t('select_start_time')}
            value={start}
            onChange={value => this.setState({ start: value, range: [] })}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            {hours.slice(0, hours.indexOf(end)).map(item => (
              <Select.Option className="since-time-li" key={item}>{item}</Select.Option>
            ))}
          </Select>
        </div>

        <div className="margin-bottom-1">
          Hasta:
          <Select
            mode="single"
            className="full-width until-time"
            placeholder={t('select_end_time')}
            value={end}
            onChange={value => this.setState({ end: value, range: [] })}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            {hours.slice(hours.indexOf(start) + 1).map(item => (
              <Select.Option className="until-time-li" key={item}>{item}</Select.Option>
            ))}
          </Select>
        </div>

        <Row>
          <Col span={12}>
            <div className="margin-bottom-1 margin-right-1">
              Intervalo:
              <Select
                mode="single"
                className="full-width"
                placeholder={t('select_periodicity')}
                value={every}
                onChange={value => this.setState({ every: value, range: [] })}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
                {periodicity.map(item => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))}
                <Select.Option key="(Personalized)" disabled>(Personalized)</Select.Option>
              </Select>
            </div>
          </Col>
          <Col span={12}>
            <div className="margin-bottom-1">
              {t('duration')}:
              <Select
                mode="single"
                className="full-width"
                placeholder={t('select_periodicity')}
                value={duration}
                onChange={value => this.setState({ duration: value })}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
                {periodicity.map(item => (
                  <Select.Option key={item}>{item}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>

        <div className="margin-bottom-1">
          <Button
            className="full-width"
            onClick={this.setHoursInRange}
            disabled={every === '(Personalized)'}
          >
            {`Aplicar ${format(start, end, every)}`}
          </Button>
          {invalidRange && <ErrorText message={t('invalid_range')} />}
          <Select
            mode="multiple"
            className="full-width"
            placeholder={t('select_time_periodicity')}
            value={range}
            onChange={value => this.setState({ range: value })}
            getPopupContainer={triggerNode => triggerNode.parentNode}
            onDeselect={() => this.setState({ every: '(Personalized)' })}
          >
            {hoursInRange.map(item => (
              <Select.Option key={item}>{item}</Select.Option>
            ))}
          </Select>
        </div>

        <div className="justify-end margin-top-1 cron-footer">
          <Button onClick={this.resetState}>
            {t('cancel')}
          </Button>
          <Button
            className="margin-left-half-rem"
            type="primary"
            onClick={this.handleOk}
          >
            {t('accept')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Builder);
