/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-08 17:10:47 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-09-07 11:47:19
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import moment from 'moment';
import epics from './epics';
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  REGISTER_WIDGET,
  REGISTER_WIDGET_PERFORMANCE_BY_BRANCH,
  UPDATE_WIDGET,
  UPDATE_WIDGET_FULFILLED,
  DELETE_WIDGET,
  DELETE_WIDGET_FULFILLED,
} from './types';
import {
  FETCH_TASKS_FULFILLED, FETCH_BRANCH_OFFICES_FULFILLED, FETCH_WIDGETS,
  FETCH_WIDGETS_DATA, FETCH_WIDGETS_FULFILLED, FETCH_WIDGETS_DATA_FULFILLED,
  FETCH_RULES_BY_NAME_FULFILLED,
} from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';

const defaultState = {
  reducerKey: 'widgetsContainer',
  showWidgetForm: false,
  selectedWidget: null,
  loadingWidgets: false,
  catalogs: {
    tasks: [],
    widgets: [],
    branchOffices: [],
    rules: {
      content: [],
      size: 100,
      number: 0,
      totalElements: 1,
    },
  },
  filters: {
    initDate: moment().valueOf(),
    endDate: moment().valueOf(),
    branchOfficeId: null,
  },
};

// Actions
// Payload { form, defaultActions, navigate }
const openModal = () => ({ type: OPEN_MODAL });
const closeModal = () => ({ type: CLOSE_MODAL });
const registerWidget = (form, filters) => ({ type: REGISTER_WIDGET, form, filters });
const registerWidgetPerformanceByBranch = (form, filters) => ({
  type: REGISTER_WIDGET_PERFORMANCE_BY_BRANCH, form, filters,
});
const updateWidget = (form, filters) => ({ type: UPDATE_WIDGET, form, filters });
const updateWidgetFulfilled = widget => ({ type: UPDATE_WIDGET_FULFILLED, payload: widget });
const deleteWidget = id => ({ type: DELETE_WIDGET, id });
const deleteWidgetFulfilled = payload => ({ type: DELETE_WIDGET_FULFILLED, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_TASKS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.tasks', value: payload }),
  [FETCH_WIDGETS]: state =>
    update(state, { path: 'loadingWidgets', value: true }),
  [FETCH_BRANCH_OFFICES_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.branchOffices', value: payload }),
  [FETCH_WIDGETS_FULFILLED]: (state, payload) => ({
    ...state,
    catalogs: {
      ...state.catalogs,
      widgets: payload,
    },
    loadingWidgets: false,
  }),
  [FETCH_WIDGETS_DATA]: (state, payload) => {
    const updatedWidgets = state.catalogs.widgets.map(i => (
      i.id === payload.widget.id ? { ...i, data: null, error: false } : i
    ));

    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        widgets: updatedWidgets,
      },
    };
  },
  [FETCH_WIDGETS_DATA_FULFILLED]: (state, payload) => {
    let updatedWidgets;

    if (payload.error) {
      updatedWidgets = state.catalogs.widgets.map(i => (
        i.id === payload.widget.id ? { ...i, error: true } : i
      ));
    } else {
      updatedWidgets = state.catalogs.widgets.map(i => (
        i.id === payload.widget.id ? { ...i, data: payload, error: false } : i
      ));
    }

    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        widgets: updatedWidgets,
      },
    };
  },
  [UPDATE_WIDGET_FULFILLED]: (state, payload) => {
    const updatedWidgets = state.catalogs.widgets.map(i => (
      i.id === payload.id ? payload : i
    ));

    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        widgets: updatedWidgets,
      },
    };
  },
  [DELETE_WIDGET_FULFILLED]: (state, payload) => {
    const updatedWidgets = state.catalogs.widgets.filter(i => i.id !== payload);

    return {
      ...state,
      catalogs: {
        ...state.catalogs,
        widgets: updatedWidgets,
      },
    };
  },
  [OPEN_MODAL]: state => ({
    ...state,
    showWidgetForm: true,
  }),
  [CLOSE_MODAL]: state => ({
    ...state,
    showWidgetForm: false,
  }),
  [FETCH_RULES_BY_NAME_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.rules', value: payload }),
});

const actions = {
  ...defaultActions,
  ...generalActions,
  openModal,
  closeModal,
  registerWidget,
  registerWidgetPerformanceByBranch,
  updateWidget,
  updateWidgetFulfilled,
  deleteWidget,
  deleteWidgetFulfilled,
};

export { reducer, actions, epics, defaultActions };
