/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-19 11:41:23 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-10-23 16:33:53
 */
import React, { Component } from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Builder from './builder';

class ChecklistBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      formatted: '',
    };
  }

  componentWillMount =() => {
    if (this.props.value) {
      this.setState({
        formatted: `${this.props.t('tasks')}: ${Object.keys(this.props.value).length}`,
      });
    }
  }

  done = (values) => {
    if (values && Object.keys(values).length > 0) {
      this.setState({ visible: false, formatted: Object.keys(values).length > 0 ? `${this.props.t('tasks')}: ${Object.keys(values).length}` : '' });
      this.props.onChange(values);
    } else {
      this.setState({ visible: false, formatted: null });
      this.props.onChange('');
    }
  }

  toggle = () => this.setState(state => ({ visible: !state.visible }));

  handleVisibleChange = (visible) => {
    if (!visible) {
      const { state } = this.builderTask;
      this.done(state.checklist);
    }
    this.setState({ visible });
  }

  render() {
    const { formatted } = this.state;
    const { value, evaluations, t } = this.props;
    const valueLength = Object.keys(value).length;

    return (
      <Popover
        content={
          <Builder
            ref={(ref) => { this.builderTask = ref; }}
            onDone={this.done}
            created={this.props.created}
            onCancel={this.done}
            evaluations={evaluations}
          />}
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        trigger="click"
      >
        <div
          role="link"
          tabIndex={0}
          onKeyUp={null}
          onClick={this.toggle}
          className="link-button"
        >
          {valueLength ? formatted : t('add_tasks')}
        </div>
      </Popover>
    );
  }
}

ChecklistBuilder.propTypes = {
  onChange: PropTypes.func,
};

ChecklistBuilder.defaultProps = {
  onChange: () => {},
};

export default withTranslation()(ChecklistBuilder);

