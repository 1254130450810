/*
 * @Author: Jhony Reyes 
 * @Date: 2018-05-07 22:47:32 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-05-07 23:05:27
 */

import React from 'react';

const ErrorText = ({ message }) => <div style={{ color: '#dc0030', fontSize: '0.84em' }}>{message}</div>;

export default ErrorText;
