/*
 * @Author: Cesar Medina
 * @Date: 2019-10-07 01:22:32 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-06-10 19:08:55
 */

import React from 'react';
import { Select, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { TaskTypes } from '../../common/constants';

const { Option } = Select;

const tasksFilters = ({ onChangeRequired, onChangeType }) => {
  const { t } = useTranslation();

  return (
    <Row className="filters-container">
      <span className="evidence-filter">
        Mostrar:&nbsp;
        <Select
          placeholder={t('all_singular')}
          style={{ width: 120 }}
          onChange={onChangeRequired}
        >
          <Option value={null}>{t('all_singular')}</Option>
          <Option value="required">{t('required_fem')}</Option>
          <Option value="optional">{t('optional')}</Option>
        </Select>
      </span>
      <span className="evidence-filter">
        {t('type')}:&nbsp;
        <Select
          placeholder={t('all_singular')}
          style={{ width: 120 }}
          onChange={onChangeType}
        >
          <Option value={null}>{t('all_singular')}</Option>
          <Option value={TaskTypes.CHECK}>{t('check')}</Option>
          <Option value={TaskTypes.ENUM}>{t('numbering')}</Option>
          <Option value={TaskTypes.MULTI_ENUM}>{t('multi_numbering')}</Option>
          <Option value={TaskTypes.TEXT}>{t('text')}</Option>
          <Option value={TaskTypes.NUMERIC}>{t('number')}</Option>
          <Option value={TaskTypes.CONTACT}>{t('contact')}</Option>
          <Option value={TaskTypes.FILE}>{t('file')}</Option>
          <Option value={TaskTypes.BARCODE}>{t('qr_code')}</Option>
          <Option value={TaskTypes.PHOTO}>{t('photo')}</Option>
          <Option value={TaskTypes.SIGNATURE}>{t('signature')}</Option>
          <Option value={TaskTypes.RULE_LINK}>{t('link')}</Option>
          <Option value={TaskTypes.RULE_FOLIO}>{t('folio')}</Option>
          <Option value={TaskTypes.USER}>{t('user')}</Option>
        </Select>
      </span>
    </Row>
    );
};

export default tasksFilters;
