/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-06 06:45:37 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-03 15:41:40
 */
import React from 'react';
import { ActionsCell } from '../../index';

const RenderActionsCell = ({
  record, onSave, onCancel, onEdit, onDelete, creating, onSendEmail,
  onDeleteTitle, customButtons, onUserPermissions,
}) => {
  const { editable } = record;
  return (
    <ActionsCell
      creating={creating}
      editable={editable}
      onSave={onSave}
      onCancel={onCancel}
      onEdit={onEdit}
      onDelete={onDelete}
      onDeleteTitle={onDeleteTitle}
      onSendEmail={onSendEmail}
      customButtons={customButtons}
      onUserPermissions={onUserPermissions}
    />
  );
};

export default RenderActionsCell;
