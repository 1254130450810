/*
 * @Author: Cesar Medina
 * @Date: 2018-07-23 09:46:53
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2018-07-23 10:08:55
 */

import i18n from 'i18next';

const validations = () => ({
    name: [
      { validate: 'required', message: i18n.t('name_required') },
      {
        validate: (_, form) => form.name.trim(),
        message: i18n.t('only_spaces_invalid'),
        custom: true,
      },
    ],
    description: [
      {
        validate: (_, form) => form.description.length === 0 || form.description.trim(),
        message: i18n.t('only_spaces_invalid'),
        custom: true,
      },
    ],
  });

export default validations;
