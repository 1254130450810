/*
 * @Author: Cesar Medina
 * @Date: 2019-07-02 14:41:21
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2023-11-23 13:44:04
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import momentTimeZone from 'moment-timezone';
import { Row, Col, Card, Result, Button } from 'antd';
import { update } from 'aleonor-redux-helpers';
import { withTranslation } from 'react-i18next';

import { WebFormBuilder, Notification } from '../../common/components/widgets';
import { RuleExecutionStatus, RuleTypes, TaskTypes } from '../../common/constants';
import { actions } from './redux';
import { Loading } from '../../common/components/layout';
import FileService from '../../services/files';
import { getFormattedFolio, uuidv5 } from '../../common/helpers/functions';
import { getUserData } from '../../security';

const fileService = new FileService();


class WebActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const {
      fetchRuleById, match, isRuleExecution, fetchExecutionById,
    } = this.props;
    if (match.params.id && !isRuleExecution) {
      fetchRuleById(match.params.id);
    } else {
      fetchExecutionById(match.params.id);
    }
  };

  componentWillUnmount = () => {
    this.props.resetState();
  }

  uploadFile = async (file) => {
    const fileForm = new FormData();
    fileForm.append('file', file);
    const imageId = await fileService.uploadFile(fileForm);
    return imageId;
  }

  uploadFileBase64 = async (file) => {
    const fileForm = new FormData();
    const fileData = this.dataURIToBlob(file);
    fileForm.append('file', fileData, 'image.jpg');
    const imageId = await fileService.uploadFile(fileForm);
    return imageId;
  }

  // eslint-disable-next-line class-methods-use-this
  dataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    // eslint-disable-next-line no-undef
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteString.length; i++) { ia[i] = byteString.charCodeAt(i); }

    return new Blob([ia], { type: mimeString });
  }

  repeatTask = (index) => {
    const { groupedChecklist, updateState } = this.props;
    const repeatTaskItem = Object.assign({}, groupedChecklist[index]);
    repeatTaskItem.repeatNumber = groupedChecklist
      .filter(i => i.name === repeatTaskItem.name).length + 1;
    if (repeatTaskItem.isGroup) {
      repeatTaskItem.checklist = repeatTaskItem.checklist.map(t => ({ ...t, value: null }));
    }
    const newGroupedChecklist = [...groupedChecklist];
    newGroupedChecklist.splice((index + 1), 0, repeatTaskItem);
    if (newGroupedChecklist[index + 1].value) {
      newGroupedChecklist[index + 1].value = null;
    }
    updateState({ path: 'groupedChecklist', value: newGroupedChecklist });
    // setTaskIndex(index + 1);
  };

  deleteTask = (index) => {
    const { groupedChecklist, updateState } = this.props;
    const newGroupedChecklist = [...groupedChecklist];
    newGroupedChecklist.splice(index, 1);
    updateState({ path: 'groupedChecklist', value: newGroupedChecklist });
  };

  unprocessChecklist = (checklist) => {
    let checklistTasks = [];
    for (let i = 0; i < checklist.length; i += 1) {
      const taskOrGroup = checklist[i];
      if (taskOrGroup.isGroup) {
        const groupTasks = taskOrGroup.checklist.map(t => ({
          ...t,
          groupId: taskOrGroup.id,
          groupInfo: {
            name: taskOrGroup.name,
            repetitive: taskOrGroup.repetitive,
            repeatNumber: taskOrGroup.repeatNumber,
          },
        }));

        checklistTasks = [
          ...checklistTasks,
          ...groupTasks,
        ];
      } else { checklistTasks.push({ ...taskOrGroup }); }
    }

    return checklistTasks;
  };

  saveExecution = (values, saveAsDraft = false, saveAndContinue = false) => {
    const {
      rule, createExecution, updateState, isRuleExecution, groupedChecklist,
    } = this.props;
    const isSavedRuleExecution = rule.id && rule.ruleId;
    const userInfo = getUserData();
    const branchOfficeId = userInfo && userInfo.id && userInfo.branchOffice ?
      userInfo.branchOffice.id : null;

    const newRuleExecution = {
      status: saveAsDraft ? RuleExecutionStatus.PENDING : RuleExecutionStatus.DONE,
      evidence: { comments: null, completion: null },
      id: isRuleExecution || isSavedRuleExecution ? rule.id : null,
      userId: isRuleExecution || isSavedRuleExecution ? rule.userId : null,
      ruleId: isRuleExecution || isSavedRuleExecution ? rule.ruleId : rule.id,
      folio: isRuleExecution || isSavedRuleExecution ? rule.folio : null,
      completedAt: isRuleExecution || isSavedRuleExecution ? rule.completedAt : null,
      createdAt: isRuleExecution || isSavedRuleExecution ? rule.createdAt : null,
      updatedAt: isRuleExecution || isSavedRuleExecution ? rule.updatedAt : null,
      companyId: rule.companyId,
      branchOfficeId,
      roleId: rule.roleId,
      name: rule.name,
      nameRegister: rule.nameRegister,
      description: rule.description,
      checklist: rule.checklist,
      cron: rule.cron,
      ruleType: rule.ruleType,
      collaborative: rule.collaborative,
      usersIds: rule.usersIds,
      userNotifications: rule.userNotifications,
      internal: rule.internal,
      timeZone: momentTimeZone.tz.guess(),
      reportIds: rule.reportIds,
      taskitProcessId: rule.taskitProcessId,
      predefinedRuleId: rule.predefinedRuleId,
    };

    updateState({ path: 'savingExecution', value: true });
    const checklist = this.unprocessChecklist(groupedChecklist);
    Promise.all(checklist.map(async (task) => {
      const key = task.groupId ?
        uuidv5(task.groupInfo.name + task.groupInfo.repeatNumber + task.name)
        :
        uuidv5(task.name + (task.repeatNumber || 1));

      if (task.type === TaskTypes.FILE && values[key]) {
        if (!values[key].file || values[key].file.status === 'removed') {
          return { ...task };
        }

        const fileId = await this.uploadFile(values[key].file);
        return {
          ...task,
          value: {
            id: fileId,
            filename: values[key].file.name,
          },
        };
      }

      if (task.type === TaskTypes.PHOTO && values[key]) {
        if (!values[key].file) {
          return { ...task };
        }

        const fileId = await this.uploadFileBase64(values[key].file);
        return {
          ...task,
          value: fileId,
        };
      }

      if (task.type === TaskTypes.SIGNATURE && values[key]) {
        if (!values[key].file) {
          return { ...task };
        }
        const fileId = await this.uploadFileBase64(values[key].file);
        return {
          ...task,
          value: fileId,
        };
      }

      if (task.type === TaskTypes.DATE) {
        if (values[key]) {
          return { ...task, value: values[key].format('YYYY-MM-DD') };
        }
      }

      if (task.type === TaskTypes.TIME) {
        if (values[key]) {
          return { ...task, value: values[key].format('HH:mm') };
        }
      }

      if (task.type === TaskTypes.RULE_LINK && values[key]) {
        if (!values[key].id) {
          return { ...task };
        }
        return {
          ...task,
          value: {
            ruleName: values[key].nameRegister,
            folio: values[key].folio,
            ruleExecutionId: values[key].id,
          },
        };
      }

      if (task.type === TaskTypes.MULTI_ENUM && values[key]) {
        return {
          ...task,
          value: values[key].join(','),
        };
      }

      if (task.type === TaskTypes.CONTACT && values[key]) {
        return {
          ...task,
          value: rule.ruleType === RuleTypes.WEB ? values[key][0] : {
            contacts: values[key].map(c => c.id),
            groups: [],
          },
        };
      }

      return ({ ...task, value: values[key] });
    })).then((updatedChecklist) => {
      const completeExecution = (location) => {
        const completion = {
          checklist: updatedChecklist,
          total: 100,
        };
        if (location) {
          createExecution(update(newRuleExecution, {
            path: 'evidence',
            value: {
              completion,
              location: {
                latitude: location.latitude,
                longitude: location.longitude,
                accuracy: location.accuracy,
              },
              comments: null,
            },
          }), saveAndContinue);
        } else {
          createExecution(update(newRuleExecution, { path: 'evidence.completion', value: completion }), saveAndContinue);
        }
      };
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          completeExecution(position.coords);
        });
      } else {
        completeExecution(null);
      }
    }).catch(() => {
      Notification.error('Error', this.props.t('save_files_fail'), 4);
      updateState({ path: 'savingExecution', value: false });
    });
  }

  render() {
    const {
      rule,
      isRuleExecution,
      isLoading,
      savingExecution,
      savedExecution,
      t,
      catalogs: { users, executions, contacts },
      searchUsersPaginated,
      fetchExecutions,
      groupedChecklist,
      fetchContacts,
    } = this.props;

    if (isLoading) {
      return <Loading />;
    }
    return (
      <Row type="flex" justify="center">
        <Col xs={24} sm={24} md={24} lg={24}>
          {
            !savedExecution && rule &&
            <Card>
              <h1 style={{ color: '#2984D9', fontSize: '22px' }}>{`${getFormattedFolio(rule.folio)}${rule.nameRegister}`}</h1>
              <p>{rule.description}</p>

              <WebFormBuilder
                rule={rule}
                onSubmit={this.saveExecution}
                loading={savingExecution}
                users={users}
                searchUsersPaginated={searchUsersPaginated}
                executions={executions}
                fetchExecutions={fetchExecutions}
                isRuleExecution={isRuleExecution}
                groupedChecklist={groupedChecklist}
                repeatTask={this.repeatTask}
                deleteTask={this.deleteTask}
                fetchContacts={fetchContacts}
                contacts={contacts}
              />
            </Card>
          }
          {
            savedExecution &&
            <Result
              status="success"
              title={this.props.t('form_sent')}
              subTitle={this.props.t('thanks')}
              extra={[
                <Link to={rule.ruleType === RuleTypes.WEB ? '/' : '/executionActivities'} key="home">
                  <Button type="primary" key="console">
                    {t('go_back')}
                  </Button>
                </Link>,
              ]}
            />
          }
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ webActivityContainer }) => ({
  ...webActivityContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(WebActivity));
