/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-24 11:18:03 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-05-27 15:58:05
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './index';
import {
  FETCH_RULES,
  REGISTER_RULE,
  UPDATE_RULE,
  DELETE_RULE,
  LOAD_FORM,
  FETCH_TASKIT_PROCESSES,
  SWITCH_ENABLE_TASKIT_PROCESS,
} from './types';

import RuleService from '../../../services/rule';
import BranchOfficeService from '../../../services/branch.office';
import { Notification } from '../../../common/components/widgets';
import { parseApiError } from '../../../common/error';
import TaskitProcessesService from '../../../services/taskitProcesses';

const ruleService = new RuleService();
const taskitProcessesService = new TaskitProcessesService();

const branchOfficeService = new BranchOfficeService();

const registerSuccessAction = (rule) => {
  Notification.success(i18n.t('success'), `${rule.name} ${i18n.t('registered_success')}`, 4);
  return { type: '' };
};

const updateSuccessAction = (rule) => {
  Notification.success(i18n.t('success'), `${rule.name} ${i18n.t('updated_success')}`, 4);
  return { type: '' };
};

const deleteSuccessAction = (rule) => {
  Notification.success(i18n.t('success'), `${rule} ${i18n.t('deleted_success')}`, 4);
  return { type: '' };
};

const errorFetchRule = () => {
  Notification.error(i18n.t('list_activities_fail'), i18n.t('verify_server_conection'));
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const fetchRulesEpic = action$ =>
  action$.ofType(FETCH_RULES).flatMap(() =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(branchOfficeService.getActive())
        .flatMap((branchOffices) => {
          if (branchOffices.length) {
            return Observable.fromPromise(ruleService.getByBranchOfficeId(branchOffices[0].id))
              .flatMap(response => Observable.concat(
                Observable.of(actions.fetchRulesFulfilled(response)),
                Observable.of(actions.loading(false)),
              ));
          }
          return Observable.concat(
            Observable.of(actions.fetchRulesFulfilled([])),
            Observable.of(actions.loading(false)),
          );
        })
        .catch(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => errorFetchRule())),
          )),
    ));

const registerRuleEpic = action$ =>
  action$.ofType(REGISTER_RULE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(ruleService.postRule(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.ruleRegistered(false)),
            Observable.of(actions.fetchRulesByFilters(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.updateState({ path: 'registering', value: false })),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateRuleEpic = action$ =>
  action$.ofType(UPDATE_RULE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(ruleService.putRule(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(updateSuccessAction(response)),
            Observable.of(actions.ruleRegistered(false)),
            Observable.of(actions.fetchRulesByFilters(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.updateState({ path: 'registering', value: false })),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteRuleEpic = action$ =>
  action$.ofType(DELETE_RULE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(ruleService.deleteRule(action.payload.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(deleteSuccessAction(response)),
            Observable.of(actions.ruleRegistered(false)),
            Observable.of(actions.fetchRulesByFilters(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const loadFormEpic = action$ =>
  action$.ofType(LOAD_FORM).flatMap(action =>
    Observable.fromPromise(ruleService.getById(action.id))
      .flatMap(response =>
        Observable.of(actions.toggleRuleForm(response, true)))
      .catch(error =>
        Observable.of(actions.showMessage(() => {
          const parsedError = parseApiError(error);
          errorMessage(parsedError);
        }))));

const fetchTaskitProcessesEpic = action$ =>
  action$.ofType(FETCH_TASKIT_PROCESSES).flatMap(action =>
    Observable.fromPromise(taskitProcessesService.getWithCompanyRules(action.payload.filters))
      .flatMap(response =>
        Observable.of(actions.fetchTaskitProcessesFullfilled(response)))
      .catch(error =>
        Observable.of(actions.showMessage(() => {
          const parsedError = parseApiError(error);
          errorMessage(parsedError);
        }))));

const switchEnableTaskitProcessEpic = action$ =>
  action$.ofType(SWITCH_ENABLE_TASKIT_PROCESS).flatMap(action =>
    Observable.concat(
    Observable.of(actions.loading(true)),
    Observable.fromPromise(taskitProcessesService.switchEnableTaskitProcessForCompany(action.form))
        .flatMap(response => Observable.concat(
          Observable.of(actions.loading(false)),
          Observable.of(actions.updateTaskitProcess(response)),
        ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

export default combineEpics(
  fetchRulesEpic, loadFormEpic,
  registerRuleEpic, updateRuleEpic, deleteRuleEpic,
  fetchTaskitProcessesEpic,
  switchEnableTaskitProcessEpic,
);
