/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 14:41:21 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-27 11:07:43
 */

import React, { Component } from 'react';
import { Table, Tooltip, Tabs, Icon, Checkbox, Modal, Alert, Row, Col, Button, Input } from 'antd';
import { connect } from 'react-redux';
import { init } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';

import { RenderActionsCell, RenderChecklistCell } from '../../common/components/widgets/table';
import { TaskForm, PopoverList } from '../../common/components/widgets';
import validations from './utils/validations';
import { HeaderContent } from './utils/components';
import { getTypeIcon, taskTypeLabel, evidenceTypeLabel } from '../../common/labels';
import { TaskTypes, EvidenceTypes, RuleTypes } from './../../common/constants';
import { actions } from './redux';
import RuleService from '../../services/rule';
import TasksGroupForm from '../../common/components/widgets/tasksGroupForm';

const { Search } = Input;

const ruleService = new RuleService();

class Tasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchRule: null,
    };

    this.validator = init(validations());
    this.columnsTask = this.getColumnsTask();
    this.columnsGroup = this.getColumnsGroup();
  }

  componentDidMount = () => {
    const {
      fetchTasks,
      fetchEvaluations,
      filters,
      fetchTaskGroups,
    } = this.props;

    fetchTasks(filters);
    fetchTaskGroups();
    fetchEvaluations({ page: 0, size: 10000 });
    this.fetchRulesByName();
  };

  componentWillUnmount = () => {
    const { resetState } = this.props;
    resetState();
  }

  onSearchRules = (value) => {
    this.setState({ searchRule: value });
    clearTimeout(this.timer);
    if (value) {
      this.timer = setTimeout(() => this.fetchRulesByName({
        name: this.state.searchRule,
      }), 250);
    } else { this.fetchRulesByName(); }
  }

  onBlurRule = () => {
    this.fetchRulesByName();
  }

  onchangeFilter = (filter, value) => {
    const {
      fetchTasks,
      filters,
      updateState,
    } = this.props;
    const updatedFilters = {
      ...filters,
      [filter]: value,
      value: null,
    };

    updateState({ path: 'filters', value: updatedFilters });

    fetchTasks(updatedFilters);
  }

  onSearchValueFilter = (val) => {
    const {
      fetchTasks,
      updateState,
    } = this.props;
    const updatedFilters = {
      value: val,
      type: null,
      evidenceType: null,
    };

    updateState({ path: 'tasks', value: updatedFilters });

    fetchTasks(updatedFilters);
  }

  onSearchNameFilter = () => {
    const {
      filters,
    } = this.props;
    const {
      fetchTasks,
    } = this.props;
    fetchTasks({ ...filters });
  }

  onChangeField = (name, prop, value) => {
    const { task, updateState } = this.props;
    let { tracking, evidenceType } = task;

    if (prop === 'type' && value !== TaskTypes.TEXT &&
      value !== TaskTypes.BARCODE && value !== TaskTypes.NUMERIC) {
      tracking = false;
    }

    if (prop === 'type' && value === TaskTypes.CHECK) {
      evidenceType = EvidenceTypes.OPTIONAL;
    }

    if (prop === 'evidenceType') {
      evidenceType = value;
    }

    if (prop === 'tracking') {
      tracking = value;
    }

    const updatedTask = {
      ...task,
      [prop]: value,
      evidenceType,
      tracking,
    };
    updateState({ path: 'task', value: updatedTask });
  };

  onPopoverRuleVisibleChange = async (record, visible) => {
    if (visible) {
      this.setState({ popoverRuleData: [`${this.props.t('loading')} ...`] });
      try {
        const rule = await ruleService.getById(record.additionalInfo);
          this.setState({ popoverRuleData: [rule.name] });
      } catch (error) {
        this.setState({ popoverRuleData: [this.props.t('error')] });
      }
    }
  }

  onSaveTaskGroup = () => {
    const { registerTaskGroup, updateTaskGroup, form } = this.props;
    if (!form.id) { registerTaskGroup(form); } else if (form.id) { updateTaskGroup(form); }
  }

  onCancelTaskGroup = () => {
    const { toggleModalTaskGroup, resetTaskGroupForm } = this.props;
    toggleModalTaskGroup();
    resetTaskGroupForm();
  }

  onChangeName = (e) => {
    const { updateState } = this.props;
    updateState({ path: 'filters.name', value: e.target.value });
  };

  onSearchName = () => {
    const { fetchRulesByFilters, filters } = this.props;
    fetchRulesByFilters({ ...filters });
  };

  onChangeTab = (activeKey) => {
    const { fetchTasks } = this.props;
    if (activeKey === 'tasks') {
      fetchTasks({ includeTaskitProccess: true });
    } else if (activeKey === 'groups') {
      fetchTasks({ includeTaskitProccess: false });
    }
  }

  getColumnsTask = () => [
    {
      title: this.props.t('name'),
      dataIndex: 'name',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('name') }),
    },
    {
      title: this.props.t('description'),
      dataIndex: 'description',
      width: '15%',
      onCell: () => ({ 'data-label': this.props.t('description') }),
      ellipsis: true,
      render: text => (
        <Tooltip title={text}>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: 400 }}>{text}</div>
        </Tooltip>
     ),
    },
    {
      title: this.props.t('type'),
      dataIndex: 'type',
      width: '15%',
      onCell: () => ({ 'data-label': this.props.t('type') }),
      render: (text, record) => (
        <span>
          <Icon type={getTypeIcon(record.type)} className="tasks-icon" />
          {taskTypeLabel(record.type)}
        </span>
      ),
    },
    {
      title: this.props.t('values'),
      dataIndex: 'enumValues',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('values') }),
      render: (text, record) => (
        <span style={{ wordBreak: 'break-all' }}>
          {record.type === TaskTypes.ENUM ?
            <PopoverList
              title={this.props.t('values')}
              label={this.props.t('values')}
              data={record.enumValues ? record.enumValues.split(',') : []}
              renderItem={item => item}
            /> : ''}
          {record.type === TaskTypes.RULE_LINK ?
            <PopoverList
              title={this.props.t('activity')}
              label={this.props.t('activity')}
              onVisibleChange={(visible) => {
                this.onPopoverRuleVisibleChange(record, visible);
              }}
              data={this.state.popoverRuleData}
              renderItem={item => item}
            /> : ''}
        </span>
      ),
    },
    {
      title: this.props.t('evidence'),
      dataIndex: 'evidenceType',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('evidence') }),
      render: (text, record) => evidenceTypeLabel(record.evidenceType),
    },
    {
      title: this.props.t('evaluation'),
      dataIndex: 'evaluation',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('evaluation') }),
      render: (text, record) => (record.evaluation ? record.evaluation.name : 'Sin evaluación'),
    },
    {
      title: (
        <div>{this.props.t('conserved')}&nbsp;
          <Tooltip
            placement="right"
            title={this.props.t('save_continues_msg')}
          >
            <Icon type="question-circle" theme="outlined" />
          </Tooltip>
        </div>
      ),
      align: 'center',
      dataIndex: 'keepValue',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('conserved') }),
      render: text => <Checkbox checked={text} />,
    },
    {
      title: this.props.t('trackable'),
      align: 'center',
      dataIndex: 'tracking',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('trackable') }),
      render: text => <Checkbox checked={text} />,
    },
    {
      title: this.props.t('actions'),
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('actions') }),
      render: (text, record) => (
        !record.taskitProcessId ?
          <RenderActionsCell
            record={record}
            onSave={() => {}}
            onCancel={() => {}}
            onEdit={() => this.edit(record)}
            onDelete={() => this.delete(record.id)}
          />
        :
          <span>{this.props.t('taskit_process')}</span>
      ),
    },
  ];

  getColumnsGroup = () => [
    {
      title: this.props.t('name'),
      dataIndex: 'name',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('name') }),
    },
    {
      title: this.props.t('tasks'),
      dataIndex: 'checklist',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('tasks') }),
      render: (text, record) => (
        <RenderChecklistCell
          required
          record={record}
          checklist={record.checklist}
        />
      ),
    },
    {
      title: this.props.t('repetitive'),
      dataIndex: 'name',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('name') }),
      render: (text, record) => (
        <Checkbox
          checked={record.repetitive}
        />
      ),
    },
    {
      title: this.props.t('actions'),
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('actions') }),
      render: (text, record) => (
        !record.taskitProcessId ?
          <RenderActionsCell
            record={record}
            onSave={() => {}}
            onCancel={() => {}}
            onEdit={() => this.editTaskGroup(record)}
            onDelete={() => this.props.deleteTaskGroup(record.id)}
          />
        :
          <span>{this.props.t('taskit_process')}</span>
      ),
    },
  ];


  openTaskModal= () => {
    this.props.toggleModal(true);
  }

  closeTaskModal= () => {
    this.props.toggleModal(false);
    this.props.resetTask();
  }

  handleChange(value, id, column) {
    const { catalogs: { tasks }, updateState } = this.props;
    const newData = [...tasks];
    const target = newData.find(item => id === item.id);

    if (target) {
      target[column] = value;
      updateState({ path: 'catalogs.tasks', value: newData });
    }
  }

  save = () => {
    const {
      registerTask,
      updateTask,
      updateState,
      task,
      filters,
    } = this.props;
    const errorMessage = this.validate();

    if (errorMessage) {
      updateState({ path: 'errorMessage', value: errorMessage });
    } else if (task.id) {
      updateTask(task, filters);
    } else {
      registerTask(task, filters);
    }
  }

  cancel = () => {
    const { fetchTasks } = this.props;
    fetchTasks();
  }

  edit(task) {
    const { updateState } = this.props;

    updateState({ path: 'task', value: task });
    this.openTaskModal();
  }

  editTaskGroup(taskGroup) {
    const { updateState, toggleModalTaskGroup } = this.props;

    updateState({ path: 'form', value: { ...taskGroup } });
    toggleModalTaskGroup();
  }

  delete(id) {
    const { deleteTask, filters } = this.props;
    deleteTask(id, filters);
  }

  validate = () => {
    const { task } = this.props;
    let errorMessage = '';

    if (!task.name) {
      errorMessage += this.props.t('name_not_empty');
    }
    if (task.type === TaskTypes.RULE_LINK && !task.additionalInfo) {
      errorMessage = this.props.t('activity_not_empty');
    }

    return errorMessage;
  }

  fetchRulesByName = (params) => {
    const { fetchRulesByName, catalogs: { rules: { size } } } = this.props;
    fetchRulesByName({
      page: 0,
      size,
      ...params,
    });
  }

  renderGroupTab = () => {
    const {
      toggleModalTaskGroup,
      catalogs: {
        taskGroups,
      },
      t,
    } = this.props;
    return (
      <div>
        <div className="headerContainer">
          <Row>
            <Col span={6} className="filter-box">
              <h1 className="textBranch">
                {t('name')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
              </h1>
              <Search
                className="select-data"
                onSearch={value => this.props.fetchTaskGroups({ name: value })}
                enterButton
              />
            </Col>
            <Col span={18} >
              <Row justify="end" type="flex">
                <Button
                  onClick={() => toggleModalTaskGroup()}
                  type="primary"
                >
                  {t('new_group')}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
        <Table
          ref={(table) => { this.table = table; }}
          rowKey="id"
          className="responsive-table"
          dataSource={taskGroups}
          columns={this.columnsGroup}
          locale={{ emptyText: t('show_no_data') }}
          pagination={{
              current: 1,
              onChange: page => this.props.updateState({ path: 'currentPage', value: page }),
            }}
        />
      </div>
    );
  }

  render() {
    // const { currentEvent } = this.state;
    const {
      catalogs: {
        tasks,
        evaluations,
        users,
        branchOffices,
        roles,
        rules: { content: rules },
      },
      isLoading,
      currentPage,
      updateState,
      registering,
      showModalTask,
      showModalGroup,
      task,
      errorMessage,
      filters: {
        type,
        evidenceType,
        value,
        name,
      },
      t,
    } = this.props;

    return (
      <div>
        <Tabs onChange={this.onChangeTab} defaultActiveKey="tasks">
          <Tabs.TabPane tab={t('tasks')} key="tasks">
            {showModalTask &&
            <Modal
              title={t('new_task')}
              visible={showModalTask}
              width={1000}
              maskClosable={false}
              className="modal-header-dark"
              style={{ top: 20 }}
              maskStyle={{ backgroundColor: '#00000066' }}
              onOk={this.save}
              onCancel={this.closeTaskModal}
              okText={t('save_task')}
              cancelText={t('cancel')}
              confirmLoading={isLoading}
            >
              <div className="form-description">
                {t('task_form_msg')}
              </div>
              <TaskForm
                task={task}
                readOnly={false}
                taskIndex={null}
                ruleType={null}
                evaluationsData={evaluations}
                onChangeField={this.onChangeField}
                rules={rules}
                onSearchRules={this.onSearchRules}
                fetchRulesByName={this.fetchRulesByName}
                onBlurRule={this.onBlurRule}
              />
              { errorMessage && <Alert message={errorMessage} type="error" /> }
            </Modal>
            }
            <HeaderContent
              onCreate={this.openTaskModal}
              selectedType={type}
              onSelectType={val => this.onchangeFilter('type', val)}
              selectedEvidenceType={evidenceType}
              onSelectEvidenceType={val => this.onchangeFilter('evidenceType', val)}
              selectedValue={value}
              onChangeValue={e => updateState({ path: 'filters.value', value: e.target.value })}
              onSearchValue={val => this.onSearchValueFilter(val)}
              name={name}
              onSearchName={() => this.onSearchNameFilter()}
              onChangeName={e => this.onchangeFilter('name', e.target.value)}
            />
            <Table
              ref={(table) => { this.table = table; }}
              rowKey="id"
              className="responsive-table"
              dataSource={tasks}
              columns={this.columnsTask}
              locale={{ emptyText: t('show_no_data') }}
              loading={isLoading}
              pagination={{
                  current: currentPage,
                  onChange: page => this.props.updateState({ path: 'currentPage', value: page }),
                }}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab={t('groups')} key="groups">
            { this.renderGroupTab() }
          </Tabs.TabPane>
        </Tabs>
        {
          showModalGroup &&
          <TasksGroupForm
            visible={showModalGroup}
            form={this.props.form}
            updateForm={this.props.updateForm}
            t={this.props.t}
            catalogs={this.props.catalogs}
            activePanel={this.props.activePanel}
            updateState={this.props.updateState}
            onCancel={this.onCancelTaskGroup}
            onSave={this.onSaveTaskGroup}

            usersData={users}
            tasksData={tasks}
            evaluationsData={evaluations.content}
            rolesData={roles}
            branchOfficesData={branchOffices}
            onDelete={this.handleDelete}
            loading={registering}
            created=""
            ruleType={RuleTypes.TASKGROUP}
            fetchRulesByName={this.fetchRulesByName}

          />
        }
      </div>
    );
  }
}

const mapStateToProps = ({ tasksContainer }) => ({
  ...tasksContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(Tasks));
