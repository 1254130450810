/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:13:19
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2018-07-23 10:07:55
 */
const CONTAINER_KEY = 'ROLES_CONTAINER';

export const CREATE_ROLES = `${CONTAINER_KEY}_CREATE_ROLES`;
export const CREATE_ROLES_FULFILLED = `${CONTAINER_KEY}_CREATE_ROLES_FULFILLED`;
export const UPDATE_ROLES = `${CONTAINER_KEY}_UPDATE_ROLES`;
export const DELETE_ROLES = `${CONTAINER_KEY}_DELETE_ROLES`;
