/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 10:58:50 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-09-24 17:02:59
 */
import axios from 'axios';
import { orderBy } from 'lodash';
import Service from '../Service';
import { createCoreServiceUrl } from '../../common/helpers/service';

const formatData = users => orderBy(users, ['createdAt'], ['desc']);

class RoleService extends Service {
    getAll = () => axios.get(createCoreServiceUrl('roles')).then(data => formatData(data.data));
    postRole = form => axios.post(createCoreServiceUrl('roles'), form).then(data => data.data);
    putRole = form => axios.put(createCoreServiceUrl('roles'), form).then(data => data.data);
    deleteRole = form => axios.delete(createCoreServiceUrl(`roles/${form.id}`)).then(data => data.data);
}

export default RoleService;
