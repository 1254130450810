/*
 * @Author: Cesar Medina
 * @Date: 2018-07-23 09:55:17 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-09-24 17:18:02
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import { CREATE_ROLES, CREATE_ROLES_FULFILLED, UPDATE_ROLES, DELETE_ROLES } from './types';
import { FETCH_ROLES_FULFILLED } from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';

const defaultState = {
  reducerKey: 'rolesContainer',
  catalogs: {
    roles: [],
    rolesEdit: [],
  },
  formErrors: {},
  errorForm: false,
  formReset: {
    id: -1,
    name: '',
    description: '',
    new: true,
    editable: true,
  },
  newForm: {
    id: -1,
    name: '',
    description: '',
    new: true,
    editable: true,
  },
  creating: false,
  currentPage: 1,
  createdFulfilled: false,
};

// Actions
// Payload {form, navigate}
const createRole = payload => ({ type: CREATE_ROLES, payload });
const roleCreated = payload => ({ type: CREATE_ROLES_FULFILLED, payload });
const updateRole = payload => ({ type: UPDATE_ROLES, payload });
const deleteRole = payload => ({ type: DELETE_ROLES, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_ROLES_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.roles', value: payload }),
  [CREATE_ROLES_FULFILLED]: state => update(state, { path: 'newForm', value: { ...state.formReset } }),
});

const actions = {
  ...generalActions,
  ...defaultActions,
  createRole,
  roleCreated,
  updateRole,
  deleteRole,
};

export {
  epics,
  reducer,
  actions,
  defaultActions,
};
