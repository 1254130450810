/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:46:03 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-08-24 18:39:03
 */
const CONTAINER_KEY = 'CONTACTS_CONTAINER';

export const REGISTER_CONTACT = `${CONTAINER_KEY}_REGISTER_CONTACT`;
export const UPDATE_CONTACT = `${CONTAINER_KEY}_UPDATE_CONTACT`;
export const DELETE_CONTACT = `${CONTAINER_KEY}_DELETE_CONTACT`;
export const REGISTER_GROUP = `${CONTAINER_KEY}_REGISTER_GROUP`;
export const UPDATE_GROUP = `${CONTAINER_KEY}_UPDATE_GROUP`;
export const DELETE_GROUP = `${CONTAINER_KEY}_DELETE_GROUP`;
export const OPEN_GROUP_MODAL = `${CONTAINER_KEY}_OPEN_GROUP_MODAL`;
export const CLOSE_GROUP_MODAL = `${CONTAINER_KEY}_CLOSE_GROUP_MODAL`;
export const LOAD_GROUP_FORM = `${CONTAINER_KEY}_LOAD_GROUP_FORM`;
export const RESET_GROUP_FORM = `${CONTAINER_KEY}_RESET_GROUP_FORM`;
export const RESET_FILTERS = `${CONTAINER_KEY}_RESET_FILTERS`;
export const DOWNLOAD_CONTACTS_IN_CSV = `${CONTAINER_KEY}_DOWNLOAD_CONTACTS_IN_CSV`;
export const UPLOAD_CONTACTS_IN_CSV = `${CONTAINER_KEY}_UPLOAD_CONTACTS_IN_CSV`;
