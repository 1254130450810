/*
 * @Author: Jhony Reyes 
 * @Date: 2019-01-24 15:02:57 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-09-26 17:33:09
 */

import React, { Component } from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Builder from './builder';

class PermissionsBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      formatted: '',
    };
  }

  done = (values) => {
    this.setState({ visible: false, formatted: `${this.props.t('permissions')}: ${Object.keys(values).length}` }, this.props.onChange(values));
  }

  toggle = () => this.setState(state => ({ visible: !state.visible }));

  handleVisibleChange = (visible) => {
    if (!visible) {
      const { state } = this.builderTask;
      this.done(state.checkedList);
    }
    this.setState({ visible });
  }

  render() {
    const { formatted } = this.state;
    const { t } = this.props;

    return (
      <Popover
        content={
          <Builder
            ref={(ref) => { this.builderTask = ref; }}
            onDone={this.done}
            created={this.props.created}
            onCancel={this.done}
            data={this.props.data}
            disabled={this.props.disabled}
          />}
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        trigger="click"
      >
        <div
          role="link"
          tabIndex={0}
          onKeyUp={null}
          onClick={this.toggle}
          className="link-button"
        >
          {formatted || t('add_permissions')}
        </div>
      </Popover>
    );
  }
}

PermissionsBuilder.propTypes = {
  onChange: PropTypes.func,
};

PermissionsBuilder.defaultProps = {
  onChange: () => {},
};

export default withTranslation()(PermissionsBuilder);

