/*
 * @Author: Cesar Medina
 * @Date: 2019-07-02 14:41:21 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2023-03-02 20:25:20
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Pagination, DatePicker, Select, TimePicker,
  Input, LocaleProvider, Empty, Radio, Tabs, Button,
  Modal, Drawer,
} from 'antd';
import localeEs from 'antd/lib/locale-provider/es_ES';
import { isEmpty, get } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

import { actions, defaultActions } from './redux';
import { Loading } from '../../common/components/layout';
import { ExecutionCard } from '../../common/components/widgets';
import { RuleExecutionStatus, TaskTypes } from '../../common/constants';
import { getImageUrl } from '../../common/helpers/images';
import { getUserData, isAllowed } from '../../security';
import { getExecutionCardImage, getRuleExecutionStatus } from '../../common/helpers/functions';

const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

class Evidences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersModal: false,
      filtersDrawer: false,
      searchRule: null,
      canMonitorAllBranches: false,
      canMonitorYourBranch: false,
    };
    this.timer = null;

    const {
      updateState,
    } = this.props;

    updateState({ path: 'filters.initDate', value: '' });
    updateState({ path: 'filters.endDate', value: '' });
  }

  componentDidMount = async () => {
    const {
      fetchBranchOffices,
      fetchRoles,
      fetchTasks,
      fetchUsers,
    } = this.props;

    fetchBranchOffices(defaultActions);
    fetchRoles(defaultActions);
    fetchUsers();

    await this.isABranch();

    this.fetchRulesByName();

    fetchTasks({ includeTaskitProccess: true });

    this.getAllEvidents();
  };

  componentWillUnmount = () => {
    const { resetView, history } = this.props;
    const targetPath = get(history, 'location.pathname') || '';

    if (history.action === 'PUSH' && !targetPath.startsWith('/evidences')) {
      resetView();
    }
  }

  onChangeFilter = (filter, value) => {
    const {
      updateState,
      fetchExecutions,
      catalogs: { executions },
    } = this.props;
    const updatedFilters = { ...this.getFiltersToFetch(), [filter]: value };

    updateState({ path: `filters.${filter}`, value });

    fetchExecutions({
      page: 0,
      size: executions.size,
      ...updatedFilters,
    });
  }

  onChangeDateFilter = (filter, value, filter2, value2) => {
    const {
      updateState,
      fetchExecutions,
      catalogs: { executions },
    } = this.props;
    const updatedFilters = { ...this.getFiltersToFetch(), [filter]: value, [filter2]: value2 };

    updateState({ path: 'filters', value: updatedFilters });

    fetchExecutions({
      page: 0,
      size: executions.size,
      ...updatedFilters,
    });
  }

  onChangeTaskFilter = (filterId, value) => {
    const {
      catalogs: {
        taskFilters,
        executions,
      },
      updateState,
      fetchExecutions,
    } = this.props;
    const updatedTaskFilters = taskFilters.map(i => (i.id === filterId ? { ...i, value } : i));
    const customFilters = encodeURIComponent(JSON.stringify(updatedTaskFilters
      .filter(i => !isEmpty(i.value))
      .map(i => ({
        id: i.id,
        value: i.value,
        name: i.name,
        type: i.type,
      }))));
    const updatedFilters = { ...this.getFiltersToFetch(), filters: customFilters };

    updateState({ path: 'catalogs.taskFilters', value: updatedTaskFilters });
    updateState({ path: 'filters.filters', value: customFilters });

    fetchExecutions({
      page: 0,
      size: executions.size,
      ...updatedFilters,
    });
  }

  onChangeTextFilter = (filterId, value) => {
    const {
      catalogs: {
        taskFilters,
      },
      updateState,
    } = this.props;
    const updatedTaskFilters = taskFilters.map(i => (i.id === filterId ? { ...i, value } : i));

    updateState({ path: 'catalogs.taskFilters', value: updatedTaskFilters });
  }

  onChangeBranchOffice = (value) => {
    const {
      updateState,
      fetchExecutions,
      catalogs: { executions },
      fetchUsersByBranchOffice,
    } = this.props;
    const updatedFilters = {
      ...this.getFiltersToFetch(),
      branchOfficeId: value,
      userId: null,
    };

    if (!isEmpty(value)) {
      fetchUsersByBranchOffice(value);
    }

    updateState({ path: 'filters.branchOfficeId', value });
    updateState({ path: 'filters.userId', value: null });

    fetchExecutions({
      page: 0,
      size: executions.size,
      ...updatedFilters,
    });
  }

  onAddFilters = () => {
    const {
      catalogs: { executions, taskFilters },
      fetchExecutions,
      updateState,
      selectedFilter,
    } = this.props;
    const updatedTaskFilters = taskFilters.map(i => (
      selectedFilter.includes(i.name) ? i : { ...i, value: null }
    ));
    const customFilters = encodeURIComponent(JSON.stringify(updatedTaskFilters
      .filter(i => !isEmpty(i.value))
      .map(i => ({
        id: i.id,
        value: i.value,
        type: i.type,
      }))));
    const updatedFilters = { ...this.getFiltersToFetch(), filters: customFilters };

    updateState({ path: 'filters.filters', value: customFilters });

    fetchExecutions({
      page: 0,
      size: executions.size,
      ...updatedFilters,
    });
  }

  onResetFilters = async () => {
    const {
      resetFilters,
    } = this.props;

    resetFilters();

    this.isABranch();

    this.getAllEvidents();
  }

  onSelectRule = (value) => {
    const {
      updateState,
      fetchExecutions,
      catalogs: { executions, rules },
      selectedFilterNames,
    } = this.props;
    const updatedFilters = {
      ...this.getFiltersToFetch(true),
      ruleId: value,
      folio: null,
    };
    updateState({ path: 'filters.folio', value: '' });
    updateState({ path: 'filters.ruleId', value });

    fetchExecutions({
      page: 0,
      size: executions.size,
      ...updatedFilters,
    });

    if (!isEmpty(value)) {
      const selectRule = rules.content.filter(i => (i.id === value ||
          i.predefinedRuleId === value))[0]
          .checklist.filter(i => (
            i.type !== TaskTypes.FILE && i.type !== TaskTypes.CONTACT &&
            i.type !== TaskTypes.PHOTO && i.type !== TaskTypes.SIGNATURE &&
            i.type !== TaskTypes.RULE_LINK && i.type !== TaskTypes.RULE_FOLIO &&
            i.type !== TaskTypes.MULTI_ENUM
          )).map(t => t.name);
      selectedFilterNames(selectRule);
      this.getTaskFilters();
    } else {
      selectedFilterNames([]);
    }
  }

  onSearchRules = (value) => {
    this.setState({ searchRule: value });
    clearTimeout(this.timer);
    if (value) {
      this.timer = setTimeout(() => this.fetchRulesByName({
        name: this.state.searchRule,
      }), 250);
    } else { this.fetchRulesByName(); }
  }

  onBlurRule = () => {
    this.fetchRulesByName();
  }

  onChangeFolio = (value) => {
    if (value && value.trim() !== '') {
      const onlyNumbersRegexp = new RegExp(/^\d+$/);
      if (onlyNumbersRegexp.test(value) && !Number.isNaN(Number.parseInt(value, 10))) {
        this.props.updateState({ path: 'filters.folio', value });
      } else if (value.length) {
        this.props.updateState({ path: 'filters.folio', value: value.replace(/[^0-9]/, '') });
      }
    } else {
      const { catalogs: { executions }, fetchExecutions } = this.props;
      this.props.updateState({ path: 'filters.folio', value: null });

      fetchExecutions({
        ...this.getFiltersToFetch(true),
        page: 0,
        size: executions.size,
        folio: null,
      });
    }
  }

  getAllEvidents = async () => {
    const {
      updateState,
      fetchExecutions,
      catalogs: { executions },
    } = this.props;

    updateState({ path: 'filters.initDate', value: '' });
    updateState({ path: 'filters.endDate', value: '' });

    await fetchExecutions({
      page: 0,
      size: executions.size,
      ...this.getFiltersToFetch(),
      initDate: '',
      endDate: '',
    });
  }

  getFiltersToFetch = (ignoreFolio = false) => {
    const { filters } = this.props;

    if (filters.folio && !ignoreFolio) {
      return {
        folio: filters.folio,
        ruleId: filters.ruleId,
        initDate: null,
        endDate: null,
      };
    }

    return {
      ...filters,
      initDate: filters.initDate,
      endDate: filters.endDate,
    };
  }

  getTaskFilters = () => {
    const { catalogs: { taskFilters } } = this.props;
    return taskFilters.filter(i => (
      i.type !== TaskTypes.FILE && i.type !== TaskTypes.CONTACT &&
      i.type !== TaskTypes.PHOTO && i.type !== TaskTypes.SIGNATURE &&
      i.type !== TaskTypes.RULE_LINK && i.type !== TaskTypes.RULE_FOLIO &&
      i.type !== TaskTypes.MULTI_ENUM
    ));
  }

  isABranch = async () => {
    const {
      fetchExecutions,
      catalogs: { executions },
      updateState,
      fetchUsersByBranchOffice,
    } = this.props;
    const userData = getUserData();
    const canMonitorAllBranches = isAllowed(['can_monitor_all_branches']);
    const canMonitorYourBranch = isAllowed(['can_monitor_your_branch']);

    this.setState({ canMonitorAllBranches, canMonitorYourBranch });

    const userId = !canMonitorYourBranch && !canMonitorAllBranches ? userData.id : null;
    updateState({ path: 'filters.userId', value: userId });

    if (!canMonitorAllBranches) {
      updateState({ path: 'filters.branchOfficeId', value: userData.branchOffice.id });
      await fetchExecutions({
        page: 0,
        size: executions.size,
        ...this.getFiltersToFetch(),
        branchOfficeId: userData.branchOffice.id,
      });
      fetchUsersByBranchOffice(userData.branchOffice.id);
    } else {
      await fetchExecutions({
        page: 0,
        size: executions.size,
        ...this.getFiltersToFetch(),
      });
    }
  }

  searchByFolio = () => {
    const { fetchExecutions, catalogs: { executions } } = this.props;
    fetchExecutions({
      ...this.getFiltersToFetch(),
      page: 0,
      size: executions.size,
    });
  }

  fetchRulesByName(params) {
    const { fetchRulesByName, catalogs: { rules: { size } } } = this.props;
    fetchRulesByName({
      page: 0,
      size,
      ...params,
    });
  }

  pagination = (page, pageSize) => {
    const { fetchExecutions } = this.props;
    fetchExecutions({
      page: page - 1,
      size: pageSize,
      ...this.getFiltersToFetch(),
    });
  }

  renderTaskFilter = (filter) => {
    const { catalogs: { usersAll } } = this.props;

    if (filter.type === TaskTypes.TEXT
      || filter.type === TaskTypes.BARCODE
      || filter.type === TaskTypes.NUMERIC
    ) {
      return (
        <Search
          onChange={e => this.onChangeTextFilter(filter.id, e.target.value)}
          onSearch={val => this.onChangeTaskFilter(filter.id, val)}
          value={filter.value}
          enterButton
          disabled={this.props.filters.folio}
        />
      );
    } else if (filter.type === TaskTypes.ENUM) {
      return (
        <Select
          value={filter.value}
          className="filter-select"
          onChange={val => this.onChangeTaskFilter(filter.id, val)}
          disabled={this.props.filters.folio}
        >
          <Option value={null}>{this.props.t('all_plural')}</Option>
          {
            filter.enumValues.split(',').map(i => (
              <Option value={i} key={i}>{i}</Option>
            ))
          }
        </Select>
      );
    } else if (filter.type === TaskTypes.CHECK) {
      return (
        <Radio.Group
          value={filter.value}
          buttonStyle="solid"
          onChange={e => this.onChangeTaskFilter(filter.id, e.target.value)}
          disabled={this.props.filters.folio}
        >
          <Radio.Button value={undefined}>{this.props.t('all_plural')}</Radio.Button>
          <Radio.Button value="true">&#10003;</Radio.Button>
          <Radio.Button value="false">&#10005;</Radio.Button>
        </Radio.Group>
      );
    } else if (filter.type === TaskTypes.DATE) {
      return (
        <DatePicker
          value={filter.value ? moment(filter.value) : null}
          onChange={(date) => {
            const value = date ? moment(date).format('YYYY-MM-DD') : null;
            this.onChangeTaskFilter(filter.id, value);
          }}
          className="filter-select"
          disabled={this.props.filters.folio}
          placeholder="Seleccionar fecha"
        />
      );
    } else if (filter.type === TaskTypes.TIME) {
      return (
        <TimePicker
          format="HH:mm"
          value={filter.value ? moment(`2020-09-01T${filter.value}:00`) : null}
          className="filter-select"
          placeholder={this.props.t('select_hour')}
          onChange={(date) => {
            const value = date ? moment(date).format('HH:mm') : null;
            this.onChangeTaskFilter(filter.id, value);
          }}
          disabled={this.props.filters.folio}
        />
      );
    } else if (filter.type === TaskTypes.USER) {
      return (
        <Select
          value={filter.value}
          className="filter-select"
          onChange={val => this.onChangeTaskFilter(filter.id, val)}
          disabled={this.props.filters.folio}
        >
          <Option value={null}>{this.props.t('all_plural')}</Option>
          {
            usersAll.map(i => (
              <Option value={i.id} key={i.id}>{`${i.name} ${i.lastName}`}</Option>
            ))
          }
        </Select>
      );
    }

    return null;
  }

  renderRules() {
    const { catalogs: { rules: { content: rules } } } = this.props;
    const rulesToRender = rules.map(rule => (
      <Option
        value={rule.predefinedRuleId ? rule.predefinedRuleId : rule.id}
        key={rule.id}
      >
        {rule.name}
      </Option>
    ));
    rulesToRender.unshift(<Option value="" key="">{this.props.t('all_plural_fem')}</Option>);
    return rulesToRender;
  }

  renderFilters = () => {
    const {
      catalogs: {
        branchOffices,
        roles,
        users,
      },
      filters,
      selectedTab,
      selectedFilter,
      updateState,
    } = this.props;

    return (
      <div style={{ borderRight: '1px solid #CFCFCF', paddingRight: '10px', marginRight: '20px' }}>
        <div className="filter-section">
          <div className="filter-item">
            <div className="filter-title">{this.props.t('search_activity')}</div>
            <Select
              value={this.props.filters.ruleId}
              showSearch
              style={{ width: '100%' }}
              placeholder={this.props.t('write_activity')}
              onBlur={this.onBlurRule}
              onSelect={this.onSelectRule}
              onSearch={this.onSearchRules}
              filterOption={false}
            >
              {this.renderRules()}
            </Select>
          </div>
        </div>

        {this.props.filters.ruleId &&
        <div className="filter-section">
          <div className="filter-item">
            <div className="filter-title">{this.props.t('filter_by')}</div>
            <p>Folio</p>
            <Search
              placeholder={this.props.t('enter_folio')}
              onSearch={this.searchByFolio}
              onChange={e => this.onChangeFolio(e.target.value)}
              value={filters.folio}
              enterButton
            />
          </div>
        </div>
        }

        <div className="filter-section" >
          <div className="filter-item">
            {!this.props.filters.ruleId &&
            <div className="filter-title">{this.props.t('filter_by')}</div>
            }
            <p>{this.props.t('branch_offices')}</p>
            <Select
              value={filters.branchOfficeId}
              className="filter-select"
              onChange={this.onChangeBranchOffice}
              disabled={this.props.filters.folio || !this.state.canMonitorAllBranches}
            >
              <Option value={null}>{this.props.t('all_plural_fem')}</Option>
              {branchOffices.map(i => <Option key={i.id} value={i.id}>{i.name}</Option>)}
            </Select>
          </div>
        </div>

        <div className="filter-section">
          <Tabs activeKey={selectedTab} onTabClick={tab => updateState({ path: 'selectedTab', value: tab })}>
            <Tabs.TabPane tab={this.props.t('date')} key="0">
              <div className="filter-item">
                <LocaleProvider locale={localeEs}>
                  <DatePicker
                    value={filters.initDate ? moment(filters.initDate) : null}
                    disabledDate={current => current > moment().endOf('day')}
                    allowClear={false}
                    showToday={false}
                    onChange={(val) => {
                      if (val) {
                        this.onChangeDateFilter('initDate', val.valueOf(), 'endDate', val.valueOf());
                      }
                    }}
                    className="filter-select"
                    disabled={this.props.filters.folio}
                  />
                </LocaleProvider>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={this.props.t('period')} key="1">
              <div className="filter-item">
                <LocaleProvider locale={localeEs}>
                  <RangePicker
                    value={
                      [filters.initDate ? moment(filters.initDate) : null,
                      filters.endDate ? moment(filters.endDate) : null]}
                    disabledDate={current => current > moment().endOf('day')}
                    onChange={(val) => {
                      this.onChangeDateFilter(
                        'initDate', val[0] ? val[0].valueOf() : moment().valueOf(),
                        'endDate', val[1] ? val[1].valueOf() : moment().valueOf(),
                      );
                    }}
                    className="filter-select"
                    disabled={this.props.filters.folio}
                  />
                </LocaleProvider>
              </div>
            </Tabs.TabPane>
          </Tabs>
          <div className="filter-item">
            <p>{this.props.t('time')}</p>
            <TimePicker
              format="HH:mm"
              defaultValue={moment('00:00', 'HH:mm')}
              minuteStep={15}
              value={filters.time && moment(filters.time)}
              className="filter-select"
              placeholder={this.props.t('select_hour')}
              onChange={(val) => {
                if (val) {
                  if (![0, 15, 30, 45].includes(val.minutes())) {
                    val.minutes(0);
                  }
                }

                if (val === null) {
                  this.onChangeFilter('time', null);
                } else {
                  this.onChangeFilter('time', val.valueOf());
                }
              }}
              disabled={this.props.filters.folio}
            />
          </div>
        </div>
        <div className="filter-section">
          <div className="filter-item">
            <p>{this.props.t('role')}</p>
            <Select
              value={filters.roleId}
              className="filter-select"
              onChange={val => this.onChangeFilter('roleId', val)}
              disabled={this.props.filters.folio}
            >
              <Option value={null}>{this.props.t('all_plural')}</Option>
              {roles.map(i => <Option key={i.id} value={i.id}>{i.name}</Option>)}
            </Select>
          </div>
          <div className="filter-item">
            <p>{this.props.t('user')}</p>
            <Select
              disabled={
                filters.branchOfficeId === null ||
                this.props.filters.folio ||
                (!this.state.canMonitorYourBranch && !this.state.canMonitorAllBranches)
              }
              value={filters.userId}
              className="filter-select"
              onChange={val => this.onChangeFilter('userId', val)}
            >
              <Option value={null}>{this.props.t('all_plural')}</Option>
              {users.map(i => <Option key={i.id} value={i.id}>{`${i.name} ${i.lastName}`}</Option>)}
            </Select>
          </div>
        </div>

        <div className="filter-section">
          <div className="filter-item">
            <p>{this.props.t('status')}</p>
            <Select
              value={filters.status}
              className="filter-select"
              onChange={val => this.onChangeFilter('status', val)}
              disabled={this.props.filters.folio}
            >
              <Option value={null}>{this.props.t('all_plural')}</Option>
              <Option value="completed">{this.props.t('completed')}</Option>
              <Option value="inProgress">{this.props.t('in_process')}</Option>
              <Option value="pending">{this.props.t('draft')}</Option>
              <Option value="expired">{this.props.t('expired')}</Option>
            </Select>
          </div>
        </div>
        {
          this.getTaskFilters().map(i => (
            selectedFilter.includes(i.name) &&
            <div className="filter-item" key={i.id}>
              <p>{i.name}</p>
              {this.renderTaskFilter(i)}
            </div>
          ))
        }
        <Button
          type="primary"
          ghost
          style={{
            borderStyle: 'dashed',
            width: '100%',
          }}
          onClick={() => this.setState({ filtersModal: true })}
          disabled={this.props.filters.folio}
        >
          {this.props.t('add_filters')}
        </Button>
        <Button
          type="primary"
          ghost
          style={{
            marginTop: '7px',
            color: 'red',
            borderStyle: 'dashed',
            borderColor: 'red',
            width: '100%',
          }}
          onClick={this.onResetFilters}
        >
          {this.props.t('clean_filters')}
        </Button>
      </div>
    );
  }

  renderExecutions = () => {
    const { catalogs: { executions, rules } } = this.props;
    return (
      <div>
        {
          executions.content.map(e => (
            <ExecutionCard
              key={e.id}
              id={e.id}
              folio={e.folio}
              ruleName={e.name}
              executionName={e.nameRegister}
              branchOffice={e.branchOfficeName}
              dateName={`${this.props.t('date_time')}:`}
              date={e.completedAt ? `${moment(e.completedAt).format('DD/MM/YY LT')}h` : '-----'}
              status={getRuleExecutionStatus(e)}
              roleName={e.roleName || this.props.t('no_role')}
              username={e.username}
              profileImageUrl={getImageUrl(e.profileImageUrl)}
              image={getExecutionCardImage(e)}
              done={
                e.status === RuleExecutionStatus.DONE ||
                e.status === RuleExecutionStatus.PENDING
              }
              execution={e}
              rules={rules}
            />
          ))
        }
        <Pagination
          current={executions.number + 1}
          pageSize={executions.size}
          total={executions.totalElements}
          onChange={this.pagination}
          style={{ float: 'right' }}
        />
      </div>
    );
  }

  render() {
    const {
      catalogs: {
        executions,
      },
      selectedFilter,
      loadingExecutions,
      selectedFilterNames,
      t,
    } = this.props;
    return (
      <Row>
        <Button
          type="link"
          icon="filter"
          className="filters-drawer-button"
          onClick={() => this.setState({ filtersDrawer: true })}
        />
        <Drawer
          title={t('filters')}
          placement="left"
          visible={this.state.filtersDrawer}
          onClose={() => this.setState({ filtersDrawer: false })}
        >
          {this.renderFilters()}
        </Drawer>
        <Col
          className="hide-sm"
          md={0}
          lg={8}
          xl={6}
          xxl={4}
        >
          {this.renderFilters()}
        </Col>
        <Col xs={24} sm={24} md={24} lg={16} xl={18} xxl={20}>
          {loadingExecutions && <div style={{ height: '200px' }}><Loading /></div>}
          {
            !loadingExecutions && (
              executions.content.length > 0 ?
                this.renderExecutions() :
                <Empty description={t('no_evidences')} />
            )
          }
        </Col>
        <Modal
          title={t('add_filters')}
          visible={this.state.filtersModal}
          className="modal-header-dark"
          maskStyle={{ backgroundColor: '#00000066' }}
          onCancel={() => this.setState({ filtersModal: false })}
          closable={false}
          maskClosable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={() => {
                this.setState({ filtersModal: false });
                this.onAddFilters();
              }}
            >
              {t('accept')}
            </Button>,
          ]}
        >
          <p>{t('add_more_filters')}</p>
          <Select
            className="filter-select"
            mode="multiple"
            notFoundContent={null}
            value={selectedFilter}
            onChange={value => selectedFilterNames(value.map(i => (i.slice(i.indexOf('|') + 1))))}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {this.getTaskFilters().map(i => (
              <Option key={i.id} value={`${i.id}|${i.name}`}>{i.name}</Option>
            ))}
          </Select>
        </Modal>
      </Row>
    );
  }
}

const mapStateToProps = ({ evidencesContainer }) => ({
  ...evidencesContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(Evidences));
