/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:28:07
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 15:38:21
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions, defaultActions } from './index';
import { CREATE_ROLES, UPDATE_ROLES, DELETE_ROLES } from './types';

import RoleService from '../../../services/role';
import { Notification } from '../../../common/components/widgets';
import { parseApiError } from '../../../common/error';

const roleService = new RoleService();

const registerSuccessAction = () => {
  Notification.success(i18n.t('success'), i18n.t('role_registered'), 4);
  return { type: '' };
};

const updateSuccessAction = () => {
  Notification.success(i18n.t('success'), i18n.t('role_updated'), 4);
  return { type: '' };
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const createRoleEpic = action$ =>
  action$.ofType(CREATE_ROLES).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(roleService.postRole(action.payload.form))
        .flatMap(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(registerSuccessAction()),
            Observable.of(actions.resetState()),
            Observable.of(actions.fetchRoles(defaultActions)),
            Observable.of(actions.roleCreated()),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateRoleEpic = action$ =>
  action$.ofType(UPDATE_ROLES).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(roleService.putRole(action.payload.form))
        .flatMap(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.resetState()),
            Observable.of(updateSuccessAction()),
            Observable.of(actions.fetchRoles(defaultActions)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteRoleEpic = action$ =>
    action$.ofType(DELETE_ROLES).flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(roleService.deleteRole(action.payload.form))
          .flatMap(() =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.resetState()),
              Observable.of(updateSuccessAction()),
              Observable.of(actions.fetchRoles(defaultActions)),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                errorMessage(parsedError);
              })),
            )),
      ));

export default combineEpics(createRoleEpic, updateRoleEpic, deleteRoleEpic);
