/*
 * @Author: Cesar Medina
 * @Date: 2019-07-02 14:41:21 
 * @Last Modified by: Misael Jiménez
 * @Last Modified time: 2020-07-15 18:15:07
 */

import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { NoImageCard } from '../../widgets';
import { getImageUrl } from '../../../../common/helpers/images';
import { RuleExecutionStatus, TaskTypes } from '../../../constants';
import './executionCardTimeline.css';
import { getFormattedFolio } from '../../../helpers/functions';

const getStatus = (e) => {
  if (e.status === RuleExecutionStatus.DONE || e.status === RuleExecutionStatus.PENDING) {
    return e.status;
  }

  const endTime = moment(e.createdAt).add(e.cron.duration, 'm');
  if (endTime < moment()) {
    return 'expired';
  }
  return 'process';
};

const getImage = (e) => {
  if (e.status === RuleExecutionStatus.DONE || e.status === RuleExecutionStatus.PENDING) {
    const { checklist } = e.evidence.completion;
    const task = checklist.find(i => i.type === TaskTypes.PHOTO && !isEmpty(i.value));

    if (task) {
      return getImageUrl(task.value);
    }
  }
  return null;
};

const renderActivityInfo = (execution, status) => {
  switch (status) {
    case 'expired':
      return i18n.t('expired_fem');
    case 'process':
      return i18n.t('in_process');
    default:
      return null;
  }
};

const isDone = e => (
  e.status === RuleExecutionStatus.DONE || e.status === RuleExecutionStatus.PENDING
);


const executionCardTimeline = ({
  execution,
}) => {
  const { t } = useTranslation();

  return (
    <Link
      to={execution.allowViewTimeline ? `/evidences/${execution.id}` : '#'}
      className={(isDone(execution) && execution.allowViewTimeline) ? '' : 'disabled-link'}
      onClick={e => !isDone(execution) && e.preventDefault()}
    >
      <div className="execution-card-timeline">
        <Row type="flex" style={{ alignItems: 'center' }}>
          <Col xs={24} sm={24} md={24} lg={getStatus(execution) === 'expired' ? 24 : 16}>
            <Row className="execution-section" style={{ color: '#727272' }}>
              {`${moment(execution.completedAt).format('DD/MM/YYYY LT')} h`}
            </Row>
            <Row className="execution-section">
              <Col span={24} className="execution-title">
                {`${getFormattedFolio(execution.folio)}${execution.name}`}
              </Col>
            </Row>
            <Row className="execution-section-info">
              {renderActivityInfo(execution, getStatus(execution))}
            </Row>
            <Row className="execution-section">
              <Col xs={24} sm={7}>
                <span className="text-bold">{t('branch_office')}:</span><br />
                {execution.branchOfficeName}
              </Col>
              <Col xs={24} sm={10}>
                <span className="text-bold">{t('role')}:</span><br />
                {execution.roleName}
              </Col>
              <Col xs={24} sm={7}>
                <span className="text-bold">{t('user')}:</span><br />
                {execution.username}
              </Col>
            </Row>
          </Col>
          {getStatus(execution) !== 'expired' &&
            <Col xs={24} sm={24} md={24} lg={8} align="middle">
              {(getStatus(execution) === 'process') || (getStatus(execution) === 'pending') ?
                <NoImageCard /> :
                <img src={getImage(execution)} alt="" style={{ maxHeight: '180px' }} />
              }
            </Col>}
        </Row>
      </div>
    </Link>
  );
};

export default executionCardTimeline;
