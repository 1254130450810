/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:28:07
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-18 16:25:04
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { parseApiError } from '../../../common/error';
import { actions } from './index';
import { FETCH_EXECUTION_TIMELINE } from './types';
import { Notification } from '../../../common/components/widgets';
import ExecutionService from '../../../services/execution';

const executionsService = new ExecutionService();

const fetchExecutionEpic = action$ =>
  action$.ofType(FETCH_EXECUTION_TIMELINE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(executionsService.getExecutionTimeline(action.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchExecutionTimelineFulfilled(response)),
          ))
        .catch((error) => {
          const parsedError = parseApiError(error);

          if (parsedError.status === 403) {
            return Observable.of(actions.updateState({ path: 'forbidden', value: true }));
          }

          return Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              Notification.error(parsedError.message);
            })),
          );
        }),
    ));

export default combineEpics(fetchExecutionEpic);
