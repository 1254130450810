/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:46:03 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-05-22 11:26:04
 */
const CONTAINER_KEY = 'RULES_CONTAINER';

export const FETCH_NOT_SCHEDULED_RULES = `${CONTAINER_KEY}_FETCH_NOT_SCHEDULED_RULES`;
export const FETCH_NOT_SCHEDULED_RULES_FULFILLED = `${FETCH_NOT_SCHEDULED_RULES}_FULFILLED`;
export const REGISTER_RULE = `${CONTAINER_KEY}_REGISTER_RULE`;
export const UPDATE_RULE = `${CONTAINER_KEY}_UPDATE_RULE`;
export const DELETE_RULE = `${CONTAINER_KEY}_DELETE_RULE`;
