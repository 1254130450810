import React from 'react';
import { Form, Button, Checkbox, Input, Modal, Row, Col, Icon, Select } from 'antd';
import { withTranslation } from 'react-i18next';

import './styles.css';
import ContactService from '../../../../services/contacts';
import { Notification } from '..';
import { parseApiError } from '../../../error';
// TODO: Support selection of contacts list
const contactService = new ContactService();

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount = () => {
    if (!this.props.isWebType) {
      const { fetchContacts } = this.props;
      fetchContacts();
    }
  }

  getCurrentValue = () => {
    const { value } = this.props;
    return value && value.length ? value : [];
  }

  handleOk = () => {
    const {
 fetchContacts, form, onChange, isWebType,
} = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        let newContact = null;
        if (!isWebType) {
          try {
            newContact = await contactService.createContact({ active: true, ...values });
          } catch (error) {
            const parsedError = parseApiError(error);
             Notification.error(parsedError.message);
          }
          fetchContacts();
        } else {
          newContact = values;
        }
        if (newContact) {
          const newValue = isWebType ? [newContact] : [...this.getCurrentValue(), newContact];
          onChange(newValue);
          this.handleClose();
        }
      }
    });
  }

  handleSelect = (id) => {
    const { onChange, contacts } = this.props;
    const contact = contacts.find(c => c.id === id);
    if (contact) {
      onChange([...this.getCurrentValue(), contact]);
    }
  }

  handleOpen = () => {
    this.setState({ visible: true });
  }

  handleClose = () => {
    this.props.form.resetFields();
    this.setState({ visible: false });
  }

  handleEdit = (contact) => {
    const { form: { setFieldsValue } } = this.props;
    setFieldsValue(contact);
    this.handleOpen();
  }

  handleDelete = (contact) => {
    const {
      value,
     } = this.props;
     const updatedContacts = value.filter(c => c.id !== contact.id || c.email !== contact.email);
    this.props.onChange(updatedContacts);
  }

  emailValidator = (rule, value, callback) => {
    if (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)) { // NOSONAR
      callback();
    }
    callback(this.props.t('invalid_email'));
  }

  phoneNumberValidator = (rule, value, callback) => {
    if (!value || /^$|^\d{10}$/.test(value)) {
      callback();
    }
    callback(this.props.t('invalid_format_10_digits'));
  }

  render() {
    const {
 form: { getFieldDecorator }, value, t, contacts, isWebType,
} = this.props;
    return (
      <div>
        <Modal
          title={t('add_contact')}
          className="modal-header-dark"
          maskStyle={{ backgroundColor: '#00000066' }}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleClose}
          okText={t('save')}
          cancelText={t('cancel')}
        >
          <Form>
            <Form.Item key="name" label={t('name')} name="name">
              {getFieldDecorator('name', {
                initialValue: '',
                rules: [{ required: true, message: t('required_field') }],
              })(<Input />)}
            </Form.Item>
            <Form.Item key="lastName" label={t('lastname')} name="lastName">
              {getFieldDecorator('lastName', {
                initialValue: '',
                rules: [{ required: true, message: t('required_field') }],
              })(<Input />)}
            </Form.Item>
            <Form.Item key="companyName" label={t('company')} name="companyName">
              {getFieldDecorator('companyName', {
                initialValue: '',
                rules: [{ required: true, message: t('required_field') }],
              })(<Input />)}
            </Form.Item>
            <Form.Item key="role" label={t('role')} name="role">
              {getFieldDecorator('role', {
                initialValue: '',
              })(<Input />)}
            </Form.Item>
            <Form.Item key="email" label={t('email_long')} name="email">
              {getFieldDecorator('email', {
                initialValue: '',
                rules: [
                  { required: true, message: t('required_field') },
                  { validator: this.emailValidator },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item key="phoneNumber" label="Whatsapp" name="phoneNumber">
              {getFieldDecorator('phoneNumber', {
                initialValue: '',
                rules: [
                  { required: true, message: t('required_field') },
                  { validator: this.phoneNumberValidator },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item key="notifyWhatsapp" name="notifyWhatsapp">
              {getFieldDecorator('notifyWhatsapp', {
                initialValue: false,
              })((
                <Checkbox>{t('send_whatsapp_notification')}</Checkbox>
              ))}
            </Form.Item>
            <Form.Item key="notifyEmail" name="notifyEmail">
              {getFieldDecorator('notifyEmail', {
                initialValue: false,
              })((
                <Checkbox>{t('send_email_notification')}</Checkbox>
              ))}
            </Form.Item>
          </Form>
        </Modal>
        <Row type="flex">
          {contacts && !!contacts.length && !isWebType &&
            <Col>
              <Select
                placeholder={t('search_contact')}
                style={{ minWidth: '180px' }}
                notFoundContent={t('no_data')}
                onChange={val => this.handleSelect(val)}
                showSearch
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
              >
                {contacts.map(e => (
                  <Select.Option key={e.id} value={e.id}>{`${e.name} ${e.lastName}, ${e.companyName}`}</Select.Option>
                  ))}
              </Select>
            </Col>
          }
          <Col>
            <Button icon="user" onClick={this.handleOpen}>
              {t('add_contact')}
            </Button>
          </Col>
        </Row>
        {
          value &&
          value.map(c =>
            (
              <Row type="flex" justify="space-between" align="middle" className="contact-container" >
                <Col>
                  <p className="contact-name">
                    {c.name} {c.lastName}
                  </p>
                  <p className="contact-info">
                    {`${c.companyName}${c.companyName && c.role ? '. ' : ''}${c.role}`}
                  </p>
                </Col>
                <Col >
                  <Icon type="edit" className="contact-button" onClick={() => this.handleEdit(c)} />
                  <Icon type="delete" className="contact-button" onClick={() => this.handleDelete(c)} />
                </Col>
              </Row>
            ))
        }
      </div>
    );
  }
}

const WrappedContactForm = Form.create({ name: 'form_test' })(ContactForm);

export default withTranslation()(WrappedContactForm);
