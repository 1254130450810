const validateInputStatus = (value) => {
  if (value.length > 0) {
    return 'success';
  }
  return null;
};

const redirectLogin = (props) => { props.resetState(); props.history.push('/auth/login'); };

const validatePermissionWeb = (permission) => {
  const { keyName } = permission || '';
  if (keyName === 'MINOR_USER_KEY' || keyName === 'MANAGER_KEY') {
    return false;
  }
  return true;
};

export { validateInputStatus, redirectLogin, validatePermissionWeb };

