/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-22 12:53:54 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2023-11-23 11:52:46
 */

import React from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { init, getFieldError } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import '../styles.css';
import logo from '../../../assets/images/taskit_logo_web.png';
import { actions } from './redux';
import { formValidations } from './validations';
import { Loading } from '../../../common/components/layout';
import { validateInputStatus, validatePermissionWeb } from '../utils';

const FormItem = Form.Item;

const ResetSucess = ({ user, onRedirect, translate }) => (
  <div className="login">
    <Row type="flex" align="middle" justify="center" className="full-height">
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} sm={18} md={16} lg={16} xl={18}>
          <div className="login__box">
            <div className="login__box__img">
              <img src={logo} alt="logo-taskit" className="login__logo" />
            </div>
            <h1 className="login__text">{translate('success')}</h1>
            <Icon type="check-circle" className="auth-leyend-box__icon auth-leyend-box__icon--success" />
            <h3 className="login__text">{translate('congratulations')} {user.name} {translate('registration_completed')}</h3>
            {validatePermissionWeb(user.permission) ? (
              <div className="justify-center">
                <Button type="primary" onClick={onRedirect}>{translate('log_in')}</Button>
              </div>
            ) : (
              <div className="justify-center">
                <h3 className="auth-leyend-box__icon--error"><b>{translate('log_in_mobile')}</b></h3>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Row>
  </div>
);

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.validator = init(formValidations());
  }

  componentWillMount = () => {
    const { location: { search }, fetchToken, resetState } = this.props;
    resetState();
    const token = search.substring(search.indexOf('=') + 1);
    fetchToken(token);
  }

  onChangeForm = (prop, value) => {
    const { updateState, formErrors, form } = this.props;
    const { error } = this.validator.validate(prop, value, form);
    updateState({ path: `form.${prop}`, value });
    updateState({ path: 'formErrors', value: { ...formErrors, [prop]: error } });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form, updateState, resetPassword, successFetchToken,
    } = this.props;
    const { valid, errors } = this.validator.validateForm(form);
    updateState({ path: 'formErrors', value: errors });
    if (!valid) { return; }
    updateState({ path: 'loadingButton', value: true });
    resetPassword({
      form: successFetchToken.user,
      password: form.password,
    });
  }

  redirectLogin = () => { this.props.resetState(); this.props.history.push('/auth/login'); };

  render() {
    const {
      form: { password, newPassword }, formErrors, loadingButton,
      successFetchToken, isLoading, resetPassworsSucessful, t,
    } = this.props;
    const passwordError = getFieldError(formErrors, 'password');
    const newPasswordError = getFieldError(formErrors, 'newPassword');

    if (isLoading) { return <Loading />; }
    if (resetPassworsSucessful.reset) {
      return (<ResetSucess
        user={resetPassworsSucessful.user}
        onRedirect={this.redirectLogin}
        translate={t}
      />);
    }

    return (
      <div className="login">
        <Row type="flex" align="middle" justify="center" className="full-height">
          <Row type="flex" justify="center" align="middle">
            <Col xs={22} sm={18} md={16} lg={16} xl={18}>
              <div className="login__box">
                {!successFetchToken.error && <h3 className="login__text login__welcome-text">{t('set_password')}</h3>}
                <div className="login__box__img">
                  <img src={logo} alt="logo-taskit" className="login__logo" />
                </div>
                {successFetchToken.error ? (
                  <div>
                    <h1 className="login__text">Error</h1>
                    <Icon type="close-circle" className="auth-leyend-box__icon auth-leyend-box__icon--error" />
                    <h3 className="login__text">{successFetchToken.error.message}</h3>
                    <div className="justify-center">
                      <h3>{t('request_new_link')}</h3>
                    </div>
                  </div>
                    ) : (
                      <div>
                        <h3 className="login__text login__text--justyfy">
                          {t('greeting')} {successFetchToken.user.name}, {t('complete_password_msg')}
                        </h3>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                          <FormItem
                            label={t('new_password')}
                            hasFeedback
                            validateStatus={passwordError ? 'error' : validateInputStatus(password)}
                            help={Boolean(passwordError) && passwordError}
                          >
                            <Input
                              type="password"
                              prefix={<Icon type="lock" />}
                              placeholder={t('new_password')}
                              value={password}
                              onChange={value => this.onChangeForm('password', value.target.value)}
                            />
                          </FormItem>
                          <FormItem
                            label={t('confirm_password')}
                            hasFeedback
                            validateStatus={newPasswordError ? 'error' : validateInputStatus(newPassword)}
                            help={Boolean(newPasswordError) && newPasswordError}
                          >
                            <Input
                              type="password"
                              prefix={<Icon type="lock" />}
                              placeholder={t('confirm_password')}
                              value={newPassword}
                              onChange={value => this.onChangeForm('newPassword', value.target.value)}
                            />
                          </FormItem>
                          <FormItem className="login__form__footer">
                            <div className="login__button-container justify-center">
                              <Button loading={loadingButton} type="primary" htmlType="submit" onSubmit={this.handleSubmit} className="login-form-button">
                                Establecer
                              </Button>
                            </div>
                            <div className="login__button-container justify-center">
                              <Button onClick={this.redirectLogin} className="login-form-button">
                                {t('cancel')}
                              </Button>
                            </div>
                          </FormItem>
                        </Form>
                      </div>
                    )}
              </div>
            </Col>
          </Row>
        </Row>
      </div>

    );
  }
}

const mapStateToProps = ({ registerContainer }) => registerContainer;

export default withTranslation()(withRouter(connect(mapStateToProps, actions)(ResetPasswordForm)));

