/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 10:58:50 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-14 16:51:01
 */
import axios from 'axios';
import { orderBy } from 'lodash';
import Service from '../Service';
import { createRulesServiceUrl } from '../../common/helpers/service';

const formatData = data => orderBy(data, ['createdAt'], ['desc']);

class WebHookService extends Service {
    getAll = () => axios.get(createRulesServiceUrl('webHooks')).then(data => formatData(data.data));
    postWebHook = form => axios.post(createRulesServiceUrl('webHooks'), form).then(data => data.data);
    putWebHook = form => axios.put(createRulesServiceUrl('webHooks'), form).then(data => data.data);
    deleteWebHook = form => axios.delete(createRulesServiceUrl(`webHooks/${form.id}`)).then(data => data.data);
}

export default WebHookService;
