import React, { useEffect } from 'react';
import { Select } from 'antd';

const UserTask = ({
 value, onChange, users, placeholder, onSearchUsers, searchUsers,
}) => {
    useEffect(() => {
        if (value) {
            searchUsers({
              id: value,
            });
          } else {
            searchUsers();
          }
    }, []);
    return (
      <Select
        showSearch
        onSearch={onSearchUsers}
        placeholder={placeholder}
        filterOption={false}
        value={value}
        onChange={onChange}
      >
        {
      users.map(user => (
        <Select.Option key={user.id} >{`${user.name} ${user.lastName}, ${user.email}`}</Select.Option>
      ))
    }
      </Select>
);
};
export default UserTask;
