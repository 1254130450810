/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-11 01:07:37
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-26 02:19:09
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  FETCH_ACTIVITIES,
  FETCH_DRAFTS,
  FETCH_DRAFTS_FULFILLED,
  FETCH_ACTIVITIES_FULFILLED,
  FETCH_PROGRESS_FULFILLED,
  CREATE_EXECUTION,
} from './types';
import { FETCH_NOT_SCHEDULED_RULES_FULFILLED, FETCH_RULES_FILTER_FULFILLED } from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';
import * as userActions from '../../users/redux/index';
import { UPDATE_USER_FULFILLED } from '../../users/redux/types';

// Initial State
const defaultState = {
  reducerKey: 'executionActivitiesContainer',
  catalogs: {
    rules: [],
    drafts: [],
    executions: [],
    rulesExecution: [],
  },
  todayProgress: 0,
  updatedUser: false,
};

// Action Creators
// Payload { form, defaultActions }
const fetchDrafts = () => ({ type: FETCH_DRAFTS });
const fetchDraftsFulfilled = payload => ({ type: FETCH_DRAFTS_FULFILLED, payload });
const fetchActivities = (payload, branchOfficeId, roleIds, timeZone, userId, callback) => ({
  type: FETCH_ACTIVITIES, payload, roleIds, branchOfficeId, timeZone, userId, callback,
});
const fetchActivitiesFulfilled = payload => ({ type: FETCH_ACTIVITIES_FULFILLED, payload });
const fetchProgressFulfilled = payload => ({ type: FETCH_PROGRESS_FULFILLED, payload });
const createExecution = payload => ({ type: CREATE_EXECUTION, payload });

// Reducer(Pure Functions) handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_NOT_SCHEDULED_RULES_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.rules', value: payload }),
  [FETCH_DRAFTS_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.drafts', value: payload }),
  [FETCH_ACTIVITIES_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.executions', value: payload }),
  [FETCH_PROGRESS_FULFILLED]: (state, payload) => ({ ...state, todayProgress: payload }),
  [UPDATE_USER_FULFILLED]: state => update(state, { path: 'updatedUser', value: true }),
  [FETCH_RULES_FILTER_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.rulesExecution', value: payload }),
});

const actions = {
  ...userActions.actions,
  ...generalActions,
  ...defaultActions,
  fetchActivities,
  fetchDrafts,
  fetchDraftsFulfilled,
  fetchActivitiesFulfilled,
  fetchProgressFulfilled,
  createExecution,
};

export { epics, reducer, actions, defaultActions };

