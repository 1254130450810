/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-22 13:04:36 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 15:30:31
 */

import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from '.';
import { SEND_EMAIL, FETCH_TOKEN, RESET_PASSWORD } from './types';

import UserService from '../../../../services/user';
import { Notification } from '../../../../common/components/widgets';
import { parseApiError } from '../../../../common/error';

const userService = new UserService();

const forgotPasswordEpic = action$ =>
  action$.ofType(SEND_EMAIL)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(userService.forgotPassword(action.payload.email))
          .flatMap(user =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.resetState()),
              Observable.of(actions.sendingEmail(false)),
              Observable.of(actions.sendEmailSuccess({ send: true, email: user.email })),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.sendingEmail(false)),
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                Notification.error(i18n.t('request_fail'), parsedError.message);
              })),
            )),
      ));

const fetchTokenEpic = action$ =>
  action$.ofType(FETCH_TOKEN).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(userService.getPasswordResetToken(action.token))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.resetState()),
            Observable.of(actions.fetchTokenSucess({ user: response })),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchTokenSucess({ error: parseApiError(error) })),
          )),
    ));

const resetPasswordEpic = action$ =>
  action$.ofType(RESET_PASSWORD)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable
          .fromPromise(userService.resetPassword(action.payload.form, action.payload.password))
          .flatMap(user =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.resetState()),
              Observable.of(actions.sendingEmail(false)),
              Observable.of(actions.resetPasswordSucess({ reset: true, user })),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.sendingEmail(false)),
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                Notification.error(i18n.t('password_update_fail'), parsedError.message);
              })),
            )),
      ));


export default combineEpics(forgotPasswordEpic, fetchTokenEpic, resetPasswordEpic);
