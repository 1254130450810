/*
 * @Author: Jhony Reyes 
 * @Date: 2018-05-04 12:15:26 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-11-21 15:45:59
 */
import { isEmpty } from 'lodash';

import i18n from 'i18next';

const validations = () => ({
  name: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.name.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    }],
  description: [
    { validate: 'required', message: i18n.t('description_required') },
    {
      validate: (_, form) => form.description.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    }],
  roleId: [{
    validate: (_, form) => !(isEmpty(form.roleId) && isEmpty(form.usersIds)),
    message: i18n.t('required_role_users'),
    custom: true,
  }],
  cron: [{ validate: 'required', message: i18n.t('periodicity_required') }],
  checklist: [
    {
      validate: (_, form) => !isEmpty(form.checklist) || !isEmpty(form.tasks),
      message: i18n.t('checklist_required'),
      custom: true,
    },
  ],
});

export default validations;
