/*
 * @Author: Jhony Reyes
 * @Date: 2018-06-19 14:41:21
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-10-23 13:55:15
 */

import React, { Component } from 'react';
import { Table, Tooltip, Icon, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { RenderActionsCell } from '../../common/components/widgets/table';
import { ChecklistShow, PopoverList } from '../../common/components/widgets';
import { HeaderContent } from './utils/components';
import { actions } from './redux';

class Rules extends Component {
  constructor(props) {
    super(props);
    this.columns = this.getColumns();
  }

  getColumns = () => [
    {
      title: this.props.t('name'),
      dataIndex: 'name',
      onCell: () => ({ 'data-label': this.props.t('name') }),
      width: '10%',
    },
    {
      title: this.props.t('description'),
      dataIndex: 'description',
      width: '15%',
      onCell: () => ({ 'data-label': this.props.t('description') }),
      ellipsis: true,
      render: text => (
        <Tooltip title={text}>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{text}</div>
        </Tooltip>
     ),
    },
    {
      title: this.props.t('branch_office'),
      dataIndex: 'branchOfficeIds',
      width: '15%',
      onCell: () => ({ 'data-label': this.props.t('branch_office') }),
      render: (text, record) => {
        const { branchOffices } = this.props;
        const namesList = [];
        record.branchOfficeIds.forEach((item) => {
          const value = branchOffices.find(e => e.id === item);
          if (value) {
            namesList.push(value.name);
          }
        });
        const target = Object.assign({}, record, {
        namesList,
        });
        return (`${target.namesList}`);
      },
    },
    {
      title: this.props.t('collaborative'),
      dataIndex: 'collaborative',
      width: '5%',
      onCell: () => ({ 'data-label': this.props.t('collaborative') }),
      render: (text, record) => <Checkbox checked={record.collaborative} />,
    },
    {
      title: (
        <div>{this.props.t('role')}/{this.props.t('users')}&nbsp;
          <Tooltip
            placement="right"
            title={this.props.t('users_same_brach')}
          >
            <Icon type="question-circle" theme="outlined" />
          </Tooltip>
        </div>
      ),
      dataIndex: 'roleId',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('role') }),
      render: (text, record) => {
        const {
          roles,
          defaultItem,
          users,
        } = this.props;
        const target = roles.find(item => record.roleId === item.id);
        const selected = target ? { name: target.name, id: target.id } : { ...defaultItem };

        if (record.collaborative) {
          return selected.name;
        }

        const userIds = record.usersIds.split(',');
        const selectedUsers = users
          .filter(i => userIds.includes(i.id));

        return (
          <PopoverList
            title={this.props.t('users')}
            label={`${this.props.t('users')}: ${selectedUsers.length}`}
            data={selectedUsers}
            renderItem={user => `${user.name} ${user.lastName}`}
          />
        );
      },
    },
    {
      title: this.props.t('tasks'),
      dataIndex: 'checklist',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('tasks') }),
      render: (text, record) => (
        <ChecklistShow key={record.id} created={record.checklist} />
      ),
    },
    {
      title: this.props.t('private'),
      dataIndex: 'internal',
      width: '5%',
      onCell: () => ({ 'data-label': this.props.t('private') }),
      render: (text, record) => <Checkbox checked={record.internal} />,
    },
    {
      title: this.props.t('gps_required'),
      dataIndex: 'gpsRequired',
      width: '5%',
      onCell: () => ({ 'data-label': this.props.t('gps_required') }),
      render: (text, record) => <Checkbox checked={record.gpsRequired} />,
    },
    {
      title: (
        <div>{this.props.t('notify')}&nbsp;
          <Tooltip
            placement="right"
            title={this.props.t('user_notification_msg')}
          >
            <Icon type="question-circle" theme="outlined" />
          </Tooltip>
        </div>
      ),
      dataIndex: 'users',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('notify') }),
      render: (text, record) => {
        const { users } = this.props;
        const selectedUsers = record.userNotifications ?
          users.filter(i => record.userNotifications.some(userId => i.id === userId)) : [];

        return (
          <PopoverList
            title={this.props.t('users')}
            label={`${this.props.t('users')}: ${selectedUsers.length}`}
            data={selectedUsers}
            renderItem={user => user.email}
          />
        );
      },
    },
    {
      title: this.props.t('actions'),
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('actions') }),
      render: (text, record) => (
        <RenderActionsCell
          record={record}
          onCancel={() => this.cancel()}
          onEdit={() => this.props.handleEdit(record.id)}
          onDelete={() => this.delete(record.id)}
        />
      ),
    },
  ];

  handleChange(value, id, column) {
    const { rules, updateState } = this.props;
    const newData = [...rules];
    const target = newData.find(item => id === item.id);

    if (target) {
      target[column] = value;
      updateState({ path: 'catalogs.rules', value: newData });
    }
  }

  cancel = () => {
    const { fetchRulesByFilters, filters } = this.props;
    fetchRulesByFilters({ ...filters });
  }

  delete(id) {
    const { deleteRule, filters } = this.props;
    deleteRule({ id }, filters);
  }

  render() {
    const {
      filters: {
        branchOfficeId, roleId, name,
      },
      isLoading,
      currentPage,
      onCreate,
      rules,
      branchOffices,
      roles,
      onChangeBranchOffice,
      onChangeRole,
      onChangeName,
      onSearchName,
      t,
    } = this.props;
    const selectedBranchOffice = branchOffices.find(item => branchOfficeId === item.id);
    const selectedRole = roles.find(item => roleId === item.id);

    return (
      <div>
        <HeaderContent
          branchOffices={branchOffices}
          selectedBranchOffice={selectedBranchOffice}
          roles={roles}
          selectedRole={selectedRole}
          onChangeBranchOffice={onChangeBranchOffice}
          onChangeRole={onChangeRole}
          onCreate={onCreate}
          onChangeName={onChangeName}
          onSearchName={onSearchName}
          name={name}

        />
        <Table
          ref={(table) => { this.table = table; }}
          rowKey="id"
          className="responsive-table"
          dataSource={rules}
          columns={this.columns}
          locale={{ emptyText: t('show_no_data') }}
          loading={isLoading}
          pagination={{
            current: currentPage,
            onChange: page => this.props.updateState({ path: 'currentPage', value: page }),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ rulesContainer }) => ({
  ...rulesContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(Rules));
