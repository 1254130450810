/*
 * @Author: Diana Resendiz
 * @Date: 2021-09-01 17:45:39
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-23 12:39:26
 */

import { Button, Col, Icon, Modal, Popconfirm, Radio, Row, Select, Switch, Tabs } from 'antd';
import React from 'react';

const { TabPane } = Tabs;
const { Option } = Select;

const defaultState = {
  currentText: '',
  checklist: {},
  checkedList: [],
  permissionRoleIds: [],
  indeterminate: false,
  checkAll: true,
  checkRolesAll: true,
  checkNoRoles: false,
  checkBrancOffice: null,
  checkContact: null,
  created: [],
  permissions: [
    // permissionsScream
    { id: '31ab1fc0-1e76-11e9-ab14-d663bd873d93', keyName: 'can_access_web_platform' },
    { id: '047a0ff0-7854-11ea-bc55-0242ac130003', keyName: 'can_edit_catalogs' },
    { id: '6f72c6e5-0717-439a-8a51-2a5d4a4d5dba', keyName: 'can_edit_rule_executions' },
    { id: '50debd00-1e78-11e9-ab14-d663bd873d93', keyName: 'can_view_summary_report' },
    { id: '797cb388-53dd-54ae-602a-b84d3537ff7d', keyName: 'can_create_and_edit_users' },
    { id: 'c1020a28-9f4f-02d1-7b51-9a654d9ee94b', keyName: 'can_create_and_edit_activities_tasks_and_evaluations' },
    { id: '36c94d8c-6fb1-1bc3-efc3-dd1214f246b1', keyName: 'can_remove_evidences' },
    { id: '869efba8-8c98-a843-c791-b8c736d9c1bc', keyName: 'can_access_notification_filters' },
    // permissionsSupervisor
    { id: '83b90178-1e77-11e9-ab14-d663bd873d93', keyName: 'can_monitor_your_branch' },
    { id: '0727bd10-1e78-11e9-ab14-d663bd873d93', keyName: 'can_monitor_all_branches' },
    // permissionsContact
    { id: '14a282cf-d065-28f1-f4f2-41fdd8417c6e', keyName: 'can_see_contacts_of_his_branch' },
    { id: 'ea7097cb-835b-5869-0ff6-1206c62e5bcb', keyName: 'can_see_all_contacts' },
  ],
  permissionsScream: [
    { id: '31ab1fc0-1e76-11e9-ab14-d663bd873d93', keyName: 'can_access_web_platform' },
    { id: '047a0ff0-7854-11ea-bc55-0242ac130003', keyName: 'can_edit_catalogs' },
    { id: '6f72c6e5-0717-439a-8a51-2a5d4a4d5dba', keyName: 'can_edit_rule_executions' },
    { id: '50debd00-1e78-11e9-ab14-d663bd873d93', keyName: 'can_view_summary_report' },
    { id: '797cb388-53dd-54ae-602a-b84d3537ff7d', keyName: 'can_create_and_edit_users' },
    { id: 'c1020a28-9f4f-02d1-7b51-9a654d9ee94b', keyName: 'can_create_and_edit_activities_tasks_and_evaluations' },
    { id: '36c94d8c-6fb1-1bc3-efc3-dd1214f246b1', keyName: 'can_remove_evidences' },
    { id: '869efba8-8c98-a843-c791-b8c736d9c1bc', keyName: 'can_access_notification_filters' },
  ],
  permissionsSupervisor: [
    { id: '83b90178-1e77-11e9-ab14-d663bd873d93', keyName: 'can_monitor_your_branch' },
    { id: '0727bd10-1e78-11e9-ab14-d663bd873d93', keyName: 'can_monitor_all_branches' },
  ],
  permissionsContact: [
    { id: '14a282cf-d065-28f1-f4f2-41fdd8417c6e', keyName: 'can_see_contacts_of_his_branch' },
    { id: 'ea7097cb-835b-5869-0ff6-1206c62e5bcb', keyName: 'can_see_all_contacts' },
  ],
};

export default class UserPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentWillMount = () => {
    const { permissionsUser, user, roles } = this.props;
    const { permissionsScream, permissionsSupervisor, permissionsContact } = this.state;

    this.setState({
      permissionRoleIds: user.permissionRoleIds,
    });

    if (permissionsUser.length) {
      this.setState({
        checkedList: permissionsUser,
      });
    }
    permissionsScream.map((permission) => {
      const exist = permissionsUser.find(i => i.id === permission.id);
      if (!exist) {
        this.setState({
          checkAll: false,
        });
      }
      return null;
    });
    permissionsSupervisor.map((permission) => {
      const exist = permissionsUser.find(i => i.id === permission.id);
      if (exist) {
        this.setState({
          checkBrancOffice: permission.id,
        });
      }
      return null;
    });
    roles.map((role) => {
      const exist = user.permissionRoleIds.find(i => i === role.id);
      if (!exist) {
        this.setState({
          checkRolesAll: false,
        });
      }
      if (user.owner) {
        this.setState(state => ({
          permissionRoleIds: [
            ...state.permissionRoleIds, role.id,
          ],
          checkRolesAll: true,
        }));
      }
      return null;
    });
    permissionsContact.map((permission) => {
      const exist = permissionsUser.find(i => i.id === permission.id);
      if (exist) {
        this.setState({
          checkContact: permission.id,
        });
      }
      return null;
    });
    if (user.owner) {
      this.setState({
        checkRolesAll: true,
        checkBrancOffice: '0727bd10-1e78-11e9-ab14-d663bd873d93',
        checkContact: 'ea7097cb-835b-5869-0ff6-1206c62e5bcb',
      });
    }
    const noRole = user.permissionRoleIds.find(role => role === null);
    if (noRole === null) {
      this.setState({
        checkNoRoles: true,
      });
    } else {
      this.setState({
        checkRolesAll: false,
      });
    }
  }

  onOk = () => {
    const { checkedList, permissionRoleIds } = this.state;
    const {
  onChangePermissions, onChangePermissionRoles, onCancel, onSave,
  } = this.props;

    onChangePermissions(checkedList);
    onChangePermissionRoles(permissionRoleIds);
    onSave();
    onCancel();
  }

  onChangeAll = (e) => {
    const { permissionsScream } = this.state;
    permissionsScream.map((perm) => {
      this.setState(state => ({
        checkedList: state.checkedList.filter(checked => checked.id !== perm.id),
        checkAll: false,
      }));
      return null;
    });
    if (e) {
      permissionsScream.map((perm) => {
        this.setState(state => ({
          checkedList: [
            ...state.checkedList, perm,
          ],
          checkAll: true,
        }));
        return null;
      });
    }
  }

  onChangeRolesAll = (e) => {
    const { roles } = this.props;
    roles.map((perm) => {
      this.setState(state => ({
        permissionRoleIds: state.permissionRoleIds.filter(role => role !== perm.id),
        checkRolesAll: false,
        checkNoRoles: false,
      }));
      return null;
    });
    if (e) {
      roles.map((perm) => {
        this.setState(state => ({
          permissionRoleIds: [
            ...state.permissionRoleIds, perm.id,
          ],
        }));
        return null;
      });
      this.setState(state => ({
        permissionRoleIds: [
          ...state.permissionRoleIds, null,
        ],
        checkRolesAll: true,
        checkNoRoles: true,
      }));
    }
  }

  onChangeNoRoles = (e) => {
    if (e) {
      this.setState(state => ({
        permissionRoleIds: [
          ...state.permissionRoleIds, null,
        ],
        checkNoRoles: true,
      }));
    } else {
      this.setState(state => ({
        checkNoRoles: false,
        checkRolesAll: false,
        permissionRoleIds: state.permissionRoleIds.filter(role => role !== null),
      }));
    }
  }

  onChange = (id, value) => {
    const { permissions } = this.props;

    const permissionSelected = permissions.find(i => i.id === id);
    if (value) {
      this.setState(state => ({
        checkedList: [
          ...state.checkedList, permissionSelected,
        ],
      }));
    } else {
      this.setState(state => ({
        checkedList: state.checkedList.filter(checked => checked.id !== id),
        checkAll: false,
      }));
    }
  }

  onChangeBranchOffice = (id) => {
    const { permissions } = this.props;
    const yoursBranchID = '83b90178-1e77-11e9-ab14-d663bd873d93';
    const allBranchID = '0727bd10-1e78-11e9-ab14-d663bd873d93';
    this.setState(state => ({
      checkedList: state.checkedList.filter(checked =>
        (checked.id !== yoursBranchID && checked.id !== allBranchID)),
    }));

    if (id !== null) {
      const permissionSelected = permissions.find(i => i.id === id);
      this.setState(state => ({
        checkedList: [
          ...state.checkedList, permissionSelected,
        ],
        checkBrancOffice: id,
      }));
    } else {
      this.setState({ checkBrancOffice: null });
    }
  }

  onChangeContact = (id) => {
    const { permissions } = this.props;
    const yoursContactID = '14a282cf-d065-28f1-f4f2-41fdd8417c6e';
    const allContactID = 'ea7097cb-835b-5869-0ff6-1206c62e5bcb';
    this.setState(state => ({
      checkedList: state.checkedList.filter(checked =>
        (checked.id !== yoursContactID && checked.id !== allContactID)),
    }));

    if (id !== null) {
      const permissionSelected = permissions.find(i => i.id === id);
      this.setState(state => ({
        checkedList: [
          ...state.checkedList, permissionSelected,
        ],
        checkContact: id,
      }));
    } else {
      this.setState({ checkContact: null });
    }
  }

  onChangePermissionRoles = (id, value) => {
    const { roles } = this.props;

    const roleSelected = roles.find(i => i.id === id);
    if (value) {
      this.setState(state => ({
        permissionRoleIds: [
          ...state.permissionRoleIds, roleSelected.id,
        ],
      }));
    } else {
      this.setState(state => ({
        permissionRoleIds: state.permissionRoleIds.filter(role => role !== id),
        checkRolesAll: false,
      }));
    }
  }

  onSelectRole = (roleId) => {
      this.setState(state => ({
        permissionRoleIds: [
          ...state.permissionRoleIds, roleId,
        ],
      }));
  }

  onDeselectRole = (roleId) => {
    this.setState(state => ({
      permissionRoleIds: state.permissionRoleIds.filter(role => role !== roleId),
      checkRolesAll: false,
    }));
  }

  getButtons = () => {
    const { t, onCancel } = this.props;
    return [
      <Popconfirm
        key="cancel"
        title={t('cancel_entry_confirmation')}
        cancelText={t('no')}
        okText={t('yes')}
        onConfirm={onCancel}
      >
        <Button>{t('cancel')}</Button>
      </Popconfirm>,
      <Button
        key="save-button"
        type="primary"
        onClick={this.onOk}
      >
        { t('save_contact') }
      </Button>,
    ];
  }

  render() {
    const {
      t,
      visible,
      roles,
      user,
    } = this.props;
    const {
      checkedList, checkAll, checkRolesAll,
      checkBrancOffice, checkContact, permissionRoleIds,
      checkNoRoles,
    } = this.state;
    return (
      <Modal
        title={t('permissions')}
        visible={visible}
        width={800}
        maskClosable={false}
        closable={false}
        className="modal-header-dark"
        style={{ top: 20 }}
        footer={this.getButtons()}
        maskStyle={{ backgroundColor: '#00000066' }}
        bodyStyle={{ padding: '0px' }}

      >
        <Tabs defaultActiveKey="1" size="default" >
          <TabPane
            tab={
              <span>
                <Icon type="profile" />
                {t('permissions_screem')}
              </span>
            }
            key="1"
          >
            <Row className="form-section">
              <Switch
                onChange={this.onChangeAll}
                checked={checkAll}
                disabled={user.owner}
                size="small"
              />
              &nbsp;&nbsp;
              {t('all')}
              {
               defaultState.permissionsScream.map(p => (
                 <Row>
                   <Col sm={24} lg={24} className="form-input">
                     <Switch
                       onChange={e => this.onChange(p.id, e)}
                       checked={checkedList.some(permission => permission.id === p.id)}
                       disabled={user.owner}
                       size="small"
                     />
                     &nbsp;&nbsp;
                     {t(p.keyName)}
                   </Col>
                 </Row>
              ))
              }
            </Row>
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="eye" />
                {t('supervisory_permissions')}
              </span>
            }
            key="2"
          >
            <Row className="form-section">
              <Row>
                <Radio.Group
                  onChange={e => this.onChangeBranchOffice(e.target.value)}
                  value={checkBrancOffice}
                  disabled={user.owner}
                >
                  <Radio value={null}>
                    {t('only_my_activities')}
                  </Radio>
                  <Radio value="83b90178-1e77-11e9-ab14-d663bd873d93">
                    {t('can_monitor_your_branch')}
                  </Radio>
                  <Radio value="0727bd10-1e78-11e9-ab14-d663bd873d93">
                    {t('can_monitor_all_branches')}
                  </Radio>
                </Radio.Group>
              </Row>
              { checkBrancOffice !== null &&
              <Row>
                <Row>
                  <Col sm={24} lg={24} className="form-input">
                    <div className="form-label">
                      {t('roles')}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8} lg={8} className="form-input">
                    <Switch
                      size="small"
                      onChange={this.onChangeRolesAll}
                      checked={checkRolesAll}
                      disabled={user.owner}
                    />
                    &nbsp;&nbsp;
                    {t('all')}
                  </Col>
                </Row>
                <Row>
                  <Col sm={8} lg={8} className="form-input">
                    <Switch
                      size="small"
                      onChange={this.onChangeNoRoles}
                      checked={checkNoRoles}
                      disabled={user.owner}
                    />
                    &nbsp;&nbsp;
                    {t('no_role')}
                  </Col>
                </Row>
                {<br />}
                {
                  <Select
                    mode="multiple"
                    size="middle"
                    showArrow
                    value={permissionRoleIds}
                    placeholder={t('roles')}
                    style={{ width: 700 }}
                    onSelect={this.onSelectRole}
                    onDeselect={this.onDeselectRole}
                    notFoundContent={t('no_data')}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {
                      roles.map(r => (
                        <Option
                          key={r.id}
                          value={r.id}
                          disabled={user.owner}
                        >
                          {r.name}
                        </Option>
                      ))
                    }
                  </Select>
                 }
              </Row>
          }
            </Row>
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="user" />
                {t('permission_contact')}
              </span>
            }
            key="3"
          >
            <Row className="form-section">
              <Row>
                <Radio.Group
                  onChange={e => this.onChangeContact(e.target.value)}
                  value={checkContact}
                  disabled={user.owner}
                >
                  <Radio value={null}>
                    {t('only_my_contacts')}
                  </Radio>
                  <Radio value="14a282cf-d065-28f1-f4f2-41fdd8417c6e">
                    {t('can_see_contacts_of_his_branch')}
                  </Radio>
                  <Radio value="ea7097cb-835b-5869-0ff6-1206c62e5bcb">
                    {t('can_see_all_contacts')}
                  </Radio>
                </Radio.Group>
              </Row>
            </Row>
          </TabPane>
        </Tabs>
      </Modal>
    );
  }
}
