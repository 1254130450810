/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 10:54:52 
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-04-20 10:55:19
 */
class Service {
  getAll = () => {
    throw new Error('Method not implemented');
  }
}

export default Service;

