/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 14:41:21 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-10-30 14:06:06
 */

import React, { Component } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { RenderActionsCell } from '../../common/components/widgets/table';
import EvaluationForm from './utils/components/EvaluationForm';
import { EvaluationTypes } from '../../common/constants';
import { HeaderContent } from './utils/components';
import { actions } from './redux';

class Evaluations extends Component {
  constructor(props) {
    super(props);
    this.columns = this.getColumns();
  }

  componentDidMount = () => {
    const {
      fetchEvaluations,
      filters,
      catalogs: { evaluations },
    } = this.props;

    fetchEvaluations({
      page: 0,
      size: evaluations.size,
      ...filters,
    });
  };

  onSave = (evaluation) => {
    const {
      registerEvaluation,
      updateEvaluation,
      filters,
      catalogs: { evaluations },
    } = this.props;

    if (evaluation.id) {
      updateEvaluation(
        evaluation,
        {
          page: evaluations.number,
          size: evaluations.size,
          ...filters,
        },
      );
    } else {
      registerEvaluation(evaluation);
      this.onChangeFilter('type', null);
    }
  }

  onDelete = (id) => {
    const {
      deleteEvaluation,
      filters,
      catalogs: { evaluations },
    } = this.props;

    deleteEvaluation(
      id,
      {
        page: evaluations.numberOfElements === 1 ? 0 : evaluations.number,
        size: evaluations.size,
        ...filters,
      },
    );
  }

  onChangeFilter = (filter, value) => {
    const {
      filters,
      updateState,
      fetchEvaluations,
      catalogs: { evaluations },
    } = this.props;
    const updatedFilters = { ...filters, [filter]: value };

    updateState({ path: 'filters', value: updatedFilters });

    fetchEvaluations({
      page: 0,
      size: evaluations.size,
      ...updatedFilters,
    });
  }

  onChangePagination = (page, pageSize) => {
    const { fetchEvaluations, filters } = this.props;

    fetchEvaluations({
      page: page - 1,
      size: pageSize,
      ...filters,
    });
  }

  getColumns = () => [
    {
      title: this.props.t('name'),
      dataIndex: 'name',
      onCell: () => ({ 'data-label': this.props.t('name') }),
    },
    {
      title: this.props.t('description'),
      dataIndex: 'description',
      onCell: () => ({ 'data-label': this.props.t('description') }),
    },
    {
      title: this.props.t('type'),
      dataIndex: 'type',
      onCell: () => ({ 'data-label': this.props.t('type') }),
      render: this.getTypeLabel,
    },
    {
      title: this.props.t('actions'),
      onCell: () => ({ 'data-label': this.props.t('actions') }),
      render: (text, record) => {
        const { openModal } = this.props;

        return (
          <RenderActionsCell
            creating={null}
            record={record}
            onSave={() => {}}
            onCancel={() => {}}
            onEdit={() => openModal(record)}
            onDelete={() => this.onDelete(record.id)}
          />
        );
      },
    },
  ];

  getTypeLabel = (type) => {
    switch (type) {
      case EvaluationTypes.GRAPHIC:
        return this.props.t('graph');
      case EvaluationTypes.RANGE:
        return this.props.t('range');
      case EvaluationTypes.ENUM:
        return this.props.t('numbering');
      default:
        return '-----';
    }
  }

  handleChange(value, id, column) {
    const { catalogs: { filters }, updateState } = this.props;
    const newData = [...filters];
    const target = newData.find(item => id === item.id);

    if (target) {
      target[column] = value;
      updateState({ path: 'catalogs.filters', value: newData });
    }
  }

  render() {
    const {
      catalogs: { evaluations },
      isLoading,
      filters: {
        type,
      },
      showModal,
      currentEvaluation,
      openModal,
      closeModal,
      t,
    } = this.props;

    return (
      <div>
        <HeaderContent
          onClickNew={openModal}
          selectedType={type}
          onSelectType={val => this.onChangeFilter('type', val)}
        />
        <Table
          ref={(table) => { this.table = table; }}
          rowKey="id"
          className="responsive-table"
          dataSource={evaluations.content}
          columns={this.columns}
          locale={{ emptyText: t('show_no_data') }}
          loading={isLoading}
          pagination={{
            current: evaluations.number + 1,
            pageSize: evaluations.size,
            total: evaluations.totalElements,
            onChange: this.onChangePagination,
          }}
        />
        {
          showModal &&
          <EvaluationForm
            loading={isLoading}
            onSave={this.onSave}
            closeModal={closeModal}
            evaluation={currentEvaluation}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = ({ evaluationsContainer }) => ({
  ...evaluationsContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(Evaluations));
