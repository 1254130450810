/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-19 14:54:40 
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-04-19 14:55:14
 */
import { flatten } from 'lodash';

export const hours = [...Array(24).keys()].map(item => `${String(item).padStart(2, '0')}:00`);

export const dividedHours = flatten([...Array(24).keys()].map(item => [
  `${String(item).padStart(2, '0')}:00`,
  `${String(item).padStart(2, '0')}:15`,
  `${String(item).padStart(2, '0')}:30`,
  `${String(item).padStart(2, '0')}:45`,
]));

export const periodicity = ['15 min', '30 min', '1 hora'];
