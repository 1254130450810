/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 14:01:06 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-06-16 23:54:11
 */
import i18n from 'i18next';

const validations = () => ({
  name: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.name.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  email: [
    { validate: 'email', message: i18n.t('invalid_email') },
    {
      validate: (_, form) => form.email.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  phoneNumber: [
    {
      validate: (_, form) => /^$|^\d{10}$/.test(form.phoneNumber),
      message: i18n.t('10_digits_validation'),
      custom: true,
    },
  ],
  branchOfficeId: [
    { validate: 'required', message: i18n.t('office_required') },
  ],
  userId: [
    { validate: 'required', message: i18n.t('user_required') },
  ],
});

export default validations;
