/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-24 12:11:50 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-10-23 16:33:23
 */
import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ChecklistBuilder, ChecklistShow, ErrorText } from '../../index';

const RenderChecklistCell = ({
  record, checklist, onChange, error, errorMessage, required, evaluations,
}) => {
  const { t } = useTranslation();
  return (
    record.editable ?
      <div>
        <div className="inputContainer">
          {required &&
          <Tooltip title={t('field_required')} placement="topLeft">
            <span className="inputContainer__span">*</span>
          </Tooltip>}
          <ChecklistBuilder
            value={checklist}
            created={checklist}
            onChange={onChange}
            evaluations={evaluations}
          />
        </div>
        <ErrorText message={error && errorMessage} />
      </div>
      : <ChecklistShow key={record.id} created={checklist} />
  );
        };

export default RenderChecklistCell;
