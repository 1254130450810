/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-27 12:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-25 14:11:36
 */
import React from 'react';
import { Row, Col, Icon, Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { SelectData } from '../../../../common/components/widgets';

const { Search } = Input;


const HeaderContent = ({
  branchOffices, roles, creating,
  selectedBranchOffice, selectedRole,
  onChangeBranchOffice, onChangeRole, onCreate,
  onChangeName, onSearchName, name,

}) => {
  const { t } = useTranslation();

  return (
    <div className="headerContainer">
      <Row className="filtersRow">
        <Col xs={24} sm={12} md={12} lg={8} xl={8} className="filter-box">
          <h1 className="textBranch">
            {t('name')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <Search
            className="select-data"
            value={name}
            onChange={onChangeName}
            onSearch={onSearchName}
            enterButton
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} className="filter-box">
          <h1 className="textBranch">
            {t('branch_office')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <SelectData
            data={[{ name: t('all_plural'), id: '' }, ...branchOffices]}
            selected={selectedBranchOffice || { name: t('all_plural'), id: '' }}
            onSelect={onChangeBranchOffice}
            className="select-data"
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={8} xl={8} className="filter-box">
          <h1 className="textBranch">
            {t('role')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <SelectData
            data={[{ name: t('all_plural'), id: '' }, ...roles]}
            selected={selectedRole || { name: t('all_plural'), id: '' }}
            onSelect={onChangeRole}
            className="select-data"
          />
        </Col>
      </Row>
      <Row>
        <Button type="primary" onClick={onCreate} disabled={creating}>
          {t('create_nonScheduled_activity')}
        </Button>
      </Row>
    </div>
  );
};

export { HeaderContent };

