/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:46:03 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-02-08 17:46:18
 */
const CONTAINER_KEY = 'TASKS_CONTAINER';

export const REGISTER_TASK = `${CONTAINER_KEY}_REGISTER_TASK`;
export const UPDATE_TASK = `${CONTAINER_KEY}_UPDATE_TASK`;
export const DELETE_TASK = `${CONTAINER_KEY}_DELETE_TASK`;
export const TOGGLE_MODAL = `${CONTAINER_KEY}_TOGGLE_MODAL`;
export const TOGGLE_MODAL_TASK_GROUP = `${CONTAINER_KEY}_TOGGLE_MODAL_TASK_GROUP`;
export const RESET_TASK = `${CONTAINER_KEY}_RESET_TASK`;
export const TOGGLE_RULE_FORM = `${CONTAINER_KEY}_TOGGLE_RULE_FORM`;
export const UPDATE_FORM = `${CONTAINER_KEY}_UPDATE_FORM`;
export const REGISTER_TASK_GROUP = `${CONTAINER_KEY}_REGISTER_TASK_GROUP`;
export const UPDATE_TASK_GROUP = `${CONTAINER_KEY}_UPDATE_TASK_GROUP`;
export const RESET_TASK_GROUP_FORM = `${CONTAINER_KEY}_RESET_TASK_GROUP_FORM`;
export const DELETE_TASK_GROUP = `${CONTAINER_KEY}_DELETE_TASK_GROUP`;
