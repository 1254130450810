/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-24 10:53:46 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-24 17:27:28
 */

const CONTAINER_KEY = 'ACTIVITY_FORM_CONTAINER';

export const LOAD_RULE = `${CONTAINER_KEY}_LOAD_RULE`;
export const UPDATE_FORM = `${CONTAINER_KEY}_UPDATE_FORM`;
export const CHANGE_STEP = `${CONTAINER_KEY}_CHANGE_STEP`;
export const INSERT_TASK = `${CONTAINER_KEY}_INSERT_TASK`;
export const UPDATE_COLLABORATIVE = `${CONTAINER_KEY}_UPDATE_COLLABORATIVE`;
export const UPDATE_SELECTED_USERS = `${CONTAINER_KEY}_UPDATE_SELECTED_USERS`;
