import React, { Component } from 'react';
import Viz from 'viz.js';
import svgPanZoom from 'svg-pan-zoom';
import DOMPurify from 'dompurify';
import RuleParser from './rulesParser';
import './styles.css';

const { Module, render } = require('viz.js/full.render.js');

class Graphviz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      version: 1,
      graph: null,
    };

    this.load();
  }

  async load() {
    const { version } = this.state;
    const viz = new Viz({ Module, render });
    let dotString;

    switch (version) {
      case 2:
        dotString = RuleParser.rulesToDotStringV2(this.props.data);
        break;
      default:
        dotString = RuleParser.rulesToDotString(this.props.data);
    }

    await viz.renderSVGElement(dotString)
      .then((result) => {
        this.setState({ graph: result }, () => {
          svgPanZoom('#output svg', {
            controlIconsEnabled: true,
            zoomScaleSensitivity: 0.5,
          });
        });
      })
      .catch(() => {});
  }

  toggleVersion = () => {
    const { version } = this.state;
    this.setState({ version: version === 1 ? 2 : 1 }, this.load);
  }

  render() {
    return (
      <div>
        <div
          id="output"
          style={{ maxWidth: '100%', height: '500px' }}
          dangerouslySetInnerHTML={{ // eslint-disable-line
            __html: DOMPurify.sanitize(this.state.graph),
          }}
        />
        <button
          onClick={this.toggleVersion}
          className="button-exchange"
        >
          <i className="fa fa-exchange" aria-hidden="true" />
        </button>
      </div>
    );
  }
}

export default Graphviz;
