/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:28:14
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2022-06-30 19:53:27
 */
let gatewayUrl;
let webUrl;
let conektaKey;
let apiDocUrl;

switch (process.env.REACT_APP_STAGE) {
  case 'test':
    gatewayUrl = 'http://taskit.dal.togglebox.site';
    webUrl = 'http://taskit.dal.togglebox.site';
    apiDocUrl = 'http://taskit.dal.togglebox.site/rules-service/swagger-ui.html';
    conektaKey = 'key_FLyNJUVoeFNnVU8cY8cRdyw';
    break;
  case 'production':
    gatewayUrl = 'https://web.taskit.com.mx';
    webUrl = 'https://web.taskit.com.mx';
    apiDocUrl = 'https://web.taskit.com.mx/rules-service/swagger-ui.html';
    conektaKey = 'key_UrXgvkDnSeLPQ9eWfrSsXqQ';
    break;
  case 'dev':
    gatewayUrl = 'http://172.20.0.100:8765';
    webUrl = 'http://localhost:3000';
    apiDocUrl = 'http://localhost:8200/swagger-ui.html';
    conektaKey = 'key_FLyNJUVoeFNnVU8cY8cRdyw';
    break;
  default:
    gatewayUrl = 'http://localhost:8765';
    webUrl = 'http://localhost:3000';
    apiDocUrl = 'http://localhost:8200/swagger-ui.html';
    conektaKey = 'key_FLyNJUVoeFNnVU8cY8cRdyw';
    break;
}

export const CONEKTA_KEY = conektaKey;
export const GATEWAY_API_URL = gatewayUrl;
export const WEB_URL = webUrl;
export const API_DOC_URL = apiDocUrl;
export const RULES_SERVICE_KEY = 'rules-service';
export const CORE_SERVICE_KEY = 'core-service';
export const FILES_SERVICE_KEY = 'files-service';
export const AUTHENTICATION_SERVICE_KEY = 'authentication-service';
export const PAYMENT_SERVICE_KEY = 'payment-service';
export const MAIL_SERVICE_KEY = 'mail-service';
