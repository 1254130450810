import React, { Component } from 'react';
import { Button, Row, Col, Icon, Modal } from 'antd';
import QrReader from 'react-qr-reader';
import i18n from 'i18next';
import Text from 'antd/lib/typography/Text';

class QrTask extends Component {
  state = {
    modalVisible: false,
  }

  handleScan = (data) => {
    if (data) {
      this.setState({
        modalVisible: false,
      });
      this.props.onChange(data);
    }
  }

  deleteResult = () => {
    this.props.onChange(null);
  }

  render() {
    const { modalVisible } = this.state;

    return (

       <>
         <Row>
           <Col span={18} >
             <div style={{ width: 426 }}>
               {this.props.value ?
                 <Row type="flex" gutter={16}>
                   <Col>
                     <Text>
                       {this.props.value}
                     </Text>
                   </Col>
                   <Col>
                     <Button icon="delete" type="danger" onClick={() => this.props.onChange(null)} />
                   </Col>
                 </Row>
             :
                 <Button
                   size="large"
                   type="primary"
                   icon="qrcode"
                   style={{ alignSelf: 'center' }}
                   onClick={() => this.setState({ modalVisible: true })}
                 >
                   {i18n.t('scan')}
                 </Button>
             }
             </div>
           </Col>
         </Row>

         {modalVisible &&
         <Modal
           title={i18n.t('capture_qrcode')}
           visible={modalVisible}
           width={500}
           maskClosable={false}
           className="modal-header-dark"
           style={{ top: 20 }}
           maskStyle={{ backgroundColor: '#00000066' }}
           onCancel={() => this.setState({ modalVisible: false })}
           footer={null}
         >
           <Row>
             <Col span={18} >
               <div style={{ width: 426 }}>
                 {this.props.value ?
                   <div >
                     <p>{this.props.value}</p>
                   </div>
             :
                   <div>
                     <QrReader
                       delay={300}
                      //  onError={this.handleError}
                       onScan={this.handleScan}
                       style={{ width: '100%' }}
                     />

                   </div>
             }
               </div>
             </Col>
             <Col span={6} style={{ textAlign: 'center' }}>
               {this.props.value &&
               <Button type="danger" onClick={this.deleteResult}>
                 <Icon type="delete"> Delete </Icon>
               </Button>
                }
             </Col>
           </Row>
         </Modal>
            }


     </>
    );
  }
}

export default QrTask;

