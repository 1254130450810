/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-10 01:20:52 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-07-10 01:26:25
 */
import axios from 'axios';
import Service from '../Service';
import { createCoreServiceUrl } from '../../common/helpers/service';


class StateService extends Service {
    getAll = () => axios.get(createCoreServiceUrl('states')).then(data => data.data);
}

export default StateService;
