/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 14:01:06 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-01-24 12:58:12
 */

import i18n from 'i18next';

const allRequired = () => ({
  name: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.name.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  lastName: [
    { validate: 'required', message: i18n.t('lastnames_required') },
    {
      validate: (_, form) => form.lastName.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  email: [
    { validate: 'required', message: i18n.t('required_email') },
    { validate: 'email', message: i18n.t('invalid_email') },
    {
      validate: (_, form) => form.email.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
});

const notRoleAndBranch = () => ({
  name: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.name.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  lastName: [
    { validate: 'required', message: i18n.t('lastnames_required') },
    {
      validate: (_, form) => form.lastName.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  email: [
    { validate: 'required', message: i18n.t('required_email') },
    { validate: 'email', message: i18n.t('invalid_email') },
    {
      validate: (_, form) => form.email.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
});

export { allRequired, notRoleAndBranch };
