/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-11 01:08:55
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-24 10:30:42
 */
import { Table, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ChecklistShow, GeneralButton as Buttons } from '../../../common/components/widgets';
import { getRemainingTime } from './../util';

const Scheduled = ({ t, rules }) => {
  const getColumns = () => [
    {
      title: t('name'),
      dataIndex: 'name',
      onCell: () => ({ 'data-label': t('name') }),
      width: '10%',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      width: '15%',
      onCell: () => ({ 'data-label': t('description') }),
      ellipsis: true,
      render: text => (
        <Tooltip title={text}>
          <div>{text}</div>
        </Tooltip>
     ),
    },
    {
      title: t('tasks'),
      dataIndex: 'checklist',
      width: '10%',
      onCell: () => ({ 'data-label': t('tasks') }),
      render: (text, record) => (
        <ChecklistShow key={record.id} created={record.checklist} />
      ),
    },
    {
      title: t('allotted_time'),
      width: '10%',
      onCell: () => ({ 'data-label': t('allotted_time') }),
      render: (text, record) => (
        <>
          {
            getRemainingTime(record)
          }
        </>
      ),
    },
    {
      title: t('actions'),
      width: '10%',
      onCell: () => ({ 'data-label': t('actions') }),
      render: (text, record) => (
        <Link to={`/web-form-execution/${record.id}`}>
          <Buttons
            name={t('run')}
            type="default"
          />
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        className="responsive-table"
        dataSource={rules}
        columns={getColumns()}
        locale={{ emptyText: t('show_no_data') }}
      />
    </div>
  );
};

export default Scheduled;
