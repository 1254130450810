/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 14:01:06 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-08-08 11:55:50
 */

import i18n from 'i18next';

const validations = () => ({
  name: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.name.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  type: [
    { validate: 'required', message: i18n.t('required_type') },
    {
      validate: (_, form) => form.type.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  enumValues: [
    {
      validate: (_, form) => form.type !== 'enum' || form.enumValues.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  evidenceType: [
    { validate: 'required', message: i18n.t('evidence_type_required') },
    {
      validate: (_, form) => form.evidenceType.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
});


export default validations;
