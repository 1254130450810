/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-08 17:10:47 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-25 18:18:07
 */
import { createReducer } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  SEARCH_USERS,
  SEARCH_USERS_FULFILLED,
} from '../../../store/general/types';
import {
  REGISTER_RULE,
  UPDATE_RULE,
  DELETE_RULE,
} from './types';
import * as generalActions from '../../../store/general/actions';


const defaultState = {
  reducerKey: 'rulesContainer',
  currentPage: 1,
  searchedUsers: [],
  fetchingSelectData: false,
};

// Actions
// Payload { form, defaultActions, navigate }
const registerRule = (form, filters) => ({ type: REGISTER_RULE, form, filters });
const updateRule = (form, filters) => ({ type: UPDATE_RULE, form, filters });
const deleteRule = (payload, filters) => ({ type: DELETE_RULE, payload, filters });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [SEARCH_USERS]: state => ({ ...state, fetchingSelectData: true }),
  [SEARCH_USERS_FULFILLED]: (state, payload) => ({
    ...state,
    searchedUsers: payload,
    fetchingSelectData: false,
  }),
});

const actions = {
  ...defaultActions,
  ...generalActions,
  registerRule,
  updateRule,
  deleteRule,
};

export { reducer, actions, epics, defaultActions };
