/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:34:07
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-10 15:52:31
 */
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { MainLayout } from '../common/components/layout';

import { isAuthenticated, isAllowed } from '../security/index';
// Main Application Routes
import Dashboard from './dashboard';
// import Account from './account';
import RulesCalendar from './calendar';
import TaskitProcesses from './taskitProcesses';
import TaskitProcess from './taskitProcesses/taskitProcess';
import Evidences from './evidences';
import TasksEvidences from './tasksEvidences';
import TasksTimeline from './tasksTimeline';
import Roles from './roles';
import Login from './auth/login';
import Register from './auth/register';
import RegisterPassword from './auth/register/activate';
import Users from './users';
import BranchOffices from './branchOffices';
import ResetPassword from './auth/resetPassword';
import ResetPasswordForm from './auth/resetPassword/resetPasswordForm';
import Page404 from '../common/components/layout/Page404';
import Page403 from '../common/components/layout/Page403';
import Subscriptions from './subscriptions';
import Orders from './orders';
import termsConditions from '../common/components/layout/termsConditions';
import Payments from './payments';
import Tasks from './tasks';
import Evaluations from './evaluations';
import Companies from './companies';
import Contacts from './contacts';
import WebActivity from './webActivity';
import ChartViewer from './chartViewer';
import ExecutionActivities from './executionActivities';
import Notifications from './notifications';


export const menu = () => [
  {
    key: 'home',
    path: '/',
    component: Dashboard,
    icon: 'desktop',
    title: i18n.t('dashboard'),
  },
  {
    key: 'executionActivities',
    path: '/executionActivities',
    component: Evaluations,
    icon: 'schedule',
    title: i18n.t('execution_of_activities'),
    permissions: ['can_edit_catalogs'],
  // Temporal commented
  // {
  //   key: 'executionActivities',
  //   path: '/executionActivities',
  //   component: Evaluations,
  //   icon: 'schedule',
  //   title: i18n.t('execution_of_activities'),
  //   permissions: ['can_edit_catalogs'],
  // },
  },
  {
    key: 'notifications',
    path: '/notifications',
    component: Notifications,
    icon: 'bell',
    title: i18n.t('notifications'),
  },
  {
    key: 'taskitProcesses',
    path: '/taskitProcesses',
    component: TaskitProcesses,
    icon: 'sync',
    title: i18n.t('taskitProcesses'),
  },
  {
    key: 'evidences',
    path: '/evidences',
    component: Evidences,
    icon: 'eye',
    title: i18n.t('evidences'),
  },
  {
    key: 'calendar',
    path: '/calendar',
    component: RulesCalendar,
    icon: 'check-square-o',
    title: i18n.t('activities'),
    permissions: ['can_edit_catalogs', 'can_create_and_edit_activities_tasks_and_evaluations'],
  },
  {
    key: 'tasks',
    path: '/tasks',
    component: Tasks,
    icon: 'filter',
    title: i18n.t('tasks'),
    permissions: ['can_edit_catalogs', 'can_create_and_edit_activities_tasks_and_evaluations'],
  },
  {
    key: 'users',
    path: '/users',
    component: Users,
    icon: 'usergroup-add',
    title: i18n.t('users'),
    permissions: ['can_create_and_edit_users'],
  },
  {
    key: 'branchOffices',
    path: '/branchOffices',
    component: BranchOffices,
    icon: 'shop',
    title: i18n.t('branch_offices'),
    permissions: ['can_edit_catalogs'],
  },
  {
    key: 'roles',
    path: '/roles',
    component: Roles,
    icon: 'solution',
    title: i18n.t('roles'),
    permissions: ['can_edit_catalogs'],
  },
  {
    key: 'contacts',
    path: '/contacts',
    component: Contacts,
    icon: 'contacts',
    title: i18n.t('contacts'),
  },
  {
    key: 'evaluations',
    path: '/evaluations',
    component: Evaluations,
    icon: 'like',
    title: i18n.t('evaluations'),
    permissions: ['can_edit_catalogs', 'can_create_and_edit_activities_tasks_and_evaluations'],
  },
  {
    key: 'subscriptions',
    path: '/subscriptions',
    component: Subscriptions,
    icon: 'idcard',
    title: i18n.t('subscriptions'),
    permissions: ['can_edit_catalogs'],
  },
  {
    key: 'orders',
    path: '/orders',
    component: Orders,
    icon: 'credit-card',
    title: 'Mis pagos',
    permissions: ['can_edit_catalogs'],
  },
  {
    key: 'companies',
    path: '/companies',
    component: Companies,
    icon: 'home',
    title: i18n.t('companies'),
  },
];

const MainLayoutRoute = ({
  component: Component,
  contentStyle,
  permissions,
  ...rest
}) => (
  <Route
    {...rest}
    render={(matchProps) => {
      if (!isAuthenticated()) {
        return <Redirect to={{ pathname: '/auth/login' }} />;
      }

      if (permissions && !isAllowed(permissions)) {
        return <Redirect to={{ pathname: '/403' }} />;
      }

      return (
        <Suspense fallback="loading">
          <MainLayout title={rest.title} contentStyle={contentStyle}>
            <Component {...matchProps} />
          </MainLayout>
        </Suspense>
      );
    }}
  />
);

export default () => {
  const { t } = useTranslation();

  return (
    <Suspense fallback="loading">
      <BrowserRouter>
        <Switch>
          <Route exact path="/auth/login" component={Login} />
          <Route exact path="/auth/activate" component={RegisterPassword} />
          <Route exact path="/auth/register" component={Register} />
          <Route exact path="/auth/reset-password" component={ResetPassword} />
          <Route exact path="/auth/form-reset-password" component={ResetPasswordForm} />
          <Route exact path="/terms-conditions" component={termsConditions} />

          <Route
            exact
            path="/evidences/:id/timeline"
            render={matchProps => (
              <MainLayout title="Timeline" contentStyle={{ background: '#f0f2f5' }}>
                <TasksTimeline {...matchProps} />
              </MainLayout>
            )}
          />

          <Route
            exact
            path="/evidences/:id"
            render={matchProps => (
              <MainLayout title={t('tasks')} contentStyle={{ background: '#f0f2f5' }}>
                <TasksEvidences {...matchProps} />
              </MainLayout>
            )}
          />

          <Route
            exact
            path="/web-form/:id"
            render={matchProps => (
              <MainLayout title={t('web_activity')} contentStyle={{ background: '#f0f2f5' }}>
                <WebActivity {...matchProps} />
              </MainLayout>
            )}
          />

          <Route
            exact
            path="/web-form-execution/:id"
            render={matchProps => (
              <MainLayout title={t('web_activity')} contentStyle={{ background: '#f0f2f5' }}>
                <WebActivity {...matchProps} isRuleExecution />
              </MainLayout>
            )}
          />

          <MainLayoutRoute
            exact
            path="/evidences"
            component={Evidences}
            title={t('evidences')}
            contentStyle={{ background: '#f0f2f5', padding: '0px', margin: '20px' }}
          />
          <MainLayoutRoute exact path="/calendar" component={RulesCalendar} title={t('activities')} permissions={['can_edit_catalogs', 'can_create_and_edit_activities_tasks_and_evaluations']} />
          <MainLayoutRoute exact path="/taskitProcesses" component={TaskitProcesses} title={t('taskitProcesses')} />
          <Route
            exact
            path="/taskitProcesses/:id"
            render={matchProps => (
              <MainLayout title={t('taskitProcesses')} contentStyle={{ background: '#f0f2f5' }}>
                <TaskitProcess {...matchProps} />
              </MainLayout>
            )}
          />
          <MainLayoutRoute exact path="/notifications" component={Notifications} title={t('notifications')} />
          <MainLayoutRoute exact path="/evaluations" component={Evaluations} title={t('evaluations')} permissions={['can_edit_catalogs', 'can_create_and_edit_activities_tasks_and_evaluations']} />
          <MainLayoutRoute exact path="/users" component={Users} title={t('users')} permissions={['can_create_and_edit_users']} />
          <MainLayoutRoute exact path="/branchOffices" component={BranchOffices} title={t('branch_offices')} permissions={['can_edit_catalogs']} />
          <MainLayoutRoute exact path="/roles" component={Roles} title={t('roles')} permissions={['can_edit_catalogs']} />
          <MainLayoutRoute exact path="/tasks" component={Tasks} title={t('tasks')} permissions={['can_edit_catalogs', 'can_create_and_edit_activities_tasks_and_evaluations']} />
          <MainLayoutRoute exact path="/subscriptions" component={Subscriptions} title={t('subscriptions')} permissions={['can_edit_catalogs']} />
          <MainLayoutRoute exact path="/payments" component={Payments} permissions={['can_edit_catalogs']} />
          <MainLayoutRoute exact path="/orders" component={Orders} title="Pagos" permissions={['can_edit_catalogs']} />
          <MainLayoutRoute exact path="/companies" component={Companies} title={t('companies')} />
          <MainLayoutRoute exact path="/contacts" component={Contacts} title={t('contacts')} />
          <MainLayoutRoute exact path="/executionActivities" component={ExecutionActivities} title={t('execution_of_activities')} permissions={['can_edit_catalogs']} />
          <MainLayoutRoute
            exact
            path="/"
            title={t('dashboard')}
            component={Dashboard}
            contentStyle={{ background: '#f0f2f5', padding: '0px', margin: '0px' }}
          />
          <MainLayoutRoute
            exact
            path="/chartViewer"
            title={t('dashboard')}
            component={ChartViewer}
            contentStyle={{ background: '#f0f2f5', padding: '0px', margin: '0px' }}
          />

          <Route exact path="/403" component={Page403} />
          <Route exact path="*" component={Page404} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};
