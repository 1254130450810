/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-27 12:47:00 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-08-24 22:04:43
 */
import React, { useState } from 'react';
import { Row, Col, Button, Input, Select, Icon, Upload } from 'antd';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';

const HeaderContent = ({
  creating,
  onCreate,
  groups,
  selectedGroup,
  onChangeGroup,
  selectedNotificationMode,
  onChangeNotificationFilter,
  searchValue,
  onChangeSearchFilter,
  onSearchFilter,
  resetFilters,
  downloadContactsInCsv,
  onFileChange,
}) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState([]);

  return (
    <div className="headerContainer">
      <Row type="flex" align="bottom">
        <Col xs={24} sm={12} md={12} lg={12} xl={5} className="filter-box">
          <h1 className="textBranch">{t('search_contact')}</h1>
          <Input.Search
            className="select-data"
            value={searchValue}
            onChange={onChangeSearchFilter}
            onSearch={onSearchFilter}
            enterButton
          />
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={5} className="filter-box">
          <h1 className="textBranch">
            Grupo <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <Select
            value={selectedGroup}
            className="select-data"
            onChange={onChangeGroup}
          >
            <Select.Option value={null}>{t('all_groups')}</Select.Option>
            <Select.Option value="none" style={{ borderBottom: '1px solid #E0E0E0' }}>{t('contacts_without_group')}</Select.Option>
            {groups.map(i => <Select.Option key={i.id} value={i.id}>{i.name}</Select.Option>)}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} className="filter-box">
          <h1 className="textBranch">
            {t('notification_mode')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <Select
            value={selectedNotificationMode}
            className="select-data"
            onChange={onChangeNotificationFilter}
          >
            <Select.Option value="all">{t('all_modes')}</Select.Option>
            <Select.Option value="whatsapp">{t('receive_whatsapp_notification')}</Select.Option>
            <Select.Option value="email">{t('receive_email_notification')}</Select.Option>
            <Select.Option value="both">{t('receive_both_notifications')}</Select.Option>
            <Select.Option value="none">{t('receive_no_notification')}</Select.Option>
          </Select>
        </Col>
        <Col xs={24} sm={12} md={7} lg={7} xl={4} className="filter-box">
          <Button type="link" icon="filter" onClick={resetFilters}>
            {t('clean_filters')}
          </Button>
        </Col>
        <Col xs={24} sm={24} md={5} lg={5} xl={4} className="filter-box">
          <Button type="primary" onClick={onCreate} disabled={creating}>{t('new_contact')}</Button>
        </Col>
      </Row>
      <Row>
        <Text style={{ marginRight: 20 }}>
          CSV
        </Text>
        <Button onClick={() => downloadContactsInCsv()} style={{ marginRight: 20 }} >
          <Icon type="download" />
          {t('download')}
        </Button>
        <Upload
          fileList={fileList}
          customRequest={({ file }) => {
            onFileChange(file);
            setFileList([]);
          }}
          style={{ marginRight: 20 }}
        >
          <Button title={t('contacts_csv_specification')}>
            <Icon type="upload" />
            {t('upload')}
          </Button>
        </Upload>
      </Row>
    </div>
  );
  };

export { HeaderContent };
