import i18n from 'i18next';
import { TaskTypes } from './constants';


export const evidenceTypeLabel = (evidenceType) => {
  switch (evidenceType) {
    case 'required': return i18n.t('required_fem');
    case 'optional': return i18n.t('optional');
    default: return '';
  }
};

export const taskTypeLabel = (taskType) => {
  switch (taskType) {
    case TaskTypes.CHECK: return i18n.t('check');
    case TaskTypes.ENUM: return i18n.t('numbering');
    case TaskTypes.MULTI_ENUM: return i18n.t('numbering');
    case TaskTypes.TEXT: return i18n.t('text');
    case TaskTypes.DATE: return i18n.t('date');
    case TaskTypes.TIME: return i18n.t('time');
    case TaskTypes.NUMERIC: return i18n.t('number');
    case TaskTypes.CONTACT: return i18n.t('contact');
    case TaskTypes.FILE: return i18n.t('file');
    case TaskTypes.BARCODE: return i18n.t('qr_code');
    case TaskTypes.PHOTO: return i18n.t('photo');
    case TaskTypes.SIGNATURE: return i18n.t('signature');
    case TaskTypes.RULE_LINK: return i18n.t('link');
    case TaskTypes.RULE_FOLIO: return i18n.t('folio');
    case TaskTypes.USER: return i18n.t('user');
    default: return '';
  }
};

export const getTypeIcon = (taskType) => {
  switch (taskType) {
    case TaskTypes.CHECK: return 'check';
    case TaskTypes.ENUM: return 'unordered-list';
    case TaskTypes.MULTI_ENUM: return 'unordered-list';
    case TaskTypes.TEXT: return 'message';
    case TaskTypes.NUMERIC: return 'number';
    case TaskTypes.DATE: return 'calendar';
    case TaskTypes.TIME: return 'clock-circle';
    case TaskTypes.CONTACT: return 'user';
    case TaskTypes.FILE: return 'file';
    case TaskTypes.BARCODE: return 'qrcode';
    case TaskTypes.PHOTO: return 'camera';
    case TaskTypes.SIGNATURE: return 'highlight';
    case TaskTypes.RULE_LINK: return 'link';
    case TaskTypes.RULE_FOLIO: return 'number';
    case TaskTypes.USER: return 'user';
    default: return '';
  }
};
