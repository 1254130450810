/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-14 01:48:35 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-06-22 13:10:25
 */

import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './index';
import { LOGIN_USER } from './types';
import { setAuthorizationHeader } from '../../../../services/axiosConfig';
import UserService from '../../../../services/user';
import { Notification } from '../../../../common/components/widgets';
import { parseApiError } from '../../../../common/error';
import { saveTokenData, saveUserData, isAllowed } from '../../../../security';

const userService = new UserService();

const loginSuccessAction = (user) => {
  Notification.success(`${i18n.t('welcome_gender')} ${user.name}`, '', 2);
  return { type: '' };
};

const validatePermissions = (user) => {
  if (!isAllowed(['can_access_web_platform'], user)) {
    throw new Error(i18n.t('user_restricted_web'));
  }
};

const loginUserEpic = action$ => action$.ofType(LOGIN_USER).flatMap(action => Observable.concat(
  Observable.of(actions.loading(true)),
  Observable.fromPromise(userService.login(action.payload.form)).flatMap((tokenData) => {
    setAuthorizationHeader(tokenData.access_token);
    saveTokenData(tokenData);

    return Observable.fromPromise(userService.getLoggedUser()).flatMap((user) => {
      validatePermissions(user);
      saveUserData(user);

      return Observable.concat(
        Observable.of(actions.loading(false)),
        Observable.of(actions.resetState()),
        Observable.of(actions.loginUserSuccess(false)),
        Observable.of(actions.showMessage(() =>
          loginSuccessAction(user))),
      ).do({
        complete: () => {
          action.payload.navigate();
        },
      });
    });
  }).catch(error => Observable.concat(
    Observable.of(actions.loginUserSuccess(false)),
    Observable.of(actions.loading(false)),
    Observable.of(actions.showMessage(() => {
      const parsedError = parseApiError(error);
      Notification.error(i18n.t('log_in_fail'), parsedError.message);
    })),
  )),
));

export default combineEpics(loginUserEpic);
