/*
 * @Author: Misael Jiménez 
 * @Date: 2020-07-09 17:17:38 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-03-24 18:42:41
 */

import React from 'react';
import { Row, Col, Select, Input, Avatar, Button, Icon, Upload, Tabs } from 'antd';
import { withTranslation } from 'react-i18next';
import { getFieldError } from 'aleonor-object-validations';
import Text from 'antd/lib/typography/Text';
import { getImageUrl } from '../../../helpers/images';

import ErrorText from '../errorText';

const { TabPane } = Tabs;

class EditProfileForm extends React.Component {
  componentDidMount = () => {
    this.props.fetchActions();
  }

  render() {
    const {
      user,
      onChangeField,
      formErrors,
      uploadImage,
      uploadCompanyLogoImage,
      uploadCompanyHeaderImage,
      currenLanguage,
      currentUser,
      onChangeLanguage,
      t,
      onSave,
    } = this.props;

    return (
      <div>
        <Tabs defaultActiveKey="profilePicture">
          <TabPane tab={t('profile_picture')} key="profilePicture">
            <Row className="form-section">
              <div className="form-title">{t('profile_picture')}</div>
              <Col sm={24} lg={24} className="form-input" >
                <Row type="flex" justify="center">
                  <Avatar icon="user" src={getImageUrl(user.profileImageUrl)} size={140} />
                </Row>
                <Row type="flex" justify="center" className="actions-container">
                  <Upload
                    showUploadList={false}
                    customRequest={uploadImage}
                  >
                    <Button type="link"><Icon type="upload" style={{ fontSize: 18 }} /> {t('change_photo')}</Button>
                  </Upload>
                </Row>
              </Col>
            </Row>
          </TabPane>
          {currentUser.owner &&
            <TabPane tab={t('company_logo')} key="companyLogo">
              <Row className="form-section">
                <div className="form-title">{t('company_logo')}</div>
                <Col sm={24} lg={24} className="form-input" >
                  <Row type="flex" justify="center">
                    <Avatar icon="picture" src={getImageUrl(currentUser.company.logoImageUrl)} size={100} style={{ width: 400, height: 100, borderRadius: 0 }} />
                  </Row>
                  <Row type="flex" justify="center">
                    <Text type="secondary">{`${t('aspect_ratio')} 4:1`}</Text>
                  </Row>
                  <Row type="flex" justify="center" className="actions-container">
                    <Upload
                      showUploadList={false}
                      customRequest={uploadCompanyLogoImage}
                    >
                      <Button type="link"><Icon type="upload" style={{ fontSize: 18 }} /> {t('change_photo')}</Button>
                    </Upload>
                  </Row>
                </Col>
              </Row>
            </TabPane>
          }
          {currentUser.owner &&
            <TabPane tab={t('report_header')} key="reportHeader">
              <Row className="form-section">
                <div className="form-title">{t('report_header')}</div>
                <Col sm={24} lg={24} className="form-input" >
                  <Row type="flex" justify="center">
                    <Avatar icon="picture" src={getImageUrl(currentUser.company.headerImageUrl)} size={40} style={{ width: 612, height: 36, borderRadius: 0 }} />
                  </Row>
                  <Row type="flex" justify="center">
                    <Text type="secondary">{`${t('aspect_ratio')} 17:1`}</Text>
                  </Row>
                  <Row type="flex" justify="center" className="actions-container">
                    <Upload
                      showUploadList={false}
                      customRequest={uploadCompanyHeaderImage}
                    >
                      <Button type="link"><Icon type="upload" style={{ fontSize: 18 }} /> {t('change_photo')}</Button>
                    </Upload>
                  </Row>
                </Col>
              </Row>
            </TabPane>
          }
          <TabPane tab={t('personal_information')} key="personalInformation">
            <Row className="form-section">
              <div className="form-title">{t('personal_information')}</div>
              <Col sm={24} lg={24} className="form-input">
                <Row>
                  <Col sm={24} className="form-input">
                    <div className="form-label">{t('names')}</div>
                    <Input
                      value={user.name}
                      onChange={e => onChangeField('name', e.target.value)}
                    />
                    <ErrorText message={getFieldError(formErrors, 'name')} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="form-input">
                    <div className="form-label">{t('lastnames')}</div>
                    <Input
                      value={user.lastName}
                      onChange={e => onChangeField('lastName', e.target.value)}
                    />
                    <ErrorText message={getFieldError(formErrors, 'lastName')} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} className="form-input">
                    <div className="form-label">{t('email_long')}</div>
                    <Input
                      value={user.email}
                      onChange={e => onChangeField('email', e.target.value)}
                    />
                    <ErrorText message={getFieldError(formErrors, 'email')} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={24} md={24} style={{ paddingTop: '20px' }}>
                    <Button
                      type="primary"
                      style={{ float: 'right' }}
                      onClick={() => onSave('personal_information')}
                    >
                      {t('save_changes')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={t('change_password')} key="password">
            <Row className="form-section ">
              <div className="form-title">{t('change_password')}</div>
              <Row>
                <Col sm={24} lg={12} className="form-input">
                  <div className="form-label">{t('enter_current_password')}</div>
                  <Input
                    value={user.password}
                    onChange={e => onChangeField('password', e.target.value)}
                    type="password"
                    maxLength={30}
                  />
                  <ErrorText message={getFieldError(formErrors, 'password')} />
                </Col>
              </Row>
              <Row>
                <Col sm={24} lg={12} className="form-input">
                  <div className="form-label">{t('enter_new_password')}</div>
                  <Input
                    value={user.newPassword}
                    onChange={e => onChangeField('newPassword', e.target.value)}
                    type="password"
                    maxLength={30}
                  />
                  <ErrorText message={getFieldError(formErrors, 'newPassword')} />
                </Col>
                <Col sm={24} lg={12} className="form-input">
                  <div className="form-label">{t('enter_new_password_confirm')}</div>
                  <Input
                    value={user.newPasswordConfirm}
                    onChange={e => onChangeField('newPasswordConfirm', e.target.value)}
                    type="password"
                    maxLength={30}
                  />
                  <ErrorText message={getFieldError(formErrors, 'newPasswordConfirm')} />
                </Col>
              </Row>
              <Row>
                <Col sm={24} md={24} style={{ paddingTop: '20px' }}>
                  <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => onSave('password')}
                  >
                    {t('save_changes')}
                  </Button>
                </Col>
              </Row>
            </Row>
          </TabPane>
          <TabPane tab={t('language')} key="language">
            <Row className="form-section">
              <div className="form-title">{t('language')}</div>
              <Row>
                <Col sm={24} md={12}>
                  <div className="form-label">
                    {t('select_language')}
                  </div>
                  <Select
                    value={currenLanguage}
                    style={{ width: '100%', paddingRight: '15px' }}
                    onChange={onChangeLanguage}
                  >
                    <Select.Option key="en" value="en">
                      {t('english')}
                    </Select.Option>
                    <Select.Option key="en" value="es">
                      {t('spanish')}
                    </Select.Option>
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col sm={24} md={24} style={{ paddingTop: '20px' }}>
                  <Button
                    type="primary"
                    style={{ float: 'right' }}
                    onClick={() => onSave('language')}
                  >
                    {t('save_changes')}
                  </Button>
                </Col>
              </Row>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withTranslation()(EditProfileForm);
