/*
 * @Author: Misael Jimenez 
 * @Date: 2021-02-08 17:45:39 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-02-11 23:39:32
 */

import React from 'react';
import { Row, Col, Button, Collapse, Select, Icon, Alert, Modal, Popconfirm, Input, Radio } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getFieldError } from 'aleonor-object-validations';
import Text from 'antd/lib/typography/Text';

/* import validations from './validations'; */
import { RuleTypes, TaskTypes, EvidenceTypes } from '../../../constants';
import { getTypeIcon } from '../../../labels';
import { ErrorText, Notification, TaskForm } from '../../widgets';
import ConservarSVG from '../../../../assets/icons/conservar';
import RastreoSVG from '../../../../assets/icons/rastreo';


const reorder = (checklist, startIndex, endIndex) => {
  const sortedChecklist = checklist.sort((a, b) => a.position - b.position);

  const [removed] = sortedChecklist.splice(startIndex, 1);
  sortedChecklist.splice(endIndex, 0, removed);

  return sortedChecklist.map((t, index) => ({ ...t, position: index }));
};

export default class TasksGroupForm extends React.Component {
  constructor(props) {
    super(props);
    /* this.validator = init(validations()); */
    this.state = {
      formErrors: null,
      searchRule: null,
    };
  }

  componentDidMount = () => {
    /* this.fetchRulesByName(); */
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const orderedChecklist = reorder(
      this.props.form.checklist,
      result.source.index,
      result.destination.index,
    );

    this.props.updateState({ path: 'form.checklist', value: orderedChecklist });
  }

  onChangeTask = (taskIndex, prop, value) => {
    const {
      form: { checklist },
      updateState,
      t,
    } = this.props;
    let { tracking, evidenceType, descriptionEdited = false } = checklist[taskIndex];

    if (prop === 'type' && value === TaskTypes.RULE_FOLIO &&
      checklist.findIndex(tsk => tsk.type === TaskTypes.RULE_FOLIO) >= 0) {
      Notification.error(t('task_folio_duplicated'), t('task_folio_duplicated_desc'));
      return;
    }

    if (prop === 'type' && value !== TaskTypes.TEXT &&
      value !== TaskTypes.BARCODE && value !== TaskTypes.NUMERIC) {
      tracking = false;
    }

    if (prop === 'type' && value === TaskTypes.CHECK) {
      evidenceType = EvidenceTypes.OPTIONAL;
    }

    if (prop === 'evidenceType') {
      evidenceType = value;
    }

    if (prop === 'tracking') {
      tracking = value;
    }

    if (prop === 'description') {
      descriptionEdited = true;
    }

    const updatedChecklist = checklist.map((i, index) => {
      if (index === taskIndex) {
        return {
          ...i,
          [prop]: value,
          evidenceType,
          tracking,
          descriptionEdited,
        };
      }
      return i;
    });

    updateState({ path: 'form.checklist', value: updatedChecklist });
  };

  onSearchRules = (value) => {
    this.setState({ searchRule: value });
    clearTimeout(this.timer);
    if (value) {
      this.timer = setTimeout(() => this.fetchRulesByName({
        name: this.state.searchRule,
      }), 250);
    } else { this.fetchRulesByName(); }
  }

  onBlurRule = () => {
    this.fetchRulesByName();
  }

  onSelectTask = (values) => {
    const {
      form: { checklist }, tasksData, updateForm, t,
    } = this.props;
    const task = tasksData.find(i => i.id === values[0]);
    if (task.type === TaskTypes.RULE_FOLIO &&
        checklist.findIndex(tsk => tsk.type === TaskTypes.RULE_FOLIO) >= 0) {
      Notification.error(t('task_folio_duplicated'), t('task_folio_duplicated_desc'));
      return;
    }
    let displayId = 1;
    if (checklist.find(i => i.id === values[0])) {
      displayId = checklist.reduce((prev, current, currentIndex) => {
        if (current.id === values[0]) {
          if (current.displayId && current.displayId >= prev) {
            return current.displayId + 1;
          } else if (current.displayId === undefined) {
            checklist[currentIndex].displayId = prev;
            return prev + 1;
          }
        }
          return prev;
      }, displayId);
    }

    const updatedChecklist = [
      {
        ...task,
        name: `${task.name}${displayId > 1 ? ` ${displayId}` : ''}`,
        displayId,
        position: 0,
      },
      ...checklist,
    ];
    updateForm({ prop: 'checklist', value: updatedChecklist });
  }

  getEvaluationIcon = (type) => {
    switch (type) {
      case 'graphic': return 'smile';
      case 'enum': return 'unordered-list';
      case 'range': return 'column-width';
      default: return 'line';
    }
  }

  getButtons = () => {
    const { t, onCancel } = this.props;
    return [
      <Popconfirm
        key="cancel"
        title={t('cancel_entry_confirmation')}
        cancelText={t('no')}
        okText={t('yes')}
        onConfirm={onCancel}
      >
        <Button>{t('cancel')}</Button>
      </Popconfirm>,
      <Button
        key="save-button"
        type="primary"
        onClick={() => this.props.onSave()}
      >
        { t('save_group') }
      </Button>,
    ];
  }

  deleteTask = (taskIndex) => {
    const { updateState, form: { checklist } } = this.props;
    const updatedChecklist = checklist.filter((i, index) => index !== taskIndex);
    updateState({ path: 'form.checklist', value: updatedChecklist });
  }

  fetchRulesByName = (params) => {
    const { fetchRulesByName, catalogs: { rules: { size } } } = this.props;
    fetchRulesByName({
      page: 0,
      size,
      ...params,
    });
  }

  renderTask = (taskIndex, task) => {
    const { form, form: { checklist }, catalogs: { rules: { content: rules } } } = this.props;
    const { evaluationsData, fromTaskitProcesses } = this.props;
    const readOnly = !!task.id;
    const canBeTracked = task.type === TaskTypes.TEXT ||
      task.type === TaskTypes.BARCODE || task.type === TaskTypes.NUMERIC;

    const disableFolioType = checklist.findIndex(t => t.type === TaskTypes.RULE_FOLIO) >= 0;

    return (
      <Collapse.Panel
        header={
          <Row>
            <Col sm={24} md={8}>
              {task.name || <span style={{ color: 'darkgray' }}>{this.props.t('new_task')}</span>}
            </Col>
            <Col sm={24} md={12}>
              <Icon type={getTypeIcon(task.type)} className={['collapse-icon', 'vertical-border-right']} />
              <i
                className={`fa fa-asterisk collapse-icon vertical-border-right ${task.evidenceType === 'required' ? '' : 'color-lightgray'}`}
              />
              {
                form.ruleType !== RuleTypes.WEB &&
                <Icon
                  type={this.getEvaluationIcon(task.evaluation && task.evaluation.type)}
                  className={['collapse-icon', 'vertical-border-right', task.evaluation ? '' : 'color-lightgray']}
                />
              }
              {
                canBeTracked &&
                <Icon
                  component={RastreoSVG}
                  className={[
                    'collapse-icon',
                    'vertical-border-right',
                    task.tracking ? '' : 'color-lightgray',
                  ]}
                />
              }
              {
                form.ruleType !== RuleTypes.WEB &&
                <Icon
                  component={ConservarSVG}
                  className={[
                    'collapse-icon',
                    'vertical-border-right',
                    task.keepValue ? '' : 'color-lightgray',
                  ]}
                />
              }
              <Icon
                type="save"
                className={['collapse-icon', (task.id || task.saveTask) ? '' : 'color-lightgray']}
              />
            </Col>
            <Col sm={24} md={4}>
              <Row type="flex" justify="end">
                { readOnly && <div style={{ color: '#8D8D8D' }}>{this.props.t('read_only')}</div> }
                {!fromTaskitProcesses &&
                <Icon
                  type="delete"
                  className="collapse-icon color-gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.deleteTask(taskIndex);
                  }}
                />
                }
              </Row>
            </Col>
          </Row>
        }
        key={taskIndex}
      >
        <div className="form-description">
          {this.props.t('complete_task_form')}
        </div>
        <TaskForm
          task={task}
          readOnly={readOnly}
          taskIndex={taskIndex}
          ruleType={form.ruleType}
          evaluationsData={evaluationsData}
          fromTaskitProcesses={fromTaskitProcesses}
          onChangeField={this.onChangeTask}
          enableTaskSaving={!readOnly}
          rules={rules}
          disableFolioType={disableFolioType}
          onSearchRules={this.onSearchRules}
          onBlurRule={this.onBlurRule}
          fetchRulesByName={this.fetchRulesByName}
          isTaskGroup
        />
        {form.checklist[taskIndex].type === TaskTypes.RULE_LINK &&
          !form.checklist[taskIndex].additionalInfo &&
            <Alert message={this.props.t('activity_not_empty')} type="error" />
        }
      </Collapse.Panel>
    );
  }


  render() {
    const {
      tasksData,
      form,
      activePanel,
      updateState,
      updateForm,
      t,
      visible,

    } = this.props;
    const sortedChecklist = form.checklist.sort((a, b) => a.position - b.position);

    return (
      <Modal
        title={form.id === undefined ? t('new_group') : t('edit_group')}
        visible={visible}
        width={1000}
        maskClosable={false}
        closable={false}
        className="modal-header-dark"
        style={{ top: 20 }}
        footer={this.getButtons()}
        maskStyle={{ backgroundColor: '#00000066' }}
        bodyStyle={{ padding: '0px' }}

      >
        <Row className="form-section">
          <Col sm={10} lg={10} className="form-input">
            <div className="form-label">
              {t('group_name')}
            </div>
            <Input
              value={form.name}
              onChange={e => updateForm({ prop: 'name', value: e.target.value })}
            />
          </Col>
          <Col sm={14} lg={14} className="form-input" style={{ paddingLeft: '30px' }}>
            <div className="form-label">
              {t('can_group_run_repeatedly')}
            </div>
            <Row>
              <Col span={12}>
                <Radio checked={form.repetitive} onChange={() => updateForm({ prop: 'repetitive', value: !form.repetitive })} />
                <Text>{t('yes')}</Text>
              </Col>
              <Col span={12}>
                <Radio checked={!form.repetitive} onChange={() => updateForm({ prop: 'repetitive', value: !form.repetitive })} />
                <Text>{t('no')}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="form-section form-border-top" style={{ paddingBottom: '0px' }}>
          <Col sm={10} lg={10}>
            <div className="form-label">
              {t('add_tasks_to_group')}
            </div>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={t('add_saved_task')}
              value={[]}
              notFoundContent={null}
              filterOption={(input, option) => option.props.children.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
              onChange={this.onSelectTask}
            >
              {tasksData.filter(task => (
                form.ruleType !== RuleTypes.WEB || (
                task.type === TaskTypes.CHECK || task.type === TaskTypes.ENUM ||
                task.type === TaskTypes.TEXT || task.type === TaskTypes.NUMERIC ||
                task.type === TaskTypes.DATE || task.type === TaskTypes.TIME
              ))).map(item => (
                <Select.Option key={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="form-section" style={{ paddingTop: '0px' }}>
          <DragDropContext
            onDragStart={() => null}
            onDragEnd={this.onDragEnd}
          >
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  { sortedChecklist.map((task, index) => (
                    <Draggable
                      key={`key_${index}`} // eslint-disable-line
                      draggableId={`key_${index}`}
                      index={index}
                    >
                      {provided2 => (
                        <div
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                        >
                          <i
                            className="fa fa-bars"
                            aria-hidden="true"
                            style={{ position: 'relative', top: '35px' }}
                            {...provided2.dragHandleProps}
                          />
                          <Collapse
                            activeKey={activePanel}
                            onChange={(k) => {
                              const active = k.length > 0 ? k[k.length - 1] : '';
                              updateState({ path: 'activePanel', value: [active] });
                            }}
                            style={{ marginLeft: '30px' }}
                          >
                            { this.renderTask(index, task) }
                          </Collapse>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ErrorText message={getFieldError(this.state.formErrors, 'checklist')} />
        </Row>
      </Modal>

    );
  }
}
