/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Row, Modal } from 'antd';
import React, { useState } from 'react';
import Webcam from 'react-webcam';
import i18n from 'i18next';

const orientation = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
const videoFrameWidth = orientation === 'landscape' ? window.innerWidth * 0.25 : window.innerWidth * 0.65;
const videoConstraints = {
    width: videoFrameWidth,
    height: videoFrameWidth * (9 / 16),
    facingMode: 'user',
  };

const WebcamTask = ({ value, onChange }) => {
    const webcamRef = React.useRef(null);
    const [modalVisible, setModalVisible] = useState(false);

    const capture = React.useCallback(

      () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setModalVisible(false);
        if (onChange) {
          onChange({ file: imageSrc });
        }
      },
      [webcamRef],
    );

    const deleteImage = () => {
      onChange(null);
    };

    return (
      <div>

        <Row >
          {value && !modalVisible ?
            <div
              style={{ width: videoConstraints.width, height: videoConstraints.height }}
              onClick={() => setModalVisible(true)}
            >
              <div >
                <img src={value.file || value} width={videoConstraints.width} />
              </div>
            </div>
                :
            <Button size="large" type="primary" icon="camera" onClick={() => setModalVisible(true)} style={{ alignSelf: 'center' }}>
              {i18n.t('capture')}
            </Button>
            }
        </Row>

        {modalVisible &&
        <Modal
          title={i18n.t('capture_photo')}
          visible={modalVisible}
          width={orientation === 'landscape' ? window.innerWidth * 0.40 : window.innerWidth * 0.90}
          maskClosable={false}
          className="modal-header-dark"
          style={{ top: 20 }}
          maskStyle={{ backgroundColor: '#00000066' }}
          onCancel={() => setModalVisible(false)}
          footer={null}
        >
          <Row>
            <Row>
              <div style={{ width: videoConstraints.width, height: videoConstraints.height }}>
                {value ?
                  <div >
                    <img src={value.file || value} />
                  </div>
                    :
                  <Webcam
                    audio={false}
                    height={videoConstraints.height}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={videoConstraints.width}
                    videoConstraints={videoConstraints}
                    mirrored
                    style={{ alignSelf: 'center' }}
                  />
                    }
              </div>

            </Row>
            <Row span={6} style={{ textAlign: 'center', padding: 20 }}>
              {!value ?
                <Button size="large" icon="camera" type="primary" onClick={capture} >{i18n.t('capture')}</Button>
                  :
                <Button size="large" type="danger" icon="delete" onClick={deleteImage} >{i18n.t('delete')}</Button>
                  }
            </Row>
          </Row>
        </Modal>
            }


      </div>
    );
  };

export default WebcamTask;
