/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:28:07
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-11-26 11:51:13
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { CREATE_EXECUTION, SEARCH_USERS_PAGINATED } from './types';
import { actions } from './index';
import { Notification } from '../../../common/components/widgets';
import ExecutionService from '../../../services/execution';
import UserService from '../../../services/user';

const executionsService = new ExecutionService();
const userService = new UserService();

const createExecutionEpic = action$ =>
  action$.ofType(CREATE_EXECUTION).flatMap(action =>
    Observable.concat(
      Observable.of(actions.updateState({ path: 'savingExecution', value: true })),
      Observable.fromPromise(executionsService.createExecution(action.ruleExecution))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.updateState({ path: 'savingExecution', value: false })),
            Observable.of(actions.updateState({ path: 'savedExecution', value: !action.saveAndContinue })),
            Observable.of(actions.updateState({ path: 'rule', value: response })),
            Observable.of(actions.showMessage(() => {
              Notification.success(i18n.t('save_activity_completed'));
            })),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.updateState({ path: 'savingExecution', value: false })),
            Observable.of(actions.showMessage(() => {
              Notification.error(i18n.t('save_activity_fail'), error.message);
            })),
          )),
    ));

const searchUsersPaginatedEpic = action$ =>
action$.ofType(SEARCH_USERS_PAGINATED).switchMap(action =>
  Observable.concat(Observable.fromPromise(userService.searchUsersPaginated({ ...action.filters }))
    .flatMap(response =>
      Observable.concat(Observable.of(actions.searchUsersPaginatedFullfilled(response))))
    .catch(error =>
      Observable.concat(Observable.of(actions.showMessage(() => {
        Notification.error('Error al obtener lista de usuarios', error.message);
      }))))));

export default combineEpics(
  createExecutionEpic,
  searchUsersPaginatedEpic,
);
