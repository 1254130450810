/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-14 15:26:06 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-18 12:34:27
 */
const formatPermissions = permissions => permissions
  .filter(item => item.keyName !== 'ADMINISTRATIVE_KEY')
  .filter(item => item.keyName !== 'DIRECTOR_KEY')
  .filter(item => item.keyName !== 'ROOT_KEY')
  .filter(item => item.keyName !== 'OWNER_KEY')
  .filter(item => item.keyName !== 'MINOR_USER_KEY')
  .filter(item => item.keyName !== 'MANAGER_KEY');

export default formatPermissions;
