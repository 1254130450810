/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:13:19
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-11-09 19:36:32
 */
const CONTAINER_KEY = 'WEB_ACTIVITY_CONTAINER';

export const CREATE_EXECUTION = `${CONTAINER_KEY}_CREATE_EXECUTION`;
export const SEARCH_USERS_PAGINATED = `${CONTAINER_KEY}_SEARCH_USERS_PAGINATED`;
export const SEARCH_USERS_PAGINATED_FULLFILLED = `${CONTAINER_KEY}_SEARCH_USERS_PAGINATED_FULLFILLED`;
