/*
 * @Author: Jose Juan Muñoz
 * @Date: 2019-05-07 10:22:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-02-19 14:41:38
 */
import { Card, Form, Radio, InputNumber, Button } from 'antd';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Background from '../../../../assets/images/background-web_task-it.jpg';
import { ErrorText } from '..';

const FormItem = Form.Item;

class PlanForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      readOnly, onChange,
      formData: { subscriptionType, userQuantity, code },
      error, openModal, annualCost, monthlyCost, minValue, t,
    } = this.props;
    const quantity = isNaN(userQuantity) ? 1 : userQuantity; // eslint-disable-line
    const cost = subscriptionType === 'year' ? annualCost : monthlyCost;
    const totalCost = subscriptionType === 'year' ? (quantity * annualCost * 12) : (quantity * monthlyCost);
    const iva = code ? (totalCost * 0.16) : 0;
    const months = subscriptionType === 'year' ? 12 : 1;

    return (

      <Card
        align="center"
        title="Elige el plan a tu medida"
        headStyle={{
          background: `url(${Background})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          color: 'white',
        }}
        bordered={false}
      >
        <Card.Grid style={{ width: '100%', textAlign: 'center' }}>
          <Form className="login-form">
            <FormItem
              align="center"
              label={t('subscribe_users')}
              hasFeedback
            >
              <InputNumber
                disabled={readOnly}
                min={minValue}
                defaultValue={minValue}
                max={1000}
                onChange={(value) => {
                  onChange(value, 'userQuantity');
                }}
                value={userQuantity}
              />
              {error && <ErrorText message="Número invalido" />}
            </FormItem>
            <Form.Item label="Tipo de suscripción">
              <Radio.Group
                disabled={readOnly}
                onChange={e => onChange(e.target.value, 'subscriptionType')}
                value={subscriptionType}
              >
                <Radio value="monthly">{t('monthly')}</Radio>
                <Radio value="year">{t('anual')}</Radio>
              </Radio.Group>
            </Form.Item>
            <div>
              <h3>${cost} USD x {quantity} {t('users')} x {months} mes {code ? `+ $${iva.toFixed(2)} IVA` : ''} = </h3>
              <span style={{ fontSize: '50px' }}>${(totalCost + iva).toFixed(2)} USD </span>
              <br />
              {
                !readOnly && !code &&
                <Button
                  type="link"
                  onClick={openModal}
                >
                  ¿Tienes un código de distribuidor?
                </Button>
              }
              {
                code &&
                <h4 style={{ color: '#1CAD62' }}>Código Aplicado: {code}</h4>
              }
              <h4 style={{ color: '#A7A7A7' }}>
                *Se facturará un cobro de ${totalCost} USD
                  por la contratación de los usuarios adicionales,
                los cuales podrás usar hasta la terminación de la suscripción.
              </h4>
            </div>
          </Form>
        </Card.Grid>
      </Card>
    );
  }
}
const WrappedPlanForm = Form.create({ name: 'plan_form' })(PlanForm);

export default withTranslation()(WrappedPlanForm);
