/*
 * @Author: Cesar Medina
 * @Date: 2019-07-02 10:58:50
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-12-05 18:56:41
 */
import axios from 'axios';
import { update } from 'aleonor-redux-helpers';
import Service from '../Service';
import { createRulesServiceUrl, createCoreServiceUrl, parseParams } from '../../common/helpers/service';


class ExecutionService extends Service {
    getExecutions = params => axios.get(createRulesServiceUrl(`executions${parseParams(params)}`)).then(data => data.data);
    getExecutionById = id => axios.get(createRulesServiceUrl(`executions/${id}`))
      .then((data) => {
        if (data.data.evidence.completion &&
          data.data.evidence.completion.checklist) {
          return this.getContactInformation(data.data);
        }
        return data.data;
      });
    getExecutionTimeline = id => axios.get(createRulesServiceUrl(`executions/${id}/timeline`)).then(data => data.data);
    createExecution = ruleExecution => axios.post(createRulesServiceUrl('executions'), ruleExecution).then(data => data.data);
    getContactInformation = response =>
      Promise.all(response.evidence.completion.checklist.map((i) => {
        if (i.type === 'contact' && typeof (i.value) === 'string' && i.value.length > 0) {
          return axios.get(createCoreServiceUrl(`contacts/${i.value}`))
            .then(data => ({
              ...i,
              value: data.data,
            }))
            .catch(() => i);
        }
        return i;
      })).then(tasks => update(response, { path: 'evidence.completion.checklist', value: tasks }));
      createExecution = ruleExecution => axios.post(createRulesServiceUrl('executions'), ruleExecution).then(data => data.data);
      getExecutionDrafts = () => axios.get(createRulesServiceUrl('executions/drafts')).then(data => data.data);
      getCurrentExecutions = (params = {}) => axios.get(createRulesServiceUrl(`executions/current${parseParams(params)}`)).then(data => data.data);
      getProgressByDates = (roleIds, branchOfficeId, timeZone, initDate, endDate, userId) => {
        const url = createRulesServiceUrl(`executions/progressByDates${parseParams({
          roleIds, branchOfficeId, timeZone, initDate, endDate, userId,
        })}`);
        return axios.get(url).then(data => data.data);
      }
      deleteExecution = id => axios.delete(createRulesServiceUrl(`/executions/${id}`)).then(data => data.data);
}

export default ExecutionService;
