/*
 * @Author: Jhony Reyes 
 * @Date: 2019-03-04 14:31:05 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-03-04 14:31:29
 */
import React from 'react';

const AgendaEvent = ({
  event, onEvent,
}) => (
  <div onClick={onEvent} onKeyUp={onEvent} role="button" tabIndex={0} >
    {event.title}
  </div>
);

export default AgendaEvent;
