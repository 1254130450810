/*
 * @Author: Jhony Reyes
 * @Date: 2018-11-13 17:52:08
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2019-11-07 15:49:58
 */

import React from 'react';
import Recaptcha from 'react-google-recaptcha';
import { Form, Input, Button, Row, Col, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { init, getFieldError } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';

import '../styles.css';
import { actions } from './redux';
import { isAuthenticated } from '../../../security';
import { companyFormValidations } from './validations';
import { validateInputStatus, redirectLogin } from '../utils';
import logo from '../../../assets/images/taskit_logo_web.png';

const FormItem = Form.Item;


class Register extends React.Component {
  constructor(props) {
    super(props);
    this.validator = init(companyFormValidations());
  }

  componentWillMount = () => {
    const { resetState, registerSuccessfulReset } = this.props;
    resetState();
    registerSuccessfulReset();
  };

  onChangeForm = (prop, value) => {
    const {
      updateState, companyFormErrors, companyForm,
    } = this.props;
    const { error } = this.validator.validate(prop, value, companyForm);
    updateState({ path: `companyForm.${prop}`, value });
    updateState({ path: 'companyFormErrors', value: { ...companyFormErrors, [prop]: error } });
    if (prop === 'companyName') {
      updateState({ path: 'companyAvailability.value', value: true });
    }
  };
  onChangeRecaptcha = () => {
    const { updateState } = this.props;
    updateState({ path: 'recapthaNotverified', value: false });
  }
  onExpiredRecaptcha = () => {
    const { updateState } = this.props;
    updateState({ path: 'recapthaNotverified', value: true });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const {
      companyForm, updateState, register, companyAvailability,
    } = this.props;
    const { valid, errors } = this.validator.validateForm(companyForm);
    updateState({ path: 'companyFormErrors', value: errors });
    if (!valid || !companyAvailability.value) {
      return;
    }
    updateState({ path: 'loadingButton', value: true });
    register({ form: { ...companyForm }, navigate: () => this.props.history.push('/') });
  };

  handleAvailability = () => {
    const { fetchCompanyAvailability, companyForm: { companyName } } = this.props;
    fetchCompanyAvailability(companyName);
  }

  render() {
    const {
      companyForm: {
        companyName, name, lastName, email, password, password2, terms, phoneNumber,
      },
      companyAvailability,
      companyFormErrors,
      loadingButton,
      recapthaNotverified,
      t,
    } = this.props;
    const companyNameError = getFieldError(companyFormErrors, 'companyName');
    const nameError = getFieldError(companyFormErrors, 'name');
    const lastNameError = getFieldError(companyFormErrors, 'lastName');
    const emailError = getFieldError(companyFormErrors, 'email');
    const passwordError = getFieldError(companyFormErrors, 'password');
    const passwordError2 = getFieldError(companyFormErrors, 'password2');
    const phoneNumberError = getFieldError(companyFormErrors, 'phoneNumber');
    const authenticated = isAuthenticated();
    const termsError = getFieldError(companyFormErrors, 'terms');
    if (authenticated) {
      return <Redirect to="/" />;
    }

    const handleCompanyError = () => {
      if (!companyAvailability.value) {
        return t('unavailable_name');
      } return companyNameError;
    };

    return (
      <Row type="flex" align="middle" justify="center" className="register">
        <Col xs={20} sm={20} md={20} lg={12} xl={12}>
          <h1 className="register__paragraph">
            {t('taskit_description')}
          </h1>
        </Col>
        <Col xs={20} sm={20} md={20} lg={8} xl={8}>
          <div className="register__box-form">
            <div className="login__box__img">
              <img src={logo} alt="logo-taskit" className="register__logo" />
            </div>
            <h1 className="register__title-form">{t('register_you')}</h1>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem
                hasFeedback
                validateStatus={companyNameError || !companyAvailability.value ? 'error' : validateInputStatus(companyName)}
                help={(Boolean(companyNameError) || !companyAvailability.value)
                  && handleCompanyError()}
                extra={companyAvailability.fulfilled && t('name_available')}
              >
                <Input
                  type="text"
                  placeholder={t('company_name')}
                  value={companyName}
                  onChange={value => this.onChangeForm('companyName', value.target.value)}
                  onBlur={this.handleAvailability}
                  maxLength={40}
                />
              </FormItem>
              <h4 className="register__title-form">{t('enter_data')}</h4>
              <FormItem
                hasFeedback
                validateStatus={nameError ? 'error' : validateInputStatus(name)}
                help={Boolean(nameError) && nameError}
              >
                <Input
                  type="text"
                  placeholder={t('name')}
                  value={name}
                  onChange={value => this.onChangeForm('name', value.target.value)}
                  maxLength={40}
                />
              </FormItem>
              <FormItem
                hasFeedback
                validateStatus={lastNameError ? 'error' : validateInputStatus(lastName)}
                help={Boolean(lastNameError) && lastNameError}
              >
                <Input
                  type="text"
                  placeholder={t('lastnames')}
                  value={lastName}
                  onChange={value => this.onChangeForm('lastName', value.target.value)}
                  maxLength={40}
                />
              </FormItem>
              <FormItem
                hasFeedback
                validateStatus={emailError ? 'error' : validateInputStatus(email)}
                help={Boolean(emailError) && emailError}
              >
                <Input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={value => this.onChangeForm('email', value.target.value)}
                  maxLength={100}
                />
              </FormItem>
              <FormItem
                hasFeedback
                validateStatus={passwordError ? 'error' : validateInputStatus(password)}
                help={Boolean(passwordError) && passwordError}
              >
                <Input
                  type="password"
                  placeholder={t('password')}
                  value={password}
                  onChange={value => this.onChangeForm('password', value.target.value)}
                  maxLength={20}
                />
              </FormItem>
              <FormItem
                hasFeedback
                validateStatus={passwordError2 ? 'error' : validateInputStatus(password2)}
                help={Boolean(passwordError2) && passwordError2}
              >
                <Input
                  type="password"
                  placeholder={t('confirm_password')}
                  value={password2}
                  onChange={value => this.onChangeForm('password2', value.target.value)}
                  maxLength={20}
                />
              </FormItem>
              <FormItem
                hasFeedback
                validateStatus={phoneNumberError ? 'error' : validateInputStatus(phoneNumber)}
                help={Boolean(phoneNumberError) && phoneNumberError}
              >
                <Input
                  type="text"
                  placeholder={t('phone')}
                  value={phoneNumber}
                  onChange={value => this.onChangeForm('phoneNumber', value.target.value)}
                  maxLength={20}
                />
              </FormItem>
              <div className="justify-center">
                <FormItem
                  validateStatus={termsError ? 'error' : validateInputStatus(terms)}
                  help={Boolean(termsError) && termsError}
                >
                  <Checkbox checked={terms} onChange={e => this.onChangeForm('terms', e.target.checked)} >
                    {t('accept_the')} <Link target="_blank" to="/terms-conditions">{t('terms_and_conditions')}</Link>
                  </Checkbox>
                </FormItem>
              </div>
              <FormItem className="login__form__footer">
                <Recaptcha
                  sitekey="6Lc7DJMUAAAAAFvTBltSgcjOWjJmah29_YKnghZG"
                  render="explicit"
                  onChange={this.onChangeRecaptcha}
                  onExpired={this.onExpiredRecaptcha}
                />
                <div className="login__button-container justify-center">
                  <Button
                    loading={loadingButton}
                    htmlType="submit"
                    onSubmit={this.handleSubmit}
                    type="primary"
                    disabled={recapthaNotverified}
                  >
                    {t('accept')}
                  </Button>
                </div>
              </FormItem>
              <FormItem className="login__form__footer">
                <div className="justify-center">
                  <Button onClick={() => redirectLogin(this.props)} className="login-form-button">
                    {t('back')}
                  </Button>
                </div>
              </FormItem>
            </Form>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ registerContainer }) => registerContainer;

export default withTranslation()(connect(
  mapStateToProps,
  actions,
)(Register));
