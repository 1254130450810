/*
 * @Author: Jhony Reyes 
 * @Date: 2018-05-04 12:15:26 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-02-23 15:37:43
 */

import i18n from 'i18next';

const validations = () => ({
  state: [
    { validate: 'hasSelectedOption', message: i18n.t('state_required') },
  ],
  city: [
    { validate: 'required', message: i18n.t('city_required') },
    {
      validate: (_, form) => form.city.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  name: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.name.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  numberExt: [
    { validate: 'required', message: i18n.t('number_required') },
    {
      validate: (_, form) => form.numberExt.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  postalCode: [
    { validate: 'required', message: i18n.t('post_code_required') },
    { validate: (_, form) => (/^[0-9]*$/.test(form.postalCode)), custom: true, message: i18n.t('only_numbers') },
    {
      validate: (_, form) => {
        if (form.postalCode.length < 5) {
          return false;
        }
        return true;
      },
      message: i18n.t('min_5_characters'),
      custom: true,
    },
  ],
});

export default validations;
