/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:13:19
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-12-06 01:51:23
 */
const CONTAINER_KEY = 'TASKS_EVIDENCES_CONTAINER';

export const DOWNLOAD_REPORT = `${CONTAINER_KEY}_DOWNLOAD_REPORT`;
export const DELETE_EXECUTION = `${CONTAINER_KEY}_DELETE_EXECUTION`;
