import React from 'react';
import { Row, Col, Select, Radio, Input, Icon } from 'antd';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

import { LinkSpecificallyTypes, RuleTypes, TaskTypes } from '../../../constants';
import { getTypeIcon } from '../../../labels';
import ConservarSVG from '../../../../assets/icons/conservar';
import RastreoSVG from '../../../../assets/icons/rastreo';
import './styles.css';

const { Option } = Select;

const taskTypeOptions = () => [
  { value: TaskTypes.PHOTO, label: i18n.t('capture_photo') },
  { value: TaskTypes.SIGNATURE, label: i18n.t('capture_signature') },
  { value: TaskTypes.ENUM, label: i18n.t('select_value') },
  { value: TaskTypes.MULTI_ENUM, label: i18n.t('select_multi_value') },
  { value: TaskTypes.TEXT, label: i18n.t('enter_text') },
  { value: TaskTypes.NUMERIC, label: i18n.t('enter_numbers') },
  { value: TaskTypes.DATE, label: i18n.t('select_date') },
  { value: TaskTypes.TIME, label: i18n.t('select_time') },
  { value: TaskTypes.CONTACT, label: i18n.t('add_contact_group') },
  { value: TaskTypes.BARCODE, label: i18n.t('read_qr') },
  { value: TaskTypes.CHECK, label: i18n.t('mark_task_done') },
  { value: TaskTypes.FILE, label: i18n.t('attach_file') },
  { value: TaskTypes.RULE_LINK, label: i18n.t('rule_link'), hideOnWebType: true },
  { value: TaskTypes.RULE_FOLIO, label: i18n.t('rule_folio'), hideOnWebType: true },
  { value: TaskTypes.USER, label: i18n.t('select_user'), hideOnWebType: true },
];

const defaultRuleLinkSettings = {
  oneToOneRelationType: false,
  linkSpecificallyBy: null,
  linkSpecificallyUserTaskName: '',
};

class TaskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
    const { task: { additionalInfo }, fetchRulesByName } = this.props;
    if (additionalInfo) {
      fetchRulesByName({ ruleId: additionalInfo });
    }
  }

  onChangeRuleLinkSettings = (taskIndex, ruleLinkSettings, key, value) => {
    const { onChangeField } = this.props;
    onChangeField(taskIndex, 'ruleLinkSettings', {
      ...ruleLinkSettings,
      [key]: value,
    });
  }

  renderRules() {
    const { rules } = this.props;
    return rules.map(rule => (
      <Option value={rule.taskitProcessId ? rule.predefinedRuleId : rule.id}>{rule.name}</Option>
    ));
  }

  renderUserTasksForLink = () => {
    const { rules, task } = this.props;
    const ruleFinded = rules.find(r => r.id === task.additionalInfo);
    if (ruleFinded) {
      return ruleFinded.checklist
          .filter(tsk => tsk.type === TaskTypes.USER).map(tsk => (
            <Option value={tsk.name}>{tsk.name}</Option>
        ));
    }
    return null;
  }


  render() {
    const {
      task,
      readOnly,
      taskIndex,
      ruleType,
      evaluationsData,
      onChangeField,
      enableTaskSaving,
      t,
      onSearchRules,
      onBlurRule,
      fromTaskitProcesses,
      isTaskGroup,
    } = this.props;
    const isWebType = ruleType === RuleTypes.WEB;
    const isRuleFolioTaskType = task.type === TaskTypes.RULE_FOLIO;
    const canBeTracked = task.type === TaskTypes.TEXT ||
      task.type === TaskTypes.BARCODE || task.type === TaskTypes.NUMERIC;
    const canBeVisible = task.type === TaskTypes.TEXT || task.type === TaskTypes.NUMERIC ||
      task.type === TaskTypes.DATE || task.type === TaskTypes.TIME ||
      task.type === TaskTypes.ENUM || task.type === TaskTypes.MULTI_ENUM ||
      task.type === TaskTypes.CHECK || task.type === TaskTypes.RULE_FOLIO ||
      task.type === TaskTypes.USER || task.type === TaskTypes.CONTACT ||
      task.type === TaskTypes.BARCODE;

    const ruleLinkSettings = task.ruleLinkSettings || defaultRuleLinkSettings;

    return (
      <div>
        <Row className="form-section">
          <Col sm={24} lg={8} className="form-input">
            <div className="form-label">{t('name')}</div>
            <Input
              value={task.name}
              onChange={e => onChangeField(taskIndex, 'name', e.target.value)}
              placeholder={t('what_to_do')}
              disabled={fromTaskitProcesses || readOnly}
            />
          </Col>
          <Col sm={24} lg={16}>
            <div className="form-label">{t('description')}<span>({t('optional')})</span></div>
            <Input
              value={task.description}
              onChange={e => onChangeField(taskIndex, 'description', e.target.value)}
              placeholder={t('add_activity_comment')}
              disabled={fromTaskitProcesses}
            />
          </Col>
        </Row>
        <Row className="form-section form-border-top">
          <Col sm={24} md={12}>
            <div className="form-label">
              {t('task_type')}
              <span>
                {t('what_action_type')}
              </span>
            </div>
            <Select
              value={task.type}
              style={{ width: '100%', paddingRight: '15px' }}
              onChange={val => onChangeField(taskIndex, 'type', val)}
              disabled={fromTaskitProcesses || readOnly}
            >
              {
                taskTypeOptions()
                    .map(i => (
                  !(i.hideOnWebType && isWebType) &&
                  <Select.Option key={i.value} value={i.value}>
                    <Icon
                      type={getTypeIcon(i.value)}
                      className={`task-type__icon ${task.type === i.value ? 'task-type__icon--blue' : ''}`}
                    />
                    {i.label}
                  </Select.Option>
                ))
              }
            </Select>
          </Col>
          {
            task.type === TaskTypes.PHOTO &&
            <Col sm={24} md={12}>
              <div className="form-label">
                {t('force_taking_picture')}
              </div>
              <Radio.Group
                onChange={e => onChangeField(taskIndex, 'forceTakingPicture', e.target.value)}
                value={!!task.forceTakingPicture}
                disabled={fromTaskitProcesses || readOnly}
              >
                <Radio value={!!true}>{t('yes')}</Radio>
                <Radio value={false}>{t('no')}</Radio>
              </Radio.Group>
            </Col>
          }
          {
            (task.type === TaskTypes.ENUM || task.type === TaskTypes.MULTI_ENUM) &&
            <Col sm={24} md={12}>
              <div className="form-label">
                {t('define_options')}.
                <span>
                  {t('separate_coma')}
                </span>
              </div>
              <Input
                placeholder={t('example_options')}
                value={task.enumValues}
                onChange={e => onChangeField(taskIndex, 'enumValues', e.target.value)}
                disabled={fromTaskitProcesses ? false : readOnly}
              />
            </Col>
          }
          {
            task.type === TaskTypes.RULE_LINK &&
            <Col sm={24} md={12}>
              <Row>
                <div className="form-label">
                  {t('activity')}.
                </div>
                <Select
                  value={task.additionalInfo}
                  showSearch
                  style={{ width: '100%' }}
                  placeholder={this.props.t('write_activity')}
                  onBlur={onBlurRule}
                  onSelect={(value) => {
                    onChangeField(taskIndex, 'additionalInfo', value);
                    setTimeout(() => {
                      onChangeField(taskIndex, 'ruleLinkSettings', defaultRuleLinkSettings);
                    }, 300);
                  }}
                  onSearch={onSearchRules}
                  filterOption={false}
                  disabled={fromTaskitProcesses || readOnly}
                >
                  {this.renderRules()}
                </Select>
              </Row>
              <Row>
                <div className="form-label">
                  {t('link_type')}.
                </div>
                <Radio.Group
                  onChange={e => this.onChangeRuleLinkSettings(taskIndex, ruleLinkSettings, 'oneToOneRelationType', e.target.value)}
                  value={ruleLinkSettings.oneToOneRelationType}
                  disabled={fromTaskitProcesses || readOnly}
                >
                  <Radio value={false} >{t('one_to_n')}</Radio>
                  <Radio value >{t('one_to_one')}</Radio>
                </Radio.Group>
              </Row>
              <Row>
                <div className="form-label">
                  {t('what_executions_can_be_linked')}.
                </div>
                <Select
                  value={ruleLinkSettings.linkSpecificallyBy}
                  showSearch
                  style={{ width: '100%' }}
                  placeholder={this.props.t('write_activity')}
                  onBlur={onBlurRule}
                  onSelect={value => this.onChangeRuleLinkSettings(taskIndex, ruleLinkSettings, 'linkSpecificallyBy', value)}
                  onSearch={onSearchRules}
                  filterOption={false}
                  disabled={fromTaskitProcesses || readOnly}
                >
                  <Option value={null}>{t('all_plural')}</Option>
                  <Option value={LinkSpecificallyTypes.BY_PERMISSIONS}>{t('by_permission_can_link')}</Option>
                  <Option value={LinkSpecificallyTypes.USER_CREATOR}>{t('user_creator_can_link')}</Option>
                  <Option value={LinkSpecificallyTypes.USER_TASK_IN_SOURCE_RULE}>{t('user_in_source_activity_can_link')}</Option>
                </Select>
              </Row>
              {ruleLinkSettings.linkSpecificallyBy === 'USER_TASK_IN_SOURCE_RULE' &&
              task.additionalInfo &&
              <Row>
                <div className="form-label">
                  {t('task')}.
                </div>
                <Select
                  value={ruleLinkSettings.linkSpecificallyUserTaskName}
                  style={{ width: '100%' }}
                  placeholder={this.props.t('write_activity')}
                  onBlur={onBlurRule}
                  onSelect={value => this.onChangeRuleLinkSettings(taskIndex, ruleLinkSettings, 'linkSpecificallyUserTaskName', value)}
                  filterOption={false}
                  disabled={fromTaskitProcesses || readOnly}
                >
                  {this.renderUserTasksForLink()}
                </Select>
              </Row>
              }
            </Col>
          }
        </Row>
        {
          task.type !== TaskTypes.CHECK &&
          <Row className="form-section form-border-top">
            <Col sm={24} lg={24}>
              <div className="form-label">
                {t('required_fem')}<span>{t('need_evidence')}</span>
              </div>
              <Radio.Group
                onChange={e => onChangeField(taskIndex, 'evidenceType', e.target.value)}
                value={isRuleFolioTaskType ? 'required' : task.evidenceType}
                disabled={fromTaskitProcesses || (readOnly || isRuleFolioTaskType)}
              >
                <Radio value="required">{t('required_fem')}</Radio>
                <Radio value="optional">{t('optional')}</Radio>
              </Radio.Group>
            </Col>
          </Row>
        }
        {
          !isWebType && !fromTaskitProcesses && !!evaluationsData && !!evaluationsData.length &&
          <Row className="form-section form-border-top">
            <Col sm={24} lg={12}>
              <div className="form-label">
                {t('evaluation')}<span>{t('need_evaluation')}</span>
              </div>
              <Select
                value={(task.evaluation && task.evaluation.id) || null}
                placeholder={t('evaluation')}
                style={{ width: '100%', paddingRight: '15px' }}
                onChange={(val) => {
                  const evaluation = evaluationsData.find(e => e.id === val) || null;
                  onChangeField(taskIndex, 'evaluation', evaluation);
                }}
                notFoundContent={t('no_data')}
                disabled={fromTaskitProcesses || readOnly}
              >
                <Select.Option value={null}>{t('no_evaluation')}</Select.Option>
                {evaluationsData.map(e => (
                  <Select.Option key={e.id} value={e.id}>{e.name}</Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        }
        {
          canBeTracked &&
          <Row className="form-section form-border-top">
            <Col sm={24} lg={24}>
              <div className="form-label">
                {t('allows_linking')}
                <Icon
                  component={RastreoSVG}
                  className={`task-type__icon ${task.tracking ? 'task-type__icon--blue' : ''}`}
                />
              </div>
              <Radio.Group
                onChange={e => onChangeField(taskIndex, 'tracking', e.target.value)}
                value={task.tracking}
                disabled={fromTaskitProcesses || readOnly}
              >
                <Radio value={!!true}>{t('yes')}</Radio>
                <Radio value={false}>{t('no')}</Radio>
              </Radio.Group>
            </Col>
          </Row>
        }
        {
          !isTaskGroup &&
          <Row className="form-section form-border-top">
            <Col sm={24} lg={24}>
              <div className="form-label">
                {t('repeat_activity')}
                <Icon
                  type="reload"
                  className={`task-type__icon ${task.repeat ? 'task-type__icon--blue' : ''}`}
                />
              </div>
              <Radio.Group
                onChange={e => onChangeField(taskIndex, 'repeat', e.target.value)}
                value={task.repeat}
                disabled={fromTaskitProcesses || readOnly}
              >
                <Radio value={!!true}>{t('yes')}</Radio>
                <Radio value={false}>{t('no')}</Radio>
              </Radio.Group>
            </Col>
          </Row>
        }
        {
          !isWebType &&
          <Row className="form-section form-border-top">
            <Col sm={24} lg={24}>
              <div className="form-label">
                {t('conserve_value_task')}
                <Icon
                  component={ConservarSVG}
                  className={`task-type__icon ${task.keepValue ? 'task-type__icon--blue' : ''}`}
                />
              </div>
              <Radio.Group
                onChange={e => onChangeField(taskIndex, 'keepValue', e.target.value)}
                value={task.keepValue}
                disabled={fromTaskitProcesses || readOnly}
              >
                <Radio value={!!true}>{t('yes')}</Radio>
                <Radio value={false}>{t('no')}</Radio>
              </Radio.Group>
            </Col>
          </Row>
        }
        {
          enableTaskSaving &&
          <Row className="form-section form-border-top">
            <Col sm={24} lg={24}>
              <div className="form-label">
                {t('reuse_task')}
                <Icon
                  type="save"
                  className={`task-type__icon ${task.saveTask ? 'task-type__icon--blue' : ''}`}
                />
              </div>
              <Radio.Group
                onChange={e => onChangeField(taskIndex, 'saveTask', e.target.value)}
                value={!!task.saveTask}
                disabled={fromTaskitProcesses || readOnly}
              >
                <Radio value={!!true}>{t('yes')}</Radio>
                <Radio value={false}>{t('no')}</Radio>
              </Radio.Group>
            </Col>
          </Row>
        }
        {canBeVisible &&
          <Row className="form-section form-border-top">
            <Col sm={24} lg={24}>
              <div className="form-label">
                {t('visible_evidence')}
                <Icon
                  type="eye"
                  className={`task-type__icon ${task.visibleEvidence ? 'task-type__icon--blue' : ''}`}
                />
              </div>
              <Radio.Group
                onChange={e => onChangeField(taskIndex, 'visibleEvidence', e.target.value)}
                value={!!task.visibleEvidence}
                disabled={fromTaskitProcesses || readOnly}
              >
                <Radio value={!!true}>{t('yes')}</Radio>
                <Radio value={false}>{t('no')}</Radio>
              </Radio.Group>
            </Col>
          </Row>
        }
      </div>
    );
  }
}

export default withTranslation()(TaskForm);
