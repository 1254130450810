/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-26 17:16:31
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-12-20 14:13:23
 */
import { getFieldError, init } from 'aleonor-object-validations';
import { Col, Icon, Radio, Row, Select, Spin, Tooltip } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ErrorText } from '../../../../widgets';
import { actions } from '../../redux';
import validations from './validations';

const { Option } = Select;
class BranchOfficeStep extends React.Component {
  constructor(props) {
    super(props);
    this.validator = init(validations());
    this.state = { formErrors: {} };
  }

  onSelectBranchOffice = (branchOfficeId) => {
    const {
 form: { branchOfficeIds }, updateForm, updateState, branchOfficesData,
} = this.props;
    if (branchOfficeId !== 'ALL') {
      let newBranchOfficeIds = [];
      if (branchOfficesData.length === branchOfficeIds.length) {
         newBranchOfficeIds = Array.of(branchOfficeId);
      } else {
        newBranchOfficeIds = branchOfficeIds.findIndex(id => id === branchOfficeId) > -1 ?
          branchOfficeIds : Array.of(branchOfficeId, ...branchOfficeIds);
      }
      updateForm({ prop: 'branchOfficeIds', value: newBranchOfficeIds });
    } else {
      updateForm({ prop: 'branchOfficeIds', value: branchOfficesData.map(({ id }) => (id)) });
    }
    updateState({ path: 'selectedUsers', value: [] });
  }

  onDeselectBranchOffice = (branchOfficeId) => {
    const { form: { branchOfficeIds }, updateForm, updateState } = this.props;
    if (branchOfficeId !== 'ALL') {
      const newBranchOfficeIds = branchOfficeIds.filter(id => id !== branchOfficeId);
      updateForm({ prop: 'branchOfficeIds', value: newBranchOfficeIds });
    } else {
      updateForm({ prop: 'branchOfficeIds', value: [] });
    }
    updateState({ path: 'selectedUsers', value: [] });
  }

  validate = () => {
    const { form } = this.props;
    const { valid, errors } = this.validator.validateForm(form);

    if (!valid) {
      this.setState({ formErrors: errors });
      return false;
    }
    this.setState({ formErrors: {} });
    return true;
  }

  render() {
    const {
      form,
      selectedUsers,
      branchOfficesData,
      rolesData,
      fetchingSelectData,
      searchedUsers,
      updateForm,
      updateCollaborative,
      updateSelectedUsers,
      t,
    } = this.props;

    return (
      <div>
        <div className="form-description">
          {t('define_details_activity')}
        </div>
        <Row className="form-section">
          <Col sm={24} lg={24}>
            <div className="form-label">
              {t('branch_office')}
            </div>
            <Select
              mode="multiple"
              size="middle"
              showArrow
              value={branchOfficesData.length === form.branchOfficeIds.length ? ['ALL'] : form.branchOfficeIds}
              placeholder={t('branch_office')}
              style={{ width: 1050 }}
              onSelect={this.onSelectBranchOffice}
              onDeselect={this.onDeselectBranchOffice}
              notFoundContent={t('no_data')}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option key="ALL"> {t('all')}</Option>
              {branchOfficesData.map(e => (
                <Option key={e.id} value={e.id}>{e.name}</Option>
              ))}
            </Select>
            <ErrorText message={getFieldError(this.state.formErrors, 'branchOfficeIds')} />
          </Col>
        </Row>
        <Row className="form-section">
          <Col sm={24} lg={24}>
            <div className="form-label">
              {t('who_performs_activity')}
            </div>
            <Radio.Group
              value={form.collaborative}
              onChange={e => updateCollaborative({ value: e.target.value })}
            >
              <Radio value={!!true}>
                {t('assign_activity_role')}&nbsp;
                <Tooltip
                  placement="topLeft"
                  title={t('activity_available_msg')}
                >
                  <Icon type="question-circle" theme="outlined" />
                </Tooltip>
              </Radio>
              <Radio value={false}>
                {t('assign_activity_group')}
              </Radio>
            </Radio.Group>
            <br /><br />
            { form.collaborative ?
              <Select
                value={form.roleId}
                placeholder={t('role')}
                style={{ minWidth: '180px' }}
                notFoundContent={t('no_data')}
                onChange={val => updateForm({ prop: 'roleId', value: val })}
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {rolesData.map(e => (
                  <Option key={e.id} value={e.id}>{e.name}</Option>
                ))}
              </Select> :
              <Select
                mode="multiple"
                labelInValue
                value={selectedUsers}
                placeholder={t('select')}
                notFoundContent={fetchingSelectData ? <Spin size="small" /> : null}
                filterOption={false}
                onSearch={(val) => {
                  const { form: { branchOfficeIds }, onSearch } = this.props;
                  onSearch(val, { branchOfficeIds, ids: selectedUsers.reduce((prev, currt) => `${prev}${currt.key},`, '') });
                }}
                onChange={values => updateSelectedUsers({ values })}
                style={{ width: '50%' }}
              >
                {searchedUsers.map(item => (
                  <Option key={item.id}>{item.name}</Option>
                ))}
              </Select>
            }
            <ErrorText message={getFieldError(this.state.formErrors, 'roleId')} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ activityFormContainer }) => ({
  ...activityFormContainer,
});

export default withTranslation('translation', { withRef: true })(connect(
  mapStateToProps, actions, null,
  { withRef: true },
  )(BranchOfficeStep));
