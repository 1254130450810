/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-22 13:21:34 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-02-06 11:10:08
 */
import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { ErrorText } from '../../index';
import SelectMultipleData from '../../selectMultipleData';

const showSelected = selected => (selected && selected.length ? selected.map(s => s.name).join(', ') : '');


const RenderSelectCellForMultiple = ({
  record, data, error, errorMessage, selected, onSelect, required, disabled,
}) => {
  const { editable } = record;
  const { t } = useTranslation();

  return editable ?
    <div>
      <div>
        {required &&
        <Tooltip title={t('field_required')} placement="topLeft">
          <span className="inputContainer__span">*</span>
        </Tooltip>}
        <SelectMultipleData
          data={data}
          onSelect={onSelect}
          selected={selected}
          disabled={disabled}
          style={{ width: '90%' }}
        />
      </div>
      <ErrorText message={error ? errorMessage : null} />
    </div>
    :
    showSelected(selected);
};

export default RenderSelectCellForMultiple;

