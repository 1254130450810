/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-26 14:54:21 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-30 14:02:46
 */

import React from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.css';

const Loading = ({ style = {} }) => {
  const { t } = useTranslation();

  return (
    <div className="loadingContainer" style={style}>
      <p className="loadingText">{t('loading')}...</p> <Spin />
    </div>
  );
};

export default Loading;
