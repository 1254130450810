/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 11:09:48 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-11-12 14:30:43
 */
import axios from 'axios';
import Service from '../Service';
import { createRulesServiceUrl, parseParams } from '../../common/helpers/service';

class TaskService extends Service {
    getById = id => axios.get(createRulesServiceUrl(`tasks/${id}`)).then(data => data.data);
    createTask = form => axios.post(createRulesServiceUrl('tasks'), form).then(data => data.data);
    updateTask = form => axios.put(createRulesServiceUrl('tasks'), form).then(data => data.data);
    deleteTask = id => axios.delete(createRulesServiceUrl(`tasks/${id}`)).then(data => data.data);
    getTasks = (params = {}) => axios.get(createRulesServiceUrl(`tasks${parseParams(params)}`)).then(data => data.data);
}

export default TaskService;
