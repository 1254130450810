import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Row, Col, Button, Collapse, Select, Icon, Alert, List, Input } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { init, getFieldError } from 'aleonor-object-validations';
import Text from 'antd/lib/typography/Text';

import { actions } from '../../redux';
import validations from './validations';
import { RuleTypes, TaskTypes, EvidenceTypes } from '../../../../../constants';
import { evidenceTypeLabel, getTypeIcon, taskTypeLabel } from '../../../../../labels';
import { ErrorText, Notification, TaskForm } from '../../../../widgets';
import ConservarSVG from '../../../../../../assets/icons/conservar';
import RastreoSVG from '../../../../../../assets/icons/rastreo';

const { Option } = Select;

const reorder = (checklist, startIndex, endIndex) => {
  const sortedChecklist = checklist.sort((a, b) => a.position - b.position);

  const [removed] = sortedChecklist.splice(startIndex, 1);
  sortedChecklist.splice(endIndex, 0, removed);

  return sortedChecklist.map((t, index) => ({ ...t, position: index }));
};

class TasksStep extends React.Component {
  constructor(props) {
    super(props);
    this.validator = init(validations());
    this.state = {
      formErrors: {},
      searchRule: null,
    };
  }

  componentDidMount = () => {
    this.fetchRulesByName();
    this.props.fetchTaskGroups();
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const orderedChecklist = reorder(
      this.props.form.checklist,
      result.source.index,
      result.destination.index,
    );

    this.props.updateState({ path: 'form.checklist', value: orderedChecklist });
  }

  onChangeTaskGroup = (taskIndex, taskGroupIndex, prop, value) => {
    const {
      form: { checklist },
      updateState,
    } = this.props;
    const updatedChecklist = checklist[taskIndex].checklist.map((i, index) => {
      if (index === taskGroupIndex) {
        return {
          ...i,
          [prop]: value,
        };
      }
      return i;
    });
    updateState({ path: `form.checklist[${taskIndex}].checklist`, value: updatedChecklist });
  }

  onChangeTask = (taskIndex, prop, value) => {
    const {
      form: { checklist },
      updateState,
      t,
    } = this.props;
    let { tracking, evidenceType, descriptionEdited = false } = checklist[taskIndex];

    if (prop === 'type' && value === TaskTypes.RULE_FOLIO &&
      checklist.findIndex(tsk => tsk.type === TaskTypes.RULE_FOLIO) >= 0) {
      Notification.error(t('task_folio_duplicated'), t('task_folio_duplicated_desc'));
      return;
    }

    if (prop === 'type' && value !== TaskTypes.TEXT &&
      value !== TaskTypes.BARCODE && value !== TaskTypes.NUMERIC) {
      tracking = false;
    }

    if (prop === 'type' && value === TaskTypes.CHECK) {
      evidenceType = EvidenceTypes.OPTIONAL;
    }

    if (prop === 'evidenceType') {
      evidenceType = value;
    }

    if (prop === 'tracking') {
      tracking = value;
    }

    if (prop === 'description') {
      descriptionEdited = true;
    }

    const updatedChecklist = checklist.map((i, index) => {
      if (index === taskIndex) {
        return {
          ...i,
          [prop]: value,
          evidenceType,
          tracking,
          descriptionEdited,
        };
      }
      return i;
    });

    updateState({ path: 'form.checklist', value: updatedChecklist });
  };

  onSearchRules = (value) => {
    this.setState({ searchRule: value });
    clearTimeout(this.timer);
    if (value) {
      this.timer = setTimeout(() => this.fetchRulesByName({
        name: this.state.searchRule,
      }), 1000);
    } else { this.fetchRulesByName(); }
  }

  onBlurRule = () => {
    this.fetchRulesByName();
  }

  onSelectTask = (values) => {
    const {
 form: { checklist }, tasksData, updateForm, t,
} = this.props;
    const task = tasksData.find(i => i.id === values[0]);
    if (task.type === TaskTypes.RULE_FOLIO &&
        checklist.findIndex(tsk => tsk.type === TaskTypes.RULE_FOLIO) >= 0) {
      Notification.error(t('task_folio_duplicated'), t('task_folio_duplicated_desc'));
      return;
    }
    let displayId = 1;
    if (checklist.find(i => i.id === values[0])) {
      displayId = checklist.reduce((prev, current, currentIndex) => {
        if (current.id === values[0]) {
          if (current.displayId && current.displayId >= prev) {
            return current.displayId + 1;
          } else if (current.displayId === undefined) {
            checklist[currentIndex].displayId = prev;
            return prev + 1;
          }
        }
          return prev;
      }, displayId);
    }

    const updatedChecklist = [
      {
        ...task,
        name: `${task.name}${displayId > 1 ? ` ${displayId}` : ''}`,
        displayId,
        position: 0,
      },
      ...checklist,
    ];
    updateForm({ prop: 'checklist', value: updatedChecklist });
  }

  onSelectTaskGroup = (value) => {
    const {
    form: { checklist }, catalogs: { taskGroups }, updateForm, t,
  } = this.props;

    const finded = checklist.find(task => task.isGroup && task.id === value);
    if (finded) {
      Notification.error(t('task_group_duplicated'), t('task_group_duplicated_desc'));
      return;
    }

    const taskGroupToAdd = taskGroups.find(taskGroup => taskGroup.id === value);

    if (taskGroupToAdd) {
      taskGroupToAdd.isGroup = true;

      const updatedChecklist = [
        taskGroupToAdd,
        ...checklist,
      ];
      updateForm({ prop: 'checklist', value: updatedChecklist });
    }
  }

  getEvaluationIcon = (type) => {
    switch (type) {
      case 'graphic': return 'smile';
      case 'enum': return 'unordered-list';
      case 'range': return 'column-width';
      default: return 'line';
    }
  }

  addTask = () => {
    const {
      insertTask,
      form,
      form: { checklist },
    } = this.props;
    const defaultType = form.ruleType === RuleTypes.WEB ?
      TaskTypes.TEXT : TaskTypes.PHOTO;

    insertTask({
      checklist: [
        ...checklist,
        {
          value: '',
          name: '',
          type: defaultType,
          enumValues: '',
          evidenceType: 'required',
          keepValue: false,
          tracking: false,
          evaluation: null,
          position: 0,
          saveTask: false,
          repeat: false,
          visibleEvidence: true,
          forceTakingPicture: true,
        },
      ],
      taskIndex: checklist.length,
    });
  }

  deleteTask = (index) => {
    const { updateState, form: { checklist } } = this.props;
    const updatedChecklist = checklist.filter((t, i) => i !== index);
    updateState({ path: 'form.checklist', value: updatedChecklist });
  }

  deleteTaskGroup = (taskGroupId) => {
    const { updateState, form: { checklist } } = this.props;
    const updatedChecklist = checklist.filter(task => task.id !== taskGroupId);
    updateState({ path: 'form.checklist', value: updatedChecklist });
  }

  validate = () => {
    const { form } = this.props;
    const { valid, errors } = this.validator.validateForm(form);

    if (!valid) {
      this.setState({ formErrors: errors });
      return false;
    }
    this.setState({ formErrors: {} });
    return true;
  }

  fetchRulesByName = (params) => {
    const { fetchRulesByName, catalogs: { rules: { size } } } = this.props;
    fetchRulesByName({
      page: 0,
      size,
      ...params,
    });
  }

  taskType = () => {
    const taskTypesAux = Object.values(TaskTypes);
    return taskTypesAux.map(type => (<Option value={type}>{taskTypeLabel(type)}</Option>));
  }

  evidenceTypes = () => {
    const evidenceTypesAux = Object.values(EvidenceTypes);
    return evidenceTypesAux.map(type => (<Option value={type}>{evidenceTypeLabel(type)}</Option>));
  }

  renderTask = (taskIndex, task) => {
    const { form, form: { checklist }, catalogs: { rules: { content: rules } } } = this.props;
    const { evaluationsData, fromTaskitProcesses } = this.props;
    const readOnly = !!task.id;
    const canBeTracked = task.type === TaskTypes.TEXT ||
      task.type === TaskTypes.BARCODE || task.type === TaskTypes.NUMERIC;
    const canBeVisible = task.type === TaskTypes.TEXT || task.type === TaskTypes.NUMERIC ||
      task.type === TaskTypes.DATE || task.type === TaskTypes.TIME ||
      task.type === TaskTypes.ENUM || task.type === TaskTypes.MULTI_ENUM ||
      task.type === TaskTypes.CHECK || task.type === TaskTypes.RULE_FOLIO ||
      task.type === TaskTypes.USER || task.type === TaskTypes.CONTACT ||
      task.type === TaskTypes.BARCODE;

    const disableFolioType = checklist.findIndex(t => t.type === TaskTypes.RULE_FOLIO) >= 0;

    return (
      <Collapse.Panel
        header={
          <Row>
            <Col sm={24} md={7}>
              {task.name || <span style={{ color: 'darkgray' }}>{this.props.t('new_task')}</span>}
            </Col>
            <Col sm={24} md={13}>
              <Icon type={getTypeIcon(task.type)} className={['collapse-icon', 'vertical-border-right']} />
              <i
                className={`fa fa-asterisk collapse-icon vertical-border-right ${task.evidenceType === 'required' ? '' : 'color-lightgray'}`}
              />
              {
                form.ruleType !== RuleTypes.WEB &&
                <Icon
                  type={this.getEvaluationIcon(task.evaluation && task.evaluation.type)}
                  className={['collapse-icon', 'vertical-border-right', task.evaluation ? '' : 'color-lightgray']}
                />
              }
              {
                canBeTracked &&
                <Icon
                  component={RastreoSVG}
                  className={[
                    'collapse-icon',
                    'vertical-border-right',
                    task.tracking ? '' : 'color-lightgray',
                  ]}
                />
              }
              {
                canBeVisible &&
                <Icon
                  type="eye"
                  className={['collapse-icon', (task.visibleEvidence) ? '' : 'color-lightgray']}
                />
              }
              <Icon type="reload" className={['collapse-icon', !task.repeat && 'color-lightgray']} />
              {
                form.ruleType !== RuleTypes.WEB &&
                <Icon
                  component={ConservarSVG}
                  className={[
                    'collapse-icon',
                    'vertical-border-right',
                    task.keepValue ? '' : 'color-lightgray',
                  ]}
                />
              }
              <Icon
                type="save"
                className={['collapse-icon', (task.id || task.saveTask) ? '' : 'color-lightgray']}
              />
            </Col>
            <Col sm={24} md={4}>
              <Row type="flex" justify="end">
                { readOnly && <div style={{ color: '#8D8D8D' }}>{this.props.t('read_only')}</div> }
                {!fromTaskitProcesses &&
                <Icon
                  type="delete"
                  className="collapse-icon color-gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.deleteTask(taskIndex);
                  }}
                />
                }
              </Row>
            </Col>
          </Row>
        }
        key={taskIndex}
      >
        <div className="form-description">
          {this.props.t('complete_task_form')}
        </div>
        <TaskForm
          task={task}
          readOnly={readOnly}
          taskIndex={taskIndex}
          ruleType={form.ruleType}
          evaluationsData={evaluationsData}
          fromTaskitProcesses={fromTaskitProcesses}
          onChangeField={this.onChangeTask}
          enableTaskSaving={!readOnly}
          rules={rules}
          disableFolioType={disableFolioType}
          onSearchRules={this.onSearchRules}
          onBlurRule={this.onBlurRule}
          fetchRulesByName={this.fetchRulesByName}
        />
        {form.checklist[taskIndex].type === TaskTypes.RULE_LINK &&
          !form.checklist[taskIndex].additionalInfo &&
            <Alert message={this.props.t('activity_not_empty')} type="error" />
        }
      </Collapse.Panel>
    );
  }

  renderTaskGroup = (taskGroupIndex, taskGroup) => {
    const { fromTaskitProcesses, t } = this.props;

    return (
      <Collapse.Panel
        header={
          <Row>
            <Col sm={24} md={8}>
              {taskGroup.name || <span style={{ color: 'darkgray' }}>{this.props.t('new_task')}</span>}
            </Col>
            <Col sm={24} md={12}>
              <Icon type="reload" className={['collapse-icon', 'vertical-border-right', taskGroup.repetitive ? '' : 'color-lightgray']} />
              <Text>{`${taskGroup.checklist.length} ${t('task_group_tasks_count_label')}`}</Text>
            </Col>
            <Col sm={24} md={4}>
              <Row type="flex" justify="end">
                {!fromTaskitProcesses &&
                <Icon
                  type="delete"
                  className="collapse-icon color-gray"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.deleteTaskGroup(taskGroup.id);
                  }}
                />
                }
              </Row>
            </Col>
          </Row>
        }
        key={taskGroupIndex}
      >
        <List bordered>
          {taskGroup.checklist.map((task, index) => (
            <List.Item>
              <Row style={{ flex: 1 }}>
                <Col span={9}>
                  <Text>{task.name}</Text>
                </Col>
                <Col span={15}>
                  <Row>
                    <Col sm={10} md={10}>
                      <Text>{taskTypeLabel(task.type)}</Text>
                      <Text>, {evidenceTypeLabel(task.evidenceType)}</Text>
                      <Text>{task.keepValue ? `, ${t('preserve_value_label')}` : ''}</Text>
                    </Col>
                    <Col sm={14} md={14}>
                      {
                        task.type === TaskTypes.ENUM &&
                        <div>
                          <div className="form-label" style={{ margin: '0px' }}>
                            {t('define_options')}.
                            <span>
                              {t('separate_coma')}
                            </span>
                          </div>
                          <Input
                            placeholder={t('example_options')}
                            value={task.enumValues}
                            onChange={e => this.onChangeTaskGroup(taskGroupIndex, index, 'enumValues', e.target.value)}
                          />
                        </div>
                    }
                    </Col>
                  </Row>
                </Col>
              </Row>
            </List.Item>
          ))}
        </List>
      </Collapse.Panel>
    );
  }

  render() {
    const {
      tasksData,
      form,
      form: { checklist },
      activePanel,
      updateState,
      t,
      fromTaskitProcesses,
      catalogs: { taskGroups },
    } = this.props;


    return (
      <div>
        {!fromTaskitProcesses &&
        <Row className="form-section" style={{ paddingBottom: '0px' }}>
          <Col sm={24} lg={24}>
            <div className="form-label">
              {t('what_tasks_to_complete')}
              <span>
                {t('define_activity_tasks')}
              </span>
            </div>
            <Button
              type="link"
              onClick={this.addTask}
            >
              + {t('add_task')}
            </Button> {t('or')}
            <Select
              mode="multiple"
              style={{ width: '200px', marginLeft: '20px' }}
              placeholder={t('add_saved_task')}
              value={[]}
              notFoundContent={null}
              filterOption={(input, option) => option.props.children.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
              onChange={this.onSelectTask}
            >
              {tasksData.filter(task => (
                form.ruleType !== RuleTypes.WEB || (
                task.type === TaskTypes.CHECK || task.type === TaskTypes.ENUM ||
                task.type === TaskTypes.TEXT || task.type === TaskTypes.NUMERIC ||
                task.type === TaskTypes.DATE || task.type === TaskTypes.TIME
              ))).map(item => (
                <Select.Option key={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
            <Select
              style={{ width: '200px', marginLeft: '20px' }}
              placeholder={t('add_task_group')}
              value={[]}
              notFoundContent={null}
              filterOption={(input, option) => option.props.children.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0}
              onSelect={this.onSelectTaskGroup}
            >
              {taskGroups.map(item => (
                <Select.Option key={item.id}>{item.name}</Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        }
        <Row className="form-section" style={{ paddingTop: '0px' }}>
          <DragDropContext
            onDragStart={() => {}}
            onDragEnd={this.onDragEnd}
          >
            <Droppable droppableId="droppable">
              {provided => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  { checklist.map((task, index) => (
                    <Draggable
                      key={`key_${index}`} // eslint-disable-line
                      draggableId={`key_${index}`}
                      index={index}
                      isDragDisabled={fromTaskitProcesses}
                    >
                      {provided2 => (
                        <div
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                        >
                          <i
                            className="fa fa-bars"
                            aria-hidden="true"
                            style={{ position: 'relative', top: '35px' }}
                            {...provided2.dragHandleProps}
                          />
                          <Collapse
                            activeKey={activePanel}
                            onChange={(k) => {
                              const active = k.length > 0 ? k[k.length - 1] : '';
                              updateState({ path: 'activePanel', value: [active] });
                            }}
                            style={{ marginLeft: '30px' }}
                          >
                            { task.isGroup ?
                              this.renderTaskGroup(index, task) :
                                this.renderTask(index, task)
                            }

                          </Collapse>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ErrorText message={getFieldError(this.state.formErrors, 'checklist')} />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ activityFormContainer }) => ({
  ...activityFormContainer,
});

export default withTranslation('translation', { withRef: true })(connect(
  mapStateToProps, actions, null,
  { withRef: true },
  )(TasksStep));
