/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-28 00:25:28 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-02-06 11:19:58
 */
import React from 'react';
import { Row, Col, Tooltip, Button, Icon, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { SelectData } from '../../../../common/components/widgets';

const { TabPane } = Tabs;

const HeaderContent = ({
  dataState, selectedState, onSelectState, onCreate,
  onDeleteFilter, disabledCreate, filtering, onTab,
}) => {
  const { t } = useTranslation();

  return (
    <div className="headerContainer" >
      <Tabs defaultActiveKey="1" onChange={onTab} >
        <TabPane key="1" tab={t('home')}>
          <Button type="primary" onClick={onCreate} disabled={disabledCreate}>{t('new_office')}</Button>
        </TabPane>
        <TabPane key="2" tab={<span><Icon type="filter" className="filterIcon" />{t('filter')}</span>}>
          <Row className="filtersRow" >
            <Col span={6}>
              <h1 className="textBranch">{t('state')}</h1>
              <SelectData
                selected={selectedState}
                data={dataState}
                onSelect={onSelectState}
              />
              {filtering && (
                <Tooltip title={t('delete_filter')}>
                  <Button className="deleteFilterButton" type="ghost" size="small" shape="circle" icon="close" onClick={onDeleteFilter} />
                </Tooltip>)}
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    </div>
  );
};

export { HeaderContent };
