/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-06 05:21:48 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-07-17 16:52:49
 */

const renderColumn = (title, dataIndex, type, width, maxLength, required) =>
  ({
    title, dataIndex, type, width, maxLength, required,
  });
export default renderColumn;
