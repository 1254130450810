/*
 * @Author: Cesar Medina
 * @Date: 2019-04-25 23:46:03 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-01-30 16:05:30
 */
const CONTAINER_KEY = 'PAYMENTS_CONTAINER';
export const GENERATE_SUBSCRIPTION = `${CONTAINER_KEY}_GENERATE_SUBSCRIPTION`;
export const GENERATE_SUBSCRIPTION_FULFILLED = `${GENERATE_SUBSCRIPTION}_FULFILLED`;
export const VALIDATE_CODE = 'VALIDATE_CODE';
export const VALIDATE_CODE_FULFILLED = `${VALIDATE_CODE}_FULFILLED`;
