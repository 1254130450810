/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 14:41:21 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-18 12:22:50
 */

import React, { Component } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import numeral from 'numeral';

import { columns, fetchActions, setPaymentStatus } from './utils';
import { actions } from './redux';

class Orders extends Component {
  constructor(props) {
    super(props);
    this.data = this.getColumns();
  }

  componentDidMount = () => {
    fetchActions(this.props);
  };

  getColumns = () => columns().map((item) => {
    switch (item.dataIndex) {
      case 'createdAt':
        return ({
          ...item,
          render: text => <div>{moment.unix(text).format('DD/MM/YYYY - hh:mm a')}</div>,
        });
      case 'amount':
        return ({
          ...item,
          render: text => <div>{numeral(text).format('$0,0.00')}</div>,
        });
      case 'status':
        return ({
          ...item,
          render: text => <div>{setPaymentStatus(text)}</div>,
        });
      case 'last4':
        return ({
          ...item,
          render: text => (
            <div>
              &#x25CF;&#x25CF;&#x25CF;&#x25CF;
              &#x25CF;&#x25CF;&#x25CF;&#x25CF;
              &#x25CF;&#x25CF;&#x25CF;&#x25CF; {text}
            </div>
          ),
        });
      // case 'currency':
      // case 'quantity':
      // case 'unitPrice':
      default:
        return ({
          ...item,
          render: text => <div>{text}</div>,
        });
    }
  });

  render() {
    const { catalogs: { orders }, isLoading, t } = this.props;

    return (
      <div>
        <Table
          scroll={{ x: 1024 }}
          ref={(table) => { this.table = table; }}
          rowKey="createdAt"
          dataSource={orders}
          columns={this.data}
          locale={{ emptyText: t('show_no_data') }}
          loading={isLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ ordersContainer }) => ordersContainer;

export default withTranslation()(connect(mapStateToProps, actions)(Orders));
