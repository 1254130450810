/*
 * @Author: Cesar Medina
 * @Date: 2019-04-25 12:00:49 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-02-04 10:29:29
 */

import React, { Component } from 'react';
import { Col, Steps, Button, Icon, Row, Modal, Input } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { CONEKTA_KEY } from '../../boot/config';
import { PaymentForm, PaymentComponent, PlanForm, Notification } from '../../common/components/widgets/';
import { actions } from './redux';
import { formatCardData } from './utils';

const conekta = Conekta; // eslint-disable-line
const { Step } = Steps;

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  componentDidMount() {
    const { updateState } = this.props;

    conekta.setPublicKey(CONEKTA_KEY);
    conekta.setLanguage('es');
    updateState({ path: 'planForm.userQuantity', value: 1 });
    updateState({ path: 'planForm.subscriptionType', value: 'monthly' });
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  onChangePlanForm = (value, index) => {
    const { updateState } = this.props;
    updateState({ path: `planForm.${index}`, value });
  }

  getSteps = () => ([{
    title: 'Selección de Suscripción',
    content: (
      <Row gutter={16} >
        <Col span={9} offset={3}>
          <PlanForm
            onChange={this.onChangePlanForm}
            formData={this.props.planForm}
            error={this.props.error}
            openModal={() => this.props.updateState({ path: 'codeModal', value: true })}
            annualCost={this.props.costs.annual}
            monthlyCost={this.props.costs.monthly}
            minValue={this.props.minQuantity}
          />
        </Col>
        <Col span={12} >
          <PaymentComponent
            annualCost={this.props.costs.annual}
            monthlyCost={this.props.costs.monthly}
          />
        </Col>
      </Row>),
  }, {
    title: 'Metodo de Pago',
    content: (
      <Row gutter={16}>
        <Col span={10} offset={4}>
          <PaymentForm
            onSubmit={this.handleSubmit}
            isLoading={this.props.loadingButton}
          />
        </Col>
        <Col span={6} >
          <PlanForm
            readOnly
            formData={this.props.planForm}
            annualCost={this.props.costs.annual}
            monthlyCost={this.props.costs.monthly}
          />
        </Col>
      </Row>),
  }]);

  errorResponseHandler = (error) => {
    const { updateState } = this.props;
    updateState({ path: 'loadingButton', value: false });
    Notification.error('Error', error.message_to_purchaser);
  };

  successResponseHandler = (token) => {
    const {
      generateSubscription,
      planForm: { userQuantity, subscriptionType, code },
    } = this.props;

    generateSubscription(
      token.id,
      userQuantity,
      subscriptionType,
      code,
      () => this.props.history.push('/subscriptions'),
    );
  };

  handleSubmit = (form) => {
    const { updateState } = this.props;
    updateState({ path: 'loadingButton', value: true });

    conekta.Token.create(
      formatCardData(form),
      this.successResponseHandler,
      this.errorResponseHandler,
    );
  }

  validateNumber() {
    const { planForm: { userQuantity } } = this.props;
    return !isNaN(userQuantity); // eslint-disable-line
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    const { current } = this.state;
    const {
      updateState,
      codeModal,
      validateCode,
      distributorCode,
      errorDistributorCode,
      t,
    } = this.props;
    const steps = this.getSteps();
    return (
      <div>
        <Steps current={current}>
          {steps.map(item => <Step key={item.title} title={item.title} />)}
        </Steps>
        <div>
          <div className="steps-content">
            {steps[current].content}
            <br />
            <br />
            {
              current - 1 === steps.length - 2
              && <Button type="primary" onClick={() => this.prev()}><Icon type="left" />{t('back')}</Button>
            }
            {
              current < steps.length
              && (
                <Link to="/subscriptions">
                  <Button
                    onClick={() => { }}
                    type="danger"
                    style={{ marginBottom: 16, marginLeft: 8, marginRight: 8 }}
                  >
                    {t('cancel')}
                    <Icon type="close" />
                  </Button>
                </Link>)
            }
            {
              current - 1 < steps.length - 2 &&
              <Button
                type="primary"
                onClick={() => {
                  if (this.validateNumber()) {
                    this.next();
                    updateState({ path: 'error', value: false });
                  } else {
                    updateState({ path: 'error', value: true });
                  }
                }}
              >
                {t('next')}
                <Icon type="right" />
              </Button>
            }
          </div>
        </div>
        <Modal
          title="Código de distribuidor"
          visible={codeModal}
          onOk={() => validateCode(distributorCode)}
          onCancel={() => updateState({ path: 'codeModal', value: false })}
          cancelText={t('cancel')}
          okText="Aplicar"
          className="modal-header-dark"
          centered
          maskStyle={{ backgroundColor: '#0000007F' }}
        >
          <p>Introduce código de distribuidor</p>
          <Input
            value={distributorCode}
            onChange={event => updateState({ path: 'distributorCode', value: event.target.value })}
          />
          {
            errorDistributorCode &&
            <p style={{ color: '#F5353F' }}>Código Invalido</p>
          }
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = ({ paymentsContainer }) => paymentsContainer;
export default withTranslation()(connect(mapStateToProps, actions)(Payments));
