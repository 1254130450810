/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 14:41:21 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-05-04 10:14:51
 */

import React, { Component } from 'react';
import { Table, Row, Col, Button, Input } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { GroupForm, PopoverList } from '../../../../../common/components/widgets';
import { RenderActionsCell } from '../../../../../common/components/widgets/table';
import { actions } from './../../../redux';

class Groups extends Component {
  constructor(props) {
    super(props);
    this.columns = this.getColumns();
  }

  componentDidMount = () => {
    const { fetchGroups } = this.props;

    fetchGroups();
  };

  onChangeFilter = (filter, value) => {
    const {
      updateState,
      filters,
      fetchGroups,
    } = this.props;
    const updatedFilters = {
      ...filters,
      [filter]: value,
    };

    updateState({ path: 'filters', value: updatedFilters });
    fetchGroups(updatedFilters);
  }

  onSave = (form) => {
    const {
      registerGroup,
      updateGroup,
      currentGroup,
      filters,
    } = this.props;
    const group = {
      ...currentGroup,
      ...form,
    };

    if (currentGroup) {
      updateGroup(group, filters);
    } else {
      registerGroup(group, filters);
    }
  }

  onDelete(id) {
    const { deleteGroup, filters } = this.props;
    deleteGroup(id, filters);
  }

  getColumns = () => [
    {
      title: this.props.t('name'),
      dataIndex: 'name',
      onCell: () => ({ 'data-label': this.props.t('name') }),
    },
    {
      title: this.props.t('description'),
      dataIndex: 'description',
      onCell: () => ({ 'data-label': this.props.t('description') }),
    },
    {
      title: this.props.t('contacts'),
      dataIndex: 'contacts',
      align: 'center',
      onCell: () => ({ 'data-label': this.props.t('contacts') }),
      render: (text, record) => (
        <PopoverList
          title={`${this.props.t('group_contacts')}:`}
          label={`${this.props.t('contacts')}: ${record.contacts.length}`}
          data={record.contacts}
          renderItem={this.renderContactInfo}
        />
      ),
    },
    {
      title: this.props.t('actions'),
      width: 200,
      onCell: () => ({ 'data-label': this.props.t('actions') }),
      render: (text, record) => (
        <RenderActionsCell
          creating={null}
          record={record}
          onSave={() => {}}
          onCancel={() => {}}
          onEdit={() => {
            this.props.openGroupModal();
            this.props.loadGroupForm(record);
          }}
          onDelete={() => this.onDelete(record.id)}
        />
      ),
    },
  ];

  handleFormChange = (changedFields) => {
    const { groupForm } = this.props;
    this.props.updateState({
      path: 'groupForm',
      value: { ...groupForm, ...changedFields },
    });
  };

  renderContactInfo = (contact) => {
    const info = [
      `${contact.name} ${contact.lastName}`,
      contact.companyName || '-----',
      contact.role || '-----',
      contact.email || '-----',
      contact.phoneNumber || '-----',
    ];

    return info.join(' / ');
  }

  render() {
    const {
      catalogs: { groups },
      filters,
      isLoading,
      currentPage,
      updateState,
      openGroupModal,
      closeGroupModal,
      resetGroupForm,
      showGroupModal,
      groupForm,
      t,
    } = this.props;

    return (
      <div>
        <GroupForm
          visible={showGroupModal}
          onChange={this.handleFormChange}
          onClose={() => {
            closeGroupModal();
            resetGroupForm();
          }}
          onSave={this.onSave}
          {...groupForm}
        />
        <div className="headerContainer">
          <Row className="filtersRow">
            <Col xs={24} sm={24} md={12} className="filter-box">
              <div style={{ fontSize: '14px' }}>{t('search_group')}</div>
              <Input.Search
                className="select-data"
                value={filters.name}
                onChange={e => updateState({ path: 'filters.name', value: e.target.value })}
                onSearch={value => this.onChangeFilter('name', value)}
                enterButton
                style={{ width: '200px' }}
              />
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Row justify="end" type="flex">
                <Button type="primary" onClick={openGroupModal}>
                  {t('new_group')}
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
        <Table
          ref={(table) => { this.table = table; }}
          rowKey="id"
          className="responsive-table"
          dataSource={groups}
          columns={this.columns}
          locale={{ emptyText: t('show_no_data') }}
          loading={isLoading}
          pagination={{
            current: currentPage,
            onChange: page => this.props.updateState({ path: 'currentPage', value: page }),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ contactsContainer, generalData: { isLoading } }) => ({
  ...contactsContainer,
  isLoading,
});

export default withTranslation()(connect(mapStateToProps, actions)(Groups));
