/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:39:16
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 17:14:56
 */

import i18n from 'i18next';

import { renderColumn } from '../../common/components/widgets/table';

const columns = () => [
  renderColumn(i18n.t('name'), 'name', 'input', 160, 100, true),
  renderColumn(i18n.t('description'), 'description', 'input', 260, 100, false),
  renderColumn(i18n.t('actions'), 'actions', 'actions', 125),
];

export default columns;
