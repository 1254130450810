import React from 'react';
import { Radio, Input, Modal, Form, Select, Icon, Switch, Tooltip, InputNumber, Row, Col, Checkbox } from 'antd';
import { withTranslation } from 'react-i18next';
import { TaskTypes, WidgetTypes } from '../../../constants';
import './styles.css';


class WidgetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialRuleIdDisabled: false,
    };
    this.timer = null;
  }

  componentDidMount = () => {
    const { widget: { taskId, type }, fetchTasks, fetchRulesByName } = this.props;
    if (type && type === WidgetTypes.TIME_BETWEEN_RULES) {
      fetchTasks({ linked: true, includeTaskitProccess: true });
      const self = this;
      setTimeout(() => {
        const { tasks } = self.props;
        const task = tasks.find(tsk => tsk.id === taskId);
        if (task) {
          fetchRulesByName({ taskId, ruleId: task.additionalInfo });
        }
      }, 500);
    }
  }

  onSelectTaskOfLink = (value) => {
    const { tasks, form } = this.props;
    const task = tasks.find(tsk => tsk.id === value);
    if (task && task.type === TaskTypes.RULE_LINK) {
      this.props.fetchRulesByName({ taskId: value, ruleId: task.additionalInfo });
      form.setFieldsValue({
        initialRuleId: task.additionalInfo,
        finalRuleId: null,
      });
      this.setState({ initialRuleIdDisabled: true });
    } else {
      this.props.fetchRulesByName({ taskId: value });
      form.setFieldsValue({
        initialRuleId: null,
        finalRuleId: null,
      });
      this.setState({ initialRuleIdDisabled: false });
    }
  }

  onSearchFinalRule = value => this.props.onSearchRules(
    value,
    {
      taskId: this.props.form.getFieldValue('taskId'),
      ruleId: this.props.form.getFieldValue('initialRuleId') ? this.props.form.getFieldValue('initialRuleId') : null,
  },
)

  onSearchInitialRule = value => this.props.onSearchRules(
    value,
    {
      taskId: this.props.form.getFieldValue('taskId'),
      ruleId: this.props.form.getFieldValue('finalRuleId') ? this.props.form.getFieldValue('finalRuleId') : null,
  },
)

  getDateToFinishFromValues(values) {
    if (values.useDateToFinish && values.dateToFinish) {
      if (this.isUUID(values.dateToFinish)) {
        return { taskId: values.dateToFinish };
      }
        return { taskName: values.dateToFinish };
    }
      return null;
  }

  getTaskToFinishFromValues(values) {
    if (values.useTaskToFinish && values.taskToFinish) {
      if (this.isUUID(values.taskToFinish)) {
        return {
          id: values.taskToFinish,
          value: values.taskToFinishValue,
        };
      }
        return {
          name: values.taskToFinish,
          value: values.taskToFinishValue,
        };
    }
      return null;
  }

  isUUID = (value) => {
    const regExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regExp.test(value);
  }

  handleChangeTask = () => {
    const { form } = this.props;

    form.setFieldsValue({
      selectedEnumValues: [],
    });
  }

  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSave({
          ...values,
          taskToFinish: this.getTaskToFinishFromValues(values),
          dateToFinish: this.getDateToFinishFromValues(values),
          presentationType: values.useDateToFinish &&
            values.dateToFinish &&
              values.presentationType ? values.presentationType : null,
          referenceNumber: values.enableReferenceNumber ? values.referenceNumber : null,
          view: 'frequency',
        });
      }
    });
  }

  handleClose = () => {
    this.props.onClose();
  }

  hasFinalTasks() {
    const { rules, form: { getFieldValue } } = this.props;
    const finalRule = rules.find(r => r.id === getFieldValue('finalRuleId'));
    if (finalRule && finalRule.checklist) {
      const checklist = finalRule.checklist.filter(task => (
        task.type === TaskTypes.ENUM || task.type === TaskTypes.CHECK
      ));
      return !!checklist.filter((task, index) => (
        !checklist.find((t, i) => (t.id && task.id ?
           t.id === task.id && i !== index :
           t.name === task.name && i !== index))
      )).length;
    }
    return false;
  }

  hasInitialRuleDateTasks() {
    const { rules, form: { getFieldValue } } = this.props;
    const initialRule = rules.find(r => r.id === getFieldValue('initialRuleId'));
    if (initialRule && initialRule.checklist) {
      return !!initialRule.checklist.filter(task => task.type === TaskTypes.DATE).length;
    }
    return false;
  }

  referenceNumberValidator = (rule, value, callback) => {
    if (value >= 0 && value <= 100) {
      callback();
    }
    callback(this.props.t('invalid_number'));
  }

  labelWithInfo = (labelText, title) => (
    <span>
      {labelText}&nbsp;
      <Tooltip title={title}>
        <Icon type="question-circle" theme="outlined" />
      </Tooltip>
    </span>
  )

  renderRules() {
    const { rules } = this.props;
    return rules.map(rule => (<Select.Option value={rule.id}>{rule.name}</Select.Option>));
  }

  renderFinalTasks() {
    const { rules, form: { getFieldValue } } = this.props;
    const finalRule = rules.find(r => r.id === getFieldValue('finalRuleId'));
    if (finalRule && finalRule.checklist) {
      let checklist = finalRule.checklist.filter(task => (
        task.type === TaskTypes.ENUM || task.type === TaskTypes.CHECK
      ));
      checklist = checklist.filter((task, index) => (
        !checklist.find((t, i) => (
          t.id && task.id ?
            t.id === task.id && i !== index :
              t.name === task.name && i !== index))
      ));
      return checklist.map(task => (
        <Select.Option
          value={task.id || task.name}
        >{task.name}
        </Select.Option>
      ));
    }
    return null;
  }

  renderFinalTaskValues() {
    const { rules, form: { getFieldValue }, t } = this.props;
    const finalRule = rules.find(r => r.id === getFieldValue('finalRuleId'));
    if (finalRule && finalRule.checklist) {
      const selectedTask = finalRule.checklist.find(task => getFieldValue('taskToFinish') === task.id ||
          getFieldValue('taskToFinish') === task.name);
      if (selectedTask) {
        if (selectedTask.type === TaskTypes.CHECK) {
          const options = [
            <Select.Option value="true">{t('selected')}</Select.Option>,
            <Select.Option value="false">{t('not_selected')}</Select.Option>,
          ];
          return options;
        } else if (selectedTask.type === TaskTypes.ENUM) {
          const enumValues = selectedTask.enumValues.split(',').filter(e => e && e.trim());
          return enumValues.map(value => (<Select.Option value={value}>{value}</Select.Option>));
        }
      }
    }
    return null;
  }

  renderInitialRuleDateTasks() {
    const { rules, form: { getFieldValue } } = this.props;
    const initialRule = rules.find(r => r.id === getFieldValue('initialRuleId'));
    if (initialRule && initialRule.checklist) {
      const checklist = initialRule.checklist.filter(task => task.type === TaskTypes.DATE);
      return checklist.map(task => (
        <Select.Option
          value={task.id || task.name}
        >{task.name}
        </Select.Option>
      ));
    }
    return null;
  }


  render() {
    const {
      tasks,
      widget,
      form: { getFieldDecorator, getFieldValue },
      t,
    } = this.props;

    const hasFinalTasks = this.hasFinalTasks();
    const hasInitialRuleDateTasks = this.hasInitialRuleDateTasks();

    return (
      <Modal
        title={t('add_widget')}
        className="modal-header-dark"
        style={{ minWidth: '40vw' }}
        maskStyle={{ backgroundColor: '#00000066' }}
        onOk={this.handleOk}
        onCancel={this.handleClose}
        okText={t('save')}
        cancelText={t('cancel')}
        visible
      >
        <Form layout="vertical">
          <Form.Item label={t('name')}>
            {getFieldDecorator('name', {
              rules: [{ required: true, message: t('field_required') }],
              initialValue: widget.name || '',
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t('widget_type')}>
            {getFieldDecorator('type', {
              rules: [{ required: true, message: t('field_required') }],
              initialValue: widget.type || 'bar',
            })((
              <Radio.Group size="large">
                <Radio.Button value="bar" style={{ height: '60px' }} onChange={() => this.props.fetchTasks({ type: TaskTypes.ENUM, includeTaskitProccess: true })} >
                  <div className="form__radio-container">
                    <Icon type="bar-chart" className="radio-icon" />
                    <div>{t('bar_chart')}</div>
                  </div>
                </Radio.Button>
                <Radio.Button value="pie" style={{ height: '60px' }} onChange={() => this.props.fetchTasks({ type: TaskTypes.ENUM, includeTaskitProccess: true })} >
                  <div className="form__radio-container">
                    <Icon type="pie-chart" className="radio-icon" />
                    <div>{t('pie_chart')}</div>
                  </div>
                </Radio.Button>
                <Radio.Button value="line" style={{ height: '60px' }} onChange={() => this.props.fetchTasks({ type: TaskTypes.ENUM, includeTaskitProccess: true })} >
                  <div className="form__radio-container">
                    <Icon type="line-chart" className="radio-icon" />
                    <div>{t('line_chart')}</div>
                  </div>
                </Radio.Button>
                <Radio.Button value="time_between_rules" style={{ height: '60px' }} onChange={() => this.props.fetchTasks({ linked: true, includeTaskitProccess: true })}>
                  <div className="form__radio-container">
                    <Icon type="clock-circle" className="radio-icon" />
                    <div>{t('time_between_rules_chart')}</div>
                  </div>
                </Radio.Button>
                <Radio.Button value="performance_by_branch" style={{ height: '60px' }} onChange={() => this.props.fetchTasks({ type: TaskTypes.ENUM, includeTaskitProccess: true })} >
                  <div className="form__radio-container">
                    <Icon type="bar-chart" className="radio-icon" />
                    <div>{t('branch_performance')}</div>
                  </div>
                </Radio.Button>
              </Radio.Group>
            ))}
          </Form.Item>
          {getFieldValue('type') !== WidgetTypes.TIME_BETWEEN_RULES &&
            <Form.Item
              label={t('graph_from_task')}
              style={{ display: (getFieldValue('type') !== WidgetTypes.TIME_BETWEEN_RULES && getFieldValue('type') !== WidgetTypes.PERFORMANCE_BY_BRANCH) ? 'block' : 'none' }}
            >
              {getFieldDecorator('taskId', {
                rules: [(getFieldValue('type') !== WidgetTypes.PERFORMANCE_BY_BRANCH) ? { required: true, message: t('field_required') } : ''],
                initialValue: widget.taskId,
              })((
                <Select
                  placeholder={t('select_task')}
                  onChange={this.handleChangeTask}
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    tasks.map(i => (
                      <Select.Option key={i.id} value={i.id}>{ i.taskitProcessId === null ? i.name : `PT - ${i.name}`}</Select.Option>
                    ))
                  }
                </Select>
              ))}
            </Form.Item>
          }
          <Form.Item
            label={(
              <span>
                {t('show_dimensions_with_value')}&nbsp;
                <Tooltip title={t('show_dimensions_with_value_info')}>
                  <Icon type="question-circle" theme="outlined" />
                </Tooltip>
              </span>
            )}
            style={{
                display: getFieldValue('type') !== WidgetTypes.PIE
                && getFieldValue('type') !== WidgetTypes.TIME_BETWEEN_RULES
                && getFieldValue('type') !== WidgetTypes.PERFORMANCE_BY_BRANCH ? 'block' : 'none',
            }}
          >
            {getFieldDecorator('hideEmpty', {
                rules: [{ required: true, message: t('field_required') }],
                valuePropName: 'checked',
                initialValue: widget.hideEmpty || false,
              })(<Switch />)}
          </Form.Item>
          <Form.Item
            label={t('exclude_chart_dimensions')}
            style={{
                display: getFieldValue('type') !== WidgetTypes.TIME_BETWEEN_RULES
                && getFieldValue('type') !== WidgetTypes.PERFORMANCE_BY_BRANCH ? 'block' : 'none',
}}
          >
            {getFieldDecorator('selectedEnumValues', {
              initialValue: widget.selectedEnumValues || [],
            })((
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Selecciona..."
                disabled={!getFieldValue('taskId')}
              >
                {getFieldValue('taskId') &&
                  tasks.find(i => i.id === getFieldValue('taskId')).enumValues
                    .split(',')
                    .filter(i => i.trim().length > 0)
                    .map(i => (
                      <Select.Option key={i}>{i}</Select.Option>
                    ))
                }
              </Select>
            ))}
          </Form.Item>
          {getFieldValue('type') === WidgetTypes.PERFORMANCE_BY_BRANCH &&
          <Form.Item
            label={t('choose_branches')}
            style={{ display: getFieldValue('type') === WidgetTypes.PERFORMANCE_BY_BRANCH ? 'block' : 'none' }}
          >
            {getFieldDecorator('selectedBranchOffices', {
              rules: [{ required: true, message: t('field_required') }],
              initialValue: widget.selectedBranchOffices,
            })((
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="Selecciona..."
              >
                {this.props.branchOffices.map(i => <Select.Option key={i.id} value={`${i.id},${i.name},${i.state.timeZone}`}>{i.name}</Select.Option>)}
              </Select>
            ))}
          </Form.Item>
          }
          <Form.Item
            label={t('reference_number')}
            style={{ display: getFieldValue('type') === WidgetTypes.LINE ? 'block' : 'none' }}
          >
            {getFieldDecorator('enableReferenceNumber', {
              valuePropName: 'checked',
              initialValue: widget.referenceNumber !== null,
            })(<Switch style={{ marginRight: '10px' }} />)}
            {getFieldDecorator('referenceNumber', {
              rules: [{ validator: this.referenceNumberValidator }],
              initialValue: widget.referenceNumber || 0,
            })(<InputNumber disabled={!getFieldValue('enableReferenceNumber')} />)}
          </Form.Item>

          {getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES &&
            <Form.Item
              label={this.labelWithInfo(t('task_of_link'), t('task_of_link_info'))}
              style={{ display: getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES ? 'block' : 'none' }}
            >
              {getFieldDecorator('taskId', {
                rules: [{ required: true, message: t('field_required') }],
                initialValue: widget.taskId,
              })((
                <Select placeholder={t('select_task')} onSelect={this.onSelectTaskOfLink}>
                  {
                    tasks.map(i => (
                      <Select.Option key={i.id} value={i.id}>{i.taskitProcessId === null ? i.name : `PT - ${i.name}`}</Select.Option>
                    ))
                  }
                </Select>
              ))}
            </Form.Item>
          }
          {getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES &&
          <Form.Item
            label={this.labelWithInfo(t('initial_activity'), t('initial_activity_info'))}
            style={{
              display: getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES ?
              'block' : 'none',
            }}
          >
            {getFieldDecorator('initialRuleId', {
              rules: [{ required: true, message: t('field_required') }],
              initialValue: widget.initialRuleId,
            })((
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder={t('select_activity')}
                // onBlur={this.props.onBlurRule}
                // onSelect={value => this.form.setFieldsValue({initialRuleId: value})}
                onSearch={this.onSearchInitialRule}
                filterOption={false}
                disabled={this.state.initialRuleIdDisabled}
              >
                {this.renderRules()}
              </Select>
            ))}
          </Form.Item>
          }

          {getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES &&
          <Form.Item
            label={this.labelWithInfo(t('final_activity'), t('final_activity_info'))}
            style={{
              display: getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES ? 'block' : 'none',
            }}
          >
            {getFieldDecorator('finalRuleId', {
              rules: [
                { required: true, message: t('field_required') },
                {
                  validator: () => getFieldValue('finalRuleId') !== getFieldValue('initialRuleId'),
                  message: t('final_activity_cant_same_initial_activity'),
                },
              ],
              initialValue: widget.finalRuleId,
            })((
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder={t('select_activity')}
                // onBlur={this.props.onBlurRule}
                onSelect={() => this.props.form.setFieldsValue({
                  useTaskToFinish: false, useDateToFinish: false,
                })}
                onSearch={this.onSearchFinalRule}
                filterOption={false}
                // disabled={readOnly}
              >
                {this.renderRules()}
              </Select>
            ))}
          </Form.Item>
  }

          {getFieldValue('finalRuleId') && hasFinalTasks &&
            <Form.Item
              style={{
                display: getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES
                  && hasFinalTasks
                    ? 'block' : 'none',
                }}
            >
              {getFieldDecorator('useTaskToFinish', {
                initialValue: !!widget.taskToFinish,
              })((
                <Checkbox defaultChecked={!!widget.taskToFinish}>{this.labelWithInfo(t('task_to_finish_label'), t('task_to_finish_label_info'))}</Checkbox>
              ))}
            </Form.Item>
          }

          {getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES
            && hasFinalTasks
            && getFieldValue('useTaskToFinish') === true &&
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t('task')}
                >
                  {getFieldDecorator('taskToFinish', {
                    rules: [
                      { required: true, message: t('field_required') },
                    ],
                    initialValue: widget.taskToFinish ?
                      widget.taskToFinish.id || widget.taskToFinish.name : null,
                  })((
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder={t('select_task')}
                      // onBlur={this.props.onBlurRule}
                      onSelect={() => this.props.form.setFieldsValue({ taskToFinishValue: null })}
                      filterOption={false}
                      // disabled={readOnly}
                    >
                      {this.renderFinalTasks()}
                    </Select>
                  ))}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t('value')}
                  style={{
                    display: getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES
                      && getFieldValue('taskToFinish')
                        ? 'block' : 'none',
                  }}
                >
                  {getFieldDecorator('taskToFinishValue', {
                    rules: [
                      { required: true, message: t('field_required') },
                    ],
                    initialValue: widget.taskToFinish && widget.taskToFinish.value,
                  })((
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      filterOption={false}
                    >
                      {this.renderFinalTaskValues()}
                    </Select>
                  ))}
                </Form.Item>
              </Col>
            </Row>
          }

          {hasInitialRuleDateTasks &&
            <Form.Item
              style={{ display: getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES ? 'block' : 'none' }}
            >
              {getFieldDecorator('useDateToFinish', {
                initialValue: !!widget.dateToFinish,
              })((
                <Checkbox defaultChecked={!!widget.dateToFinish}>{t('use_date_to_finish_label')}</Checkbox>
              ))}
            </Form.Item>
          }

          {getFieldValue('useDateToFinish') && hasInitialRuleDateTasks &&
            <Form.Item
              label={t('date_to_finish_label')}
              style={{
                display: getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES
                  && getFieldValue('useDateToFinish')
                    ? 'block' : 'none',
              }}
            >
              {getFieldDecorator('dateToFinish', {
                rules: [
                  { required: true, message: t('field_required') },
                ],
                initialValue: widget.dateToFinish ?
                  widget.dateToFinish.taskId || widget.dateToFinish.taskName : null,
              })((
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  filterOption={false}
                >
                  {this.renderInitialRuleDateTasks()}
                </Select>
              ))}
            </Form.Item>
          }
          {getFieldValue('useDateToFinish') &&
            <Form.Item
              style={{
                display: getFieldValue('type') === WidgetTypes.TIME_BETWEEN_RULES
                  && getFieldValue('useDateToFinish')
                    ? 'block' : 'none',
              }}
            >
              {getFieldDecorator('presentationType', {
                initialValue: widget.presentationType || 'days',
              })((
                <Radio.Group size="small">
                  <Radio value="days">{t('present_days_label')}</Radio>
                  <Radio style={{ textJustify: 'inter-character' }} value="percentage">{t('present_percentage_label')}</Radio>
                </Radio.Group>
              ))}
            </Form.Item>
          }

        </Form>
      </Modal>
    );
  }
}


const WrappedWidgetForm = Form.create({ name: 'Widget_Form' })(WidgetForm);
export default withTranslation()(WrappedWidgetForm);
