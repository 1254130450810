/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:39:38
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-09-08 10:44:29
 */
import React from 'react';
import { Card, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.css';

const WidgetContainer = ({
  title,
  onEdit,
  onDelete,
  children,
}) => {
  const { t } = useTranslation();
  return (
    <Card
      title={title}
      size="small"
      extra={(
        <div>
          <Button type="link" icon="edit" onClick={onEdit} />
          <Popconfirm
            title={t('delete_confirmation')}
            onConfirm={onDelete}
            cancelText={t('no')}
            okText={t('yes')}
          >
            <Button type="link" icon="close" />
          </Popconfirm>
        </div>
      )}
      className="widget-container__card"
    >
      {children}
    </Card>
  );
};

export default WidgetContainer;
