/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-20 11:29:44 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-09-27 09:56:32
 */
import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import './styles.css';

const { Option } = Select;

const SelectMultipleData = ({
  data, onSelect, selected, disabled,
  refer, style = {}, className = '',
}) => (
  <Select
    mode="multiple"
    value={selected.map(e => e.id)}
    onChange={onSelect}
    className={className}
    style={style}
    disabled={disabled}
    ref={refer}
    notFoundContent={i18n.t('show_no_data')}
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {data.map(item => <Option value={item.id} key={item.id}>{item.name}</Option>)}
  </Select>);

SelectMultipleData.propTypes = {
  data: PropTypes.array.isRequired,
  selected: PropTypes.object,
  onSelect: PropTypes.func,
};

SelectMultipleData.defaultProps = {
  selected: { id: -1, name: i18n.t('select_option') },
  onSelect: () => {},
};

export default SelectMultipleData;
