/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-19 14:48:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2018-08-07 09:44:44
 */
import { inRange } from 'lodash';

export const format = (start, end, every) => {
  if (every === '(Personalized)') {
    return `${start} - ${end} (Personalized)`;
  }
  return `${start} - ${end} cada ${every}`;
};

export const parseHourToInt = value => Number(String(value).replace(':', ''));

export const parsePeriodicityToInt = value => Number(String(value).split(' ')[0]);

export const filterPeriodicity = (item, every) => {
  const itemPer = Number(item.split(':')[1]);
  const periodicityInt = parsePeriodicityToInt(every);

  if (periodicityInt === 1) {
    return item.includes('00');
  }

  return itemPer % periodicityInt === 0;
};

export const filterRange = (item, start, end) => {
  const intValue = parseHourToInt(item);
  const intStart = parseHourToInt(start);
  const intEnd = parseHourToInt(end);

  return inRange(intValue, intStart, intEnd);
};
