/*
 * @Author: Cesar Medina
 * @Date: 2019-04-25 17:10:47 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-01-31 12:20:31
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  GENERATE_SUBSCRIPTION,
  GENERATE_SUBSCRIPTION_FULFILLED,
  VALIDATE_CODE,
  VALIDATE_CODE_FULFILLED,
} from './types';

const defaultState = {
  reducerKey: 'paymentsContainer',
  loadingButton: false,
  planForm: {
    userQuantity: 1,
    subscriptionType: 'monthly',
    code: null,
  },
  costs: {
    annual: 23,
    monthly: 25,
  },
  minQuantity: 1,
  codeModal: false,
  distributorCode: '',
  errorDistributorCode: false,
  error: false,
};

// Actions
// Payload { form, defaultActions, navigate }
const generateSubscription = (tokenId, quantity, subscriptionType, code, navigate) =>
  ({
    type: GENERATE_SUBSCRIPTION, tokenId, quantity, subscriptionType, code, navigate,
  });
const generateSubscriptionFulfilled = payload =>
  ({ type: GENERATE_SUBSCRIPTION_FULFILLED, payload });
const validateCode = code => ({ type: VALIDATE_CODE, code });
const validateCodeFulfilled = payload => ({ type: VALIDATE_CODE_FULFILLED, payload });


// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [GENERATE_SUBSCRIPTION_FULFILLED]: (state, payload) => update(state, { path: 'loadingButton', value: payload }),
  [VALIDATE_CODE_FULFILLED]: (state, payload) => {
    const { planForm, planForm: { userQuantity } } = state;
    return {
      ...state,
      planForm: {
        ...planForm,
        userQuantity: userQuantity >= 5 ? userQuantity : 5,
        code: payload.code,
      },
      costs: {
        annual: 13,
        monthly: 15,
      },
      minQuantity: 5,
      codeModal: false,
    };
  },
});

const actions = {
  ...defaultActions,
  generateSubscription,
  generateSubscriptionFulfilled,
  validateCode,
  validateCodeFulfilled,
};

export { reducer, actions, epics, defaultActions };
