/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 14:41:21 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-03-25 10:34:00
 */

import React, { Component } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { init, getFieldError } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';

import columns from './columns';
import validations from './validations';
import { formatForm, formatFormEdit } from './formatForm';
import formatData from './formatData';
import { actions, defaultActions } from './redux';
import { RenderActionsCell, RenderCheckCell, RenderSelectCell } from '.././../common/components/widgets/table';
import { EditableCell } from '../../common/components/widgets';
import { Error, Loading } from '../../common/components/layout';
import './styles.css';
import { disableConfirm, enableConfirm } from './warningMessages';
import { HeaderContent } from './utils/components';

class BranchOffices extends Component {
  constructor(props) {
    super(props);
    this.validator = init(validations());
    this.state = {
      data: [],
      key: 1,
      load: false,
    };
    this.dataColumns = columns().map((item) => {
      if (item.type === 'input') {
        return ({
          ...item,
          onCell: () => ({ 'data-label': item.title }),
          render: (text, record) =>
            this.renderInputCell(
              text, record, item.dataIndex, item.maxLength,
              item.required, item.title,
            ),
        });
      }
      switch (item.dataIndex) {
        case 'state':
          return ({
            ...item,
            onCell: () => ({ 'data-label': item.title }),
            render: (text, record) => {
              const { catalogs: { states = [] } } = this.props;
              const target = states.find(itemState => record.state === itemState.id);
              const selected = target ? { ...target } : record.state;
              return (<RenderSelectCell
                required={item.required}
                selected={selected}
                data={states}
                record={record}
                error={this.state.errorForm}
                errorMessage={getFieldError(this.state.formErrors, 'state')}
                onSelect={(value, option) => this.handleChange(value, record.id, 'state', option)}
              />);
            },
          });
        case 'manager':
          return ({
            ...item,
            onCell: () => ({ 'data-label': item.title }),
            render: (text, record) => {
              const { catalogs: { users = [] }, notAsign } = this.props;
              const target = users.find(user => record.manager === user.id);
              const selected = target ? { ...target } : record.manager;
              return (<RenderSelectCell
                selected={record.manager ? selected : notAsign}
                data={users}
                record={record}
                onSelect={(value, option) => this.handleChange(value, record.id, 'manager', option)}
              />);
            },
          });
        case 'active':
          return ({
            ...item,
            onCell: () => ({ 'data-label': item.title }),
            render: (text, record) => (
              <RenderCheckCell
                record={record}
                text={text}
                onChange={value => this.handleChange(value.target.checked, record.id, 'active')}
              />
            ),
          });
        default:
          return ({
            ...item,
            onCell: () => ({ 'data-label': item.title }),
            render: (text, record) => (<RenderActionsCell
              record={record}
              onCancel={() => this.cancel(record.id)}
              onSave={() => this.save(record.id)}
              onEdit={() => this.edit(record.id)}
              onDelete={() => this.delete(record.id)}
              creating={this.state.newBranch}
            />),
          });
      }
    });
  }

  componentWillMount = () => {
    const {
      fetchBranchOffices, fetchStates, fetchUsers, formReset, updateState,
    } = this.props;
    updateState({ path: 'newForm', value: { ...formReset } });
    fetchStates(defaultActions);
    fetchBranchOffices(defaultActions);
    fetchUsers({ active: true });
  };

  componentWillReceiveProps(props) {
    const {
      catalogs: { branchOffices }, createdFulfilled, addForm,
      fetchBranchOffices, updateState, formReset, fetchStates, fetchUsers,
    } = props;
    if (createdFulfilled) {
      fetchBranchOffices(defaultActions);
      fetchStates(defaultActions);
      updateState({ path: 'newForm', value: { ...formReset } });
      fetchUsers({ active: true });
    }
    if (branchOffices && addForm === false) {
      this.setState({
        data: formatData(branchOffices),
        load: true,
        key: 1,
        newBranch: false,
        editBranch: false,
        errorForm: false,
      });
    }
    updateState({ path: 'createdFulfilled', value: false });
  }

  onDeleteFilter = () => {
    const { fetchBranchOffices, updateState, resetFilters } = this.props;
    fetchBranchOffices(defaultActions);
    updateState({ path: 'filtering', value: false });
    updateState({ path: 'filters', value: resetFilters });
  }

  onTab = (activeKey) => {
    if (activeKey === '1' && this.props.filtering) this.onDeleteFilter();
  };

  handleAdd = () => {
    const { key, data } = this.state;
    const { newForm } = this.props;
    const newData = [newForm, ...data];

    this.setState({ currentPage: 1 });
    if (key === 1) {
      this.setState({
        data: newData, newBranch: true, key: key + 1, editBranch: true,
      });
    }
  }

  handleChange(value, id, column) {
    const newData = [...this.state.data];
    const target = newData.filter(item => id === item.id)[0];
    if (target) {
      target[column] = value;
      this.setState({ data: newData });
    }
  }

  handleFilters = (prop, value, option) => {
    const target = { id: value, name: option.children };
    const { updateState, fetchBranchOfficesByState } = this.props;
    updateState({ path: `filters.${prop}`, value: target });
    updateState({ path: 'filtering', value: true });
    fetchBranchOfficesByState(value);
  }

  cancel(id) {
    const newData = [...this.state.data];
    const {
      catalogs: { branchOffices }, updateState, formReset,
    } = this.props;
    const target = newData.filter(item => id === item.id)[0];
    if (target && target.new) {
      updateState({ path: 'newForm', value: { ...formReset } });
    }
    if (target && !target.new) {
      this.setState({
        data: formatData(branchOffices), key: 1, editBranch: false, newBranch: false,
      });
    }
  }

  save(id) {
    const { data } = this.state;
    const {
      createBranch, updateBranch, catalogs: { states, branchOffices, users },
    } = this.props;
    const target = data.filter(item => id === item.id)[0];
    if (target && target.new) {
      const { formValid, valid } = this.validateFormFunction(target);
      const state = states.find(itemState => target.state === itemState.id);
      const manager = users.find(user => target.manager === user.id);
      if (valid) {
        this.setState({ data, key: 1, newBranch: false });
        const form = { ...formValid, state, manager };
        createBranch({ form });
      }
    }
    if (target && !target.new) {
      const { formValid, valid } = this.validateFormFunction(target);
      if (valid) {
        this.setState({ data, editBranch: false });
        const state = states.find(itemState =>
          target.state === itemState.id || target.state.id === itemState.id);
        const manager = target.manager ?
          users.find(user => target.manager === user.id || target.manager.id === user.id) : null;
        const form = { ...formValid, state, manager };
        const targetActive = branchOffices.filter(item => id === item.id)[0];

        if (!target.active && targetActive.active) {
          disableConfirm(() => updateBranch({ form }));
        } else if (target.active && !targetActive.active) {
          enableConfirm(() => updateBranch({ form }));
        } else {
          updateBranch({ form });
        }
      }
    }
  }

  delete(id) {
    const { data } = this.state;
    const { deleteBranch } = this.props;
    const target = data.filter(item => id === item.id)[0];
    deleteBranch({ id: target.id });
  }

  validateFormFunction = (target) => {
    const formValid = target.new ? formatForm(target) : formatFormEdit(target);
    const { valid, errors } = this.validator.validateForm(formValid);

    if (!valid) {
      this.setState({ formErrors: errors, errorForm: true });
    }
    return ({ formValid, valid });
  }

  edit(id) {
    const newData = [...this.state.data];
    const target = newData.map((item) => {
      if (id === item.id) {
        return ({ ...item, editable: true });
      }
      return item;
    });
    this.setState({ data: target, editBranch: true, newBranch: true });
  }

  renderInputCell(text, record, column, maxLength, required, placeholder) {
    return (
      <EditableCell
        editable={record.editable}
        value={text}
        maxLength={maxLength}
        error={this.state.errorForm}
        errorMessage={getFieldError(this.state.formErrors, column)}
        onChange={value => this.handleChange(value, record.id, column)}
        required={required}
        placeholder={placeholder}
      />
    );
  }

  render() {
    const {
      error,
      isLoading,
      catalogs: {
        states,
      },
      filters: {
        state,
      },
      filtering,
      t,
    } = this.props;
    const { load, data, currentPage } = this.state;

    if (error) { return (<Error error={error} />); }

    return (
      <div>
        <HeaderContent
          dataState={states}
          onSelectState={(value, option) => this.handleFilters('state', value, option.props)}
          selectedState={state}
          onCreate={this.handleAdd}
          disabledCreate={this.state.editBranch}
          filtering={filtering}
          onDeleteFilter={this.onDeleteFilter}
          onTab={this.onTab}
        />
        {
          load ?
            <Table
              loading={isLoading}
              ref={(table) => { this.table = table; }}
              rowKey="id"
              className="responsive-table"
              dataSource={data}
              columns={this.dataColumns}
              locale={{ emptyText: t('show_no_data') }}
              pagination={{
                current: currentPage,
                onChange: page => this.setState({ currentPage: page }),
              }}
              rowClassName={(record => (!record.active ? 'disableRow' : null))}
            />
          :
              <Loading />
        }
      </div>
    );
  }
}

const mapStateToProps = ({ branchOfficesContainer }) => ({
  ...branchOfficesContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(BranchOffices));
