/*
 * @Author: Jhony Reyes 
 * @Date: 2019-02-06 11:36:49 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-17 15:59:55
 */

import React from 'react';
import moment from 'moment';
import { init } from 'aleonor-object-validations';
import {
  Modal, Form, Input, TimePicker, Tooltip, Row, Col, Icon,
  Card, Button, Popconfirm, Checkbox, Select, Spin,
} from 'antd';
import { withTranslation } from 'react-i18next';

import { SelectData, ChecklistBuilder, ErrorText } from '../../widgets';
import validations from './validations';
import { RuleTypes } from '../../../constants';
import UserService from '../../../../services/user';

const userService = new UserService();
const CheckboxGroup = Checkbox.Group;

const defaultState = {
  form: {
    branchOfficeIds: [],
    name: '',
    description: '',
    roleId: '',
    checklist: {},
    tasks: [],
    cron: {},
    requireEvidence: true,
    requireComments: true,
    ruleType: RuleTypes.SCHEDULED,
    collaborative: true,
    usersIds: '',
    userNotifications: [],
    internal: false,
  },
  selectedUsers: [],
  formErrors: [],
  checkboxDays: null,
  events: [],
};

class RuleForm extends React.Component {
  constructor(props) {
    super(props);
    const { branchOfficeDefault, roleDefault } = this.props;

    this.validator = init(validations());
    this.state = {
      ...defaultState,
      form: {
        ...defaultState.form,
        branchOfficeId: branchOfficeDefault.id,
        roleId: roleDefault ? roleDefault.id : '',
      },
    };
  }

  componentWillMount = () => {
    const { currentEvent, created } = this.props;
    const { start, end } = currentEvent;
    const durationSelected = moment(end).subtract(moment(start).hours(), 'h').subtract(moment(start).minutes(), 'm');

    if (created.id) {
      const checklistTasks = Object.values(created.checklist)
        .filter(i => !i.id).reduce((res, i) => {
          res[i.name] = i;
          return res;
        }, {});
      const dayIndex = created.cron.days.findIndex(i => i === true);
      const day = dayIndex === 0 ? 13 : 6 + dayIndex;
      const events = created.cron.events.map((e) => {
        const hour = e.substr(0, 2);
        const minute = e.substr(3, 2);
        return {
          start: moment(new Date(2019, 0, day, hour, minute)),
          end: moment(new Date(2019, 0, day, hour, minute)).add(created.cron.duration, 'm'),
        };
      });

      this.setState({
        form: {
          ...created,
          checklist: checklistTasks,
          tasks: Object.values(created.checklist).filter(i => i.id).map(i => i.id),
        },
        durationSelected:
          moment(events[0].end).subtract(moment(events[0].start).hours(), 'h').subtract(moment(events[0].start).minutes(), 'm'),
        checkboxDays:
          created.cron.days.map((val, index) => (val ? index : null)).filter(val => val != null),
        events,
      });
    } else {
      this.setState(() => ({
        checkboxDays: [parseInt(moment(start).format('d'), 10)],
        durationSelected,
        events: [{ start: moment(start), end: moment(end) }],
      }));
    }
  }

  componentDidMount = () => {
    const { created } = this.props;

    if (created.collaborative === false) {
      userService.searchUsers({ ids: created.usersIds })
        .then(data => this.setState({
          selectedUsers: data.map(i => ({ key: i.id, label: i.name })),
        }));
    }
  }

  onCancel = () => {
    this.setState(defaultState, this.props.onCancel(false));
  }

  onChangeTime = (time, propName, eventKey) => {
    const { durationSelected, events } = this.state;
    const maximumStart = this.getMaximumStart(events);
    const dataTime = propName === 'duration' ? maximumStart : durationSelected;
    const endMinutes = moment(dataTime).minutes() + time.minutes();
    const endHours = moment(dataTime).hours() + time.hours() + (endMinutes / 60);

    if (endHours >= 24) {
      time.set({
        hours: (moment(dataTime).minutes() > 0 ? 23 : 24) - moment(dataTime).hours(),
        minute: (60 - moment(dataTime).minutes()) % 60,
      });
    }

    if (propName === 'duration' && time.hours() <= 0 && time.minutes() <= 0) {
      time.set({ minute: 15 });
    }

    const end = moment(dataTime).add(time.hours(), 'h').add(time.minutes(), 'm');

    const eventStart = events.map((i, index) => {
      if (index === eventKey) {
        return ({
          ...i,
          start: time,
          end,
        });
      }
      return i;
    });

    const eventDuration = events.map((i) => {
      if (propName === 'duration') {
        return ({
          ...i,
          end: moment(i.start).add(time.hours(), 'h').add(time.minutes(), 'm'),
        });
      }
      return i;
    });

    this.setState(() => (propName === 'duration' ?
      { durationSelected: time, events: eventDuration }
      : {
        events: eventStart,
      }));
  }

  onChangeForm = (prop, value) => {
    const { form } = this.state;
    const cloneForm = { ...form, [prop]: value };

    this.setState({ form: cloneForm });
  };

  onSearchUsers = (value) => {
    const { form: { branchOfficeId } } = this.state;
    this.props.onSearch(value, { branchOfficeId });
  }

  onOk = () => {
    const {
      durationSelected,
      form,
      checkboxDays,
      events,
    } = this.state;
    const { tasksData } = this.props;
    const cron = this.buildCron(durationSelected, events, checkboxDays);
    const formTasks = form.tasks.map(filterId => (
      tasksData[tasksData.findIndex(f => f.id === filterId)]
    )).filter(f => f !== undefined).reduce((res, item) => {
      res[item.name] = item;
      return res;
    }, {});
    const checklist = {
      ...form.checklist,
      ...formTasks,
    };
    const { valid, errors } = this.validator.validateForm(form);

    if (valid) {
      this.setState(() => ({
        form: {
          ...form,
          cron,
          checklist,
        },
      }), () => this.handleOkEvent());
    } else {
      this.setState({ formErrors: errors });
    }
  }

  onChangeDays = (checkedValues) => {
    if (checkedValues.length > 0) {
      this.setState({
        ...this.state,
        checkboxDays: checkedValues,
      });
    }
  }

  getMaximumStart = (events) => {
    let maxMoment = null;

    events.forEach((e) => {
      if (maxMoment === null || e.start > maxMoment) {
        maxMoment = e.start;
      }
    });

    return maxMoment;
  }

  handleOkEvent = () => {
    const { created } = this.props;
    if (created.id) {
      return this.props.onUpdate(this.state.form);
    }
    return this.props.onSave(this.state.form);
  }

  buildCron = (duration, events, checkboxDays) => {
    const days = [false, false, false, false, false, false, false];
    const cron = {
      duration: (duration.hours() * 60) + duration.minutes(),
      days: days.map((v, i) => checkboxDays.includes(i)),
      events: events.map(e => e.start.format('HH:mm')).filter((v, i, a) => a.indexOf(v) === i),
    };
    return cron;
  }

  handleRequired = propName => this.props.requiredFields.some(item => item === propName);

  handleDisabledHours = (propName) => {
    const hours = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
    const { durationSelected, events } = this.state;
    const maximumStart = this.getMaximumStart(events);
    const dataTime = propName === 'duration' ? maximumStart : durationSelected;
    const differenceHours = (moment(dataTime).minutes() > 0 ? 23 : 24) - moment(dataTime).hours();

    return hours.filter(i => i > differenceHours);
  }

  handleDisabledMinutes = (propName, eventIndex) => {
    const minutes = [0, 15, 30, 45];
    const { durationSelected, events } = this.state;
    const maximumStart = this.getMaximumStart(events);
    const dataTime = propName === 'duration' ? maximumStart : events[eventIndex].start;
    const totalHours = dataTime.hours() + durationSelected.hours();

    if (totalHours === 23) {
      return minutes.filter(i => i > 60 - moment(dataTime).minutes());
    }

    if (totalHours === 24) {
      return [15, 30, 45];
    }

    return [];
  }

  createItem = () => {
    this.setState(state => ({
      events: [
        ...state.events,
        {
          start: moment(state.events[state.events.length - 1].start),
          end: moment(state.events[state.events.length - 1].end),
        },
      ],
    }), () => { this.repetitions.scrollTop = this.repetitions.scrollHeight; });
  };

  deleteItem = (eventIndex) => {
    const { events } = this.state;

    if (events.length > 1) {
      this.setState(state => ({
        events: state.events.filter((i, index) => index !== eventIndex),
      }));
    }
  }

  render() {
    const {
      durationSelected,
      form: {
        name, description, roleId, checklist, userNotifications,
        branchOfficeId, collaborative, tasks, internal, gpsRequired,
      },
      selectedUsers,
      formErrors,
      checkboxDays,
      events,
    } = this.state;

    const {
      rolesData,
      confirmLoading,
      created,
      onDelete,
      branchOfficesData,
      fetchingSelectData,
      searchedUsers,
      clearSearchedUsers,
      tasksData,
      evaluations,
      usersData,
      t,
    } = this.props;
    const roleSelected = rolesData.find(item => roleId === item.id);
    const removedTasks = created.id ? Object.values(created.checklist)
      .filter(i => i.id && tasksData.findIndex(f => f.id === i.id) < 0) : [];
    const formTasks = [...tasksData, ...removedTasks];
    const branchOfficeSelected = branchOfficesData.find(item => branchOfficeId === item.id);
    const selectedUserNotifications = userNotifications ?
      userNotifications.filter(u => usersData.some(i => i.id === u)) : [];
    const buttons = [
      <Button key="back" onClick={this.onCancel} loading={confirmLoading}>{t('cancel')}</Button>,
      <Button key="submit" type="primary" loading={confirmLoading} onClick={this.onOk}>
        {created.id ? t('update') : t('save')}
      </Button>,
    ];

    if (created.id) {
      buttons.unshift( // eslint-disable-line
        <Popconfirm key="delete" title={t('delete_confirmation')} cancelText={t('no')} okText={t('yes')} onConfirm={onDelete}>
          <Button
            type="danger"
            icon="delete"
            style={{ position: 'absolute', left: 0, marginLeft: 16 }}
            loading={confirmLoading}
          >
            {t('delete')}
          </Button>
        </Popconfirm>);
    }

    return (
      <Modal
        title={created.id ? t('edit_activity') : t('new_activity')}
        visible
        width={850}
        maskClosable={false}
        footer={buttons}
        closable={false}
      >
        <Form layout="inline">
          <Row>
            <Col span={11}>
              <div className="rule-form-item">
                {this.handleRequired('name') &&
                  <Tooltip title={t('field_required')} placement="topLeft">
                    <span className="inputContainer__span">*</span>
                  </Tooltip>}
                {t('name')}:
                <Input
                  value={name}
                  onChange={e => this.onChangeForm('name', e.target.value)}
                  placeholder={t('add_activity_name')}
                />
                <ErrorText message={formErrors.name} />
              </div>
              <div className="rule-form-item">
                {this.handleRequired('description') &&
                  <Tooltip title={t('field_required')} placement="topLeft">
                    <span className="inputContainer__span">*</span>
                  </Tooltip>}
                {t('description')}:
                <Input
                  value={description}
                  onChange={e => this.onChangeForm('description', e.target.value)}
                  placeholder={t('add_activity_description')}
                />
                <ErrorText message={formErrors.description} />
              </div>
              <div className="rule-form-item">
                {this.handleRequired('branchOffice') &&
                <Tooltip title={t('field_required')} placement="topLeft">
                  <span className="inputContainer__span">*</span>
                </Tooltip>}
                {t('branch_office')}:
                <SelectData
                  data={branchOfficesData}
                  selected={branchOfficeSelected}
                  onSelect={(value) => {
                    this.onChangeForm('branchOfficeId', value);
                    clearSearchedUsers();
                    this.setState({ selectedUsers: [] });
                  }}
                />
                <ErrorText message={formErrors.branchOfficeId} />
              </div>
              <div className="rule-form-item">
                <Tooltip title={t('field_required')} placement="topLeft">
                  <span className="inputContainer__span">*</span>
                </Tooltip>
                {t('type')}:<br />
                <Checkbox
                  checked={collaborative}
                  onChange={e => this.setState({
                    form: {
                      ...this.state.form,
                      collaborative: e.target.checked,
                      roleId: '',
                    },
                    selectedUsers: [],
                  })}
                >
                  {t('collaborative')}
                </Checkbox>
              </div>
              <div className="rule-form-item">
                {this.handleRequired('role') &&
                <Tooltip title={t('field_required')} placement="topLeft">
                  <span className="inputContainer__span">*</span>
                </Tooltip>}
                { collaborative ? `${t('role')}:` : `${t('users')}: ` }
                { !collaborative &&
                  <Tooltip
                    placement="right"
                    title={t('users_same_brach')}
                  >
                    <Icon type="question-circle" theme="outlined" />
                  </Tooltip>
                }
                { collaborative ?
                  <SelectData
                    data={rolesData}
                    selected={roleSelected}
                    onSelect={value => this.onChangeForm('roleId', value)}
                  /> :
                  <Select
                    mode="multiple"
                    labelInValue
                    value={selectedUsers}
                    placeholder={t('select')}
                    notFoundContent={fetchingSelectData ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={this.onSearchUsers}
                    onChange={values =>
                      this.setState({
                        form: {
                          ...this.state.form,
                          usersIds: values.map(i => i.key).join(),
                        },
                        selectedUsers: values,
                      })}
                    style={{ width: '100%' }}
                  >
                    {searchedUsers.map(item => (
                      <Select.Option key={item.id}>{item.name}</Select.Option>
                    ))}
                  </Select>
                }
                <ErrorText message={formErrors.roleId} />
              </div>
              <div className="rule-form-item">
                {
                  this.handleRequired('checklist') &&
                  <Tooltip title={t('field_required')} placement="topLeft">
                    <span className="inputContainer__span">*</span>
                  </Tooltip>
                }
                {`${t('default_tasks')}:  `}
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('select_option')}
                  value={tasks}
                  notFoundContent={null}
                  filterOption={(input, option) => option.props.children.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                  onChange={value => this.onChangeForm('tasks', value)}
                >
                  {formTasks.map(item => (
                    <Select.Option key={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
                <ErrorText message={formErrors.checklist} />
              </div>
              <div className="rule-form-item">
                {
                  this.handleRequired('checklist') &&
                  <Tooltip title={t('field_required')} placement="topLeft">
                    <span className="inputContainer__span">*</span>
                  </Tooltip>
                }
                {`${t('tasks')}:  `}
                <ChecklistBuilder
                  value={checklist}
                  created={checklist}
                  evaluations={evaluations}
                  onChange={value => this.onChangeForm('checklist', value)}
                />
                <ErrorText message={formErrors.checklist} />
              </div>
              <div className="rule-form-item">
                {`${t('notify')}:  `}
                <Tooltip
                  placement="right"
                  title={t('user_notification_msg')}
                >
                  <Icon type="question-circle" theme="outlined" />
                </Tooltip>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder={t('select_option')}
                  value={selectedUserNotifications}
                  notFoundContent={null}
                  filterOption={(input, option) => option.props.children.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0}
                  onChange={value => this.onChangeForm('userNotifications', value)}
                >
                  {usersData.map(item => (
                    <Select.Option key={item.id}>{item.email}</Select.Option>
                  ))}
                </Select>
              </div>
              <div className="rule-form-item">
                <Checkbox
                  checked={internal}
                  onChange={e => this.onChangeForm('internal', e.target.checked)}
                >
                  {t('private')}
                </Checkbox>
              </div>
              <div className="rule-form-item">
                <Checkbox
                  checked={gpsRequired}
                  onChange={e => this.onChangeForm('gpsRequired', e.target.checked)}
                >
                  {t('gps_required')}
                </Checkbox>
              </div>
            </Col>
            <Col span={12} offset={1}>
              <Card title={t('events')}>
                <Row style={{ marginBottom: 20 }}>
                  <CheckboxGroup
                    className="notranslate"
                    options={[
                      { label: t('monday'), value: 1 },
                      { label: t('tuesday'), value: 2 },
                      { label: t('wednesday'), value: 3 },
                      { label: t('thursday'), value: 4 },
                      { label: t('friday'), value: 5 },
                      { label: t('saturday'), value: 6 },
                      { label: t('sunday'), value: 0 },
                    ]}
                    value={checkboxDays}
                    onChange={this.onChangeDays}
                  />
                </Row>
                <Row type="flex" justify="end" style={{ marginBottom: 20 }}>
                  <Form.Item label={t('duration')} style={{ marginRight: 0 }}>
                    <TimePicker
                      format="HH:mm"
                      minuteStep={15}
                      allowClear={false}
                      value={moment(durationSelected, 'HH:mm')}
                      disabledHours={() => this.handleDisabledHours('duration')}
                      disabledMinutes={() => this.handleDisabledMinutes('duration')}
                      hideDisabledOptions
                      onChange={time => this.onChangeTime(time, 'duration')}
                    />
                  </Form.Item>
                </Row>
                <Row type="flex" justify="space-between" align="bottom">
                  <Col span={10}>{t('home')}</Col>
                  <Col span={14}>Final</Col>
                </Row>
                <div style={{ overflowY: 'auto', height: 200, overflowX: 'hidden' }} ref={(ref) => { this.repetitions = ref; }}>
                  {events.map((event, index) => ( // eslint-disable-next-line
                    <Row type="flex" justify="space-between" align="bottom" key={index} style={{ paddingTop: 10, paddingBottom: 10 }} >
                      <TimePicker
                        format="HH:mm"
                        minuteStep={15}
                        value={event.start}
                        allowClear={false}
                        onChange={time => this.onChangeTime(time, 'start', index)}
                        disabledHours={this.handleDisabledHours}
                        disabledMinutes={() => this.handleDisabledMinutes(null, index)}
                        hideDisabledOptions
                      />
                      <TimePicker
                        disabled
                        format="HH:mm"
                        value={event.end}
                      />
                      <Button type="danger" shape="circle" icon="minus" onClick={() => this.deleteItem(index)} />
                    </Row>
                  ))}
                </div>
                <Button
                  type="primary"
                  block
                  icon="plus"
                  size="small"
                  onClick={this.createItem}
                  style={{ marginTop: 15 }}
                >
                  {t('add_repetition')}
                </Button>
              </Card>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default withTranslation()(RuleForm);
