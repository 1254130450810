/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-14 10:10:30
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-10 03:39:16
 */
export default {
  layout: { minHeight: '100vh' },
  header: { background: '#fff', padding: 0 },
  content: {
    margin: '24px 16px', padding: 24, background: '#fff', position: 'relative',
  },
  spin: { fontSize: 42 },
  title: {
    fontWeight: 'bold',
    fontSize: 22,
  },
  buttonSeeAll: {
    float: 'right',
  },
  menu: {
    background: '#F4F4F4',
    width: 350,
    border: '1px solid #2684FF',
  },
  menuNotification: {
    background: '#ECF4FF',
    border: '1px solid #2684FF',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: 10,
    margin: 10,
  },
  textBold: {
    fontWeight: 'bold',
  },
  icon: {
    textAlign: 'right',
    fontSize: 26,
  },
  notification: {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },
};
