/*
 * @Author: Cesar Medina
 * @Date: 2018-07-23 09:55:17
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-12-07 11:23:02
 */
import { createReducer } from 'aleonor-redux-helpers';
import epics from './epics';
import { DOWNLOAD_REPORT, DELETE_EXECUTION } from './types';
import * as generalActions from '../../../store/general/actions';
import { FETCH_EXECUTION_BY_ID_FULFILLED } from '../../../store/general/types';

// Initial State
const defaultState = {
  reducerKey: 'tasksEvidencesContainer',
  catalogs: {
    execution: {
      cron: {},
      checklist: {},
    },
    checklist: [],
  },
  forbidden: false,
};

// Action Creators
// Payload { form, defaultActions, navigate }
const downloadReport = (reportId, params) => ({ type: DOWNLOAD_REPORT, reportId, params });
const deleteExecution = payload => ({ type: DELETE_EXECUTION, payload });

// Reducer(Pure Functions) handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_EXECUTION_BY_ID_FULFILLED]: (state, payload) => ({
    ...state,
    catalogs: {
      ...state.catalogs,
      execution: payload,
      checklist: payload.evidence && payload.evidence.completion ?
        payload.evidence.completion.checklist : [],
    },
  }),
});

const actions = {
  ...generalActions,
  ...defaultActions,
  downloadReport,
  deleteExecution,
};

export {
  epics,
  reducer,
  actions,
  defaultActions,
};
