/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-19 16:14:33 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-06-10 19:08:19
 */
import React, { Component } from 'react';
import { Input, Button, Select, Table } from 'antd';
import { reverse, camelCase, omit } from 'lodash';
import { withTranslation } from 'react-i18next';

import { TaskTypes } from '../../../constants';
import ErrorText from '../errorText';
import capitalizeText from '../../../helpers/capitalizeText';

const { Option } = Select;
const defaultState = {
  currentText: '',
  checklist: {},
  type: 'check',
  enumValues: '',
  evidenceType: 'required',
  evaluationId: null,
  error: '',
};

class Builder extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentWillMount = () => {
    const { created } = this.props;
    this.setState({ checklist: created });
  }

  onChangeType = (value) => {
    this.setState({ type: value, enumValues: '' });
  }

  getColumns = () => [
    {
      key: 'close',
      render: (text, record) => (
        <Button
          style={{
            width: '12px',
            fontSize: '10px',
            padding: '0px',
            height: '14px',
          }}
          size="small"
          icon="close"
          onClick={
            () => (
              this.setState(() => ({
                checklist: this.deleteTask(this.state.checklist, record.name),
              }))
            )
          }
        />
      ),
    },
    {
      key: 'name',
      title: this.props.t('name'),
      dataIndex: 'name',
      render: (text, record) => record.name,
    },
    {
      key: 'type',
      title: this.props.t('type'),
      dataIndex: 'type',
      render: (text, record) => record.type,
    },
    {
      key: 'enumValues',
      title: this.props.t('values'),
      dataIndex: 'enumValues',
      width: 150,
      render: (text, record) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
          {record.enumValues}
        </div>
      ),
    },
    {
      key: 'evidenceType',
      title: this.props.t('evidence'),
      dataIndex: 'evidenceType',
      render: (text, record) => {
        switch (record.evidenceType) {
          case 'required': return this.props.t('required_fem');
          case 'optional': return this.props.t('optional');
          default: return '';
        }
      },
    },
    {
      key: 'evaluation',
      title: this.props.t('evaluation'),
      dataIndex: 'evaluation',
      render: (text, record) => (record.evaluation ? record.evaluation.name : ''),
    },
  ];

  createItem = () => {
    const {
      currentText,
      type,
      enumValues,
      evidenceType,
      evaluationId,
    } = this.state;

    if (this.validate()) {
      const { evaluations } = this.props;

      this.setState(state => ({
        checklist: {
          ...state.checklist,
          [camelCase(currentText)]: {
            value: '',
            name: capitalizeText(currentText),
            type,
            enumValues,
            evidenceType,
            evaluation: evaluations.find(e => e.id === evaluationId) || null,
          },
        },
        currentText: '',
        enumValues: '',
        type: 'check',
        evidenceType: 'required',
        evaluationId: null,
      }));
    }
  };

  deleteTask = (checklist, name) => {
    const objKey = Object.keys(checklist).find(k => checklist[k].name === name);
    return omit(checklist, objKey);
  }

  resetState = () => {
    const { created } = this.props;
    if (created) {
      this.setState({ currentText: '', checklist: created }, this.handleCancel);
    } else {
      this.setState(defaultState, this.handleCancel);
    }
  }

  handleOk = () => {
    const { checklist } = this.state;
    const { onDone } = this.props;
    this.setState({ error: '', currentText: '' }, () => onDone({ ...checklist }));
  }

  handleCancel = () => {
    const { checklist } = this.state;
    const { onCancel } = this.props;
    if (Object.keys(checklist).length > 0) {
      onCancel(checklist);
    } else {
      onCancel();
    }
    this.setState({ error: '' });
  }

  validate = () => {
    const {
      currentText,
      type,
      enumValues,
    } = this.state;
    let error = '';

    if (currentText.length === 0) {
      error = this.props.t('task_name_required');
    } else if (type === 'enum' && enumValues.length === 0) {
      error = this.props.t('enum_required');
    }

    this.setState({ error });

    return (error.length === 0);
  }

  render() {
    const {
      currentText, checklist, evaluationId,
      error, type, enumValues, evidenceType,
    } = this.state;
    const { evaluations, t } = this.props;
    const data = reverse(Object.entries(checklist))
      .map(i => ({ ...i[1], key: i[1].name + i[1].type }));

    return (
      <div>
        <Input.Group compact>
          <Input
            style={{ width: '100px' }}
            placeholder={t('name')}
            value={currentText}
            onChange={event => this.setState({ currentText: event.target.value })}
            onPressEnter={this.createItem}
          />
          <Select value={type} style={{ width: '130px' }} onChange={this.onChangeType}>
            <Option value={TaskTypes.CHECK}>{t('check')}</Option>
            <Option value={TaskTypes.ENUM}>{t('numbering')}</Option>
            <Option value={TaskTypes.MULTI_ENUM}>{t('multi_numbering')}</Option>
            <Option value={TaskTypes.TEXT}>{t('text')}</Option>
            <Option value={TaskTypes.NUMERIC}>{t('number')}</Option>
            <Option value={TaskTypes.CONTACT}>{t('contact')}</Option>
            <Option value={TaskTypes.FILE}>{t('file')}</Option>
            <Option value={TaskTypes.BARCODE}>{t('qr_code')}</Option>
            <Option value={TaskTypes.PHOTO}>{t('photo')}</Option>
            <Option value={TaskTypes.SIGNATURE}>{t('signature')}</Option>
            <Option value={TaskTypes.RULE_LINK}>{t('link')}</Option>
            <Option value={TaskTypes.RULE_FOLIO}>{t('folio')}</Option>
            <Option value={TaskTypes.USER}>{t('user')}</Option>
          </Select>
          {
            type === 'enum' &&
            <Input
              style={{ width: '100px' }}
              placeholder={t('values')}
              value={enumValues}
              onChange={event => this.setState({ enumValues: event.target.value })}
              onPressEnter={this.createItem}
            />
          }
          <Select
            value={evidenceType}
            style={{ width: '130px' }}
            onChange={val => this.setState({ evidenceType: val })}
          >
            <Option value="required">{t('required_fem')}</Option>
            <Option value="optional">{t('optional')}</Option>
          </Select>
          <Select
            value={evaluationId}
            placeholder={t('evaluation')}
            style={{ width: '130px' }}
            onChange={val => this.setState({ evaluationId: val })}
            notFoundContent={t('no_data')}
          >
            <Option value={null}>{t('no_evaluation')}</Option>
            {evaluations.map(e => <Option key={e.id} value={e.id}>{e.name}</Option>)}
          </Select>
          <Button icon="plus" style={{ width: '40px' }} onClick={this.createItem} />
        </Input.Group>

        {error && <ErrorText message={error} />}

        <Table
          columns={this.getColumns()}
          dataSource={data}
          pagination={false}
          className="tasks-table"
          locale={{ emptyText: t('show_no_data') }}
          size="small"
        />

        <div className="justify-end margin-top-1">
          <Button onClick={this.resetState}>
            {t('cancel')}
          </Button>
          <Button
            className="margin-left-half-rem"
            type="primary"
            onClick={this.handleOk}
          >
            {t('accept')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Builder);

