/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-20 11:29:44 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-14 19:07:49
 */
import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import './styles.css';

const { Option } = Select;

const filterOption = (input, option) =>
option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
|| option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const SelectData = ({
  data, onSelect, selected, disabled,
  refer, style = {}, className = '', onSearch,
}) => (
  <Select
    defaultValue={selected.name}
    value={selected.name}
    onChange={onSelect}
    className={className}
    style={style}
    disabled={disabled}
    ref={refer}
    notFoundContent={i18n.t('show_no_data')}
    showSearch
    filterOption={!onSearch && filterOption}
    onSearch={onSearch}
  >
    {data.map(item => <Option value={item.id} key={item.id}>{item.name}</Option>)}
  </Select>);

SelectData.propTypes = {
  data: PropTypes.array.isRequired,
  selected: PropTypes.object,
  onSelect: PropTypes.func,
};

SelectData.defaultProps = {
  selected: { id: -1, name: i18n.t('select_option') },
  onSelect: () => {},
};

export default SelectData;
