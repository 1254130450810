/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-22 13:21:34 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-14 19:08:28
 */
import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { SelectData, ErrorText } from '../../index';

const RenderSelectCell = ({
  record, data, error, errorMessage, selected, onSelect, required, disabled, onSearch,
}) => {
  const { editable } = record;
  const { t } = useTranslation();

  return editable ?
    <div>
      <div>
        {required &&
        <Tooltip title={t('field_required')} placement="topLeft">
          <span className="inputContainer__span">*</span>
        </Tooltip>}
        <SelectData
          data={data}
          onSelect={onSelect}
          onSearch={onSearch}
          selected={selected}
          disabled={disabled}
          style={{ width: '90%' }}
        />
      </div>
      <ErrorText message={error ? errorMessage : null} />
    </div>
    :
    selected.name || '';
};

export default RenderSelectCell;

