import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const NivoResponsiveBar = ({ data }) => (
  <ResponsiveBar
    data={data}
    keys={['value']}
    indexBy="id"
    margin={{
      top: 20,
      right: 20,
      bottom: 50,
      left: 30,
    }}
    padding={0.3}
    colors={['hsl(212, 83%, 64%)']}
    borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 0,
      tickPadding: 5,
      tickRotation: 20,
    }}
    axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
    }}
    tooltip={({ indexValue, value }) => (
      <strong>{indexValue}: {value}</strong>
    )}
    enableLabel
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
    animate
    motionStiffness={90}
    motionDamping={15}
  />
);

export default NivoResponsiveBar;
