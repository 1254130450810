import React, { Component } from 'react';
import { Button, Row, Col, Icon, Modal, Pagination } from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import Search from 'antd/lib/input/Search';
import ExecutionCard from '../executionCard';
import { RuleExecutionStatus } from '../../../constants';
import { getImageUrl } from '../../../helpers/images';
import { getExecutionCardImage, getFormattedFolio, getRuleExecutionStatus } from '../../../helpers/functions';

class RuleLinkTask extends Component {
  state = {

    modalVisible: false,
  }

  componentDidMount = () => {
    if (this.props.value && this.props.value.ruleExecutionId) {
      this.getExecutions({ id: this.props.value.ruleExecutionId });
    }
  }


  onSelectRuleExecution = (ruleExecution) => {
    if (ruleExecution) {
      this.setState({
        modalVisible: false,
      });
      this.props.onChange(ruleExecution);
    }
  }

  onSearchFolio = (value) => {
    if (value && value.trim() !== '') {
      const onlyNumbersRegexp = new RegExp(/^\d+$/);
      if (onlyNumbersRegexp.test(value) && !Number.isNaN(Number.parseInt(value, 10))) {
        this.getExecutions({ folio: value });
      } else if (onlyNumbersRegexp.test(value) && value.length) {
        this.getExecutions({ folio: value.replace(/[^0-9]/, '') });
      } else {
        this.getExecutions();
      }
    } else {
      this.getExecutions();
    }
  }

  getExecutions = (params) => {
    if (this.props.fetchExecutions) {
      this.props.fetchExecutions({
        page: 0,
        size: 5,
        ruleId: this.props.ruleId,
        originRuleId: this.props.originRuleId,
        status: 'completed',
        ...this.props.ruleLinkSettings,
        ...params,
      });
    }
  }

  switchModal = () => {
    const visible = !this.state.modalVisible;
    this.setState({ modalVisible: visible });
    if (visible) {
      this.getExecutions();
    }
  }

  pagination = (page, pageSize) => {
    this.getExecutions({
      page: page - 1,
      size: pageSize,
    });
  }

  render() {
    const { modalVisible } = this.state;
    const { executions, rules } = this.props;

    return (

       <>
         <Row onClick={this.switchModal}>
           {this.props.value &&
           <Col span={18}>
             <div >
               <p>{`${getFormattedFolio(this.props.value.folio)}${this.props.value.nameRegister || this.props.value.ruleName}`}</p>
             </div>
           </Col>
            }
           <Col>
             <Button size="large" type="primary" icon="select" style={{ alignSelf: 'center' }}>
               {i18next.t('select')}
             </Button>
           </Col>
         </Row>

         {modalVisible &&
         <Modal
           title={i18next.t('select_activity')}
           visible={modalVisible}
           width="70vw"
           maskClosable={false}
           className="modal-header-dark"
           style={{ top: 20 }}
           maskStyle={{ backgroundColor: '#00000066' }}
           onCancel={this.switchModal}
           footer={null}
         >
           <Row>
             <Col xs={24} sm={12} md={12} lg={8} xl={8} className="filter-box">
               <h1 className="textBranch">
                 {i18next.t('folio')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
               </h1>
               <Search
                 className="select-data"
                 /* onChange={this.onChangeName} */
                 onSearch={text => this.onSearchFolio(text)}
                 enterButton
               />
             </Col>
           </Row>
           <Row>

             {
              executions.content.map(e => (
                <ExecutionCard
                  key={e.id}
                  id={e.id}
                  folio={e.folio}
                  ruleName={e.name}
                  executionName={e.nameRegister}
                  branchOffice={e.branchOfficeName}
                  dateName={`${i18next.t('date_time')}:`}
                  date={e.completedAt ? `${moment(e.completedAt).format('DD/MM/YY LT')}h` : '-----'}
                  status={getRuleExecutionStatus(e)}
                  roleName={e.roleName || i18next.t('no_role')}
                  username={e.username}
                  profileImageUrl={getImageUrl(e.profileImageUrl)}
                  image={getExecutionCardImage(e)}
                  done={
                    e.status === RuleExecutionStatus.DONE ||
                    e.status === RuleExecutionStatus.PENDING
                  }
                  execution={e}
                  onClick={() => this.onSelectRuleExecution(e)}
                  rules={rules}
                />
              ))
             }
             <Pagination
               current={executions.number + 1}
               pageSize={executions.size}
               total={executions.totalElements}
               onChange={this.pagination}
               style={{ float: 'right' }}
             />
           </Row>
         </Modal>
            }


     </>
    );
  }
}

export default RuleLinkTask;

