/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 14:41:21 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-08-05 18:51:45
 */

import React, { Component } from 'react';
import { Table, Avatar } from 'antd';
import { connect } from 'react-redux';
import { init, getFieldError } from 'aleonor-object-validations';
import { mapValues } from 'lodash';
import { withTranslation } from 'react-i18next';

import {
  allRequired, notRoleAndBranch,
  formatRegisterForm, formatUpdateForm, fetchActions, disableConfirm,
  enableConfirm, formatPermissions, disableFields,
} from './utils';
import { columns, columnTypes } from './utils/columns';
import { actions, defaultActions } from './redux';
import { RenderActionsCell, RenderCheckCell, RenderSelectCell, RenderPermissionsCell } from '.././../common/components/widgets/table';
import { HeaderContent } from './utils/components';
import { EditableCell, UpgradeSubscriptionMessage, UserPermissions } from '../../common/components/widgets';
import { getUserData } from '../../security';
import { getImageUrl } from '../../common/helpers/images';
import RenderSelectCellForMultiple from '../../common/components/widgets/table/columns/renderSelectCellForMultiple';

class Users extends Component {
  constructor(props) {
    super(props);
    this.validator = init(notRoleAndBranch());
    this.validatorAll = init(allRequired());
    this.data = this.getColumns();
    this.state = {
      modalPermissionsUser: false,
      userRow: false,
    };
  }

  componentDidMount = () => {
    fetchActions(this.props, defaultActions);
  };

  componentWillUnmount = () => {
    const { resetState } = this.props;

    resetState();
  }

  onRegisterUser = () => {
    const {
      catalogs: {
        users,
      },
      updateState, form, availableUsers,
    } = this.props;

    if (availableUsers <= 0) {
      UpgradeSubscriptionMessage('', () => this.props.history.push('/subscriptions'));
      return;
    }

    updateState({ path: 'catalogs.usersEdit', value: users });
    updateState({ path: 'catalogs.users', value: [form, ...users] });
    updateState({ path: 'newUser', value: true });
    updateState({ path: 'registering', value: true });
    updateState({ path: 'currentPage', value: 1 });
  }

  onChangeFilter = (filter, value) => {
    const {
      fetchUsers,
      filters,
      updateState,
    } = this.props;
    const updatedFilters = {
      ...filters,
      [filter]: value,
      value: null,
    };

    updateState({ path: 'filters', value: updatedFilters });
    fetchUsers(updatedFilters);
  }

  onChangeValueFilter = (event) => {
    const {
      updateState,
      filters,
      fetchUsers,
    } = this.props;

    updateState({ path: 'filters.value', value: event.target.value });
    fetchUsers({ ...filters });
  }

  onSearchFilter = (value) => {
    const {
      fetchUsers,
      setFilterValue,
    } = this.props;

    setFilterValue(value);
    fetchUsers({ value });
  }

  getEditableCell = item => ({
    ...item,
    onCell: () => ({ 'data-label': item.title }),
    render: (text, record) => (
      <EditableCell
        editable={record.editable}
        value={text}
        onChange={value => this.handleChangeForm(value, record.id, item.dataIndex)}
        error={this.props.errorForm}
        errorMessage={getFieldError(this.props.formErrors, item.dataIndex)}
        placeholder={item.title}
        maxLength={item.maxLength}
        required={item.required}
        maxWidth={item.maxLength}
      />
    ),
  });

  getRoleColumn = item => ({
    ...item,
    width: '20%',
    onCell: () => ({ 'data-label': item.title }),
    render: (text, record) => {
      const {
        catalogs: { roles },
      } = this.props;
      return (
        <RenderSelectCellForMultiple
          selected={record.roles && record.roles.length ? record.roles : []}
          data={roles}
          record={record}
          onSelect={(values) => {
            const nextVal = roles.filter(r => values.some(v => v === r.id));
            this.handleChangeForm(nextVal, record.id, 'roles');
          }}
        />
      );
    },
  });

  getBranchOfficeColumn = item => ({
    ...item,
    onCell: () => ({ 'data-label': item.title }),
    render: (text, record) => {
      const {
        catalogs: { branchOffices }, defaultItem,
      } = this.props;
      const target = branchOffices
        .find(branchOffice => record.branchOffice === branchOffice.id);
      const selected = target ? { ...target } : { ...record.branchOffice };
      return (
        <RenderSelectCell
          selected={record.branchOffice ? selected : defaultItem}
          data={branchOffices}
          record={record}
          onSelect={value => this.handleChangeForm(value, record.id, 'branchOffice')}
        />
      );
    },
  });

  getTranslatedPermission = (permission) => {
    const { t } = this.props;

    return {
      ...permission,
      name: t(permission.keyName),
      description: t(permission.keyName),
    };
  }

  getPermissionsColumn = item => ({
    ...item,
    onCell: () => ({ 'data-label': item.title }),
    render: (text, record) => {
      const { catalogs: { permissions } } = this.props;
      return (
        <RenderPermissionsCell
          record={record}
          created={record.permissions.map(p => this.getTranslatedPermission(p))}
          data={formatPermissions(permissions).map(p => this.getTranslatedPermission(p))}
          onChange={value => this.handleChangeForm(value, record.id, 'permissions')}
          disabled={record.owner}
        />
      );
    },
  });

  getActiveColumn = item => ({
    ...item,
    onCell: () => ({ 'data-label': item.title }),
    className: 'hidden-sm',
    render: (text, record) => (<RenderCheckCell
      record={record}
      text={text}
      onChange={value => this.handleChangeForm(value.target.checked, record.id, 'active')}
      disabled={record.owner || this.props.registering}
    />),
  });

  getAnalyticsColumn = item => ({
    ...item,
    onCell: () => ({ 'data-label': item.title }),
    className: 'hidden-sm',
    render: (text, record) => (<RenderCheckCell
      record={record}
      text={text}
    />),
  });

  getActionsCellColumn = item => ({
    ...item,
    onCell: () => ({ 'data-label': item.title }),
    render: (text, record) => (
      <RenderActionsCell
        record={record}
        creating={this.props.newUser}
        onCancel={this.handleCancel}
        onSave={() => this.handleSave(record.id)}
        onEdit={() => this.handleEdit(record.id)}
        onSendEmail={() => this.props.sendRegisterMail(record.id)}
        onDelete={record.owner ? null : () => this.handleDelete(record.id, getUserData().id)}
        onDeleteTitle={getUserData().id === record.id ? this.props.t('delete_main_user_confirmation') : null}
        onUserPermissions={() => this.handlePermissions(record)}
      />),
  });

  getAvatarColumn = item => ({
    ...item,
    onCell: () => ({ 'data-label': item.title }),
    className: 'hidden-sm',
    render: text => (
      <Avatar
        size="large"
        src={getImageUrl(text)}
        icon="user"
        className="execution-card__avatar"
      />),
  });

  getColumns = () => columns().map((item) => {
    if (item.type === 'input') {
      return this.getEditableCell(item);
    }

    switch (item.dataIndex) {
      case columnTypes.AVATAR:
        return this.getAvatarColumn(item);
      case columnTypes.ROLE:
        return this.getRoleColumn(item);
      case columnTypes.BRANCHOFFICE:
        return this.getBranchOfficeColumn(item);
      case columnTypes.ACTIVE:
        return this.getActiveColumn(item);
      case columnTypes.ANALYTICS:
        return this.getAnalyticsColumn(item);
      default:
        return this.getActionsCellColumn(item);
    }
  });

  clearFilters = () => {
    const {
      updateState,
      filters,
      fetchUsers,
    } = this.props;

    updateState({ path: 'filters', value: mapValues(filters, () => null) });
    fetchUsers();
  }

  handleCancel = () => {
    const { catalogs: { usersEdit }, updateState, formReset } = this.props;
    updateState({ path: 'catalogs.users', value: usersEdit });
    updateState({ path: 'newUser', value: false });
    updateState({ path: 'registering', value: false });
    updateState({ path: 'form', value: { ...formReset } });
    updateState({ path: 'errorForm', value: false });
    updateState({ path: 'formErrors', value: {} });
  }

  handleCancelPermissions = () => {
    this.setState({ modalPermissionsUser: false });
  }

  handleChangeForm(value, id, column) {
    const { updateState, catalogs: { users } } = this.props;
    const newData = [...users];
    const target = newData.find(item => id === item.id);
    if (target) {
      target[column] = value;
      updateState({ path: 'catalogs.users', value: newData });
    }
  }

  handleEdit(id) {
    const { updateState, catalogs: { users } } = this.props;
    updateState({ path: 'catalogs.usersEdit', value: users });
    const newData = [...users];
    const target = newData.map((item) => {
      if (id === item.id) {
        return ({ ...item, editable: true });
      }
      return item;
    });
    updateState({ path: 'catalogs.users', value: target });
    updateState({ path: 'newUser', value: true });
  }

  handleDelete(userId, loggedId) {
    const { deleteUser } = this.props;
    deleteUser(userId, loggedId);
  }

  handleRegister = () => {
    const {
      form, registerUser, catalogs, updateState,
    } = this.props;
    const { valid, errors } = disableFields(form.permission, catalogs.permissions)
      ? this.validator.validateForm(form) : this.validatorAll.validateForm(form);
    if (!valid) {
      updateState({ path: 'formErrors', value: errors });
      updateState({ path: 'errorForm', value: true });
      return;
    }
    const formatedForm = formatRegisterForm(form, catalogs);
    registerUser(formatedForm);
  }

  handleUpdate = (id) => {
    const {
      updateUser, updateState, catalogs, form,
    } = this.props;
    const target = [...catalogs.users].find(item => id === item.id);
    if (target && !target.new) {
      const { valid, errors } = disableFields(form.permission, catalogs.permissions)
        ? this.validator.validateForm(target) : this.validatorAll.validateForm(target);
      if (!valid) {
        updateState({ path: 'formErrors', value: errors });
        updateState({ path: 'errorForm', value: true });
        return;
      }
      const targetActive = [...catalogs.usersEdit].find(item => id === item.id);
      const formatForm = formatUpdateForm(target, catalogs);
      const oldUserData = getUserData();
      const payload = {
        form: formatForm,
        oldUserData: oldUserData.id === formatForm.id && oldUserData,
      };
      if (!target.active && targetActive.active) {
        disableConfirm(() => updateUser(payload));
      } else if (target.active && !targetActive.active) {
        enableConfirm(() => updateUser(payload));
      } else {
        updateUser(payload);
      }
    }
  }

  handleUpdatePermissions = (id) => {
    const {
      updateUser, updateState, catalogs, form,
    } = this.props;
    const target = [...catalogs.users].find(item => id === item.id);

    if (target && !target.new) {
      const { valid, errors } = disableFields(form.permission, catalogs.permissions)
        ? this.validator.validateForm(target) : this.validatorAll.validateForm(target);
      if (!valid) {
        updateState({ path: 'formErrors', value: errors });
        updateState({ path: 'errorForm', value: true });
        return;
      }
      const formatForm = formatUpdateForm(target, catalogs);
      const oldUserData = getUserData();
      const payload = {
        form: formatForm,
        oldUserData: oldUserData.id === formatForm.id && oldUserData,
      };
      updateUser(payload);
    }
  }

  handleSave = (id) => {
    if (id === -1) { this.handleRegister(); } else this.handleUpdate(id);
  }

  handleSavePermissions = (id) => {
    if (id === -1) { this.handleRegister(); } else this.handleUpdatePermissions(id);
  }

  handlePermissions = (record) => {
    this.setState({ modalPermissionsUser: true, userRow: record });
  }

  render() {
    const {
      catalogs: {
        users, branchOffices, roles, permissions,
      }, isLoading,
      filters, newUser, currentPage,
      purchasedUsers, availableUsers, t,
    } = this.props;
    const { userRow } = this.state;

    return (
      <div>
        <HeaderContent
          dataBranch={branchOffices}
          dataRole={roles}
          onCreate={this.onRegisterUser}
          disableButton={newUser}
          purchasedUsers={purchasedUsers}
          availableUsers={availableUsers}
          filters={filters}
          onChangeFilter={this.onChangeFilter}
          onChangeValueFilter={this.onChangeValueFilter}
          onSearchFilter={this.onSearchFilter}
          clearFilters={this.clearFilters}
        />
        <Table
          ref={(table) => { this.table = table; }}
          rowKey="id"
          scroll={{ x: 'max-content' }}
          className="responsive-table"
          dataSource={users}
          columns={this.data}
          locale={{ emptyText: t('show_no_data') }}
          loading={isLoading}
          pagination={{
            current: currentPage,
            onChange: page => this.props.updateState({ path: 'currentPage', value: page }),
          }}
        />
        {!!this.state.modalPermissionsUser &&
        <UserPermissions
          t={t}
          visible={this.state.modalPermissionsUser}
          user={userRow}
          permissions={formatPermissions(permissions).map(p => this.getTranslatedPermission(p))}
          onChangePermissions={value => this.handleChangeForm(value, userRow.id, 'permissions')}
          onChangePermissionRoles={value => this.handleChangeForm(value, userRow.id, 'permissionRoleIds')}
          onCancel={this.handleCancelPermissions}
          permissionsUser={userRow.permissions}
          onSave={() => this.handleSavePermissions(userRow.id)}
          roles={roles}
        />
      }
      </div>
    );
  }
}

const mapStateToProps = ({ usersContainer }) => ({
  ...usersContainer,
});

export default withTranslation()(connect(mapStateToProps, actions)(Users));
