/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-14 11:41:04 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-06-05 16:52:25
 */
import React from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { init, getFieldError } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';

import '../styles.css';
import logo from '../../../assets/images/taskit_logo_web.png';
import { actions } from './redux';
import { isAuthenticated } from '../../../security';
import validations from './validations';
import { validateInputStatus } from '../utils';
import { version } from '../../../../package.json';

const FormItem = Form.Item;

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.validator = init(validations());
  }

  onChangeForm = (prop, value) => {
    const { updateState, formErrors } = this.props;
    const { error } = this.validator.validate(prop, value);
    updateState({ path: `form.${prop}`, value });
    updateState({ path: 'formErrors', value: { ...formErrors, [prop]: error } });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form, updateState, loginUser,
    } = this.props;
    const { valid, errors } = this.validator.validateForm(form);
    updateState({ path: 'formErrors', value: errors });
    if (!valid) { return; }
    updateState({ path: 'loadingButton', value: true });
    loginUser({
      form,
      navigate: () => this.props.history.push('/'),
    });
  }

  handleRegister = (e) => {
    e.preventDefault();
    this.props.history.push('/auth/register');
  }

  handleResetPassword = (e) => {
    e.preventDefault();
    this.props.history.push('/auth/reset-password');
  }

  render() {
    const {
       form: { email, password }, formErrors, loadingButton, t,
      } = this.props;
    const emailError = getFieldError(formErrors, 'email');
    const passwordError = getFieldError(formErrors, 'password');
    const authenticated = isAuthenticated();
    if (authenticated) {
      return <Redirect to={{ pathname: '/' }} />;
    }
    return (
      <Row type="flex" align="middle" justify="center" className="register login">
        <Row type="flex" justify="center" align="middle">
          <Col xs={23} sm={24} md={24} lg={24} xl={24}>
            <div className="login__box">
              <div className="login__box__img">
                <img src={logo} alt="taskit-logo" className="login__logo" />
              </div>
              <Form onSubmit={this.handleSubmit} className="login-form">
                <FormItem
                  label="Email"
                  hasFeedback
                  validateStatus={emailError ? 'error' : validateInputStatus(email)}
                  help={Boolean(emailError) && emailError}
                >
                  <Input
                    prefix={<Icon type="mail" />}
                    placeholder="Email"
                    value={email}
                    onChange={value => this.onChangeForm('email', value.target.value)}
                  />
                </FormItem>
                <FormItem
                  label={t('password')}
                  hasFeedback
                  validateStatus={passwordError ? 'error' : validateInputStatus(password)}
                  help={Boolean(passwordError) && passwordError}
                >
                  <Input
                    prefix={<Icon type="lock" />}
                    type="password"
                    placeholder={t('password')}
                    value={password}
                    onChange={value => this.onChangeForm('password', value.target.value)}
                  />
                </FormItem>
                <FormItem>
                  <div className="justify-center">
                    <Button
                      loading={loadingButton}
                      type="primary"
                      htmlType="submit"
                      onSubmit={this.loginWithUser}
                      className="login-form-button"
                    >
                      {t('log_in')}
                    </Button>
                  </div>
                </FormItem>
              </Form>
              <div className="justify-center">
                <Button
                  type="link"
                  onClick={this.handleResetPassword}
                  className="link link--padding"
                >
                  {t('reset_password')}
                </Button>
              </div>
              <div align="right">
                {`V ${version}`}
              </div>
            </div>
          </Col>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = ({ loginUser }) => loginUser;

export default withTranslation()(connect(mapStateToProps, actions)(Login));
