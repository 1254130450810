/*
 * @Author: Jhony Reyes 
 * @Date: 2018-11-22 12:48:20 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-11-22 13:41:39
 */
import React from 'react';
import { Row, Col, Icon, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();

  return (
    <Row type="flex" align="middle" justify="center" className="register">
      <Col xs={20} sm={20} md={20} lg={12} xl={12}>
        <h1 className="register__title text-center"><Icon type="warning" /></h1>
        <h1 className="register__title text-center">{t('route_not_found')}</h1>
        <p className="register__paragraph text-center">
          {t('unavailable_route')}
        </p>
        <div className="justify-center">
          <Button className="register__button register__button--facebook">
            <Link to="/">{t('home')}</Link>
          </Button>
        </div>
      </Col>
    </Row>
  );
};
