/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-11 01:05:19
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-19 02:17:54
 */
import { Table, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChecklistShow, GeneralButton as Buttons } from '../../../common/components/widgets';
import { HeaderContent } from './../util';

const NotScheduled = ({ t, rules }) => {
  const [onChangeSearch, setOnChangeSearch] = useState('');

  const renderRules = () => {
    if (rules.length > 0) {
      const filteredCards = rules.filter(f => (
        onChangeSearch.length <= 0 ||
        f.name.toLowerCase().includes(onChangeSearch)
      ));
      return filteredCards.map(rule => rule);
    }
    return '';
  };

  const getColumns = () => [
    {
      title: t('name'),
      dataIndex: 'name',
      onCell: () => ({ 'data-label': t('name') }),
      width: '10%',
    },
    {
      title: t('description'),
      dataIndex: 'description',
      width: '15%',
      onCell: () => ({ 'data-label': t('description') }),
      ellipsis: true,
      render: text => (
        <Tooltip title={text}>
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{text}</div>
        </Tooltip>
     ),
    },
    {
      title: t('tasks'),
      dataIndex: 'checklist',
      width: '10%',
      onCell: () => ({ 'data-label': t('tasks') }),
      render: (text, record) => (
        <ChecklistShow key={record.id} created={record.checklist} />
      ),
    },
    {
      title: t('actions'),
      width: '10%',
      onCell: () => ({ 'data-label': t('actions') }),
      render: (text, record) => (
        <Link to={`/web-form/${record.id}`}>
          <Buttons
            name={t('run')}
            type="default"
          />
        </Link>
      ),
    },
  ];

  return (
    <div>
      <HeaderContent
        setOnChangeSearch={setOnChangeSearch}
        t={t}
      />
      <Table
        rowKey="id"
        className="responsive-table"
        dataSource={renderRules()}
        columns={getColumns()}
        locale={{ emptyText: t('show_no_data') }}
      />
    </div>
  );
};

export default NotScheduled;

