/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-17 23:00:45 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-10-06 10:13:10
 */

import { Modal } from 'antd';
import i18n from 'i18next';

const disableConfirm = (updateBranch) => {
  Modal.confirm({
    title: i18n.t('deactivate_office_confirmation'),
    content: i18n.t('deactivate_office_msg'),
    okText: i18n.t('yes'),
    okType: 'danger',
    cancelText: i18n.t('no'),
    onOk() {
      updateBranch();
    },
  });
};

const enableConfirm = (updateBranch) => {
  Modal.confirm({
    title: i18n.t('activate_office_confirmation'),
    content: i18n.t('activate_office_msg'),
    okText: i18n.t('yes'),
    okType: 'primary',
    cancelText: i18n.t('no'),
    onOk() {
      updateBranch();
    },
  });
};

export { disableConfirm, enableConfirm };
