/*
 * @Author: Cesar Medina
 * @Date: 2018-06-19 14:41:21 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-07-20 16:46:39
 */

import React, { Component } from 'react';
import { Form, Input, Select, InputNumber, Icon, Button, Modal, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';

import { EvaluationTypes } from '../../../../common/constants';

const { Option } = Select;

export default withTranslation()(Form.create({ name: 'form_in_modal' })((
  class EvaluationForm extends Component {
    constructor(props) {
      super(props);
      const { evaluation } = this.props;

      if (evaluation.type === EvaluationTypes.ENUM && evaluation.enumValues) {
        this.keyId = evaluation.enumValues.length;
      } else {
        this.keyId = 1;
      }
    }

    getEnumIndexes = () => {
      const { evaluation } = this.props;

      if (evaluation.enumValues) {
        return evaluation.enumValues.map((item, index) => index);
      }

      return [0];
    }

    getEnumName = (index) => {
      const { evaluation } = this.props;

      if (evaluation.enumValues) {
        const option = evaluation.enumValues.find((item, ind) => ind === index);
        return option ? option.name : null;
      }

      return null;
    }

    getEnumValue = (index) => {
      const { evaluation } = this.props;

      if (evaluation.enumValues) {
        const option = evaluation.enumValues.find((item, ind) => ind === index);
        return option ? option.value : null;
      }

      return null;
    }

    getTypeLabel = (type) => {
      switch (type) {
        case EvaluationTypes.GRAPHIC:
          return this.props.t('graph');
        case EvaluationTypes.RANGE:
          return this.props.t('range');
        case EvaluationTypes.ENUM:
          return this.props.t('numbering');
        default:
          return '-----';
      }
    }

    addEnumField = () => {
      const { form } = this.props;
      const keys = form.getFieldValue('keys');
      const nextKeys = keys.concat(this.keyId);

      this.keyId += 1;
      form.setFieldsValue({
        keys: nextKeys,
      });
    };

    removeEnumField = (k) => {
      const { form } = this.props;
      const keys = form.getFieldValue('keys');

      if (keys.length === 1) {
        return;
      }

      form.setFieldsValue({
        keys: keys.filter(key => key !== k),
      });
    };

    footerButtons = () => {
      const { closeModal, loading, t } = this.props;
      return [
        <Button key="back" onClick={closeModal}>{t('cancel')}</Button>,
        <Button
          key="submit"
          type="primary"
          onClick={this.buildEvaluation}
          loading={loading}
        >
          {t('save')}
        </Button>,
      ];
    }

    buildEnumValues = (enumNames, enumPercentages) => {
      const enumValues = [];

      enumNames.forEach((i, index) => {
        enumValues.push({
          name: enumNames[index],
          value: enumPercentages[index],
        });
      });

      return (enumValues);
    }

    buildEvaluation = () => {
      const { form, onSave, evaluation } = this.props;

      form.validateFields((errors, values) => {
        if (errors === null) {
          let newEvaluation = {
            id: evaluation.id || null,
            name: values.name,
            description: values.description || null,
            type: values.type,
          };

          if (newEvaluation.type === EvaluationTypes.ENUM) {
            newEvaluation = {
              ...newEvaluation,
              enumValues: this.buildEnumValues(values.enumNames, values.enumPercentages),
            };
          } else if (newEvaluation.type === EvaluationTypes.RANGE) {
            newEvaluation = {
              ...newEvaluation,
              minValue: values.minValue,
              maxValue: values.maxValue,
            };
          }

          onSave(newEvaluation);
        }
      });
    }

    renderColumnTitle = (index) => {
      if (index === 0) {
        return (
          <div>
            {this.props.t('percentage')}&nbsp;
            <Tooltip
              placement="right"
              title={this.props.t('range_msg')}
            >
              <Icon type="question-circle" theme="outlined" />
            </Tooltip>
          </div>
        );
      }
      return '';
    }

    renderRangeForm = () => {
      const { evaluation } = this.props;
      const { getFieldDecorator } = this.props.form;

      return (
        <div>
          <Form.Item
            label={this.props.t('minimum')}
            style={{ display: 'inline-block', width: '50%' }}
          >
            {getFieldDecorator('minValue', {
              initialValue: evaluation.minValue,
              rules: [
                { required: true, type: 'integer', message: `${this.props.t('field_required')}.` },
                { max: 9999, type: 'integer', message: this.props.t('max_4_digits') },
                {
                  validator: (rule, value, callback) => {
                    if (this.props.form.getFieldValue('maxValue') <= value) {
                      callback(this.props.t('min_smaller'));
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<InputNumber min={0} />)}
          </Form.Item>
          <Form.Item
            label={this.props.t('maximum')}
            style={{ display: 'inline-block', width: '50%' }}
          >
            {getFieldDecorator('maxValue', {
              initialValue: evaluation.maxValue,
              rules: [
                { required: true, type: 'integer', message: `${this.props.t('field_required')}.` },
                { max: 9999, type: 'integer', message: this.props.t('max_4_digits') },
                {
                  validator: (rule, value, callback) => {
                    if (this.props.form.getFieldValue('minValue') >= value) {
                      callback(this.props.t('max_greater'));
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<InputNumber min={0} />)}
          </Form.Item>
        </div>
      );
    }

    renderEnumForm = () => {
      const { getFieldDecorator, getFieldValue } = this.props.form;
      const { t } = this.props;
      const enumValues = this.getEnumIndexes();
      getFieldDecorator('keys', { initialValue: enumValues });
      const keys = getFieldValue('keys');

      return (
        <div>
          <div style={{ overflowY: 'auto', maxHeight: 250, overflowX: 'hidden' }}>
            {
              keys.map((k, index) => (
                <div key={k}>
                  <Form.Item
                    label={index === 0 ? this.props.t('options') : ''}
                    required={false}
                    key={`enumName-${k}`}
                    style={{ display: 'inline-block', width: '60%' }}
                  >
                    {getFieldDecorator(`enumNames[${k}]`, {
                      initialValue: this.getEnumName(k),
                      rules: [
                        {
                          required: true,
                          whitespace: true,
                          message: t('field_required'),
                        },
                      ],
                    })(<Input placeholder={t('name')} style={{ width: '90%' }} />)}
                  </Form.Item>
                  <Form.Item
                    label={this.renderColumnTitle(index)}
                    required={false}
                    key={`enumPercentage-${k}`}
                    style={{ display: 'inline-block', width: '40%' }}
                  >
                    {getFieldDecorator(`enumPercentages[${k}]`, {
                      initialValue: this.getEnumValue(k),
                      rules: [
                        {
                          pattern: /^\d+$/,
                          required: true,
                          whitespace: true,
                          message: t('field_required'),
                        },
                      ],
                    })(<InputNumber min={0} max={100} style={{ width: 'calc(100% - 24px)', marginRight: 8 }} />)}
                    {keys.length > 1 ? (
                      <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.removeEnumField(k)}
                      />
                    ) : null}
                  </Form.Item>
                </div>
              ))
            }
          </div>
          <Form.Item>
            <Button type="dashed" onClick={this.addEnumField} style={{ width: '100%' }}>
              <Icon type="plus" /> {t('add_option')}
            </Button>
          </Form.Item>
        </div>
      );
    }

    renderGraphicForm = () => (
      <div>
        <Icon type="smile" className="tasks-rate-icon" style={{ color: '#31B550' }} />
        <Icon type="meh" className="tasks-rate-icon" style={{ color: '#D5B821' }} />
        <Icon type="frown" className="tasks-rate-icon" style={{ color: '#D54C21' }} />
      </div>
    )

    render() {
      const { evaluation, t } = this.props;
      const { getFieldDecorator, getFieldValue } = this.props.form;

      return (
        <Modal
          title={t('evaluation')}
          visible
          closable={false}
          footer={this.footerButtons()}
        >
          <Form layout="vertical">
            <Form.Item label={t('name')}>
              {getFieldDecorator('name', {
                initialValue: evaluation.name,
                rules: [
                  { required: true, whitespace: true, message: this.props.t('field_required') },
                  { max: 20, message: this.props.t('max_20_characters') },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label={this.props.t('description')}>
              {getFieldDecorator('description', {
                initialValue: evaluation.description,
              })(<Input type="textarea" />)}
            </Form.Item>
            <Form.Item label={t('type')}>
              {getFieldDecorator('type', {
                initialValue: evaluation.type || undefined,
                rules: [{ required: true, message: this.props.t('field_required') }],
              })((
                <Select
                  placeholder={t('select_option')}
                >
                  {Object.values(EvaluationTypes)
                      .map(v => <Option key={v} value={v}>{this.getTypeLabel(v)}</Option>)
                  }
                </Select>
              ))}
            </Form.Item>
            {getFieldValue('type') === EvaluationTypes.GRAPHIC && this.renderGraphicForm()}
            {getFieldValue('type') === EvaluationTypes.RANGE && this.renderRangeForm()}
            {getFieldValue('type') === EvaluationTypes.ENUM && this.renderEnumForm()}
          </Form>
        </Modal>
      );
    }
  })));
