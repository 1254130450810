import React from 'react';
import { Button, Icon, Row, Col, Upload } from 'antd';
import Text from 'antd/lib/typography/Text';
import i18next from 'i18next';

const FileTask = ({ value, onChange }) => (
      <>
        {typeof value === 'string' &&
        <Row type="flex" gutter={16}>
          <Col>
            <Text>
              {value}
            </Text>
          </Col>
          <Col>
            <Button icon="delete" type="danger" onClick={() => onChange(null)} />
          </Col>
        </Row>
        }
        {typeof value === 'object' &&
        <Upload
          beforeUpload={() => false}
          fileList={(
            value && value.file.status !== 'removed' ?
            [value.file] : []
              )}
          onChange={onChange}
        >
          <Button>
            <Icon type="upload" /> {i18next.t('upload_file')}
          </Button>
        </Upload>
        }
      </>
    );

export default FileTask;
