/*
 * @Author: Cesar Medina
 * @Date: 2019-07-02 14:41:21 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-19 11:17:12
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { actions } from './redux';
import { Loading } from '../../common/components/layout';
import { ExecutionCardTimeline } from '../../common/components/widgets';
import './styles.css';

class TasksTimeline extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    const { fetchExecutionTimeline, match } = this.props;
    fetchExecutionTimeline(match.params.id);
  };

  render() {
    const {
      isLoading,
      match: { params: { id } },
      catalogs: { executions },
      forbidden,
    } = this.props;

    if (forbidden) {
      return <Redirect to={{ pathname: '/403' }} />;
    }

    if (isLoading) {
      return <Loading />;
    }

    return (
      <VerticalTimeline layout="1-column">
        {
          executions.map(e => (
            <VerticalTimelineElement
              key={e.id}
              className={`timeline-white ${e.id === id ? 'green-border' : ''}`}
              contentArrowStyle={e.id === id ? { borderRight: '7px solid  rgb(233, 30, 99)' } : {}}
              iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
              icon={
                <i
                  className="fa fa-chevron-up"
                  style={{ fontSize: '20px', marginLeft: '10px', marginTop: '8px' }}
                  aria-hidden="true"
                />
              }
            >
              <ExecutionCardTimeline
                key={e.id}
                execution={e}
              />
            </VerticalTimelineElement>
          ))
        }
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={
            <i
              className="fa fa-flag"
              style={{ fontSize: '20px', marginLeft: '10px', marginTop: '10px' }}
              aria-hidden="true"
            />
          }
        />
      </VerticalTimeline>
    );
  }
}

const mapStateToProps = ({ tasksTimelineContainer }) => ({
  ...tasksTimelineContainer,
});

export default connect(mapStateToProps, actions)(TasksTimeline);
