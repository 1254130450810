const rulesToDotString = (rules) => {
  let res = 'digraph G {\n';
  res += 'rankdir = LR;\n';

  rules.forEach((r, ruleIndex) => {
    res += `"${formatId(r.id)}" [\n`;
    res += 'shape = none;\n';
    res += 'tooltip=".";\n';
    res += 'label = <<table border="0" cellspacing="0">\n' +
      `<tr><td port="portName" border="1" bgcolor="${ruleIndex === 0 ? '#3089F9' : 'lightblue'}">` +
      `<font color="${ruleIndex === 0 ? 'white' : 'black'}">${r.name}</font>` +
      '</td></tr>\n';

    Object.values(r.checklist).forEach((t, index) => {
      res += `<tr><td port="port${index}" border="1">${t.name}</td></tr>\n`;
    });

    res += '</table>>\n';
    res += ']\n';
  });

  rules.forEach((r, rule1Index) => {
    Object.values(r.checklist).forEach((t, t1Index) => {
      rules.slice(rule1Index + 1).forEach((r2) => {
        Object.values(r2.checklist).forEach((t2, t2Index) => {
          if (t.id && t2.id && t.tracking && t2.tracking && t.id === t2.id) {
            res += `"${formatId(r.id)}":port${t1Index} -> "${formatId(r2.id)}":port${t2Index} [arrowhead=none];\n`;
          }
        });
      });
    });
  });

  res += '}';

  return res;
};

const rulesToDotStringV2 = (rules) => {
  let res = 'digraph G {\n';
  res += 'graph [splines=ortho, nodesep=0.1, ranksep=2];\n';
  res += 'rankdir = LR;\n';

  rules.forEach((r, ruleIndex) => {
    res += `subgraph cluster_${ruleIndex} {\n` +
      'style=filled;\n' +
      `color="${ruleIndex === 0 ? '#3089F9' : 'lightblue'}";\n` +
      `fontcolor="${ruleIndex === 0 ? 'white' : 'black'}";\n` +
      'tooltip=".";\n' +
      'node [shape=box, style=filled, color=white, tooltip="."];\n' +
      `label = "${r.name}";\n`;

    Object.values(r.checklist).forEach((t, index) => {
      res += `"${formatId(r.id)}_${index}"[label="${t.name}"];\n`;
    });

    res += '}\n';
  });

  rules.forEach((r, rule1Index) => {
    Object.values(r.checklist).forEach((t, t1Index) => {
      rules.slice(rule1Index + 1).forEach((r2) => {
        Object.values(r2.checklist).forEach((t2, t2Index) => {
          if (t.id && t2.id && t.tracking && t2.tracking && t.id === t2.id) {
            res += `"${formatId(r.id)}_${t1Index}" -> "${formatId(r2.id)}_${t2Index}" [arrowhead=none];\n`;
          }
        });
      });
    });
  });

  res += '}';

  return res;
};

const formatId = (id) => {
  if (!id) {
    return 'newItem';
  }
  return id.replace(/-/g, '');
};

export default { rulesToDotString, rulesToDotStringV2 };
