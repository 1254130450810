/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-18 17:26:53
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-12-09 18:22:38
 */
import React from 'react';
// import PropTypes from 'prop-types';
import { Button, Icon } from 'antd';
import './styles.css';

const GeneralButton = ({
  type,
  onClick,
  icon,
  name,
  disabled,
}) =>
  (
    <>
      <Button
        className="btn-border btn-effect"
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        {icon ? <Icon type={icon} /> : ''}
        {name}
      </Button>
    </>
  );

export default GeneralButton;
