/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-24 10:53:46 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-05-26 14:36:55
 */

const CONTAINER_KEY = 'TASKIT_PROCESSES_CONTAINER';

export const FETCH_RULES = `${CONTAINER_KEY}_FETCH_DATA`;
export const REGISTER_RULE = `${CONTAINER_KEY}_REGISTER_RULE`;
export const RULE_REGISTERED = `${CONTAINER_KEY}_RULE_REGISTERED`;
export const UPDATE_RULE = `${CONTAINER_KEY}_UPDATE_RULE`;
export const DELETE_RULE = `${CONTAINER_KEY}_DELETE_RULE`;
export const FETCH_RULES_BY_FILTERS = `${CONTAINER_KEY}FETCH_RULES_BY_FILTERS`;
export const LOAD_FORM = `${CONTAINER_KEY}_LOAD_FORM`;
export const TOGGLE_RULE_FORM = `${CONTAINER_KEY}_TOGGLE_RULE_FORM`;
export const CHANGE_TAB = `${CONTAINER_KEY}_CHANGE_TAB`;
export const FETCH_TASKIT_PROCESSES = `${CONTAINER_KEY}_FETCH_TASKIT_PROCESSES`;
export const FETCH_TASKIT_PROCESSES_FULLFILLED = `${CONTAINER_KEY}_FETCH_TASKIT_PROCESSES_FULLFILLED`;
export const SWITCH_ENABLE_TASKIT_PROCESS = `${CONTAINER_KEY}_SWITCH_ENABLE_TASKIT_PROCESS`;
export const UPDATE_TASKIT_PROCESS = `${CONTAINER_KEY}_UPDATE_TASKIT_PROCESS`;
