/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-11 01:08:00
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-21 18:07:01
 */
const CONTAINER_KEY = 'ACTIVITIES_CONTAINER';

export const FETCH_ACTIVITIES = `${CONTAINER_KEY}_FETCH_ACTIVITIES`;
export const FETCH_ACTIVITIES_FULFILLED = `${CONTAINER_KEY}_FETCH_ACTIVITIES_FULFILLED`;
export const FETCH_DRAFTS = `${CONTAINER_KEY}_FETCH_DRAFTS`;
export const FETCH_DRAFTS_FULFILLED = `${CONTAINER_KEY}_FETCH_DRAFTS_FULFILLED`;
export const FETCH_PROGRESS_FULFILLED = `${CONTAINER_KEY}FETCH_PROGRESS_FULFILLED`;
export const CREATE_EXECUTION = `${CONTAINER_KEY}_CREATE_EXECUTION`;

