/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-15 10:04:53
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-15 22:26:18
 */

import React from 'react';
import { Row, Col, Card, Icon } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';

import { NoImageCard } from '../../widgets';
import { TaskTypes } from '../../../../common/constants';
import { evidenceTypeLabel, taskTypeLabel, getTypeIcon } from '../../../../common/labels';
import { getImageUrl } from '../../../helpers/images';
import { filenameIsImage } from '../../../helpers/functions';

const renderTaskImage = (t, onClick, text, task) => {
  if (
    task.type === TaskTypes.PHOTO ||
    task.type === TaskTypes.SIGNATURE ||
    (
      task.type === TaskTypes.FILE &&
      !isEmpty(task.value) &&
      filenameIsImage(task.value.filename)
    )
  ) {
    const image = getImageUrl(task.type === TaskTypes.FILE ? task.value.id : task.value);
    return isEmpty(image) ?
      <NoImageCard /> :
      <div className="card-container" >
        <img
          alt={task.name}
          src={image}
          onClick={() => onClick(image)}
          style={{ objectFit: 'cover' }}
          width="100%"
          height="100%"
        />
      </div>;
  }

  return (
    <div className="card-image-container" >
      <Icon
        type={getTypeIcon(task.type)}
        className={`task-type__icon_image ${task.value ? 'task-type__icon_image--blue' : ''}`}
      />
    </div>
  );
};


const TasksGrid = ({
  checklist,
  onClick,
  renderValue,
  renderTaskRate,
  renderTaskRateComment,
}) => {
  const { t } = useTranslation();
  return (
    <Card
      className="tasks-grid"
    >
      {checklist.map((task) => {
        const group = (task.groupId && task.groupInfo) && task.groupInfo;
        let groupName = t('without_group');
        if (group) {
          groupName = group.repeatNumber > 1 ? `${group.name} ${group.repeatNumber}` : group.name;
        }

        let taskName = task.name;
        if (task.repeatNumber > 1) {
          taskName += ` ${task.repeatNumber}`;
        }

        return (
          <Card.Grid style={{
 width: 380, height: 530, margin: 10, backgroundColor: 'white', padding: 0,
}}
          >
            <Row>
              {renderTaskImage(t, onClick, task.value, task)}
            </Row>
            <Col style={{
 paddingLeft: 20, paddingRight: 20, paddingTop: 20, justifyItems: '',
}}
            >

              <Row>
                <Col title={taskName} span={22}>
                  <Paragraph ellipsis={{ rows: 2 }} style={{ fontSize: 18, fontWeight: 'bold' }}>
                    {taskName}
                  </Paragraph>
                </Col>
                <Col span={2} >
                  <Icon
                    type={getTypeIcon(task.type)}
                    className={`task-type__icon ${task.value ? 'task-type__icon--blue' : ''}`}
                  />
                </Col>
              </Row>
              <Row title={task.description && task.description}>
                <Paragraph ellipsis={{ rows: 2 }} type="secondary">
                  {task.description || t('without_description')}
                </Paragraph>
              </Row>
              <Row>
                <Text strong>
                  {t('group_name')}
                </Text>
                <Text>
                  : {groupName}
                </Text>
              </Row>
              <Row>
                {`${taskTypeLabel(task.type)}, ${evidenceTypeLabel(task.evidenceType)}`}
              </Row>
              <Row>
                <Text strong>
                  {t('grade')}
                </Text>
                : {renderTaskRate(task)}
              </Row>
              <Row>
                <Text strong>
                  {t('comment')}
                </Text>
                : {renderTaskRateComment(task)}
              </Row>
              <Row style={{ fontSize: 20, fontWeight: 'bold' }}>
                {t('value_colon')}
                {renderValue(task.value, task, { rows: 2 })}
              </Row>
            </Col>
          </Card.Grid>
        );
})}
    </Card>
  );
};

export default TasksGrid;
