/*
 * @Author: Jhony Reyes 
 * @Date: 2018-05-04 12:15:26 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-10-07 03:30:16
 */

import i18n from 'i18next';

const validations = () => ({
  name: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.name.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  nameRegister: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.nameRegister.trim(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
});

export default validations;
