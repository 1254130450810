/*
* @Author: Misael Jiménez 
* @Date: 2020-07-09 17:18:23 
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-20 11:38:56
*/

import { createReducer, update } from 'aleonor-redux-helpers';
import i18next from 'i18next';
import {
  UPDATE_FORM,
  UPDATE_USER,
  UPDATE_USER_FULFILLED,
  UPDATE_USER_WITH_PASSWORD,
  UPDATE_USER_IMAGE,
  UPDATE_COMPANY_LOGO_IMAGE,
  UPDATE_COMPANY_HEADER_IMAGE,
  FETCH_WEB_HOOKS,
  CREATE_WEB_HOOK,
  CREATE_WEB_HOOK_FULFILLED,
  UPDATE_WEB_HOOK,
  DELETE_WEB_HOOK,
  FETCH_WEB_HOOKS_FULFILLED,
  UPDATE_WEB_HOOK_FULLFILLED,
} from './types';
import { FETCH_RULES_BY_NAME_FULFILLED,
  FETCH_USER_INFO_BY_TOKEN_FULFILLED,
  FETCH_NOTIFICATIONS_BY_USER_FULFILLED,
  FETCH_USERS_FULFILLED } from '../../../../../store/general/types';
import epics from './epics';
import * as generalActions from '../../../../../store/general/actions';

const defaultState = {
  reducerKey: 'mainContainer',
  editProfileModalVisible: false,
  technicalSettingsModalVisible: false,
  form: {
    id: '',
    active: false,
    role: '',
    branchOffice: '',
    name: '',
    lastName: '',
    email: '',
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
    facebookCredentials: {},
    profileImageUrl: '',
  },
  catalogs: {
    webHooks: [],
    webHooksEdit: [],
    notifications: [],
    rules: {
      content: [],
      size: 10,
      number: 0,
      totalElements: 1,
    },
    users: [],
  },
  creating: false,
  newWebHook: {
    id: -1,
    url: '',
    token: '',
    ruleId: '',
    companyId: '',
    triggerTypes: '',
    editable: true,
  },
  resetWebHook: {
    id: -1,
    url: '',
    token: '',
    ruleId: '',
    companyId: '',
    triggerTypes: '',
    editable: true,
  },
  notAsign: { id: -1, name: i18next.t('not_assigned') },
  formErrors: {},
  errorForm: false,
};

// Actions
// Payload { form, defaultActions }
const updateForm = payload => ({ type: UPDATE_FORM, payload });
const updateUser = payload => ({ type: UPDATE_USER, payload });
const updateUserFulfilled = payload => ({ type: UPDATE_USER_FULFILLED, payload });
const updateUserWithPassword = payload => ({ type: UPDATE_USER_WITH_PASSWORD, payload });
const updateUserImage = payload => ({ type: UPDATE_USER_IMAGE, payload });
const updateCompanyLogoImage = payload => ({ type: UPDATE_COMPANY_LOGO_IMAGE, payload });
const updateCompanyHeaderImage = payload => ({ type: UPDATE_COMPANY_HEADER_IMAGE, payload });
const fetchWebHooks = payload => ({ type: FETCH_WEB_HOOKS, payload });
const fetchWebHooksFullfilled = payload => ({ type: FETCH_WEB_HOOKS_FULFILLED, payload });
const createWebHook = payload => ({ type: CREATE_WEB_HOOK, payload });
const webHookCreated = payload => ({ type: CREATE_WEB_HOOK_FULFILLED, payload });
const updateWebHook = payload => ({ type: UPDATE_WEB_HOOK, payload });
const updateWebHookFullfilled = payload => ({ type: UPDATE_WEB_HOOK_FULLFILLED, payload });
const deleteWebHook = payload => ({ type: DELETE_WEB_HOOK, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [UPDATE_FORM]: (state, payload) =>
    update(state, { path: `form.${payload.prop}`, value: payload.value }),
  [FETCH_USER_INFO_BY_TOKEN_FULFILLED]: (state, payload) =>
    update(state, {
      path: 'form',
      value: {
        ...payload,
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
      },
    }),
  [FETCH_WEB_HOOKS_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.webHooks', value: payload }),
  [CREATE_WEB_HOOK_FULFILLED]: state =>
  ({
    ...state,
    newWebHook: { ...state.resetWebHook },
    creating: false,
  }),
  [UPDATE_WEB_HOOK_FULLFILLED]: state =>
  ({
    ...state,
    newWebHook: { ...state.resetWebHook },
    creating: false,
  }),
  [FETCH_RULES_BY_NAME_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.rules', value: payload }),
  [FETCH_NOTIFICATIONS_BY_USER_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.notifications', value: payload }),
  [FETCH_USERS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.users', value: payload }),
});

const actions = {
  ...generalActions,
  ...defaultActions,
  updateForm,
  updateUser,
  updateUserFulfilled,
  updateUserWithPassword,
  updateUserImage,
  updateCompanyLogoImage,
  updateCompanyHeaderImage,
  fetchWebHooks,
  fetchWebHooksFullfilled,
  createWebHook,
  webHookCreated,
  updateWebHook,
  updateWebHookFullfilled,
  deleteWebHook,
};

export { epics, reducer, actions, defaultActions };
