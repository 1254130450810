/*
 * @Author: Cesar Medina
 * @Date: 2018-07-20 12:28:07
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-11-11 19:58:36
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { parseApiError } from '../../../common/error';
import { actions } from './index';
import { FETCH_USERS_BY_BRANCH_OFFICE } from './types';
import { Notification } from '../../../common/components/widgets';
import UserService from '../../../services/user';

const userService = new UserService();

const fetchUsersByBranchOfficeEpic = action$ =>
  action$.ofType(FETCH_USERS_BY_BRANCH_OFFICE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(userService.getByBranchOfficeId(action.branchOfficeId))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchUsersByBranchOfficeFulfilled(response)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              Notification.error(parsedError.message);
            })),
          )),
    ));

export default combineEpics(fetchUsersByBranchOfficeEpic);
