/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-08 17:10:47 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-05-17 01:48:33
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import i18n from 'i18next';

import epics from './epics';
import {
  UPDATE_USER,
  FETCH_USERS_BY_BRANCH_OFFICE,
  FETCH_USERS_BY_ROLE,
  FETCH_USERS_BY_PERMISSION,
  REGISTER_USER, REGISTER_USER_FULFILLED,
  SEND_REGISTER_MAIL,
  DELETE_USER,
  SET_FILTER_VALUE,
} from './types';
import {
  FETCH_USERS_FULFILLED,
  FETCH_BRANCH_OFFICES_FULFILLED,
  FETCH_ROLES_FULFILLED,
  FETCH_PERMISSIONS_FULFILLED,
  FETCH_AVAILABLE_USERS_FULFILLED,
} from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';


const defaultItem = () => ({
  id: -1,
  name: i18n.t('select_option'),
 });

const defaultState = () => ({
  reducerKey: 'usersContainer',
  catalogs: {
    users: [],
    branchOffices: [],
    roles: [],
    permissions: [],
    usersEdit: [],
  },
  defaultItem: { id: -1, name: i18n.t('not_assigned') },
  formErrors: {},
  form: {
    editable: true,
    id: -1,
    name: '',
    lastName: '',
    email: '',
    role: defaultItem(),
    branchOffice: defaultItem(),
    permissions: [],
    active: true,
    facebookCredentials: {},
    profileImageUrl: '',
    permissionRoleIds: [],
  },
  formReset: {
    editable: true,
    id: -1,
    name: '',
    lastName: '',
    email: '',
    role: defaultItem(),
    branchOffice: defaultItem(),
    permissions: [],
    active: true,
    facebookCredentials: {},
    profileImageUrl: '',
    permissionRoleIds: [],
  },
  errorForm: false,
  newUser: false,
  registering: false,
  filters: {
    branchOfficeId: null,
    roleId: null,
    value: null,
  },
  currentPage: 1,
  purchasedUsers: 0,
  availableUsers: 0,
});

// Actions
// Payload { form, defaultActions, navigate }
const updateUser = payload => ({ type: UPDATE_USER, payload });
const fetchUsersByBranchOffice = branchOfficeId =>
  ({ type: FETCH_USERS_BY_BRANCH_OFFICE, branchOfficeId });
const fetchUsersByRole = roleId => ({ type: FETCH_USERS_BY_ROLE, roleId });
const fetchUsersByPermission = permissionId => ({ type: FETCH_USERS_BY_PERMISSION, permissionId });
const registerUser = form => ({ type: REGISTER_USER, form });
const registeredUser = payload => ({ type: REGISTER_USER_FULFILLED, payload });
const sendRegisterMail = userId => ({ type: SEND_REGISTER_MAIL, userId });
const deleteUser = (userId, loggedId) => ({ type: DELETE_USER, userId, loggedId });
const setFilterValue = payload => ({ type: SET_FILTER_VALUE, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState(), {
  [FETCH_USERS_FULFILLED]: (state, payload) => {
    const updatedState = update(state, { path: 'catalogs.users', value: payload });
    return update(updatedState, { path: 'newUser', value: false });
  },
  [FETCH_BRANCH_OFFICES_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.branchOffices', value: payload }),
  [FETCH_ROLES_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.roles', value: payload }),
  [FETCH_PERMISSIONS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.permissions', value: payload }),
  [REGISTER_USER_FULFILLED]: state => update(state, { path: 'form', value: { ...state.formReset } }),
  [FETCH_AVAILABLE_USERS_FULFILLED]: (state, payload) => ({
    ...state,
    purchasedUsers: payload.purchasedUsers,
    availableUsers: payload.availableUsers,
  }),
  [SET_FILTER_VALUE]: (state, payload) => ({
    ...state,
    filters: {
      branchOfficeId: null,
      roleId: null,
      value: payload,
    },
  }),
});

const actions = {
  ...defaultActions,
  ...generalActions,
  updateUser,
  registeredUser,
  fetchUsersByBranchOffice,
  fetchUsersByRole,
  fetchUsersByPermission,
  registerUser,
  sendRegisterMail,
  deleteUser,
  setFilterValue,
};

export { reducer, actions, epics, defaultActions };
