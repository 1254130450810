/* eslint-disable no-restricted-globals */
/* eslint-disable radix */
/*
 * @Author: Jhony Reyes 
 * @Date: 2019-02-06 11:36:49 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2023-11-23 11:45:21
 */

import React from 'react';
import Recaptcha from 'react-google-recaptcha';
import {
  Form, Button, Input, Select, DatePicker, TimePicker, Row, Card, Col,
} from 'antd';
import { withTranslation } from 'react-i18next';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';

import { ContactForm } from '../';
import { TaskTypes, EvidenceTypes, RuleExecutionStatus, RuleTypes } from '../../../constants';
import WebcamTask from '../webcamTask';
import SignatureTask from '../signatureTask';
import QrTask from '../qrTask';
import RuleLinkTask from '../ruleLinkTask';
import FileTask from '../fileTask';
import { getImageUrl } from '../../../helpers/images';
import CheckboxTask from '../checkboxTask';
import UserTask from '../userTask';
import { uuidv5 } from '../../../helpers/functions';


class WebFormBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recaptchaVerified: false,
      searchUser: null,
    };
  }

  onSubmit = (e) => {
    const { form, onSubmit } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values);
      }
    });
  };

  onSaveDraft = (saveAndContinue = false) => {
    const { form, onSubmit } = this.props;

    onSubmit(form.getFieldsValue(), true, saveAndContinue);
  }

  onChangeRecaptcha = () => {
    this.setState({ recaptchaVerified: true });
  }

  onSearchUsers = (value) => {
    this.setState({ searchUser: value });
    clearTimeout(this.timer);
    if (value) {
      this.timer = setTimeout(() => this.props.searchUsersPaginated({
        value: this.state.searchUser,
      }), 500);
    } else { this.props.searchUsersPaginated(); }
  }

  getItem = (key, task, rule) => {
    const {
      form: {
        getFieldDecorator,
      },
      users,
      fetchContacts,
      contacts,
    } = this.props;
    let item = null;
    const isRequired = task.evidenceType === EvidenceTypes.REQUIRED;

    switch (task.type) {
      case TaskTypes.CHECK:
        item = (
          getFieldDecorator(key, {
            initialValue: task.value,
          })((
            <CheckboxTask label={task.name} />
          ))
        );
        break;
      case TaskTypes.TEXT:
        item = (
          getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value,
          })((
            <Input placeholder={task.description} />
          ))
        );
        break;
      case TaskTypes.NUMERIC:
      case TaskTypes.RULE_FOLIO:
        item = (
          getFieldDecorator(key, {
            rules: [
              { required: isRequired, message: this.props.t('field_required') },
              {
                validator: (s, value, callback) => {
                  if (isNaN(value)) { //eslint-disable-line
                    callback(this.props.t('numeric_value'));
                  } else {
                    callback();
                  }
                },
              },
            ],
            initialValue: task.value,
          })(<Input
            placeholder={task.description}
            disabled={this.folioDisabled(rule.folio, task.value)}
          />)
        );
        break;
      case TaskTypes.DATE:
        item = (
          getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value && moment(task.value),
          })(<DatePicker placeholder={task.description} style={{ width: '100%' }} />)
        );
        break;
      case TaskTypes.TIME:
        item = (
          getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value && moment().startOf(task.value),
          })(<TimePicker placeholder={task.description} format="HH:mm" style={{ width: '100%' }} />)
        );
        break;
      case TaskTypes.ENUM:
        item = (
          getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value,
          })((
            <Select placeholder={task.description} >
              {
                task.enumValues.split(',').map(v => (
                  <Select.Option key={v} value={v}>{v}</Select.Option>
                ))
              }
            </Select>
          ))
        );
        break;
      case TaskTypes.MULTI_ENUM:
        item = (
          getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value ? task.value.split(',') : undefined,
          })((
            <Select
              mode="multiple"
              placeholder={task.description}
            >
              {
                task.enumValues.split(',').map(v => (
                  <Select.Option key={v} value={v}>{v}</Select.Option>
                ))
              }
            </Select>
          ))
        );
        break;
      case TaskTypes.FILE:
        item = (
          getFieldDecorator(key, {
            rules: [
              { required: isRequired, message: this.props.t('field_required') },
              { validator: this.fileValidator },
            ],
            initialValue: task.value && task.value.filename ? task.value.filename : null,
          })((
            <FileTask />
          ))
        );
        break;
      case TaskTypes.CONTACT:
        item = (
          this.props.form.getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value,
          })((
            <ContactForm
              fetchContacts={fetchContacts}
              contacts={contacts}
              isWebType={rule.ruleType === RuleTypes.WEB}
            />
          ))
        );
        break;
      case TaskTypes.PHOTO:
        item = (
          this.props.form.getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value ? getImageUrl(task.value) : undefined,
          })((
            <WebcamTask />
          ))
        );
        break;
      case TaskTypes.SIGNATURE:
        item = (
          this.props.form.getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value ? getImageUrl(task.value) : undefined,
          })((
            <SignatureTask />
          ))
        );
        break;
      case TaskTypes.BARCODE:
        item = (
          this.props.form.getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value,
          })((
            <QrTask />
          ))
        );
        break;
        case TaskTypes.RULE_LINK:
        item = (
          this.props.form.getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value,
          })((
            <RuleLinkTask
              executions={this.props.executions}
              fetchExecutions={this.props.fetchExecutions}
              ruleId={task.additionalInfo}
              ruleLinkSettings={task.ruleLinkSettings}
              originRuleId={rule.id}
            />
          ))
        );
        break;
      case TaskTypes.USER:
        item = (
          getFieldDecorator(key, {
            rules: [{ required: isRequired, message: this.props.t('field_required') }],
            initialValue: task.value,
          })((
            <UserTask
              onSearchUsers={this.onSearchUsers}
              searchUsers={this.props.searchUsersPaginated}
              placeholder={task.description}
              users={users}
            />
          ))
        );
        break;
      default:
    }

    const taskname = task.repeatNumber > 1 ? `${task.name} ${task.repeatNumber}` : task.name;

    return (
      <Form.Item
        key={key}
        label={task.type === TaskTypes.CHECK ? null : taskname}
        name={taskname}
      >
        {item}
      </Form.Item>
    );
  }

  folioDisabled = (folio, value) => {
    if (folio && value &&
      !isNaN(folio) && !isNaN(value) &&
      parseInt(folio) === parseInt(value)) {
      return true;
    }
    return false;
  }

  fileValidator = (rule, value, callback) => {
    if (value && value.file && value.file.size > 2000000) {
      callback(this.props.t('file_less_2mb'));
    }
    callback();
  }

  showRepeatTaskOrGroup = (taskOrGroup) => {
    const totalElementsOfTaskOrGroup = this.props.groupedChecklist.filter(item =>
      item.name === taskOrGroup.name && !!item.isGroup === !!taskOrGroup.isGroup).length;
    return (taskOrGroup.repeatNumber || 1) === totalElementsOfTaskOrGroup;
  }


  renderTaskOrGroupActions = (index, showDelete = false) => (
    <Row type="flex" gutter={16}>
      <Col>
        {showDelete &&
          <Button
            icon="delete"
            type="danger"
            onClick={() => {
            this.props.deleteTask(index);
          }}
          />
        }
      </Col>
      <Col>
        <Button
          icon="redo"
          type="primary"
          onClick={() => {
                    this.props.repeatTask(index);
                  }}
        >
          {this.props.t('repeat')}
        </Button>
      </Col>
    </Row>
  )


  renderGroup = (group, index, rule) => {
    const showRepeatGroup = this.showRepeatTaskOrGroup(group);
    return (
      <Card style={{ margin: 20 }}>
        <Row>
          <Row type="flex">
            <Col span={20}>
              <Text strong >
                {`${group.name}${group.repeatNumber > 1 ? ` ${group.repeatNumber}` : ''}`}
              </Text>
            </Col>
            {group.repetitive && showRepeatGroup &&
            <Col>
              { this.renderTaskOrGroupActions(index, group.repeatNumber > 1) }
            </Col >
          }
          </Row>

          {group.checklist.map(item =>
            this.getItem(uuidv5(group.name + group.repeatNumber + item.name), item, rule))}
        </Row>
      </Card>
  );
}

  renderTask = (task, index, rule) => {
    const showRepeatTask = this.showRepeatTaskOrGroup(task);
    return (
      <Card style={{ margin: 20 }}>
        { task.repeat && showRepeatTask &&
        <Row type="flex">
          <Col span={20} />
          <Col>
            { this.renderTaskOrGroupActions(index, task.repeatNumber > 1) }
          </Col>
        </Row>
      }
        {this.getItem(uuidv5(task.name + (task.repeatNumber || 1)), task, rule)}
      </Card>
  );
}


  render() {
    const {
 rule, loading, t, groupedChecklist,
} = this.props;

    return (
      <Form onSubmit={this.onSubmit}>

        { groupedChecklist &&
              groupedChecklist.map((item, index) => {
                if (item.isGroup) {
                  return this.renderGroup(item, index, rule);
                }
                return this.renderTask(item, index, rule);
              })

        }

        {rule.ruleType === RuleTypes.WEB &&
        <Form.Item>
          <Recaptcha
            sitekey="6Lc7DJMUAAAAAFvTBltSgcjOWjJmah29_YKnghZG"
            render="explicit"
            onChange={this.onChangeRecaptcha}
          />
        </Form.Item>
        }

        <Row type="flex" gutter={20}>
          {rule.ruleType === RuleTypes.NON_SCHEDULED && rule.status !== RuleExecutionStatus.DONE &&
          <Col>
            <Form.Item>
              <Button
                icon="pen"
                type="default"
                // htmlType="submit"
                loading={loading}
                onClick={() => this.onSaveDraft(false)}
              >
                {t('save_draft')}
              </Button>
            </Form.Item>
          </Col>
          }
          {rule.ruleType === RuleTypes.NON_SCHEDULED && rule.status !== RuleExecutionStatus.DONE &&
          <Col>
            <Form.Item>
              <Button
                icon="pen"
                type="default"
                // htmlType="submit"
                loading={loading}
                onClick={() => this.onSaveDraft(true)}
              >
                {t('save_and_continue')}
              </Button>
            </Form.Item>
          </Col>
          }
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={rule.ruleType === RuleTypes.WEB && !this.state.recaptchaVerified}
              >
                {t('save_activity')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedWebFormBuilder = Form.create({ name: 'web_form_builder' })(WebFormBuilder);

export default withTranslation()(WrappedWebFormBuilder);
