/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 15:31:23
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './index';
import { UPDATE_STATUS, FETCH_SUBSCRIPTIONS } from './types';
import PaymentService from '../../../services/payment';
import { Notification } from '../../../common/components/widgets';
import { parseApiError } from '../../../common/error';

const paymentService = new PaymentService();

const errorMessage = (error) => {
  const parsedError = parseApiError(error);
  Notification.error(parsedError.message);
};

const updateStatusEpic = action$ =>
  action$.ofType(UPDATE_STATUS).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(paymentService.updateStatus(action.subscriptionId, action.status))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.fetchSubscriptions(response)),
            Observable.of(actions.showMessage(() => {
              Notification.success(i18n.t('success'), i18n.t('subscription_canceled'), 3.5);
            })),
            Observable.of(actions.loading(false)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.showMessage(() => errorMessage(error))),
            Observable.of(actions.loading(false)),
          )),
    ));

const fetchSubscriptionsEpic = action$ =>
  action$.ofType(FETCH_SUBSCRIPTIONS).flatMap(() =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(paymentService.getSubscriptions())
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.fetchSubscriptionsFulfilled(response)),
            Observable.of(actions.loading(false)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.showMessage(() => errorMessage(error))),
            Observable.of(actions.loading(false)),
          )),
    ));

export default combineEpics(
  updateStatusEpic,
  fetchSubscriptionsEpic,
);
