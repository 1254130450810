/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-06 09:47:13 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-03-25 11:15:56
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './index';
import { CREATE_BRANCH_OFFICE, UPDATE_BRANCH_OFFICE, FETCH_BRANCH_OFFICES_BY_STATE, DELETE_BRANCH_OFFICE } from './types';

import BranchOfficeService from '../../../services/branch.office';
import { Notification } from '../../../common/components/widgets';
import { parseApiError } from '../../../common/error';

const branchService = new BranchOfficeService();

const registerSuccessAction = (response) => {
  Notification.success(i18n.t('success'), `${i18n.t('office_in')} ${response.state.name} ${i18n.t('registered_success_fem_short')}`, 4);
  return { type: '' };
};

const updateSuccessAction = (response) => {
  Notification.success(i18n.t('success'), `${i18n.t('office_in')} ${response.state.name} ${i18n.t('updated_success_fem_short')}`, 4);
  return { type: '' };
};

const deleteSuccessAction = () => {
  Notification.success(i18n.t('success'), `${i18n.t('branch_office')} ${i18n.t('deleted_success_fem')}`, 4);
  return { type: '' };
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const errorFetchBranchOffices = () => {
  Notification.error(i18n.t('offices_list_fail'));
};

const createBranchEpic = action$ =>
  action$.ofType(CREATE_BRANCH_OFFICE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(branchService.postNew(action.payload.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.resetState()),
            Observable.of(actions.branchCreated(true)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateBranchEpic = action$ =>
  action$.ofType(UPDATE_BRANCH_OFFICE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(branchService.putBranch(action.payload.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.resetState()),
            Observable.of(updateSuccessAction(response)),
            Observable.of(actions.branchCreated(true)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteBranchEpic = action$ =>
  action$.ofType(DELETE_BRANCH_OFFICE).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(branchService.deleteById(action.payload.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.resetState()),
            Observable.of(deleteSuccessAction(response)),
            Observable.of(actions.branchCreated(true)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const fetchBranchByStateEpic = action$ =>
  action$.ofType(FETCH_BRANCH_OFFICES_BY_STATE)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(branchService.getByStateId(action.stateId))
          .flatMap(response =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.fetchBrancOfficesFullfilled(response)),
            ))
          .catch(() =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => errorFetchBranchOffices())),
            )),
      ));

export default combineEpics(
  createBranchEpic,
  updateBranchEpic,
  fetchBranchByStateEpic,
  deleteBranchEpic,
);
