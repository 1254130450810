/*
 * @Author: Jhony Reyes 
 * @Date: 2018-12-26 10:22:42 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-19 11:55:17
 */

import i18n from 'i18next';

const monthRegex = /^(0[1-9]|1[0-2])$/;
const yearRegex = /^([0-9]{2})$/;
const cvcRegex = /^([0-9]{3}|[0-9]{4})$/; // NOSONAR

const validations = () => ({
  name: [
    { validate: 'required', message: i18n.t('name_required') },
    {
      validate: (_, form) => form.name.trimRight(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
  ],
  cardNumber: [
    { validate: 'required', message: i18n.t('card_number_required') },
    {
      validate: (_, form) => form.cardNumber.trimRight(),
      message: i18n.t('only_spaces_invalid'),
      custom: true,
    },
    {
      validate: (_, form) => Conekta.card.validateNumber(form.cardNumber), // eslint-disable-line
      message: i18n.t('invalid_card_number'),
      custom: true,
    },
  ],
  expMonth: [
    { validate: 'required', message: i18n.t('required_month') },
    {
      validate: (_, form) => monthRegex.test(form.expMonth),
      message: i18n.t('invalid_month'),
      custom: true,
    },
  ],
  expYear: [
    { validate: 'required', message: i18n.t('year_required') },
    {
      validate: (_, form) => yearRegex.test(form.expYear),
      message: i18n.t('invalid_year'),
      custom: true,
    },
    {
      validate: (_, form) => {
        const year = new Date().getFullYear();
        const lastTwo = year.toString().substring(2, 4);
        if (form.expYear >= parseInt(lastTwo, 10)) {
          return true;
        } return false;
      },
      message: i18n.t('year_passed'),
      custom: true,
    },
  ],
  cardCVC: [
    { validate: 'required', message: i18n.t('cvc_required') },
    {
      validate: (_, form) => cvcRegex.test(form.cardCVC),
      message: i18n.t('invalid_cvc'),
      custom: true,
    },
  ],
  terms: [
    {
      validate: (_, form) => {
        if (form.terms) {
          return true;
        } return false;
      },
      message: i18n.t('accept_terms'),
      custom: true,
    },
  ],
});

export default validations;
