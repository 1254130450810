/*
 * @Author: Cesar Medina
 * @Date: 2019-09-05 12:13:16
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-04 02:16:08
 */

export const RuleExecutionStatus = {
  CREATED: 'created',
  PENDING: 'pending',
  DONE: 'done',
};

export const EvaluationTypes = {
  GRAPHIC: 'graphic',
  RANGE: 'range',
  ENUM: 'enum',
};

export const EvidenceTypes = {
  REQUIRED: 'required',
  OPTIONAL: 'optional',
};

export const TaskTypes = {
  CHECK: 'check',
  ENUM: 'enum',
  MULTI_ENUM: 'multiEnum',
  TEXT: 'text',
  NUMERIC: 'numeric',
  DATE: 'date',
  TIME: 'time',
  CONTACT: 'contact',
  FILE: 'file',
  BARCODE: 'barcode',
  PHOTO: 'photo',
  SIGNATURE: 'signature',
  RULE_LINK: 'ruleLink',
  RULE_FOLIO: 'ruleFolio',
  USER: 'user',
};

export const RuleTypes = {
  SCHEDULED: 'scheduled',
  NON_SCHEDULED: 'nonScheduled',
  WEB: 'web',
  TASKGROUP: 'taskGroup',
};

export const WidgetTypes = {
  BAR: 'bar',
  PIE: 'pie',
  LINE: 'line',
  TIME_BETWEEN_RULES: 'time_between_rules',
  PERFORMANCE_BY_BRANCH: 'performance_by_branch',
};

export const WebHookTriggerTypes = {
  ON_UPDATE: 'ON_UPDATE',
  ON_CREATE: 'ON_CREATE',
};

export const LinkSpecificallyTypes = {
  BY_PERMISSIONS: 'BY_PERMISSIONS',
  USER_CREATOR: 'USER_CREATOR',
  USER_TASK_IN_SOURCE_RULE: 'USER_TASK_IN_SOURCE_RULE',
};

export const SentByTypes = {
  PUSH: 'push',
  WHATSAPP: 'whatsapp',
  MAIL: 'email',
};

export const NotificationTypes = {
  NOTIFY: 'notify_to',
  SCHEDULED_USERS: 'assign_activity_users',
  SCHEDULED_ROLE: 'assign_activity_role',
  USER: 'user',
  RELATED: 'related',
  CONTACT: 'contact',
};
