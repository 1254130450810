/*
 * @Author: Diana Resendiz 
 * @Date: 2021-11-27 12:47:00 
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-23 12:46:47
 */
import React from 'react';
import { Row, Col, Select, Icon, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { SentByTypes, NotificationTypes } from '../../../../common/constants';

const SentByTypesOptions = () => [
  { value: SentByTypes.PUSH, label: 'Task-It' },
  { value: SentByTypes.WHATSAPP, label: 'Whatsapp' },
  { value: SentByTypes.MAIL, label: 'e-mail' },
];

const NotificationTypesOptions = () => [
  { value: NotificationTypes.NOTIFY },
  { value: NotificationTypes.SCHEDULED_USERS },
  { value: NotificationTypes.SCHEDULED_ROLE },
  { value: NotificationTypes.USER },
  { value: NotificationTypes.RELATED },
  { value: NotificationTypes.CONTACT },
];

const HeaderContent = ({
  currentUserId,
  usersData,
  sentByValue,
  typeValue,
  userId,
  dateValue,
  onChangeFilters,
  canAccessNotificationFilters,
}) => {
  const { t } = useTranslation();

  return (
    <div className="headerContainer">
      <Row type="flex" align="bottom">
        <Col xs={24} sm={12} md={12} lg={12} xl={5} className="filter-box">
          <h1 className="textBranch">
            {t('sent_by')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <Select
            value={canAccessNotificationFilters ? sentByValue : SentByTypes.PUSH}
            className="select-data"
            onChange={value => onChangeFilters(value, 'sendBy')}
            disabled={!canAccessNotificationFilters}
          >
            <Select.Option value="">{t('all')}</Select.Option>
            {
              SentByTypesOptions().map(i => (
                <Select.Option value={i.value}>{i.label}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} className="filter-box">
          <h1 className="textBranch">
            {t('type')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <Select
            value={typeValue}
            className="select-data"
            onChange={value => onChangeFilters(value, 'type')}
          >
            <Select.Option value="">{t('all')}</Select.Option>
            {
              NotificationTypesOptions().map(i => (
                <Select.Option value={i.value}>{t(i.value)}</Select.Option>
              ))
            }
          </Select>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} className="filter-box">
          <h1 className="textBranch">
            {t('user_receiving')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <Select
            value={canAccessNotificationFilters ? userId : currentUserId}
            className="select-data"
            onChange={value => onChangeFilters(value, 'userNotifyId')}
            disabled={!canAccessNotificationFilters}
          >
            <Select.Option value="">{t('all')}</Select.Option>
            {
              usersData.map(user => (<Select.Option value={user.id}>{`${user.name} ${user.lastName}`}</Select.Option>))
            }
          </Select>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} className="filter-box">
          <h1 className="textBranch">
            {t('date')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <DatePicker
            value={dateValue ? moment(dateValue) : null}
            disabledDate={current => current > moment().endOf('day')}
            allowClear={false}
            showToday={false}
            onChange={date => onChangeFilters(date ? date.valueOf() : null, 'createdAt')}
            className="filter-select"
          />
        </Col>
      </Row>
    </div>
  );
  };

export { HeaderContent };
