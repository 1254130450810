/*
 * @Author: Cesar Medina
 * @Date: 2018-07-23 09:55:17 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-12-21 10:59:19
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import { CREATE_EXECUTION, SEARCH_USERS_PAGINATED, SEARCH_USERS_PAGINATED_FULLFILLED } from './types';
import { FETCH_CONTACTS_FULFILLED, FETCH_EXECUTIONS_FULFILLED, FETCH_EXECUTION_BY_ID_FULFILLED, FETCH_RULE_BY_ID_FULFILLED } from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';

const defaultState = {
  reducerKey: 'webActivityContainer',
  catalogs: {
    users: [],
    executions: {
      content: [],
      size: 10,
      number: 0,
      totalElements: 1,
    },
    contacts: [],
  },
  rule: null,
  groupedChecklist: [],
  savingExecution: false,
  savedExecution: false,
};

// Actions
// Payload { form, defaultActions, navigate }
const createExecution = (ruleExecution, saveAndContinue = false) =>
  ({ type: CREATE_EXECUTION, ruleExecution, saveAndContinue });
const searchUsersPaginated = filters => ({ type: SEARCH_USERS_PAGINATED, filters });
const searchUsersPaginatedFullfilled = payload => ({
  type: SEARCH_USERS_PAGINATED_FULLFILLED,
  payload,
});

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_RULE_BY_ID_FULFILLED]: (state, payload) =>
    ({ ...state, rule: payload, groupedChecklist: processChecklist(payload.checklist) }),
  [SEARCH_USERS_PAGINATED_FULLFILLED]: (state, { content }) =>
    update(state, { path: 'catalogs.users', value: content }),
  [FETCH_EXECUTIONS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.executions', value: payload }),
  [FETCH_EXECUTION_BY_ID_FULFILLED]: (state, payload) =>
  ({
    ...state,
    rule: payload,
    groupedChecklist: payload.evidence.completion &&
      payload.evidence.completion.checklist &&
        payload.evidence.completion.checklist.length > 0 ?
        processChecklist(payload.evidence.completion.checklist)
        :
        processChecklist(payload.checklist),
  }),
  [FETCH_CONTACTS_FULFILLED]: (state, payload) =>
  update(state, { path: 'catalogs.contacts', value: payload.content }),
});

const processChecklist = (checklist) => {
  const checklistTasks = [];
  for (let i = 0; i < checklist.length; i += 1) {
    const task = checklist[i];
    if (task.groupId) {
      const group = checklistTasks.find(g => (
        g.id === task.groupId &&
        (
          g.repeatNumber === task.groupInfo.repeatNumber ||
          (!task.groupInfo.repeatNumber && g.repeatNumber === 1)
        )
      ));
      if (group) {
        group.checklist.push(task);
      } else {
        checklistTasks.push({
          isGroup: true,
          id: task.groupId,
          name: task.groupInfo.name,
          repetitive: task.groupInfo.repetitive,
          checklist: [task],
          repeatNumber: task.groupInfo.repeatNumber || 1,
        });
      }
    } else { checklistTasks.push({ ...task }); }
  }

  return checklistTasks;
};

const actions = {
  ...generalActions,
  ...defaultActions,
  createExecution,
  searchUsersPaginated,
  searchUsersPaginatedFullfilled,
};

export {
  epics,
  reducer,
  actions,
  defaultActions,
};
