/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 15:57:29 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2022-07-23 13:51:29
 */

import i18n from 'i18next';

import { renderColumn } from '../../../common/components/widgets/table';

const columnTypes = {
  COMPANY: 'companyName',
  NAME: 'userName',
  EMAIL: 'email',
};

const statusTypes = {
  ACTIVE: 'active',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  PAYMENT_FAILED: 'payment_failed',
};

const subscriptionTypes = {
  MONTHLY: 'monthly',
  YEAR: 'year',
  FREE: 'free',
};

const columns = () => [
  renderColumn(i18n.t('company'), columnTypes.COMPANY, 'input', 180, 100, true),
  renderColumn(i18n.t('name'), columnTypes.NAME, 'input', 180, 100, true),
  renderColumn('Email', columnTypes.EMAIL, 'input', 180, 100, true),
];


export { columns, columnTypes, statusTypes, subscriptionTypes };
