/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-24 10:53:46 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-25 17:54:33
 */

const CONTAINER_KEY = 'CALENDAR_CONTAINER';

export const FETCH_RULES = `${CONTAINER_KEY}_FETCH_DATA`;
export const REGISTER_RULE = `${CONTAINER_KEY}_REGISTER_RULE`;
export const RULE_REGISTERED = `${CONTAINER_KEY}_RULE_REGISTERED`;
export const UPDATE_RULE = `${CONTAINER_KEY}_UPDATE_RULE`;
export const DELETE_RULE = `${CONTAINER_KEY}_DELETE_RULE`;
export const FETCH_RULES_BY_FILTERS = `${CONTAINER_KEY}FETCH_RULES_BY_FILTERS`;
export const LOAD_FORM = `${CONTAINER_KEY}_LOAD_FORM`;
export const TOGGLE_RULE_FORM = `${CONTAINER_KEY}_TOGGLE_RULE_FORM`;
export const CHANGE_TAB = `${CONTAINER_KEY}_CHANGE_TAB`;
