/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 11:09:48 
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-11 01:21:23
 */
import axios from 'axios';
import Service from '../Service';
import { createRulesServiceUrl, parseParams } from '../../common/helpers/service';

class RuleService extends Service {
    getById = id => axios.get(createRulesServiceUrl(`rules/${id}`)).then(data => data.data);
    postRule = form => axios.post(createRulesServiceUrl('rules'), form).then(data => data.data);
    putRule = form => axios.put(createRulesServiceUrl('rules'), form).then(data => data.data);
    deleteRule = id => axios.delete(createRulesServiceUrl(`rules/${id}`)).then(data => data.data);
    getByBranchOfficeId = id => axios.get(createRulesServiceUrl(`rules/branchOfficeId/${id}`)).then(data => data.data);
    getByFilters = (filters = {}) => axios.get(createRulesServiceUrl(`rules/filter${parseParams(filters)}`)).then(data => data.data);
    getByFiltersPaginated = (filters = {}) => axios.get(createRulesServiceUrl(`rules/filterPaginated${parseParams(filters)}`)).then(data => data.data);
    getRuleRelations = form => axios.post(createRulesServiceUrl('rules/relations'), form).then(data => data.data);
    getNotScheduledRules = (params = {}) => axios.get(createRulesServiceUrl(`rules/not-scheduled${parseParams(params)}`)).then(data => data.data);
    getRulesFilter = (params = {}) => axios.get(createRulesServiceUrl(`rules/filter${parseParams(params)}`)).then(data => data.data);
}

export default RuleService;
