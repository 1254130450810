/*
 * @Author: Diana Resendiz
 * @Date: 2021-11-20 01:08:35
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-21 14:15:59
 */

import axios from 'axios';
import Service from '../Service';
import { createCoreServiceUrl, parseParams } from '../../common/helpers/service';

class NotificationService extends Service {
    getAll = params => axios.get(createCoreServiceUrl(`notifications${parseParams(params)}`)).then(data => data.data);
    getByUserId = () => axios.get(createCoreServiceUrl('notifications/userId')).then(data => data.data);
    getById = id => axios.get(createCoreServiceUrl(`notifications/${id}`)).then(data => data.data);
}

export default NotificationService;
