/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-20 15:39:46 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-01-14 13:09:29
 */

import React from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { init, getFieldError } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';

import '../styles.css';
import logo from '../../../assets/images/taskit_logo_web.png';
import { actions } from './redux';
import { isAuthenticated } from '../../../security';
import { defaultFormValidations } from './validations';
import { validateInputStatus, redirectLogin } from '../utils';

const FormItem = Form.Item;

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.validator = init(defaultFormValidations());
  }

  componentWillMount = () => {
    const { resetState } = this.props;
    resetState();
  }

  onChangeEmailInput = (prop, value) => {
    const { updateState, formErrors } = this.props;
    const { error } = this.validator.validate(prop, value);
    updateState({ path: `form.${prop}`, value });
    updateState({ path: 'formErrors', value: { ...formErrors, [prop]: error } });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form, updateState, sendEmail,
    } = this.props;
    const { valid, errors } = this.validator.validateForm(form);
    updateState({ path: 'formErrors', value: errors });
    if (!valid) { return; }
    updateState({ path: 'loadingButton', value: true });
    const { email } = form;
    sendEmail({
      email,
    });
  }

  render() {
    const {
      form: { email }, formErrors, loadingButton, successSendEmail, t,
    } = this.props;
    const emailError = getFieldError(formErrors, 'email');
    const authenticated = isAuthenticated();
    if (authenticated) {
      return <Redirect to="/" />;
    }
    return (
      <Row type="flex" align="middle" justify="center" className="register login">
        <Row type="flex" justify="center" align="middle">
          <Col xs={22} sm={16} md={12} lg={12} xl={12}>
            <div className="login__box">
              <div className="login__box__img">
                <img src={logo} alt="logo-taskit" className="login__logo" />
              </div>
              {successSendEmail.send ? (
                <div className="auth-leyend-box">
                  <div className="justify-center">
                    <i className="fa fa-check auth-leyend-box__icon auth-leyend-box__icon--success" aria-hidden="true" />
                  </div>
                  <h1 className="login__text">{t('success')}</h1>
                  <h3 className="login__text login__text--justyfy">
                    {t('email_sent_you')}
                    <b className="auth-leyend-box-mail">{` ${successSendEmail.email}`}</b> {t('access_info')}
                  </h3>
                  <div className="justify-center">
                    <Button type="primary" onClick={() => redirectLogin(this.props)}>{t('log_in')}</Button>
                  </div>
                </div>
            ) : (
              <div>
                <h3 className="login__text login__text--justyfy">
                  {t('access_account_restore')}
                </h3>
                <Form onSubmit={this.handleSubmit} className="login-form">
                  <FormItem
                    label="Email"
                    hasFeedback
                    validateStatus={emailError ? 'error' : validateInputStatus(email)}
                    help={Boolean(emailError) && emailError}
                  >
                    <Input
                      prefix={<Icon type="mail" />}
                      placeholder="Email"
                      value={email}
                      onChange={value => this.onChangeEmailInput('email', value.target.value)}
                    />
                  </FormItem>
                  <FormItem className="login__form__footer">
                    <div className="login__button-container justify-center">
                      <Button loading={loadingButton} type="primary" htmlType="submit" onSubmit={this.handleSubmit} className="login-form-button">
                        {t('restore')}
                      </Button>
                    </div>
                    <div className="justify-center">
                      <Button onClick={() => redirectLogin(this.props)} className="login-form-button">
                        {t('back')}
                      </Button>
                    </div>
                  </FormItem>
                </Form>
              </div>
            )}
            </div>
          </Col>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = ({ resetPassword }) => resetPassword;

export default withTranslation()(connect(mapStateToProps, actions)(ResetPassword));
