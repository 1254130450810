/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:46:03 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-12-07 13:18:24
 */
const CONTAINER_KEY = 'COMPANIES_CONTAINER';
export const FETCH_COMPANIES = `${CONTAINER_KEY}_FETCH_COMPANIES`;
export const FETCH_COMPANIES_FULFILLED = `${FETCH_COMPANIES}_FULFILLED`;
export const UPDATE_STATUS = `${CONTAINER_KEY}_CANCEL_SUBSCRIPTION`;

export const FETCH_SUBSCRIPTIONS = `${CONTAINER_KEY}_FETCH_SUBSCRIPTIONS`;
export const FETCH_SUBSCRIPTIONS_FULFILLED = `${FETCH_SUBSCRIPTIONS}_FULFILLED`;

