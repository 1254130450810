/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:46:03 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-09-07 11:47:33
 */
const CONTAINER_KEY = 'WIDGETS_CONTAINER';

export const OPEN_MODAL = `${CONTAINER_KEY}_OPEN_MODAL`;
export const CLOSE_MODAL = `${CONTAINER_KEY}_CLOSE_MODAL`;
export const REGISTER_WIDGET = `${CONTAINER_KEY}_REGISTER_WIDGET`;
export const REGISTER_WIDGET_PERFORMANCE_BY_BRANCH = `${CONTAINER_KEY}_REGISTER_WIDGET_PERFORMANCE_BY_BRANCH`;
export const UPDATE_WIDGET = `${CONTAINER_KEY}_UPDATE_WIDGET`;
export const UPDATE_WIDGET_FULFILLED = `${CONTAINER_KEY}_UPDATE_WIDGET_FULFILLED`;
export const DELETE_WIDGET = `${CONTAINER_KEY}_DELETE_WIDGET`;
export const DELETE_WIDGET_FULFILLED = `${CONTAINER_KEY}_DELETE_WIDGET_FULFILLED`;
