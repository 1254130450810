/*
 * @Author: Jhony Reyes 
 * @Date: 2019-01-24 15:02:51 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-18 12:17:53
 */

import React, { Component } from 'react';
import { Button, List, Checkbox } from 'antd';
import { withTranslation } from 'react-i18next';

const defaultState = {
  currentText: '',
  checklist: {},
  checkedList: [],
  indeterminate: false,
  checkAll: false,
  created: [],
};

const setIndeterminate = (checkedList, data, created) => {
  const res = (checkedList.length < (created ? data.length : data.length - 1));
  return !!res;
};

const setCheckAll = (checkedList, data, created) =>
  checkedList.length === (created ? data.length : data.length - 1);

class Builder extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentWillMount = () => {
    const { created, data } = this.props;
    this.setState({ data, created });
    if (created.length) {
      this.setState({
        checkedList: created,
        indeterminate: setIndeterminate(created, data, true),
        checkAll: setCheckAll(created, data, true),
      });
    }
  }

  onChange = (id, value) => {
    const { data } = this.state;
    const permissionSelected = data.find(i => i.id === id);
    if (value) {
      this.setState(state => ({
        checkedList: [
          ...state.checkedList, permissionSelected,
        ],
        indeterminate: setIndeterminate(state.checkedList, state.data),
        checkAll: setCheckAll(state.checkedList, state.data),
      }));
    } else {
      this.setState(state => ({
        checkedList: state.checkedList.filter(checked => checked.id !== id),
        checkAll: false,
        indeterminate: state.checkedList.length > 1 &&
          (state.checkedList.length <= state.data.length),
      }));
    }
  }
  onChangeAll = (e) => {
    if (e.target.checked) {
      this.setState(state =>
        ({ checkedList: state.data, checkAll: true, indeterminate: false }));
    } else {
      this.setState({ checkedList: [], checkAll: false });
    }
  }

  onCancel = () => {
    const { created } = this.state;
    if (created.length) {
      this.setState({ checkedList: created }, this.handleCancel);
    } else {
      this.setState(defaultState, this.handleCancel);
    }
  }

  onOk = () => {
    const { checkedList } = this.state;
    const { onDone } = this.props;
    onDone(checkedList);
  }

  handleCancel = () => {
    const { created, checkedList } = this.state;
    const { onCancel } = this.props;
    if (created.length) {
      onCancel(created);
    } else {
      onCancel(checkedList);
    }
  }


  render() {
    const {
      data, checkedList, indeterminate, checkAll,
    } = this.state;
    const { t } = this.props;

    return (
      <div>
        <Checkbox
          onChange={this.onChangeAll}
          indeterminate={indeterminate}
          checked={checkAll}
          disabled={this.props.disabled}
        >
          {t('all_plural')}
        </Checkbox>
        <List
          dataSource={data}
          locale={{ emptyText: t('no_permissions') }}
          style={{ maxHeight: '20rem', overflow: 'auto' }}
          renderItem={item => (
            <List.Item>
              <Checkbox
                onChange={e => this.onChange(item.id, e.target.checked)}
                checked={checkedList.some(checked => item.id === checked.id)}
                disabled={this.props.disabled}
              >
                {item.description}
              </Checkbox>
            </List.Item>
        )}
        />
        <div className="justify-end margin-top-1">
          <Button onClick={this.onCancel}>
            {t('cancel')}
          </Button>
          <Button
            className="margin-left-half-rem"
            type="primary"
            onClick={this.onOk}
          >
            {t('accept')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation('translation', { withRef: true })(Builder);

