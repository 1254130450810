/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:46:03 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-04-25 15:49:14
 */
const CONTAINER_KEY = 'SUBSCRIPTION_CONTAINER';
export const RESET_FORM = `${CONTAINER_KEY}_RESET_FORM`;
export const UPDATE_STATUS = `${CONTAINER_KEY}_CANCEL_SUBSCRIPTION`;

export const FETCH_SUBSCRIPTIONS = `${CONTAINER_KEY}_FETCH_SUBSCRIPTIONS`;
export const FETCH_SUBSCRIPTIONS_FULFILLED = `${FETCH_SUBSCRIPTIONS}_FULFILLED`;
