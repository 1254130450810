import moment from 'moment';
import { isEmpty } from 'lodash';
import { v5 } from 'uuid';
import { RuleExecutionStatus, TaskTypes } from '../constants';
import { getImageUrl } from './images';

export const getBrowserLanguage = () => (
    (navigator.language || navigator.userLanguage).substring(0, 2)
);

export const getFormattedFolio = (folio, withPostfix = true) => {
    if (typeof folio === 'number' && folio > 0) {
        const formattedFolio = folio.toString().padStart(6, '0');
        if (withPostfix) {
            return `${formattedFolio} - `;
        }
            return formattedFolio;
    }
        return '';
};

export const filenameIsImage = filename => /\.(jpe?g|png|gif|bmp)$/i.test(filename);


export const getRuleExecutionStatus = (e) => {
    if (e.status === RuleExecutionStatus.DONE || e.status === RuleExecutionStatus.PENDING) {
      return e.status;
    }

    const endTime = moment(e.createdAt).add(e.cron.duration, 'm');
    if (endTime < moment()) {
      return 'expired';
    }
    return 'process';
};

export const getExecutionCardImage = (e) => {
    if (e.status === RuleExecutionStatus.DONE || e.status === RuleExecutionStatus.PENDING) {
      const { checklist } = e.evidence.completion;
      let task = checklist.find(i => i.type === TaskTypes.PHOTO && !isEmpty(i.value));
      if (!task) {
        task = checklist.find(i => i.type === TaskTypes.FILE &&
          !isEmpty(i.value) && filenameIsImage(i.value.filename));
      }
      if (task) {
        return getImageUrl(task.type === TaskTypes.FILE ? task.value.id : task.value);
      }
    }
    return null;
  };

export function uuidv5(string) {
  return v5(string, '1b671a64-40d5-491e-99b0-da01ff1f3341');
}
