/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:41:03 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-11-09 19:29:50
 */
import axios from 'axios';
import Service from '../Service';
import { createAuthenticationServiceUrl, parseParams } from '../../common/helpers/service';

class UserService extends Service {
    getAll = (params = {}) => axios.get(createAuthenticationServiceUrl(`users${parseParams(params)}`)).then(data => data.data);
    login = (form) => {
      const formData = new FormData(); //eslint-disable-line

      formData.append('grant_type', 'password');
      formData.append('username', form.email);
      formData.append('password', form.password);

      return axios.post(createAuthenticationServiceUrl('oauth/token'), formData, {
        headers: { Authorization: 'Basic d2ViLWFwcDpVamtqMkYzbVB3cld1Y3F2' },
      }).then(data => data.data);
    };
    getLoggedUser = () => axios.get(createAuthenticationServiceUrl('users/authenticate')).then(data => data.data);
    getById = id => axios.get(createAuthenticationServiceUrl(`users/${id}`)).then(data => data.data);
    update = form => axios.patch(createAuthenticationServiceUrl('users'), form).then(data => data.data);
    forgotPassword = email => axios.post(createAuthenticationServiceUrl(`users/forgot-password?email=${email}`)).then(data => data.data);
    getPasswordResetToken = token => axios.get(createAuthenticationServiceUrl(`users/reset-password?token=${token}`)).then(data => data.data);
    resetPassword = (form, password) => axios.patch(createAuthenticationServiceUrl(`users/reset-password/${password}`), form).then(data => data.data);
    getByBranchOfficeId = branchOfficeId => axios.get(createAuthenticationServiceUrl(`users/branch-office/${branchOfficeId}`)).then(data => data.data);
    getByRoleId = roleId => axios.get(createAuthenticationServiceUrl(`users/role/${roleId}`)).then(data => data.data);
    getByPermissionId = permissionId => axios.get(createAuthenticationServiceUrl(`users/permission/${permissionId}`)).then(data => data.data);
    register = form => axios.post(createAuthenticationServiceUrl('users'), form).then(data => data.data);
    getRegisterToken = token => axios.get(createAuthenticationServiceUrl(`users/register?token=${token}`)).then(data => data.data);
    updatePasswordUser = user => axios.put(createAuthenticationServiceUrl('users'), user).then(data => data.data);
    sendRegisterToken = userId => axios.get(createAuthenticationServiceUrl(`users/register-link?userId=${userId}`)).then(data => data.data);
    getUserInfoByToken = () => axios.get(createAuthenticationServiceUrl('users/by-token')).then(data => data.data);
    delete = id => axios.delete(createAuthenticationServiceUrl(`users/${id}`)).then(data => data.data);
    searchUsers = params => axios.get(createAuthenticationServiceUrl(`users/search${parseParams(params)}`)).then(data => data.data);
    searchUsersPaginated = params => axios.get(createAuthenticationServiceUrl(`users/searchPaginated${parseParams(params)}`)).then(data => data.data);
    generateApiToken = () => axios.get(createAuthenticationServiceUrl('users/generate-api-token')).then(data => data.data);
    updateImage = (id, form) => axios.post(createAuthenticationServiceUrl(`users/${id}/image`), form).then(data => data.data);
    updateWithPassword = (form, password) => axios.patch(createAuthenticationServiceUrl(`users/${password}`), form).then(data => data.data);
    getTableauTicket = (username = '') => axios.get(createAuthenticationServiceUrl(`users/tableau-ticket?tableauUsername=${username}`)).then(data => data.data);
}

export default UserService;
