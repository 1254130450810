import React from 'react';

const ConservarSvg = () => (
  <svg width="1em" height="1em" viewBox="0 0 25 17" fill="currentColor">
    <path d="M0.660889 7.03162H15.9312V9.16299H0.660889V7.03162Z" />
    <path d="M9.00488 0.105469L15.9312 7.03162L14.424 8.53883L7.49777 1.61258L9.00488 0.105469Z" />
    <path d="M9.00488 16.0892L15.9312 9.16299L14.424 7.65587L7.49777 14.5821L9.00488 16.0892Z" />
    <path d="M16.3392 8.09732C16.3392 8.97086 15.631 9.67901 14.7575 9.67901C13.8839 9.67901 13.1758 8.97086 13.1758 8.09732C13.1758 7.22377 13.8839 6.51562 14.7575 6.51562C15.631 6.51562 16.3392 7.22377 16.3392 8.09732Z" />
    <path d="M17.0049 0.105469L23.9311 7.03172L22.424 8.53883L15.4978 1.61258L17.0049 0.105469Z" />
    <path d="M17.0049 16.0892L23.9311 9.16298L22.424 7.65587L15.4978 14.5821L17.0049 16.0892Z" />
    <path d="M22.7574 9.67901C23.631 9.67901 24.3391 8.97086 24.3391 8.09732C24.3391 7.22377 23.631 6.51562 22.7574 6.51562C22.3004 6.51562 21.8886 6.70948 21.5999 7.01945L22.4339 8.09732L21.7735 9.33582C22.0436 9.55065 22.3855 9.67901 22.7574 9.67901Z" />
  </svg>
);

export default ConservarSvg;
