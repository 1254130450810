/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 12:18:27 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2018-11-22 11:15:27
 */
import axios from 'axios';
import Service from '../Service';
import { createAuthenticationServiceUrl } from '../../common/helpers/service';

class PermissionService extends Service {
    getAll = () => axios.get(createAuthenticationServiceUrl('permissions')).then(data => data.data);
}

export default PermissionService;
