/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-22 12:53:54 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-10-06 16:23:53
 */

import React from 'react';
import { Form, Icon, Input, Button, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { init, getFieldError } from 'aleonor-object-validations';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

import '../styles.css';
import logo from '../../../assets/images/taskit_logo_web.png';
import { actions } from './redux';
import { isAuthenticated } from '../../../security';
import { resetFormValidations } from './validations';
import { Loading } from '../../../common/components/layout';
import { validateInputStatus, redirectLogin, validatePermissionWeb } from '../utils';

const FormItem = Form.Item;

const ResetSucess = ({ user, onRedirect }) => (
  <Row type="flex" align="middle" justify="center" className="register login">
    <Row type="flex" justify="center" align="middle">
      <Col xs={22} sm={18} md={16} lg={16} xl={18}>
        <div className="login__box">
          <div className="login__box__img">
            <img src={logo} alt="logo-taskit" className="login__logo" />
          </div>
          <div className="justify-center">
            <i className="fa fa-check auth-leyend-box__icon auth-leyend-box__icon--success" aria-hidden="true" />
          </div>
          <h1 className="login__text">{i18n.t('success')}</h1>
          <h3 className="login__text">{i18n.t('congratulations')} {user.name} {i18n.t('password_restored')}</h3>
          {validatePermissionWeb(user.permission) ? (
            <div className="justify-center">
              <Button type="primary" onClick={onRedirect}>{i18n.t('log_in')}</Button>
            </div>
            ) : (
              <div className="justify-center">
                <h3 className="auth-leyend-box__icon--error"><b>{i18n.t('log_in_mobile')}</b></h3>
              </div>
            )}
        </div>
      </Col>
    </Row>
  </Row>
);

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.validator = init(resetFormValidations());
  }

  componentWillMount = () => {
    const { location: { search }, fetchToken, resetState } = this.props;
    resetState();
    const token = search.substring(search.indexOf('=') + 1);
    fetchToken(token);
  }

  onChangeForm = (prop, value) => {
    const { updateState, formErrors, resetForm } = this.props;
    const { error } = this.validator.validate(prop, value, resetForm);
    updateState({ path: `resetForm.${prop}`, value });
    updateState({ path: 'formErrors', value: { ...formErrors, [prop]: error } });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      resetForm, updateState, resetPassword, successFetchToken,
    } = this.props;
    const { valid, errors } = this.validator.validateForm(resetForm);
    updateState({ path: 'formErrors', value: errors });
    if (!valid) { return; }
    updateState({ path: 'loadingButton', value: true });
    resetPassword({
      form: successFetchToken.user,
      password: resetForm.password,
    });
  }

  redirectLogin = () => { this.props.resetState(); this.props.history.push('/auth/login'); };

  redirectResetPassword = () => this.props.history.push('/auth/reset-password');

  render() {
    const {
      resetForm: { password, newPassword }, formErrors, loadingButton,
      successFetchToken, isLoading, resetPasswordSucessful,
      t,
    } = this.props;
    const passwordError = getFieldError(formErrors, 'password');
    const newPasswordError = getFieldError(formErrors, 'newPassword');
    const authenticated = isAuthenticated();
    if (authenticated) {
      return <Redirect to="/" />;
    }
    if (isLoading) { return <Loading />; }
    if (resetPasswordSucessful.reset) {
      return (<ResetSucess
        user={resetPasswordSucessful.user}
        onRedirect={() => redirectLogin(this.props)}
      />);
    }

    return (
      <Row type="flex" align="middle" justify="center" className="register login">
        <Row type="flex" justify="center" align="middle">
          <Col xs={22} sm={18} md={16} lg={16} xl={18}>
            <div className="login__box">
              <div className="login__box__img">
                <img src={logo} alt="logo-taskit" className="login__logo" />
              </div>
              {successFetchToken.error ? (
                <div>
                  <div className="justify-center">
                    <i className="fa fa-times auth-leyend-box__icon auth-leyend-box__icon--error" aria-hidden="true" />
                  </div>
                  <h1 className="login__text">Error</h1>
                  <h3 className="login__text">{successFetchToken.error.message}</h3>
                  <div className="justify-center">
                    <Button type="primary" onClick={this.redirectResetPassword}>{t('request_new')}</Button>
                  </div>
                </div>
                    ) : (
                      <div>
                        <h3 className="login__text login__text--justyfy">
                          {t('greeting')} {successFetchToken.user.name}, {t('update_password')}
                        </h3>
                        <Form onSubmit={this.handleSubmit} className="login-form">
                          <FormItem
                            label={t('new_password')}
                            hasFeedback
                            validateStatus={passwordError ? 'error' : validateInputStatus(password)}
                            help={Boolean(passwordError) && passwordError}
                          >
                            <Input
                              type="password"
                              prefix={<Icon type="lock" />}
                              placeholder={t('new_password')}
                              value={password}
                              onChange={value => this.onChangeForm('password', value.target.value)}
                            />
                          </FormItem>
                          <FormItem
                            label={t('confirm_password')}
                            hasFeedback
                            validateStatus={newPasswordError ? 'error' : validateInputStatus(newPassword)}
                            help={Boolean(newPasswordError) && newPasswordError}
                          >
                            <Input
                              type="password"
                              prefix={<Icon type="lock" />}
                              placeholder={t('confirm_password')}
                              value={newPassword}
                              onChange={value => this.onChangeForm('newPassword', value.target.value)}
                            />
                          </FormItem>
                          <FormItem className="login__form__footer">
                            <div className="login__button-container justify-center">
                              <Button loading={loadingButton} type="primary" htmlType="submit" onSubmit={this.handleSubmit} className="login-form-button">
                                {t('restore')}
                              </Button>
                            </div>
                            <div className="login__button-container justify-center">
                              <Button onClick={() => redirectLogin(this.props)} className="login-form-button">
                                {t('cancel')}
                              </Button>
                            </div>
                          </FormItem>
                        </Form>
                      </div>

                    )}
            </div>
          </Col>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = ({ resetPassword }) => resetPassword;

export default withTranslation()(connect(mapStateToProps, actions)(ResetPasswordForm));
