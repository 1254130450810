/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-12-17 15:30:42
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './';
import {
  REGISTER_EVALUATION,
  UPDATE_EVALUATION,
  DELETE_EVALUATION,
} from './types';

import EvaluationService from '../../../services/evaluations';
import { parseApiError } from '../../../common/error';
import { Notification } from '../../../common/components/widgets';

const evaluationService = new EvaluationService();

const registerSuccessAction = (evaluation) => {
  Notification.success(i18n.t('success'), `${evaluation.name} ${i18n.t('registered_success')}`, 4);
  return { type: '' };
};

const updateSuccessAction = (evaluation) => {
  Notification.success(i18n.t('success'), `${evaluation.name} ${i18n.t('updated_success')}`, 4);
  return { type: '' };
};

const deleteSuccessAction = (evaluation) => {
  Notification.success(i18n.t('success'), `${evaluation} ${i18n.t('deleted_success')}`, 4);
  return { type: '' };
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const registerEvaluationEpic = action$ =>
  action$.ofType(REGISTER_EVALUATION).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(evaluationService.createEvaluation(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.fetchEvaluations({ page: 0, size: 10, sort: 'name,asc' })),
            Observable.of(actions.closeModal()),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateEvaluationEpic = action$ =>
  action$.ofType(UPDATE_EVALUATION).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(evaluationService.updateEvaluation(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(updateSuccessAction(response)),
            Observable.of(actions.fetchEvaluations(action.filters)),
            Observable.of(actions.closeModal()),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteEvaluationEpic = action$ =>
  action$.ofType(DELETE_EVALUATION).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(evaluationService.deleteEvaluation(action.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(deleteSuccessAction(response)),
            Observable.of(actions.fetchEvaluations(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

export default combineEpics(registerEvaluationEpic, updateEvaluationEpic, deleteEvaluationEpic);
