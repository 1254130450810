/*
 * @Author: Cesar Medina
 * @Date: 2019-04-24 11:12:44 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-19 11:54:58
 */
import React, { Component } from 'react';
import { Form, Input, Row, Col, Checkbox, Button, Card } from 'antd';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Background from '../../../../assets/images/background-web_task-it.jpg';

const FormItem = Form.Item;
const conekta = Conekta; // eslint-disable-line

class PaymentForm extends Component {
  getCardIcon = () => {
    const { form: { getFieldValue } } = this.props;
    const cardNumber = getFieldValue('cardNumber') || '';
    const brand = conekta.card.getBrand(cardNumber);

    switch (brand) {
      case 'visa':
        return <i className="fa fa-cc-visa" style={{ fontSize: '30px' }} aria-hidden="true" />;
      case 'mastercard':
        return <i className="fa fa-cc-mastercard" style={{ fontSize: '30px' }} aria-hidden="true" />;
      case 'amex':
        return <i className="fa fa-cc-amex" style={{ fontSize: '30px' }} aria-hidden="true" />;
      default:
        return <i className="fa fa-credit-card" style={{ fontSize: '30px' }} aria-hidden="true" />;
    }
  }

  cardNumberValidator = (rule, value, callback) => {
    if (value && !conekta.card.validateNumber(value)) {
      callback(this.props.t('invalid_card_number'));
    }
    callback();
  }

  expMonthValidator = (rule, value, callback) => {
    if (value && !/^(0[1-9]|1[0-2])$/.test(value)) {
      callback(this.props.t('invalid_month'));
    }
    callback();
  }

  expYearValidator = (rule, value, callback) => {
    if (value && !/^([0-9]{2})$/.test(value)) {
      callback(this.props.t('invalid_year'));
    }
    callback();
  }

  cardCvcValidator = (rule, value, callback) => {
    if (value && !/^([0-9]{3}|[0-9]{4})$/.test(value)) { // NOSONAR
      callback(this.props.t('invalid_cvc'));
    }
    callback();
  }

  termsValidator = (rule, value, callback) => {
    if (!value) {
      callback(this.props.t('accept_terms'));
    }
    callback();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  }

  render() {
    const { isLoading, form: { getFieldDecorator }, t } = this.props;

    return (
      <Card
        title="Ingresa los datos de tu tarjeta"
        headStyle={{
          background: `url(${Background})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          color: 'white',
        }}
      >
        <Form className="login-form" onSubmit={this.handleSubmit}>
          <FormItem
            label="Nombre del tarjeta habiente"
            hasFeedback
          >
            {
              getFieldDecorator('name', {
                rules: [
                  { required: true, message: t('name_required') },
                  { whitespace: true, message: t('only_spaces_invalid') },
                ],
              })(<Input
                type="text"
                placeholder="Nombre del tarjeta habiente"
                maxLength={40}
              />)
            }
          </FormItem>
          <FormItem
            label="Número de tarjeta"
          >
            {
              getFieldDecorator('cardNumber', {
                rules: [
                  { required: true, message: t('card_number_required') },
                  { whitespace: true, message: t('only_spaces_invalid') },
                  { validator: this.cardNumberValidator },
                ],
              })((
                <Row>
                  <Col span={21}>
                    <Input
                      type="text"
                      placeholder="Ej. 4242424242424242"
                      maxLength={16}
                    />
                  </Col>
                  <Col span={2} offset={1}>
                    <div className="cards-box">
                      { this.getCardIcon() }
                    </div>
                  </Col>
                </Row>
              ))
            }
          </FormItem>
          <Row>
            <Col lg={{ span: 24 }} xl={{ span: 6 }}>
              <FormItem
                label="Mes de expiración"
                hasFeedback
              >
                {
                  getFieldDecorator('expMonth', {
                    rules: [
                      { required: true, message: t('required_month') },
                      { validator: this.expMonthValidator },
                    ],
                  })(<Input
                    type="text"
                    placeholder="Ej. 08"
                    maxLength={2}
                  />)
                }
              </FormItem>
            </Col>
            <Col lg={{ span: 24 }} xl={{ span: 6, offset: 3 }}>
              <FormItem
                label="Año de expiración"
                hasFeedback
              >
                {
                  getFieldDecorator('expYear', {
                    rules: [
                      { required: true, message: t('year_required') },
                      { validator: this.expYearValidator },
                    ],
                  })(<Input
                    type="text"
                    placeholder="Ej. 22"
                    maxLength={2}
                  />)
                }
              </FormItem>
            </Col>
            <Col lg={{ span: 24 }} xl={{ span: 6, offset: 3 }}>
              <FormItem
                label="CVC"
                hasFeedback
              >
                {
                  getFieldDecorator('cardCVC', {
                    rules: [
                      { required: true, message: t('cvc_required') },
                      { validator: this.cardCvcValidator },
                    ],
                  })(<Input
                    type="text"
                    placeholder="Ej. 123"
                    maxLength={4}
                  />)
                }
              </FormItem>
            </Col>
          </Row>
          <Col span={24}>
            <div className="justify-center">
              <FormItem>
                {
                  getFieldDecorator('terms', {
                    valuePropName: 'checked',
                    rules: [{ validator: this.termsValidator }],
                  })(<Checkbox>{t('accept_the')} <Link target="_blank" to="/terms-conditions">{t('terms_and_conditions')}</Link></Checkbox>)
                }
              </FormItem>
            </div>
          </Col>
          <Col span={24}>
            <FormItem>
              <Button
                type="primary"
                loading={isLoading}
                style={{ width: '100%' }}
                htmlType="submit"
              >
                  Pagar
              </Button>
            </FormItem>
          </Col>
        </Form>
      </Card>
    );
  }
}

const WrappedPaymentForm = Form.create({ name: 'payment_login' })(PaymentForm);

export default withTranslation()(WrappedPaymentForm);
