/*
 * @Author: Jhony Reyes 
 * @Date: 2018-07-09 10:42:17 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-02-23 15:27:35
 */
import { omit } from 'lodash';

const formatForm = (form) => {
  const { postalCode } = form;
  const regexNum = /^([0-9])*$/;

  const postalCodeValid = regexNum.test(postalCode) ? { postalCode } : { postalCode: '-' };

  const code = postalCode ? { ...postalCodeValid } : '';

  const target = {
    ...form, ...code,
  };

  const formatedForm = omit(target, ['id', 'editable', 'new']);

  return formatedForm;
};

const formatFormEdit = (form) => {
  const { postalCode } = form;
  const regexNum = /^([0-9])*$/;

  const postalCodeValid = regexNum.test(postalCode) ? { postalCode: parseInt(postalCode, 10) } : { postalCode: '-' };

  const code = postalCode ? { ...postalCodeValid } : '';

  const target = {
    ...form, ...code,
  };

  const formatedForm = omit(target, ['editable', 'new']);

  return formatedForm;
};

export { formatForm, formatFormEdit };
