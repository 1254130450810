/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-15 11:59:31
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-06-05 11:00:11
 */

import { createReducer, update } from 'aleonor-redux-helpers';
import {
  FETCH_SUBSCRIPTION_PLAN_FULFILLED,
  FETCH_USER_INFO_BY_TOKEN_FULFILLED,
  SEARCH_USERS_FULFILLED,
} from './types';

// DEFINE COMMON REDUCER
const defaultState = {
  reducerKey: 'generalData',
  subscriptionInfo: {},
  userInfo: {},
  searchedUsers: [],
};

const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_SUBSCRIPTION_PLAN_FULFILLED]: (state, payload) => update(state, { path: 'subscriptionInfo', value: payload }),
  [FETCH_USER_INFO_BY_TOKEN_FULFILLED]: (state, payload) => update(state, { path: 'userInfo', value: payload }),
  [SEARCH_USERS_FULFILLED]: (state, payload) => update(state, { path: 'searchedUsers', value: payload }),
});

export { reducer, defaultActions };
