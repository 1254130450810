/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 11:09:48 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-05-27 15:49:40
 */
import axios from 'axios';
import Service from '../Service';
import { createRulesServiceUrl } from '../../common/helpers/service';

class TaskitProcessesService extends Service {
    getWithCompanyRules = filters => axios.get(createRulesServiceUrl('taskit-processes/with-company-rules'), {
        params: filters,
    }).then(data => data.data);
    switchEnableTaskitProcessForCompany = form => axios.post(createRulesServiceUrl('taskit-processes/enable'), form).then(data => data.data);
}

export default TaskitProcessesService;
