/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-27 12:47:00 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2022-07-23 13:51:57
 */

import React from 'react';
import i18n from 'i18next';
import { Row, Col, Icon, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { statusTypes } from '../../utils/columns';

const { Search } = Input;
const { Option } = Select;

const HeaderContent = ({
  onChangeValueFilter, onChangeStatus, onSelectedStatus,

}) => {
  const { t } = useTranslation();
  return (

    <div className="headerContainer" >
      <h1>{i18n.t('company_list')}</h1>
      <div className="headerFilter">
        <Row className="filtersRow">
          <Col xs={24} sm={12} md={8} lg={8} xl={5} className="filter-box">
            <h1 className="textBranch">{t('searcher')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
            <Search
              className="select-data"
              onChange={onChangeValueFilter}
              placeholder={t('company')}
              enterButton
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={5} className="filter-box">
            <h1 className="textBranch">{t('status')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
            <Select
              className="select-data"
              onChange={onChangeStatus}
              onSelect={onSelectedStatus}
              placeholder={t('status')}
            >
              <Option value={null}>{t('all_plural')}</Option>
              <Option value={statusTypes.ACTIVE}>{t('active')}</Option>
              <Option value={statusTypes.CANCELED}>{t('canceled')}</Option>
              <Option value={statusTypes.EXPIRED}>{t('expired')}</Option>
              <Option value={statusTypes.PAYMENT_FAILED}>{t('payment_failed')}</Option>
            </Select>
          </Col>
        </Row>
      </div>
    </div>
  );
};


export { HeaderContent };

