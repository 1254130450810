/*
 * @Author: Leonardo Gonzalez
 * @Date: 2021-11-11 01:07:10
 * @Last Modified by: Leonardo Gonzalez
 * @Last Modified time: 2021-11-21 18:02:29
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { Message } from '../../../common/components/widgets';
import ExecutionService from '../../../services/execution';
import { actions } from './index';
import { FETCH_DRAFTS, FETCH_ACTIVITIES, CREATE_EXECUTION } from './types';
import { parseApiError } from '../../../common/error';

const ruleExecutionService = new ExecutionService();

const executeCallbackAction = (callback = () => null, response) => {
  callback(response);
  return { type: '' };
};

const updateErrorAction = (error, navigate) => {
  const parsedError = parseApiError(error);
  Message.error(parsedError.message);
  if (parsedError.message === 'La regla ha expirado') {
    setTimeout(() => navigate(), 2000);
  }
};

const fetchDraftsEpic = action$ =>
  action$.ofType(FETCH_DRAFTS).flatMap(() =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(ruleExecutionService.getExecutionDrafts())
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.fetchDraftsFulfilled(response)),
            Observable.of(actions.loading(false)),
      //     )),
      // Observable.fromPromise(taskitProcessesService.getTaskitProcesses())
      //   .flatMap(response =>
      //     Observable.concat(
      //       Observable.of(actions.fetchTaskitProcessesFulfilled(response)),
      //       Observable.of(actions.loading(false)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => Message.error('Error al cargar borradores'))),
          )),
    ));

const fetchActivitiesEpic = action$ =>
  action$.ofType(FETCH_ACTIVITIES).flatMap(action =>
    Observable.forkJoin(
      Observable.of(action.payload.loading(true)),
      Observable.fromPromise(ruleExecutionService.getCurrentExecutions({
        branchOfficeId: action.branchOfficeId,
        roleIds: action.roleIds,
      })),
      Observable.fromPromise(ruleExecutionService.getProgressByDates(
        action.roleIds,
        action.branchOfficeId,
        action.timeZone,
        null,
        null,
        action.userId,
      )),
    )
      .flatMap(values =>
        Observable.concat(
          Observable.of(action.payload.loading(false)),
          Observable.of(actions.fetchActivitiesFulfilled(values[1])),
          Observable.of(executeCallbackAction(action.callback)),
          Observable.of(actions.fetchProgressFulfilled(values[2])),
        ))
      .catch(() =>
        Observable.concat(
          Observable.of(action.payload.loading(false)),
          Observable.of(action.payload.showMessage(() => Message.error('Error al cargar lista de actividades'))),
        )));

const createExecutionEpic = action$ =>
  action$.ofType(CREATE_EXECUTION).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(ruleExecutionService.createExecution(action.payload.ruleExecution))
        .flatMap(() => {
          if (action.payload.clearData) {
            return Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.resetState()),
            );
          }
          return Observable.of(actions.loading(false));
        })
        .do({
          complete: () => {
            Message.success('Tarea realizada con éxito');
            if (action.payload.clearData) {
              action.payload.navigate();
            } else {
              action.payload.clearTasks();
            }
          },
          error: (error) => {
            updateErrorAction(error, action.payload.navigate);
          },
        })
        .catch(() => Observable.of(actions.loading(false))),
    ));

export default combineEpics(fetchDraftsEpic, fetchActivitiesEpic, createExecutionEpic);

