/*
 * @Author: Diana Resendiz 
 * @Date: 2021-11-20 00:48:56
 * @Last Modified by: Diana Resendiz
 * @Last Modified time: 2021-12-23 12:36:38
 */

import React, { Component } from 'react';
import moment from 'moment';
import { Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HeaderContent } from './utils/components';
import { actions } from './redux';
import { NotificationTypes, SentByTypes } from '../../common/constants';
import { getFormattedFolio } from '../../common/helpers/functions';
import { getUserData, isAllowed } from '../../security';

import iconTaskit from '../../assets/images/icon_taskit.png';
import iconEmail from '../../assets/images/icon_email.png';
import iconWhatsapp from '../../assets/images/icon_whatsapp.png';

import typeNotify from '../../assets/images/type_notify_to.png';
import typeRole from '../../assets/images/type_assign_activity_role.jpg';
import typeUsers from '../../assets/images/type_assign_activity_users.png';
import typeUser from '../../assets/images/type_user.png';
import typeRelated from '../../assets/images/type_related.png';
import typeContact from '../../assets/images/type_contact.png';


class Notifications extends Component {
  constructor(props) {
    super(props);
    this.columns = this.getColumns();
    this.state = {
      canAccessNotificationFilters: false,
    };
  }

  componentDidMount = async () => {
    const {
      fetchNotifications,
      fetchUsers,
    } = this.props;
    fetchUsers();

    await this.isABranch();

    const userData = getUserData();
    const canAccess = isAllowed(['can_access_notification_filters']);

    this.setState({ canAccessNotificationFilters: canAccess });

    fetchNotifications({
      page: 0,
      ruleExecutedId: null,
      userExecutedId: null,
      userNotifyId: canAccess ? null : userData.id,
      type: null,
      sendBy: canAccess ? null : SentByTypes.PUSH,
      createdAt: null,
    });
  };

  onChangeFilters = (val, filter) => {
    const { updateState, fetchNotifications, filters } = this.props;
    const updatedFilters = {
      ...filters,
      page: 0,
      [filter]: val,
    };
    updateState({ path: 'filters', value: updatedFilters });
    fetchNotifications({ ...updatedFilters });
  }

  getFiltersToFetch = (ignoreFolio = false) => {
    const { filters } = this.props;

    if (filters.folio && !ignoreFolio) {
      return {
        folio: filters.folio,
        ruleId: filters.ruleId,
        initDate: null,
        endDate: null,
      };
    }

    return {
      ...filters,
      initDate: filters.initDate,
      endDate: filters.endDate,
    };
  }

  getColumns = () => [
   {
      title: this.props.t('sent_by'),
      dataIndex: 'sendBy',
      width: '5%',
      onCell: () => ({ 'data-label': this.props.t('sent_by') }),
      render: (text, record) => this.renderSentBy(record.sendBy),
    },
    {
      title: this.props.t('type'),
      dataIndex: 'type',
      width: '5%',
      onCell: () => ({ 'data-label': this.props.t('type') }),
      render: (text, record) => this.renderType(record.type),
    },
    {
      title: this.props.t('user'),
      dataIndex: 'userExecutedId',
      width: '15%',
      onCell: () => ({ 'data-label': this.props.t('user') }),
      render: (text, record) => this.renderUserExecuted(record.userExecuteId),
    },
    {
      title: this.props.t('origen'),
      dataIndex: 'origen',
      width: '30%',
      onCell: () => ({ 'data-label': this.props.t('origen') }),
      render: (text, record) => this.renderOrigin(record),
    },
    {
      title: this.props.t('activity'),
      dataIndex: 'ruleExecutedName',
      width: '20%',
      onCell: () => ({ 'data-label': this.props.t('activity') }),
      render: (text, record) =>
          this.renderRuleExecutedName(record),
    },
    {
      title: this.props.t('date'),
      dataIndex: 'createdAt',
      width: '10%',
      onCell: () => ({ 'data-label': this.props.t('date') }),
      render: (text, record) => <div>{moment(record.createdAt).format('DD/MM/YYYY LT')}</div>,
    },
    {
      title: this.props.t('user_receiving'),
      dataIndex: 'userId',
      width: '15%',
      onCell: () => ({ 'data-label': this.props.t('user_receiving') }),
      render: (text, record) => this.renderUsersNotify(record.userNotifyId),
    },
  ]

  isABranch = async () => {
    const {
      fetchExecutions,
      catalogs: { executions },
    } = this.props;
    const userData = getUserData();
    const canMonitorAllBranches = isAllowed(['can_monitor_all_branches']);

    if (!canMonitorAllBranches) {
      await fetchExecutions({
        page: 0,
        size: executions.size,
        ...this.getFiltersToFetch(),
        branchOfficeId: userData.branchOffice.id,
      });
    } else {
      await fetchExecutions({
        page: 0,
        size: executions.size,
        ...this.getFiltersToFetch(),
      });
    }
  }

  renderSentBy = types => (
    <div>
      <center>
        <img src={iconTaskit} alt="TASK-IT" style={{ width: '0.5cm' }} hidden={!types.includes('push')} />
        <img src={iconWhatsapp} alt="TASK-IT" style={{ width: '0.5cm' }} hidden={!types.includes('whatsapp')} />
        <img src={iconEmail} alt="TASK-IT" style={{ width: '0.6cm' }} hidden={!types.includes('email')} />
      </center>
    </div>
    )

  renderType = type => (
    <div>
      <Tooltip title={this.renderTypeText(type)}>
        <center>
          <img src={this.renderTypeImage(type)} alt="TASK-IT" style={{ width: '1cm' }} />
        </center>
      </Tooltip>
    </div>
    )

  renderTypeImage = (type) => {
    switch (type) {
      case NotificationTypes.NOTIFY:
        return typeNotify;
      case NotificationTypes.SCHEDULED_USERS:
        return typeUsers;
      case NotificationTypes.SCHEDULED_ROLE:
        return typeRole;
      case NotificationTypes.USER:
        return typeUser;
      case NotificationTypes.RELATED:
        return typeRelated;
      case NotificationTypes.CONTACT:
        return typeContact;
      default:
        return null;
    }
  }

  renderTypeText = (type) => {
    const { t } = this.props;
    switch (type) {
      case NotificationTypes.NOTIFY:
        return t(NotificationTypes.NOTIFY);
      case NotificationTypes.SCHEDULED_USERS:
        return t(NotificationTypes.SCHEDULED_USERS);
      case NotificationTypes.SCHEDULED_ROLE:
        return t(NotificationTypes.SCHEDULED_ROLE);
      case NotificationTypes.USER:
        return t(NotificationTypes.USER);
      case NotificationTypes.RELATED:
        return t(NotificationTypes.RELATED);
      case NotificationTypes.CONTACT:
        return t(NotificationTypes.CONTACT);
      default:
        return null;
    }
  }

  renderOrigin = notification => (
    <div>
      {this.renderOriginText(
        notification.type,
        notification.ruleInformation.name,
        notification.ruleInformation.nameRole,
        notification.ruleInformation.nameTask,
        notification.ruleInformation.nameOriginRule,
)}
    </div>
    )

  renderOriginText = (type, name, role, task, originRule) => {
    switch (type) {
      case NotificationTypes.NOTIFY:
        return this.props.t('origin_notify_to', { rule: name });
      case NotificationTypes.SCHEDULED_USERS:
        return this.props.t('origin_scheduled_users', { rule: name });
      case NotificationTypes.SCHEDULED_ROLE:
        return this.props.t('origin_scheduled_role', { rule: name, rle: role });
      case NotificationTypes.USER:
        return this.props.t('origin_user', { rule: name });
      case NotificationTypes.RELATED:
        return this.props.t('origin_related', { rule: name, task, originRule });
      case NotificationTypes.CONTACT:
        return this.props.t('origin_contact', { rule: name, task });
      default:
        return null;
    }
  }

  renderRuleExecutedName = (notification) => {
    const folio = getFormattedFolio(notification.ruleInformation.folio);
    return (
      folio ?
        <Link
          to={`/evidences/${notification.ruleExecutedId}`}
        >
          {`${folio } ${ notification.ruleInformation.name}`}
        </Link>
      :
        notification.ruleInformation.name
      );
  }

  renderUserExecuted = (userId) => {
    const {
      catalogs: {
        users,
      },
    } = this.props;
    const selectedUserIds = userId ? users.filter(u => u.id === userId) : [];
    const names = (selectedUserIds && selectedUserIds.length) ? selectedUserIds.map(s => (`${s.name} ${s.lastName}`)).join(', ') : '';
    return names;
  }

  renderUsersNotify = (userIds) => {
    const {
      catalogs: {
        users,
      },
    } = this.props;
    const selectedUserIds = userIds ? users.filter(u => userIds.some(i => i === u.id)) : [];
    const names = (selectedUserIds && selectedUserIds.length) ? selectedUserIds.map(s => (`${s.name} ${s.lastName}`)).join(', ') : '';
    return names;
  }

  render() {
    const {
      catalogs: {
        notifications,
        users,
      },
      currentPage,
      filters, isLoading, t,
    } = this.props;
    const userData = getUserData();

    return (
      <div>
        <HeaderContent
          currentUserId={userData.id}
          usersData={users}
          sentByValue={filters.sendBy}
          typeValue={filters.type}
          userId={filters.userNotifyId}
          dateValue={filters.createdAt}
          onChangeFilters={this.onChangeFilters}
          canAccessNotificationFilters={this.state.canAccessNotificationFilters}
        />
        <Table
          ref={(table) => { this.table = table; }}
          rowKey="id"
          className="responsive-table"
          dataSource={notifications}
          columns={this.columns}
          locale={{ emptyText: t('show_no_data') }}
          loading={isLoading}
          pagination={{
            current: currentPage,
            onChange: page => this.props.updateState({ path: 'currentPage', value: page }),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ notificationsContainer, generalData }) => ({
  ...notificationsContainer,
  ...generalData,
});

export default withTranslation()(connect(mapStateToProps, actions)(Notifications));
