/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:08:43
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-12-27 13:38:05
 */
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { UpgradeSubscriptionMessage } from '../common/components/widgets';

export const TOKEN_DATA_KEY = 'token_data_key';
export const USER_DATA_KEY = 'user_data_key';

export const isAuthenticated = () => {
  try {
    return getUserData();
  } catch (err) {
    return false;
  }
};

export const getUserData = () => JSON.parse(localStorage.getItem(USER_DATA_KEY));
export const saveUserData = user => localStorage.setItem(USER_DATA_KEY, JSON.stringify(user));
export const removeUserData = () => localStorage.removeItem(USER_DATA_KEY);

export const getTokenData = () => JSON.parse(localStorage.getItem(TOKEN_DATA_KEY));
export const saveTokenData = (tokenData) => {
  localStorage.setItem(TOKEN_DATA_KEY, JSON.stringify(tokenData));
};
export const removeTokenData = () => localStorage.removeItem(TOKEN_DATA_KEY);

export const logout = () => {
  removeTokenData();
  removeUserData();
};

export const isAllowed = (permissions, user) => {
  const userData = user || getUserData();

  if (!userData) {
    return false;
  }

  if (Array.isArray(permissions)) {
    return userData.permissions
      .some(userPermission => permissions.some(p => p === userPermission.keyName));
  }

  return userData.permissions.some(p => permissions === p.keyName);
};

export const validateSubscription = (subscription) => {
  if (subscription.status === 'canceled' && moment().isAfter(moment.unix(subscription.billingCycleEnd))) {
    return false;
  } return true;
};

export const hasSubscription = (subscription, data, max, catalogName) => {
  if (!subscription.planId && data.length === max) {
    const { t } = useTranslation();
    UpgradeSubscriptionMessage(
      `${t('limit_msg')} ${catalogName}, ${t('upgrade_msg')}`,
      () => this.props.history.push('/subscription'),
    );
    return false;
  }
  return true;
};
