/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-12 00:04:18 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-08-20 16:29:16
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import { LOGIN_USER, LOGIN_USER_SUCCESSFUL } from './types';

const defaultState = {
  reducerKey: 'loginUser',
  formErrors: {},
  form: {
    email: '',
    password: '',
  },
  loadingButton: false,
};

// Actions
// Payload { form, defaultActions, navigate }
const loginUser = payload => ({ type: LOGIN_USER, payload });
const loginUserSuccess = payload => ({ type: LOGIN_USER_SUCCESSFUL, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [LOGIN_USER_SUCCESSFUL]: (state, payload) => update(state, { path: 'loadingButton', value: payload }),
});

const actions = {
  ...defaultActions, loginUser, loginUserSuccess,
};

export { reducer, actions, epics };
