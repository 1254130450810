/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-08 17:10:47 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-08-24 20:19:40
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  REGISTER_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  REGISTER_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  OPEN_GROUP_MODAL,
  CLOSE_GROUP_MODAL,
  LOAD_GROUP_FORM,
  RESET_GROUP_FORM,
  RESET_FILTERS,
  DOWNLOAD_CONTACTS_IN_CSV,
  UPLOAD_CONTACTS_IN_CSV,
} from './types';
import {
  FETCH_BRANCH_OFFICES_FULFILLED,
  FETCH_CONTACTS_FULFILLED, FETCH_GROUPS_FULFILLED,
  FETCH_USERS_FULFILLED,
  FETCH_WHATSAPP_CUSTOMERS_FULFILLED,
} from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';

const defaultGroupForm = {
  name: { value: '' },
  description: { value: '' },
};

const defaultFilters = {
  name: null,
  groupId: null,
  notifyWhatsapp: null,
  notifyEmail: null,
  notificationMode: 'all',
  search: null,
  page: 0,
  size: 10,
  sort: 'name,asc',
  totalElements: 0,
};

const defaultState = {
  reducerKey: 'contactsContainer',
  catalogs: {
    contacts: [],
    groups: [],
    whatsAppCustomers: [],
    branchOffices: [],
    users: [],
  },
  filters: { ...defaultFilters },
  currentPage: 1,
  showGroupModal: false,
  currentGroup: null,
  groupForm: { ...defaultGroupForm },
};

// Actions
// Payload { form, defaultActions, navigate }
const registerContact = (form, filters, callback) => ({
  type: REGISTER_CONTACT, form, filters, callback,
});
const updateContact = (form, filters, callback) => ({
  type: UPDATE_CONTACT, form, filters, callback,
});
const deleteContact = (id, filters) => ({ type: DELETE_CONTACT, id, filters });
const registerGroup = (form, filters) => ({ type: REGISTER_GROUP, form, filters });
const updateGroup = (form, filters) => ({ type: UPDATE_GROUP, form, filters });
const deleteGroup = (id, filters) => ({ type: DELETE_GROUP, id, filters });
const openGroupModal = (currentItem = null) =>
  ({ type: OPEN_GROUP_MODAL, payload: { currentItem } });
const closeGroupModal = () => ({ type: CLOSE_GROUP_MODAL });
const loadGroupForm = group => ({ type: LOAD_GROUP_FORM, payload: { group } });
const resetGroupForm = () => ({ type: RESET_GROUP_FORM });
const resetFilters = () => ({ type: RESET_FILTERS });
const downloadContactsInCsv = () => ({ type: DOWNLOAD_CONTACTS_IN_CSV });
const uploadContactsInCsv = (form, filters) => ({ type: UPLOAD_CONTACTS_IN_CSV, form, filters });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_CONTACTS_FULFILLED]: (state, payload) => ({
    ...state,
    catalogs: {
      ...state.catalogs,
      contacts: payload.content,
    },
    filters: {
      ...state.filters,
      totalElements: payload.totalElements,
    },
  }),
  [FETCH_GROUPS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.groups', value: payload }),
  [FETCH_WHATSAPP_CUSTOMERS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.whatsAppCustomers', value: payload }),
  [OPEN_GROUP_MODAL]: state =>
    update(state, { path: 'showGroupModal', value: true }),
  [CLOSE_GROUP_MODAL]: state =>
    update(state, { path: 'showGroupModal', value: false }),
  [LOAD_GROUP_FORM]: (state, payload) => ({
    ...state,
    currentGroup: payload.group,
    groupForm: {
      name: { value: payload.group.name },
      description: { value: payload.group.description },
    },
  }),
  [RESET_GROUP_FORM]: state => ({
    ...state,
    currentGroup: null,
    groupForm: { ...defaultGroupForm },
  }),
  [RESET_FILTERS]: state => ({
    ...state,
    filters: { ...defaultFilters },
    currentPage: 1,
  }),
  [FETCH_BRANCH_OFFICES_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.branchOffices', value: payload }),
  [FETCH_USERS_FULFILLED]: (state, payload) =>
   update(state, { path: 'catalogs.users', value: payload }),
});

const actions = {
  ...defaultActions,
  ...generalActions,
  registerContact,
  updateContact,
  deleteContact,
  registerGroup,
  updateGroup,
  deleteGroup,
  openGroupModal,
  closeGroupModal,
  loadGroupForm,
  resetGroupForm,
  resetFilters,
  downloadContactsInCsv,
  uploadContactsInCsv,
};

export { reducer, actions, epics, defaultActions };
