/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-20 10:56:25 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-03-25 10:16:12
 */
import axios from 'axios';
import { orderBy } from 'lodash';
import Service from '../Service';
import { createCoreServiceUrl } from '../../common/helpers/service';

const formatData = users => orderBy(users, ['createdAt'], ['desc']);

class BranchOfficeService extends Service {
    getActive = () => axios.get(createCoreServiceUrl('branch-offices/active')).then(data => formatData(data.data));
    getAll = () => axios.get(createCoreServiceUrl('branch-offices')).then(data => formatData(data.data));
    postNew = form => axios.post(createCoreServiceUrl('branch-offices'), form).then(data => data.data);
    putBranch = form => axios.put(createCoreServiceUrl('branch-offices'), form).then(data => data.data);
    getByStateId = stateId => axios.get(createCoreServiceUrl(`branch-offices/state/${stateId}`)).then(data => data.data);
    deleteById = id => axios.delete(createCoreServiceUrl(`branch-offices/${id}`)).then(data => data.data);
}

export default BranchOfficeService;
