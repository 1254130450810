/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-08 17:10:47 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-12-07 13:44:24
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  FETCH_COMPANIES, FETCH_COMPANIES_FULFILLED,
  FETCH_SUBSCRIPTIONS_FULFILLED, FETCH_SUBSCRIPTIONS,
  UPDATE_STATUS,
} from './types';
import * as generalActions from '../../../store/general/actions';

const defaultState = {
  reducerKey: 'companiesContainer',
  catalogs: {
    companies: [],
    subscriptions: [],
  },
};

// Actions
// Payload { form, defaultActions, navigate }
const fetchCompanies = payload => ({ type: FETCH_COMPANIES, payload });
const fetchCompaniesFulfilled = payload => ({ type: FETCH_COMPANIES_FULFILLED, payload });
const fetchSubscriptions = () => ({ type: FETCH_SUBSCRIPTIONS });
const fetchSubscriptionsFulfilled = payload => ({ type: FETCH_SUBSCRIPTIONS_FULFILLED, payload });
const renewSubscriptions = (subscriptionId, status, billingCycleEnd) => ({
 type: UPDATE_STATUS, subscriptionId, status, billingCycleEnd,
});

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_COMPANIES_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.companies', value: payload }),
  [FETCH_SUBSCRIPTIONS_FULFILLED]: (state, payload) => update(state, { path: 'catalogs.subscriptions', value: payload }),

});

const actions = {
  ...defaultActions,
  ...generalActions,
  fetchCompanies,
  fetchCompaniesFulfilled,
  fetchSubscriptions,
  fetchSubscriptionsFulfilled,
  renewSubscriptions,
};

export { reducer, actions, epics, defaultActions };
