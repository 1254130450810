/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-27 12:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-09-28 11:58:54
 */
import React from 'react';
import { Row, Col, Button, Icon, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { TaskTypes } from '../../../../common/constants';

const { Option } = Select;
const { Search } = Input;


const HeaderContent = ({
  creating,
  onCreate,
  selectedType,
  onSelectType,
  selectedEvidenceType,
  onSelectEvidenceType,
  name,
  onSearchName,
  onChangeName,
}) => {
  const { t } = useTranslation();
  return (
    <div className="headerContainer">
      <Row className="filtersRow">
        <Col span={6}>
          <h1 className="textBranch">
            {t('name')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <Search
            className="select-data"
            value={name}
            onChange={onChangeName}
            onSearch={onSearchName}
            enterButton
          />
        </Col>
        <Col span={6}>
          <h1 className="textBranch">{t('type')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
          <Select
            value={selectedType}
            style={{ width: '100%', paddingRight: '1em' }}
            onChange={onSelectType}
          >
            <Option value={null}>{t('all_plural')}</Option>
            <Option value={TaskTypes.CHECK}>{t('check')}</Option>
            <Option value={TaskTypes.MULTI_ENUM}>{t('multi_numbering')}</Option>
            <Option value={TaskTypes.ENUM}>{t('numbering')}</Option>
            <Option value={TaskTypes.TEXT}>{t('text')}</Option>
            <Option value={TaskTypes.NUMERIC}>{t('number')}</Option>
            <Option value={TaskTypes.DATE}>{t('date')}</Option>
            <Option value={TaskTypes.TIME}>{t('time')}</Option>
            <Option value={TaskTypes.CONTACT}>{t('contact')}</Option>
            <Option value={TaskTypes.FILE}>{t('file')}</Option>
            <Option value={TaskTypes.BARCODE}>{t('qr_code')}</Option>
            <Option value={TaskTypes.PHOTO}>{t('photo')}</Option>
            <Option value={TaskTypes.SIGNATURE}>{t('signature')}</Option>
            <Option value={TaskTypes.RULE_LINK}>{t('link')}</Option>
            <Option value={TaskTypes.RULE_FOLIO}>{t('folio')}</Option>
            <Option value={TaskTypes.USER}>{t('user')}</Option>
          </Select>
        </Col>
        <Col span={6}>
          <h1 className="textBranch">{t('evidence')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
          <Select
            value={selectedEvidenceType}
            style={{ width: '100%', paddingRight: '1em' }}
            onChange={onSelectEvidenceType}
          >
            <Option value={null}>{t('all_plural')}</Option>
            <Option value="required">{t('required')}</Option>
            <Option value="optional">{t('optional')}</Option>
          </Select>
        </Col>
        <Col span={6} >
          <Row justify="end" type="flex">
            <Button type="primary" onClick={onCreate} disabled={creating}>{t('new_task')}</Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export { HeaderContent };

