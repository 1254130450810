/*
 * @Author: Cesar Medina
 * @Date: 2018-07-23 10:00:11
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-09-24 11:30:57
 */
import { omit } from 'lodash';

const formatForm = (form) => {
  const formatedForm = omit(form, ['id', 'editable']);
  return formatedForm;
};

const formatFormEdit = (form) => {
  const formatedForm = omit(form, ['editable']);
  return formatedForm;
};

export { formatForm, formatFormEdit };
