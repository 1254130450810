/*
 * @Author: Jhony Reyes 
 * @Date: 2018-06-19 14:41:21 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2023-11-28 10:36:51
 */

import React, { Component } from 'react';
import { Table, Popconfirm, Button, Tag } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import moment from 'moment';
import { fetchActions } from './utils';
import { actions, defaultActions } from './redux';
import { HeaderContent } from './utils/components';
import { subscriptionTypes, statusTypes } from './utils/columns';


class Companies extends Component {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);
    this.state = {
      search: '',
      status: null,
    };
  }

  componentWillMount = () => {
    const { fetchSubscriptions } = this.props;
    fetchSubscriptions();
  }

  componentDidMount = () => {
    fetchActions(this.props, defaultActions);
  };

  onChangeValueFilter = async (event) => {
    event.persist();

    await this.setState({ search: event.target.value });
  }

  onSelectedStatus = (event) => {
    this.setState({ status: event });
  }

  getFilteredCompanies = () => {
    const {
      catalogs: { companies, subscriptions },
    } = this.props;
    let newCompanies = companies;
    if (!!this.state.search && !!this.state.search.trim()) {
      newCompanies = newCompanies.filter((item) => {
        if (item.companyName.toLowerCase().includes(this.state.search.toLocaleLowerCase())) {
          return true;
        }
        return false;
      });
    }
    if (this.state.status) {
      newCompanies = newCompanies.filter((item) => {
        if (subscriptions
          .findIndex(subs => subs.status === this.state.status
            && subs.companyId === item.id) > -1) {
          return true;
        }
        return false;
      });
    }
    return newCompanies;
  }

  getActionsButton = (record) => {
    if (record.type === subscriptionTypes.FREE) {
      return '';
    }

    if ((record.status === statusTypes.EXPIRED && !!this.urlParams.get('expired')) || record.status === statusTypes.PAYMENT_FAILED) {
      return (
        <Popconfirm
          title={this.props.t('renew_subscription_confirmation')}
          onConfirm={() =>
            this.props.renewSubscriptions(record.id, statusTypes.ACTIVE, record.billingCycleEnd)}
          cancelText={this.props.t('no')}
          okText={this.props.t('yes')}
          placement="left"
        >
          <Button>{this.props.t('renew')}</Button>
        </Popconfirm>
      );
    }
    return '';
  }

  getFiltersSubscriptions = (subscriptions, record) => subscriptions
    .filter((suscription) => {
      if (record.id === suscription.companyId) {
        if (this.state.status === null) {
          return true;
        }
        return suscription.status === this.state.status;
      }
      return false;
    })

  getColumns = () => {
    const columns = [
      {
        title: this.props.t('company'),
        dataIndex: 'companyName',
        key: 'companyName',
        sorter: (a, b) => a.companyName.localeCompare(b.companyName),
      }, {
        title: this.props.t('name'),
        key: 'userName',
        dataIndex: 'userName',
      }, {
        title: this.props.t('email'),
        dataIndex: 'email',
        key: 'email',
      }];
    return columns;
  }

  render() {
    const { catalogs: { subscriptions }, isLoading, t } = this.props;
    const expandedRowRender = (record) => {
      const columns = [
        {
          title: this.props.t('users'),
          dataIndex: 'usersPurchased',
          key: 'usersPurchased',
          // render: text => moment(text).format('DD/MM/YYYY'),
        },
        {
          title: this.props.t('initial_date'),
          dataIndex: 'billingCycleStart',
          key: 'billingCycleStart',
          render: text => moment(text).format('DD/MM/YYYY'),
        },
        {
          title: this.props.t('final_date'),
          key: 'billingCycleEnd',
          dataIndex: 'billingCycleEnd',
          render: text => moment(text).format('DD/MM/YYYY'),
        },
        {
          title: this.props.t('type'),
          key: 'type',
          dataIndex: 'type',
          render: (text, rcord) => {
            const subscriptionTypesLabels = {
              monthly: { label: this.props.t('monthly') },
              year: { label: this.props.t('anual') },
              free: { label: this.props.t('free') },
            };
            const type = subscriptionTypesLabels[rcord.type].label;

            return type;
          },
        },
        {
          title: this.props.t('status'),
          key: 'status',
          dataIndex: 'status',
          render: (text, rcord) => {
            const statusTypesLabels = {
              active: { label: this.props.t('active'), color: 'green' },
              canceled: { label: this.props.t('canceled'), color: 'volcano' },
              expired: { label: this.props.t('expired'), color: null },
              payment_failed: { label: this.props.t('payment_failed'), color: 'magenta' },
            };
            const type = statusTypesLabels[rcord.status];
            return <Tag color={type.color}>{type.label}</Tag>;
          },
        },
        {
          title: this.props.t('actions'),
          key: 'actions',
          align: 'center',
          render: (text, rcord) => (
            <div>{this.getActionsButton(rcord)}</div>
          ),
        },
      ];

      return (<Table
        rowKey="id"
        columns={columns}
        dataSource={this.getFiltersSubscriptions(subscriptions, record)}
        pagination={false}
      />);
    };

    return (
      <div>
        <HeaderContent
          onChangeValueFilter={this.onChangeValueFilter}
          onSelectedStatus={this.onSelectedStatus}
        />
        <Table
          ref={(table) => { this.table = table; }}
          rowKey="id"
          dataSource={this.getFilteredCompanies()}
          expandedRowRender={expandedRowRender}
          columns={this.getColumns()}
          size="large"
          locale={{ emptyText: t('show_no_data') }}
          loading={isLoading}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ companiesContainer }) => companiesContainer;

export default withTranslation()(connect(mapStateToProps, actions)(Companies));

