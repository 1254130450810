/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:46:03 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-10-21 15:49:55
 */
const CONTAINER_KEY = 'EVALUATIONS_CONTAINER';

export const REGISTER_EVALUATION = `${CONTAINER_KEY}_REGISTER_EVALUATION`;
export const UPDATE_EVALUATION = `${CONTAINER_KEY}_UPDATE_EVALUATION`;
export const DELETE_EVALUATION = `${CONTAINER_KEY}_DELETE_EVALUATION`;
export const OPEN_MODAL = `${CONTAINER_KEY}_OPEN_MODAL`;
export const CLOSE_MODAL = `${CONTAINER_KEY}_CLOSE_MODAL`;
