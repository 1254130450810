/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-27 12:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-08-27 15:25:06
 */

import React from 'react';
import { Row, Col, Button, Icon, Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { AvailableUsers } from '../../../../common/components/widgets';

const { Search } = Input;
const { Option } = Select;

const HeaderContent = ({
  dataBranch, filters, dataRole, clearFilters,
  onCreate, disableButton, onChangeFilter, onSearchFilter,
  onChangeValueFilter, purchasedUsers, availableUsers,
}) => {
  const { t } = useTranslation();

  return (
    <div className="headerContainer">
      <Row className="filtersRow">
        <Col xs={24} sm={12} md={8} lg={8} xl={5} className="filter-box">
          <h1 className="textBranch">{t('searcher')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
          <Search
            className="select-data"
            value={filters.value}
            onChange={onChangeValueFilter}
            onSearch={onSearchFilter}
            enterButton
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={5} className="filter-box">
          <h1 className="textBranch">{t('role')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
          <Select
            value={filters.roleId}
            className="select-data"
            onChange={val => onChangeFilter('roleId', val)}
          >
            <Option value={null}>{t('all_plural')}</Option>
            {dataRole.map(i => <Option key={i.id} value={i.id}>{i.name}</Option>)}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8} lg={8} xl={5} className="filter-box">
          <h1 className="textBranch">
            {t('branch_office')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} />
          </h1>
          <Select
            value={filters.branchOfficeId}
            className="select-data"
            onChange={val => onChangeFilter('branchOfficeId', val)}
          >
            <Option value={null}>{t('all_plural')}</Option>
            {dataBranch.map(i => <Option key={i.id} value={i.id}>{i.name}</Option>)}
          </Select>
          <div
            className="clear-filters"
            role="link"
            tabIndex={0}
            onKeyUp={null}
            onClick={clearFilters}
          >
            {t('clean_filters')}
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={6} className="filter-box">
          <Row>
            <AvailableUsers purchasedUsers={purchasedUsers} availableUsers={availableUsers} />
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={3}>
          <Row justify="end" type="flex" style={{ marginTop: '15px' }} >
            <Button type="primary" onClick={onCreate} disabled={disableButton}>{t('new_user')}</Button>
          </Row>
        </Col>
      </Row>
    </div>
    );
  };

export { HeaderContent };
