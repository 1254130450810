/*
 * @Author: Cesar Medina
 * @Date: 2019-04-25 10:38:52 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-04-25 16:29:56
 */

const formatCardData = (form) => {
  const {
    name, cardNumber, cardCVC, expMonth, expYear,
  } = form;

  return {
    card: {
      number: cardNumber,
      name,
      exp_year: expYear,
      exp_month: expMonth,
      cvc: cardCVC,
    },
  };
};

export { formatCardData };

