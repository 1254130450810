/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-08 17:10:47 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-02-11 21:52:47
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  REGISTER_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  TOGGLE_MODAL,
  RESET_TASK,
  TOGGLE_RULE_FORM,
  REGISTER_TASK_GROUP,
  RESET_TASK_GROUP_FORM,
  TOGGLE_MODAL_TASK_GROUP,
  UPDATE_TASK_GROUP,
  DELETE_TASK_GROUP,
  UPDATE_FORM,
} from './types';
import { FETCH_TASKS_FULFILLED, FETCH_EVALUATIONS_FULFILLED, FETCH_RULES_BY_NAME_FULFILLED, FETCH_TASK_GROUPS_FULFILLED } from '../../../store/general/types';
import * as generalActions from '../../../store/general/actions';

const defaultTask = {
  id: null,
  value: '',
  name: '',
  type: 'photo',
  enumValues: '',
  evidenceType: 'required',
  keepValue: false,
  tracking: false,
  evaluation: null,
  additionalInfo: null,
  repeat: false,
};

const defaultTaskGroupForm = {
  name: '',
  repetitive: false,
  checklist: [],
};

const defaultState = {
  reducerKey: 'tasksContainer',
  catalogs: {
    tasks: [],
    users: [],
    taskGroups: [],
    evaluations: [],
    rules: {
      content: [],
      size: 100,
      number: 0,
      totalElements: 1,
    },
  },
  form: {
    ...defaultTaskGroupForm,
  },
  filters: {
    type: null,
    evidenceType: null,
    value: null,
    includeTaskitProccess: true,
  },
  showModalTask: false,
  showModalGroup: false,
  task: { ...defaultTask },
  errorMessage: '',
  currentPage: 1,
  createForm: false,
  activePanel: [],
};

// Actions
// Payload { form, defaultActions, navigate }
const registerTask = (form, filters) => ({ type: REGISTER_TASK, form, filters });
const updateTask = (form, filters) => ({ type: UPDATE_TASK, form, filters });
const deleteTask = (id, filters) => ({ type: DELETE_TASK, id, filters });
const toggleModal = payload => ({ type: TOGGLE_MODAL, payload });
const toggleModalTaskGroup = payload => ({ type: TOGGLE_MODAL_TASK_GROUP, payload });
const resetTask = payload => ({ type: RESET_TASK, payload });
const updateForm = payload => ({ type: UPDATE_FORM, payload });
const registerTaskGroup = (form, filters) => ({ type: REGISTER_TASK_GROUP, form, filters });
const updateTaskGroup = (form, filters) => ({ type: UPDATE_TASK_GROUP, form, filters });
const resetTaskGroupForm = payload => ({ type: RESET_TASK_GROUP_FORM, payload });
const deleteTaskGroup = (id, filters) => ({ type: DELETE_TASK_GROUP, id, filters });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [TOGGLE_MODAL]: (state, payload) =>
    update(state, { path: 'showModalTask', value: payload }),
  [TOGGLE_MODAL_TASK_GROUP]: state =>
    update(state, { path: 'showModalGroup', value: !state.showModalGroup }),
  [RESET_TASK]: state =>
    update(state, { path: 'task', value: { ...defaultTask } }),
  [FETCH_TASKS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.tasks', value: payload }),
  [FETCH_TASK_GROUPS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.taskGroups', value: payload }),
  [FETCH_EVALUATIONS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.evaluations', value: payload.content }),
  [FETCH_RULES_BY_NAME_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.rules', value: payload }),
  [TOGGLE_RULE_FORM]: (state, payload) => ({
    ...state,
    created: payload.rule,
    createForm: payload.show,
  }),
  [UPDATE_FORM]: (state, payload) =>
    update(state, { path: `form.${payload.prop}`, value: payload.value }),
  [RESET_TASK_GROUP_FORM]: state =>
    update(state, { path: 'form', value: { ...defaultTaskGroupForm } }),
});

const actions = {
  ...defaultActions,
  ...generalActions,
  registerTask,
  updateTask,
  deleteTask,
  toggleModal,
  resetTask,
  updateForm,
  registerTaskGroup,
  resetTaskGroupForm,
  toggleModalTaskGroup,
  updateTaskGroup,
  deleteTaskGroup,
};

export { reducer, actions, epics, defaultActions };
