/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 15:46:25 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-01-24 13:55:05
 */

import { omit } from 'lodash';

const formatUpdateForm = (target, catalogs) => {
  const role = target.role && catalogs.roles.find(itemRole =>
    target.role === itemRole.id || target.role.id === itemRole.id);
  const branchOffice = target.branchOffice && catalogs.branchOffices.find(itemBranch =>
    target.branchOffice === itemBranch.id || target.branchOffice.id === itemBranch.id);
  return {
    ...target, role, branchOffice,
  };
};

const formatRegisterForm = (target, catalogs) => {
  const role = catalogs.roles.find(itemRole =>
    target.role === itemRole.id || target.role.id === itemRole.id);
  const branchOffice = catalogs.branchOffices.find(itemBranch =>
    target.branchOffice === itemBranch.id || target.branchOffice.id === itemBranch.id);
  const form = {
    ...target, role, branchOffice,
  };
  return omit(form, ['id', 'editable']);
};

export { formatUpdateForm, formatRegisterForm };
