/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-23 14:57:37 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-06 10:42:19
 */
import React from 'react';
import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';


import './styles.css';

const EditButtons = ({
  creating, onEdit, onDelete, onSendEmail, onDeleteTitle, customButtons, onUserPermissions = [],
}) => {
  const { t } = useTranslation();
  return (creating ?
    <div />
    :
    <span>
      <Button className="actionButton action-button--edit" icon="edit" onClick={onEdit} />
      {onSendEmail &&
        <span>
          <Popconfirm
            title={t('register_password')}
            onConfirm={onSendEmail}
            cancelText={t('no')}
            okText={t('yes')}
            placement="left"
          >
            <Button className="actionButton action-button--edit" icon="mail" />
          </Popconfirm>
          <Button className="actionButton action-button--permission" icon="key" onClick={onUserPermissions} />
        </span>
      }
      {onDelete &&
        <Popconfirm title={onDeleteTitle || t('delete_confirmation')} onConfirm={onDelete} cancelText={t('no')} okText={t('yes')}>
          <Button className="actionButton action-button--delete" icon="delete" />
        </Popconfirm>
      }
      {customButtons &&
        customButtons.map(b => <Button className="actionButton action-button--delete" key={b.key} icon={b.icon} onClick={b.onClick} />)
      }
    </span>);
  };

const ActionsCell = ({
  editable, onSave, onCancel, onEdit, onDelete, creating, onSendEmail,
  onDeleteTitle, customButtons, onUserPermissions,
}) => {
  const { t } = useTranslation();
  return (
    <div className="editable-row-operations">
      {
        editable ?
          <span>
            <Button className="actionButton action-button--save" icon="check" onClick={onSave} />
            <Popconfirm title={t('cancel_confirmation')} onConfirm={onCancel} cancelText={t('no')} okText={t('yes')} >
              <Button className="actionButton action-button--cancel" icon="close" />
            </Popconfirm>
          </span>
          :
          <EditButtons
            creating={creating}
            onEdit={onEdit}
            onDelete={onDelete}
            onDeleteTitle={onDeleteTitle}
            onSendEmail={onSendEmail}
            customButtons={customButtons}
            onUserPermissions={onUserPermissions}
          />
      }
    </div>
  );
};

export default ActionsCell;
