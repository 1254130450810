/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:47:00 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-12-27 11:47:19
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './';
import {
  REGISTER_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  REGISTER_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  DOWNLOAD_CONTACTS_IN_CSV,
  UPLOAD_CONTACTS_IN_CSV,
} from './types';

import ContactService from '../../../services/contacts';
import GroupService from '../../../services/groups';
import { parseApiError } from '../../../common/error';
import { Notification } from '../../../common/components/widgets';

const contactService = new ContactService();
const groupService = new GroupService();

const registerSuccessAction = (contact) => {
  Notification.success(i18n.t('success'), `${contact.name} ${i18n.t('registered_success')}`, 4);
  return { type: '' };
};

const registerCSVSuccessAction = (contacts) => {
  Notification.success(i18n.t('success'), `${contacts.length} ${i18n.t('contacts')} ${i18n.t('registered_success')}`, 4);
  return { type: '' };
};

const updateSuccessAction = (contact) => {
  Notification.success(i18n.t('success'), `${contact.name} ${i18n.t('updated_success')}`, 4);
  return { type: '' };
};

const deleteSuccessAction = (contact) => {
  Notification.success(i18n.t('success'), `${contact} ${i18n.t('deleted_success')}`, 4);
  return { type: '' };
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const registerContactEpic = action$ =>
  action$.ofType(REGISTER_CONTACT).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(contactService.createContact(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.fetchContacts(action.filters)),
          ))
        .do({
          complete: () => {
            action.callback();
          },
        })
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateContactEpic = action$ =>
  action$.ofType(UPDATE_CONTACT).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(contactService.updateContact(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(updateSuccessAction(response)),
            Observable.of(actions.fetchContacts(action.filters)),
          ))
        .do({
          complete: () => {
            action.callback();
          },
        })
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteContactEpic = action$ =>
  action$.ofType(DELETE_CONTACT).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(contactService.deleteContact(action.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(deleteSuccessAction(response)),
            Observable.of(actions.fetchContacts(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const registerGroupEpic = action$ =>
  action$.ofType(REGISTER_GROUP).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(groupService.createGroup(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.closeGroupModal()),
            Observable.of(actions.resetGroupForm()),
            Observable.of(actions.resetFilters()),
            Observable.of(registerSuccessAction(response)),
            Observable.of(actions.fetchGroups()),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateGroupEpic = action$ =>
  action$.ofType(UPDATE_GROUP).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(groupService.updateGroup(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.closeGroupModal()),
            Observable.of(actions.resetGroupForm()),
            Observable.of(updateSuccessAction(response)),
            Observable.of(actions.fetchGroups(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteGroupEpic = action$ =>
  action$.ofType(DELETE_GROUP).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(groupService.deleteGroup(action.id))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(deleteSuccessAction(response)),
            Observable.of(actions.fetchGroups(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const downloadContactsInCsv = action$ =>
  action$.ofType(DOWNLOAD_CONTACTS_IN_CSV).flatMap(() =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(contactService.getAllInCSVFormat())
        .flatMap(() =>
          Observable.concat(Observable.of(actions.loading(false))))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const uploadContactsInCsv = action$ =>
  action$.ofType(UPLOAD_CONTACTS_IN_CSV).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(contactService.createContactsWithCSV(action.form))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(registerCSVSuccessAction(response)),
            Observable.of(actions.fetchContacts(action.filters)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

export default combineEpics(
  registerContactEpic,
  updateContactEpic,
  deleteContactEpic,
  registerGroupEpic,
  updateGroupEpic,
  deleteGroupEpic,
  downloadContactsInCsv,
  uploadContactsInCsv,
);
