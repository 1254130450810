import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import {
  FETCH_BRANCH_OFFICES_FULFILLED,
  FETCH_ROLES_FULFILLED,
  FETCH_RULES_FULFILLED,
  SEARCH_USERS,
  SEARCH_USERS_FULFILLED,
  FETCH_TASKS_FULFILLED,
  FETCH_EVALUATIONS_FULFILLED,
  FETCH_USERS_FULFILLED,
} from '../../../store/general/types';
import {
  FETCH_RULES,
  REGISTER_RULE,
  RULE_REGISTERED,
  UPDATE_RULE,
  DELETE_RULE,
  LOAD_FORM,
  TOGGLE_RULE_FORM,
  CHANGE_TAB,
  FETCH_TASKIT_PROCESSES,
  FETCH_TASKIT_PROCESSES_FULLFILLED,
  SWITCH_ENABLE_TASKIT_PROCESS,
  UPDATE_TASKIT_PROCESS,
} from './types';
import * as generalActions from '../../../store/general/actions';
import { RuleTypes } from '../../../common/constants';

const defaultState = {
  reducerKey: 'taskitProcessesContainer',
  taskitProcess: null,
  catalogs: {
    branchOffices: [],
    roles: [],
    rules: [],
    tasks: [],
    evaluations: {},
    users: [],
    taskitProcesses: [],
  },
  registeredFulfilled: false,
  filters: {
    branchOfficeId: '',
    roleId: '',
    ruleType: RuleTypes.SCHEDULED,
  },
  visibleForm: false,
  registering: false,
  searchedUsers: [],
  fetchingSelectData: false,
  created: {},
  createForm: false,
  loading: false,
};

// Actions
// Payload { form, defaultActions }
const fetchRules = payload => ({ type: FETCH_RULES, payload });
const registerRule = (form, filters) => ({ type: REGISTER_RULE, form, filters });
const ruleRegistered = payload => ({ type: RULE_REGISTERED, payload });
const updateRule = (form, filters) => ({ type: UPDATE_RULE, form, filters });
const deleteRule = (payload, filters) => ({ type: DELETE_RULE, payload, filters });
const loadForm = id => ({ type: LOAD_FORM, id });
const toggleRuleForm = (rule, show) => ({ type: TOGGLE_RULE_FORM, payload: { rule, show } });
const changeTab = filters => ({ type: CHANGE_TAB, payload: { filters } });
const fetchTaskitProcesses = filters => ({ type: FETCH_TASKIT_PROCESSES, payload: { filters } });
const fetchTaskitProcessesFullfilled = payload => ({
  type: FETCH_TASKIT_PROCESSES_FULLFILLED, payload,
});
const switchEnableTaskitProcess = form => ({ type: SWITCH_ENABLE_TASKIT_PROCESS, form });
const updateTaskitProcess = payload => ({ type: UPDATE_TASKIT_PROCESS, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_BRANCH_OFFICES_FULFILLED]: (state, payload) => ({
    ...state,
    catalogs: { ...state.catalogs, branchOffices: payload },
    filters: {
      ...state.filters,
      branchOfficeId: payload.length ? payload[0].id : '',
    },
  }),
  [FETCH_ROLES_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.roles', value: payload }),
  [FETCH_RULES_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.rules', value: payload }),
  [RULE_REGISTERED]: (state, payload) => ({
    ...state, visibleForm: payload, registering: payload, created: {},
  }),
  [TOGGLE_RULE_FORM]: (state, payload) => ({
    ...state,
    created: payload.rule,
    createForm: payload.show,
  }),
  [SEARCH_USERS]: state => ({ ...state, fetchingSelectData: true }),
  [SEARCH_USERS_FULFILLED]: (state, payload) => ({
    ...state,
    searchedUsers: payload,
    fetchingSelectData: false,
  }),
  [FETCH_TASKS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.tasks', value: payload }),
  [FETCH_EVALUATIONS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.evaluations', value: payload }),
  [FETCH_USERS_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.users', value: payload }),
  [CHANGE_TAB]: (state, payload) => ({
    ...state,
    filters: payload.filters,
  }),
  [FETCH_TASKIT_PROCESSES_FULLFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.taskitProcesses', value: payload }),
  [UPDATE_TASKIT_PROCESS]: (state, payload) =>
    update(state, { path: 'taskitProcess', value: payload }),
});

const actions = {
  ...generalActions,
  ...defaultActions,
  fetchRules,
  registerRule,
  ruleRegistered,
  updateRule,
  deleteRule,
  loadForm,
  toggleRuleForm,
  changeTab,
  fetchTaskitProcesses,
  fetchTaskitProcessesFullfilled,
  switchEnableTaskitProcess,
  updateTaskitProcess,
};

export { epics, reducer, actions, defaultActions };
