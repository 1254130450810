/*
 * @Author: Jhony Reyes 
 * @Date: 2018-12-05 11:36:59 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-04-29 13:19:14
 */

import axios from 'axios';
import Service from '../Service';
import { createCoreServiceUrl, parseParams } from '../../common/helpers/service';


class GroupService extends Service {
  getById = id => axios.get(createCoreServiceUrl(`groups/${id}`)).then(data => data.data);
  createGroup = form => axios.post(createCoreServiceUrl('groups'), form).then(data => data.data);
  updateGroup = form => axios.put(createCoreServiceUrl('groups'), form).then(data => data.data);
  deleteGroup = id => axios.delete(createCoreServiceUrl(`groups/${id}`)).then(data => data.data);
  getAll = params => axios.get(createCoreServiceUrl(`groups${parseParams(params)}`))
    .then(data => data.data);
}

export default GroupService;
