/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-13 16:15:33 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2019-01-24 21:57:45
 */

const fetchActions = (props) => {
  const { fetchOrders } = props;
  fetchOrders();
};

export default fetchActions;
