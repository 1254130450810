/*
 * @Author: Misael Jiménez 
 * @Date: 2020-07-09 17:17:23 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-14 22:59:00
 */

import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';
import UserService from '../../../../../services/user';
import CompanyService from '../../../../../services/company';
import WebHookService from '../../../../../services/webHook';
import { actions } from './index';
import { UPDATE_USER, UPDATE_USER_WITH_PASSWORD, UPDATE_USER_IMAGE, UPDATE_COMPANY_LOGO_IMAGE, UPDATE_COMPANY_HEADER_IMAGE, FETCH_WEB_HOOKS, CREATE_WEB_HOOK, UPDATE_WEB_HOOK, DELETE_WEB_HOOK } from './types';
import { parseApiError } from '../../../../error';
import { Notification } from '../../../widgets';

const userService = new UserService();
const companyService = new CompanyService();
const webHookService = new WebHookService();

const registerSuccessAction = () => {
  Notification.success(i18n.t('success'), i18n.t('web_hook_registered'), 4);
  return { type: '' };
};

const errorMessage = (error) => {
  Notification.error(error.message);
};

const updateSuccessAction = (user, navigate) => {
    Notification.success(`${user.name} ${i18n.t('updated_success')}`);
    navigate();
  };

const updateSuccessImage = () => {
    Notification.success(`${i18n.t('image')} ${i18n.t('updated_success_fem_short')}`);
  };

const updateUserEpic = action$ =>
  action$.ofType(UPDATE_USER)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(userService.update(action.payload.form))
          .flatMap(response =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.resetState()),
              Observable.of(actions.showMessage(() =>
                updateSuccessAction(response, action.payload.navigate))),
              Observable.of(actions.fetchUserInfoByToken()),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                Notification.error(parsedError.message);
              })),
            )),
      ));

const updateUserWithPasswordEpic = action$ =>
  action$.ofType(UPDATE_USER_WITH_PASSWORD)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(userService.updateWithPassword(
          action.payload.form,
          action.payload.password,
        ))
          .flatMap(response =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.resetState()),
              Observable.of(actions.showMessage(() =>
                updateSuccessAction(response, action.payload.navigate))),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                Notification.error(parsedError.message);
              })),
            )),
      ));

const updateUserImageEpic = action$ =>
  action$.ofType(UPDATE_USER_IMAGE)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(userService.updateImage(action.payload.id, action.payload.form))
          .flatMap(() =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.fetchUserInfoByToken()),
              Observable.of(actions.showMessage(updateSuccessImage)),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                Notification.error(parsedError.message);
              })),
            )),
      ));

const updateCompanyLogoImageEpic = action$ =>
  action$.ofType(UPDATE_COMPANY_LOGO_IMAGE)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(companyService.updateLogoImage(
          action.payload.id,
          action.payload.form,
        ))
          .flatMap(() =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.fetchUserInfoByToken()),
              Observable.of(actions.showMessage(updateSuccessImage)),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                Notification.error(parsedError.message);
              })),
            )),
      ));

const updateCompanyHeaderImageEpic = action$ =>
  action$.ofType(UPDATE_COMPANY_HEADER_IMAGE)
    .flatMap(action =>
      Observable.concat(
        Observable.of(actions.loading(true)),
        Observable.fromPromise(companyService.updateHeaderImage(
          action.payload.id,
          action.payload.form,
        ))
          .flatMap(() =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.fetchUserInfoByToken()),
              Observable.of(actions.showMessage(updateSuccessImage)),
            ))
          .catch(error =>
            Observable.concat(
              Observable.of(actions.loading(false)),
              Observable.of(actions.showMessage(() => {
                const parsedError = parseApiError(error);
                Notification.error(parsedError.message);
              })),
            )),
      ));

  const fetchWebHooksEpic = action$ =>
      action$.ofType(FETCH_WEB_HOOKS)
        .flatMap(() =>
          Observable.concat(
            Observable.of(actions.loading(true)),
            Observable.fromPromise(webHookService.getAll())
              .flatMap(response =>
                Observable.concat(
                  Observable.of(actions.loading(false)),
                  Observable.of(actions.fetchWebHooksFullfilled(response)),
                ))
              .catch(() =>
                Observable.concat(
                  Observable.of(actions.loading(false)),
                  Observable.of(actions.showMessage(() => {
                    Notification.error('No se pudo cargar la lista de Web Hooks.', i18n.t('verify_server_conection'));
                  })),
                )),
          ));

const createWebHookEpic = action$ =>
  action$.ofType(CREATE_WEB_HOOK).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(webHookService.postWebHook(action.payload.form))
        .flatMap(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(registerSuccessAction()),
            Observable.of(actions.fetchWebHooks()),
            Observable.of(actions.webHookCreated()),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const updateWebHookEpic = action$ =>
  action$.ofType(UPDATE_WEB_HOOK).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(webHookService.putWebHook(action.payload.form))
        .flatMap(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchWebHooks()),
            Observable.of(actions.updateWebHookFullfilled()),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

const deleteWebHookEpic = action$ =>
  action$.ofType(DELETE_WEB_HOOK).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(webHookService.deleteWebHook(action.payload.form))
        .flatMap(() =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.fetchWebHooks()),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.loading(false)),
            Observable.of(actions.showMessage(() => {
              const parsedError = parseApiError(error);
              errorMessage(parsedError);
            })),
          )),
    ));

export default combineEpics(
    updateUserEpic,
    updateUserWithPasswordEpic,
    updateUserImageEpic,
    updateCompanyLogoImageEpic,
    updateCompanyHeaderImageEpic,
    fetchWebHooksEpic,
    createWebHookEpic,
    updateWebHookEpic,
    deleteWebHookEpic,
);
