import React from 'react';

const RastreoSVG = () => (
  <svg width="1em" height="1em" viewBox="0 0 48 33" fill="currentColor">
    <path d="M16.3643 32.25C17.3313 32.25 18.2782 32.1628 19.1975 31.9958L19.7416 31.897L19.3079 31.5538C17.5633 30.1731 16.2523 28.495 15.8213 27.8386L15.7528 27.7344L15.6284 27.7263C9.75832 27.3475 5.11426 22.466 5.11426 16.5C5.11426 10.2868 10.1511 5.25 16.3643 5.25C22.5775 5.25 27.6143 10.2868 27.6143 16.5C27.6143 18.4938 27.0958 20.3658 26.1866 21.9892L26.0923 22.1576L26.2312 22.2914C26.5321 22.5812 27.0732 23.0105 27.7355 23.376C28.3963 23.7405 29.1974 24.0524 30.0137 24.0832L30.1694 24.0891L30.2433 23.9519C31.437 21.7331 32.1143 19.1953 32.1143 16.5C32.1143 7.80152 25.0627 0.75 16.3643 0.75C7.66578 0.749998 0.61426 7.80151 0.614258 16.5C0.614258 25.1985 7.66577 32.25 16.3643 32.25Z" />
    <path d="M31.3643 0.75C30.3972 0.75 29.4503 0.837185 28.531 1.00419L27.987 1.10303L28.4206 1.4462C30.1652 2.82689 31.4762 4.50497 31.9072 5.16142L31.9757 5.26564L32.1001 5.27367C37.9702 5.65255 42.6143 10.534 42.6143 16.5C42.6143 22.7132 37.5775 27.75 31.3643 27.75C25.1511 27.75 20.1143 22.7132 20.1143 16.5C20.1143 14.5062 20.6327 12.6342 21.542 11.0108L21.6362 10.8424L21.4973 10.7086C21.1965 10.4188 20.6554 9.98946 19.993 9.62404C19.3322 9.2595 18.5311 8.94759 17.7148 8.91677L17.5591 8.91089L17.4852 9.04815C16.2915 11.2669 15.6143 13.8047 15.6143 16.5C15.6143 25.1985 22.6658 32.25 31.3643 32.25C40.0627 32.25 47.1143 25.1985 47.1143 16.5C47.1143 7.80152 40.0627 0.75 31.3643 0.75Z" />
  </svg>
);

export default RastreoSVG;
