/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-20 15:40:34 
 * @Last Modified by: Jhony Reyes
 * @Last Modified time: 2018-12-06 16:48:07
 */

const CONTAINER_KEY = 'REGISTER_USER_CONTAINER';
export const FETCH_TOKEN = `${CONTAINER_KEY}_FETCH_TOKEN`;
export const FETCH_TOKEN_SUCCESSFUL = `${FETCH_TOKEN}_SUCCESSFUL`;
export const RESET_PASSWORD = `${CONTAINER_KEY}_RESET_PASSWORD`;
export const RESETING_PASSWORD = `${CONTAINER_KEY}_RESETING_PASSWORD`;
export const RESET_PASSWORD_SUCCESSFUL = `${RESET_PASSWORD}_SUCCESSFUL`;
export const REGISTER = `${CONTAINER_KEY}_REGISTER`;
export const REGISTER_SUCCESSFUL = `${REGISTER}_SUCCESSFUL`;
export const FETCH_COMPANY_AVAILABILITY = `${CONTAINER_KEY}FETCH_COMPANY_AVAILABILITY`;
export const FETCH_COMPANY_AVAILABILITY_FULFILLED = `${FETCH_COMPANY_AVAILABILITY}_FULFILLED`;
export const REGISTER_SUCCESSFUL_RESET = `${REGISTER_SUCCESSFUL}_RESET`;
