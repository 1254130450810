/*
 * @Author: Jhony Reyes 
 * @Date: 2018-12-05 11:36:59 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-03-10 15:49:37
 */

import axios from 'axios';
import { orderBy } from 'lodash';
import Service from '../Service';
import { createAuthenticationServiceUrl, createPaymentServiceUrl } from '../../common/helpers/service';

const formatData = users => orderBy(users, ['createdAt'], ['desc']);

class CompanyService extends Service {
    getAll = () => axios.get(createAuthenticationServiceUrl('companies')).then(data => formatData(data.data));
    getAvailability = companyName => axios.get(createAuthenticationServiceUrl(`companies/available?name=${companyName}`)).then(data => data.data);
    registerCompanyAndUser = form => axios.post(createAuthenticationServiceUrl('companies/register'), form).then(data => data.data);
    renewSubscriptions = (subscriptionId, status, billingCycleEnd) =>
        axios.put(createPaymentServiceUrl(`subscriptions/${subscriptionId}/renew`), { status, billingCycleEnd }).then(data => data.data);
    updateLogoImage = (id, form) => axios.post(createAuthenticationServiceUrl(`companies/${id}/logoImage`), form).then(data => data.data);
    updateHeaderImage = (id, form) => axios.post(createAuthenticationServiceUrl(`companies/${id}/headerImage`), form).then(data => data.data);
}

export default CompanyService;
