/*
 * @Author: Cesar Medina
 * @Date: 2018-07-23 09:55:17 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-03-18 16:24:23
 */
import { createReducer, update } from 'aleonor-redux-helpers';
import epics from './epics';
import { FETCH_EXECUTION_TIMELINE, FETCH_EXECUTION_TIMELINE_FULFILLED } from './types';
import * as generalActions from '../../../store/general/actions';

const defaultState = {
  reducerKey: 'tasksTimelineContainer',
  catalogs: {
    executions: [],
  },
  forbidden: false,
};

// Actions
// Payload {form, navigate}
const fetchExecutionTimeline = id => ({ type: FETCH_EXECUTION_TIMELINE, id });
const fetchExecutionTimelineFulfilled = payload => (
  { type: FETCH_EXECUTION_TIMELINE_FULFILLED, payload }
);

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
  [FETCH_EXECUTION_TIMELINE_FULFILLED]: (state, payload) =>
    update(state, { path: 'catalogs.executions', value: payload }),
});

const actions = {
  ...generalActions,
  ...defaultActions,
  fetchExecutionTimeline,
  fetchExecutionTimelineFulfilled,
};

export {
  epics,
  reducer,
  actions,
  defaultActions,
};
