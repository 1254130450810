/*
 * @Author: Jhony Reyes 
 * @Date: 2018-04-20 12:17:02 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2021-07-15 13:56:15
 */
import React from 'react';
import { Input, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ErrorText from './errorText';


const EditableCell = ({
  editable, value, onChange, error, errorMessage, maxLength, required, placeholder, maxWidth,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ maxWidth }}>
      {editable ?
        <div>
          <div className="inputContainer">
            {required &&
              <Tooltip title={t('field_required')} placement="topLeft">
                <span className="inputContainer__span">*</span>
              </Tooltip>}
            <Input
              value={value}
              onChange={e => onChange(e.target.value)}
              maxLength={maxLength}
              placeholder={placeholder}
            />
          </div>
          <ErrorText message={error ? errorMessage : null} />
        </div>
        : value
      }
    </div>
  );
};

EditableCell.propTypes = {
  editable: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

EditableCell.defaultProps = {
  editable: false,
  value: '',
  onChange: () => {},
  error: false,
  errorMessage: '',
};

export default EditableCell;
