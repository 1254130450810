/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-12 23:47:00 
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2023-11-23 11:56:53
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import i18n from 'i18next';

import { actions } from './index';
import { FETCH_COMPANIES, FETCH_SUBSCRIPTIONS, UPDATE_STATUS } from './types';
import CompanyService from '../../../services/company';
import PaymentService from '../../../services/payment';
import { Notification } from '../../../common/components/widgets';
import { parseApiError } from '../../../common/error';

const companyService = new CompanyService();
const paymentService = new PaymentService();

const errorFetchCompanies = () => {
  Notification.error(i18n.t('company_list_fail'));
};

const errorMessage = (error) => {
  const parsedError = parseApiError(error);
  Notification.error(parsedError.message);
};

const fetchCompaniesEpic = action$ =>
  action$.ofType(FETCH_COMPANIES).flatMap(action =>
    Observable.concat(
      Observable.of(action.payload.loading(true)),
      Observable.fromPromise(companyService.getAll())
        .flatMap(response =>
          Observable.concat(
            Observable.of(action.payload.loading(false)),
            Observable.of(actions.fetchCompaniesFulfilled(response)),
          ))
        .catch(() =>
          Observable.concat(
            Observable.of(action.payload.loading(false)),
            Observable.of(action.payload.showMessage(() => {
              errorFetchCompanies();
            })),
          )),
    ));

const fetchSubscriptionsEpic = action$ =>
  action$.ofType(FETCH_SUBSCRIPTIONS).flatMap(() =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(paymentService.getAllSubscriptions())
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.fetchSubscriptionsFulfilled(response)),
            Observable.of(actions.loading(false)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.showMessage(() => errorMessage(error))),
            Observable.of(actions.loading(false)),
          )),
    ));

const renewSubscriptionsEpic = action$ =>
  action$.ofType(UPDATE_STATUS).flatMap(action =>
    Observable.concat(
      Observable.of(actions.loading(true)),
      Observable.fromPromise(paymentService
        .renewSubscriptions(action.subscriptionId, action.status, action.billingCycleEnd))
        .flatMap(response =>
          Observable.concat(
            Observable.of(actions.fetchSubscriptions(response)),
            Observable.of(actions.showMessage(() => {
              Notification.success(i18n.t('success'), i18n.t('subscription_renew'), 3.5);
            })),
            Observable.of(actions.loading(false)),
          ))
        .catch(error =>
          Observable.concat(
            Observable.of(actions.showMessage(() => errorMessage(error))),
            Observable.of(actions.loading(false)),
          )),
    ));
export default combineEpics(
  fetchCompaniesEpic,
  fetchSubscriptionsEpic,
  renewSubscriptionsEpic,
);
