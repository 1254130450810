/*
 * @Author: Jhony Reyes 
 * @Date: 2018-08-27 12:47:00 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2019-10-30 12:19:31
 */
import React from 'react';
import { Row, Col, Button, Icon, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { EvaluationTypes } from '../../../../common/constants';

const { Option } = Select;

const getTypeLabel = (type) => {
  switch (type) {
    case EvaluationTypes.GRAPHIC:
      return i18n.t('graph');
    case EvaluationTypes.RANGE:
      return i18n.t('range');
    case EvaluationTypes.ENUM:
      return i18n.t('numbering');
    default:
      return '-----';
  }
};

const HeaderContent = ({
  onClickNew,
  selectedType,
  onSelectType,
}) => {
  const { t } = useTranslation();

  return (
    <div className="headerContainer">
      <Row className="filtersRow">
        <Col xs={24} sm={12} md={6}>
          <h1 className="textBranch">{t('type')} <Icon type="filter" style={{ fontSize: '0.6em', color: '#949494' }} /></h1>
          <Select
            value={selectedType}
            style={{ width: '100%', paddingRight: '1em' }}
            onChange={onSelectType}
          >
            <Option value={null}>{t('all_plural')}</Option>
            {
              Object.values(EvaluationTypes)
                .map(val => <Option key={val} value={val}>{getTypeLabel(val)}</Option>)
            }
          </Select>
        </Col>
        <Col xs={24} sm={12} md={18}>
          <Row justify="end" type="flex" style={{ marginTop: '10px' }}>
            <Button type="primary" onClick={onClickNew}>{t('new_evaluation')}</Button>
          </Row>
        </Col>
      </Row>
    </div>
    );
};

export { HeaderContent };

