/*
 * @Author: Jhony Reyes 
 * @Date: 2018-05-07 14:43:45 
 * @Last Modified by: Cesar Medina
 * @Last Modified time: 2020-06-09 18:29:38
 */
import React, { Component } from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import Builder from './builder';
import '../styles.css';

class ChecklistShowBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  done = () => {
    this.setState(state => ({ visible: !state.visible }));
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  render() {
    const { created, t } = this.props;

    return (
      <div>
        <Popover
          content={
            <Builder
              onDone={this.done}
              created={created}
            />}
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          trigger="click"
        >
          <div className="link-button">
            { created ? `${t('tasks')}: ${Object.keys(created).length}` : null }
          </div>
        </Popover>
      </div>
    );
  }
}

ChecklistShowBuilder.propTypes = {
  created: PropTypes.array.isRequired,
};

export default withTranslation()(ChecklistShowBuilder);
